import { showCustomToast } from 'components';
import cookieKeys from 'constants/cookieKeys';

import { errorHandler, useAuth } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { AuthResponse } from 'services/auth/types';
import { setCookies } from 'utils/helpers';

export const useAuthHandlers = () => {
  const { setAuthenticated } = useAuth();
  const navigate = useNavigate();

  const onSuccess = (response: AuthResponse) => {
    const {
      data: {
        token = '',
        roles = [],
        permissions = {},
        manufacturers = [],
        id = '',
        email = '',
        firstName = '',
        lastName = '',
      } = {},
    } = response || {};

    const adminRoleId = (roles.length && roles[0]?.id) || '';
    const adminRoleName = (roles.length && roles[0]?.name) || '';
    const namesOfPermissions = Object.keys(permissions).toString();
    const adminManufacturersIds = manufacturers.map((manufacture) => manufacture.id).toString();
    const adminManufacturersNames = manufacturers.map((manufacture) => manufacture.name).toString();

    const isSuperAdmin = adminRoleName === 'SuperAdmin';

    if (!token) {
      showCustomToast({
        type: 'success',
        message: "Token has not been provided, you're getting redirected to login page",
      });
      setTimeout(() => navigate('/login'), 3000);
      return;
    }

    const {
      TOKEN,
      USER_ID,
      USER_EMAIL,
      PERMISSION,
      USER_LAST_NAME,
      USER_FIRST_NAME,
      USER_ROLE_ID,
      USER_ROLE_NAME,
      USER_MANUFACTURERS_IDS,
      USER_MANUFACTURERS_NAMES,
    } = cookieKeys;

    const cookieValues = {
      [TOKEN]: token,
      [USER_ID]: id,
      [USER_EMAIL]: email,
      [PERMISSION]: namesOfPermissions,

      ...(!isSuperAdmin && {
        [USER_LAST_NAME]: lastName,
        [USER_FIRST_NAME]: firstName,
        [USER_ROLE_ID]: adminRoleId,
        [USER_ROLE_NAME]: adminRoleName,
        [USER_MANUFACTURERS_IDS]: adminManufacturersIds,
        [USER_MANUFACTURERS_NAMES]: adminManufacturersNames,
      }),
    };

    setCookies(cookieValues);
    setAuthenticated(true);
  };

  return { onSuccess, onError: errorHandler };
};
