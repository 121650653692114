import { text } from 'constants/texts';
import { Content } from 'pages/ProgramDetails/programTabComponents/Content/Content';
import { ProgramDetailsUseTabReturnType } from './types';

export const useContentTab = (): ProgramDetailsUseTabReturnType => ({
  tab: {
    title: text['Content'],
    description: text['This is the repository for all of the content in this program'],
    tabData: {
      contentTitle: text['Content'],
      actionsJSX: null,
      contentJSX: <Content />,
    },
    tabKey: 'content',
  },
});
