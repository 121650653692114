import { SVGProps } from 'react';

export const Search = (props: SVGProps<SVGSVGElement>) => (
  <svg width='1em' height='1em' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='m12.122 9.47 4.006 3.83v-.01c.197.187.308.44.308.705 0 .264-.11.518-.308.705-.189.21-.473.3-.736.3-.262 0-.536-.1-.736-.3l-4.027-3.81A6.483 6.483 0 0 1 6.979 12C3.5 12 .67 9.31.67 6S3.5 0 6.98 0c3.48 0 6.31 2.69 6.31 6a5.734 5.734 0 0 1-1.168 3.47ZM2.037 6c0 2.59 2.22 4.7 4.943 4.7 2.723 0 4.942-2.11 4.942-4.7 0-2.59-2.219-4.7-4.942-4.7-2.724 0-4.943 2.11-4.943 4.7Z'
      fill='#787D83'
    />
  </svg>
);
