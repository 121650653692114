import { toBenefitDTO } from 'hooks/api/benefits/mappers';
import { CreateBenefitFields } from 'pages/ProgramDetails/programTabComponents/Benefits/types';
import { useMutation, useQueryClient } from 'react-query';
import { createBenefit } from 'services/benefits/requests';
import { queryKeys } from 'services/constants';
import { Callback } from 'types/helpers';
import { errorHandler } from 'utils/errorHandler';

export const useCreateBenefit = (onSuccess?: Callback) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(createBenefit, {
    mutationKey: queryKeys.createBenefit,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.getBenefits);
      onSuccess?.();
    },
    onError: errorHandler,
  });

  return {
    isLoading,
    createBenefit: (data: CreateBenefitFields) => mutate(toBenefitDTO(data)),
  };
};
