import { Form } from 'antd';
import { ClosableWrapper, Spacer } from 'components';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { ModalActionButtons } from 'components/fields/AppActionButtons/ModalActionButtons';
import { getFieldsJSX } from 'components/fields/utils';
import { text } from 'constants/texts';
import { useCreateMilestoneTracker } from 'hooks/api/milestoneTrackers/useCreateMilestoneTracker';

import { getCreateMilestoneTrackerFields } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/MilestoneTrackers/fields';
import { CreateMilestoneTrackerFields } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/MilestoneTrackers/types';
import { ProgramData } from 'types/programs';
import { stringModifier } from 'utils/strings';

const { useForm } = Form;

type CreateMilestoneTrackerModalProps = {
  setModalRef: (ref: ClosableWrapperRef) => void;
  modalRef: ClosableWrapperRef | undefined;
  program?: ProgramData;
};

export const CreateMilestoneTrackerModal = ({ modalRef, setModalRef, program }: CreateMilestoneTrackerModalProps) => {
  const [form] = useForm<CreateMilestoneTrackerFields>();

  const { createMilestoneTracker, isLoading } = useCreateMilestoneTracker(modalRef?.close);

  return (
    <ClosableWrapper ref={setModalRef}>
      <h1 className='modal-title'>{stringModifier(text['Add a {0}'], text['Milestone Tracker'])}</h1>
      <Spacer height={16} type='flex' />
      <h2 className='modal-description'>
        {stringModifier(text['Add a new milestone tracker to the {0} program.'], program?.programName || '')}
      </h2>
      <Form<CreateMilestoneTrackerFields>
        requiredMark={false}
        preserve={false}
        layout='vertical'
        className='form'
        form={form}
        onFinish={createMilestoneTracker}
      >
        {getFieldsJSX(getCreateMilestoneTrackerFields())}
        <ModalActionButtons isLoading={isLoading} onCancel={modalRef?.close} />
      </Form>
    </ClosableWrapper>
  );
};
