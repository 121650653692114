import { Spacer } from 'components';
import { getFieldsJSX } from 'components/fields/utils';
import { text } from 'constants/texts';
import { getUpdateBenefitFields } from 'pages/ProgramDetails/programTabComponents/Benefits/fields';
import { getFontClassName } from 'utils/getFontClassName';
import { stringModifier } from 'utils/strings';

type BenefitItemProps = {
  isEditMode: boolean;
  orderIndex: number;
};

export const BenefitItem = ({ orderIndex, isEditMode }: BenefitItemProps) => (
  <>
    <Spacer height={24} />
    <h2 className={getFontClassName('h2')}>{stringModifier(text['Phase {0}'], `${orderIndex + 1}`)}</h2>
    {getFieldsJSX(getUpdateBenefitFields(orderIndex), isEditMode)}
  </>
);
