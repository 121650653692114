import { FormInstance } from 'antd';
import { AppFields } from 'components/fields/types';
import { emptyField, fieldModifier } from 'components/fields/utils';
import {
  brandEmblemField,
  contactHoursTextField,
  contactNumberField,
  contactPopupDescriptionField,
  contactPopupTitleField,
  imageSelectUploadConfig,
  importantSafetyInformationLinkField,
  prescriberInformationLinkField,
  primaryColorField,
  programLogoField,
  programManufacturerField,
  programNameField,
  programTypeField,
  secondaryColorField,
  welcomeCardHeaderField,
  welcomeCardImageField,
  welcomeCardTextField,
} from 'pages/ProgramDetails/universalSettingsFields';
import { ProgramData } from 'types/programs';
import { RULES } from 'utils/validations';
import {
  BrandingFields,
  LegalTermsLinksFields,
  ProgramCardFields,
  ProgramContactInformationFields,
  UniversalSettingsFields,
} from './types';

const { REQUIRED } = RULES;

export const getBrandingFields = (
  { details: { universalSettings } }: ProgramData,
  form: FormInstance<UniversalSettingsFields>
): AppFields<BrandingFields> => [
  [programManufacturerField, programTypeField],
  [
    programNameField,
    fieldModifier(programLogoField, {
      form,
      selectUploadConfig: {
        ...imageSelectUploadConfig,
        fileName: universalSettings.brandLogo?.name || '',
      },
      fileName: universalSettings.brandLogo?.name || '',
      rules: [REQUIRED({ requiredFieldName: 'Program Logo' })],
    }),
  ],
  [
    fieldModifier(brandEmblemField, {
      form,
      selectUploadConfig: {
        ...imageSelectUploadConfig,
        fileName: universalSettings.brandEmblem?.name || '',
      },
      fileName: universalSettings.brandEmblem?.name || '',
    }),
    fieldModifier(primaryColorField, { rules: [REQUIRED({ requiredFieldName: 'Primary Color' })] }),
  ],
  [
    fieldModifier(secondaryColorField, { rules: [REQUIRED({ requiredFieldName: 'Secondary Color' })] }),
    fieldModifier(emptyField, { emptyKey: 'secondaryColorEmptyField' }),
  ],
];

export const programContactInformationFields: AppFields<ProgramContactInformationFields> = [
  [contactNumberField, contactHoursTextField],
  [contactPopupTitleField, contactPopupDescriptionField],
];

export const legalTermsLinkFields: AppFields<LegalTermsLinksFields> = [
  [importantSafetyInformationLinkField, prescriberInformationLinkField],
];

export const getProgramCardFields = (
  { details: { universalSettings } }: ProgramData,
  form: FormInstance<UniversalSettingsFields>
): AppFields<ProgramCardFields> => [
  [
    welcomeCardHeaderField,
    fieldModifier(welcomeCardImageField, {
      form,
      selectUploadConfig: {
        ...imageSelectUploadConfig,
        fileName: universalSettings.welcomeCardImage?.name || '',
      },
      fileName: universalSettings.welcomeCardImage?.name || '',
    }),
  ],
  [welcomeCardTextField, fieldModifier(emptyField, { emptyKey: 'welcomeCardTextEmptyField' })],
];
