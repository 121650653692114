import { Button, Form, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { Spacer } from 'components';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { getFieldsJSX } from 'components/fields/utils';
import { scrollToFirstErrorProps } from 'constants/common';
import { text } from 'constants/texts';
import { useChildrenRef } from 'hooks';
import { useUpdateSurvey } from 'hooks/api/surveys';
import { defaultAnswers } from 'pages/ProgramDetails/common/constants';
import { ProgramDetailsConfirmModal } from 'pages/ProgramDetails/common/ProgramDetailsConfirmModal';

import 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Surveys/styles.scss';

import {
  getSurveyCardFields,
  getSurveyNameField,
} from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Surveys/SurveyDetails/constants';
import { DynamicForm } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Surveys/SurveyDetails/DynamicForm';
import { showSaveEditedProgramToast } from 'pages/ProgramDetails/utils';
import { forwardRef, ForwardRefRenderFunction, useImperativeHandle } from 'react';
import { Callback } from 'types/helpers';
import { Survey, SurveyFields } from 'types/surveys';
import { stringModifier } from 'utils/strings';
import { ConditionalWrapper } from 'components/ShowWrapper/ConditionalWrapper';

export type SurveyDetailsContentRef = {
  submit: Callback;
  reset: Callback;
};

export type SurveyDetailsContentProps = {
  isEditMode: boolean;
  survey: Survey;
  onSuccess: Callback;
  isLoading: boolean;
};

const SurveyDetailsContentComponent: ForwardRefRenderFunction<SurveyDetailsContentRef, SurveyDetailsContentProps> = (
  { survey, isLoading, isEditMode, onSuccess },
  ref
) => {
  const [form] = useForm<SurveyFields>();
  const [confirmModalRef, setConfirmModalRef] = useChildrenRef<ClosableWrapperRef>();

  const { isLoading: isSurveyLoading, updateSurvey } = useUpdateSurvey();

  const handleSubmit = () => confirmModalRef?.open();

  useImperativeHandle(ref, () => ({ submit: form.submit, reset: form.resetFields }));

  const confirmHandler = async () => {
    const values = form.getFieldsValue();
    await updateSurvey({ ...survey, ...values });
    showSaveEditedProgramToast();
    onSuccess();
    confirmModalRef?.close();
  };

  const isSpinning = isLoading || isSurveyLoading;

  return (
    <Spin size='large' spinning={isSpinning}>
      <h1 className='page-content-title'>{stringModifier(text['{0} Details'], text['Survey'])}</h1>
      <h2 className='page-content-description'>
        {stringModifier(text['These are the details of the {0}'], text['Survey'])}
      </h2>
      <Spacer height={24} type='flex' />
      <Form<SurveyFields>
        scrollToFirstError={scrollToFirstErrorProps}
        layout='vertical'
        form={form}
        onFinish={handleSubmit}
        initialValues={survey}
      >
        {getFieldsJSX(getSurveyNameField(), isEditMode)}
        <h1 className='page-content-title'>{text['Landing Card']}</h1>
        <Spacer height={24} type='flex' />
        {getFieldsJSX(getSurveyCardFields(), isEditMode)}
        <Form.List name='questions'>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name }) => (
                <DynamicForm key={key} form={form} orderIndex={name} remove={remove} isEditMode={isEditMode} />
              ))}
              <ConditionalWrapper condition={isEditMode}>
                <Button type='ghost' onClick={() => add({ answers: defaultAnswers })}>
                  {stringModifier(text['Add {0}'], text['Question'])}
                </Button>
              </ConditionalWrapper>
            </>
          )}
        </Form.List>
      </Form>
      <ProgramDetailsConfirmModal
        type='Save'
        modalRef={setConfirmModalRef}
        confirmHandler={confirmHandler}
        cancelHandler={confirmModalRef?.close}
        isLoading={isSurveyLoading}
      />
    </Spin>
  );
};

export const SurveyDetailsContent = forwardRef(SurveyDetailsContentComponent);
