import { AppText, text } from 'constants/texts';

/** @description Usage -> stringModifier("{0} {1} !", "Hello", "World"); -> "Hello World !" */
export const stringModifier = (text: AppText, ...args: string[]) => {
  let newText: string = text;

  args.forEach((arg, index) => (newText = newText.replace(`{${index}}`, arg)));

  return newText;
};

type Article = Extract<keyof typeof text, 'a' | 'an'>;
type CountablePostfix = Extract<keyof typeof text, 's' | 'es'>;

/** @description Usage -> getInputPlaceholder(text['Name'], 'a') -> "Enter a Name" */
export const getInputPlaceholder = (fieldName: AppText, article?: Article) =>
  article
    ? stringModifier(text['Enter {0} {1}'], ...[text[article], fieldName])
    : stringModifier(text['Enter {0}'], fieldName);

/** @description Usage -> getSelectPlaceholder(text['Age'], 'an') -> "Select an Age" */
export const getSelectPlaceholder = (fieldName: AppText, article?: Article) =>
  article
    ? stringModifier(text['Select {0} {1}'], ...[text[article], fieldName])
    : stringModifier(text['Select {0}'], fieldName);

/** @description Usage -> getCountableText(4, text['{0} Box}'], 'es') -> "4 Boxes" */
export const getCountableText = (number: number, text: AppText, postfix: CountablePostfix = 's') =>
  stringModifier(text, `${number}`) + (number > 1 ? postfix : '');
