import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { text } from 'constants/texts';
import { Ref } from 'react';
import { Callback } from 'types/helpers';
import { Button, ClosableWrapper, Spacer } from '../index';

import './style.scss';

export type ConfirmModalProps = {
  title: string;
  isLoading?: boolean;
  description: string;
  cancelTextBtn?: string;
  confirmTextBtn?: string;
  cancelHandler?: Callback;
  confirmHandler?: Callback;
  modalRef: Ref<ClosableWrapperRef>;
};

export const ConfirmModal = ({
  title,
  isLoading = false,
  description,
  cancelTextBtn = text['Cancel'],
  confirmTextBtn = text['Confirm'],
  cancelHandler,
  confirmHandler,
  modalRef,
}: ConfirmModalProps) => (
  <ClosableWrapper ref={modalRef} onClose={cancelHandler} className='confirm-modal'>
    <h1 className='modal-title'>{title}</h1>
    <Spacer height={16} type='flex' />
    <h2 className='modal-description'>{description}</h2>
    <Spacer height={32} type='flex' />
    <div className='button-area'>
      <Button disabled={isLoading} type='ghost' onClick={cancelHandler} className='cancel-btn'>
        {cancelTextBtn}
      </Button>
      <Button loading={isLoading} type='primary' onClick={confirmHandler}>
        {confirmTextBtn}
      </Button>
    </div>
  </ClosableWrapper>
);
