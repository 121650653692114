import { UploadFile } from 'antd/lib/upload/interface';
import { AppFields } from 'components/fields/types';
import { CreateMediaFields } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Media/components/CreateMediaModal/types';
import { RULES } from 'utils/validations';

const { REQUIRED } = RULES;

export const getCreateMediaFields = (selectedFiles?: UploadFile[]): AppFields<CreateMediaFields> => [
  {
    type: 'input',
    name: 'resourceName',
    placeholder: 'Insert Resource Name Here',
    label: 'Resource',
    tooltip: 'Resource',
    disabled: !selectedFiles?.length,
    rules: [REQUIRED({ requiredFieldName: 'Resource' })],
  },
  {
    type: 'upload',
    draggerConfig: {},
    name: 'resource',
    filesCount: 1,
    fileTypes: ['PDF', 'MP4', 'PNG', 'JPG'],
    maxSize: 10,
  },
];
