import { MoreButton, PermissionWrapper } from 'components';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { DeleteSurveyConfirmModal } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Surveys/components/DeleteSurveyConfirmModal';
import { useRef } from 'react';
import { Survey } from 'types/surveys';

export type SurveyDetailMoreAction = {
  survey: Survey;
};

export const SurveyDetailMoreAction = ({ survey }: SurveyDetailMoreAction) => {
  const deleteModalRef = useRef<ClosableWrapperRef>();

  const handleDelete = () => deleteModalRef.current?.open();

  const surveyDetailMoreButtonActions = [
    {
      key: '0',
      label: 'Delete',
      onClick: handleDelete,
    },
  ];

  return (
    <PermissionWrapper permissionsRequired={['surveys.delete']}>
      <MoreButton options={surveyDetailMoreButtonActions} />
      <DeleteSurveyConfirmModal survey={survey} deleteModalRef={deleteModalRef} />
    </PermissionWrapper>
  );
};
