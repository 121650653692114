import { toCopayFinancialAssistanceDTO, toFinancialAssistanceDTO } from 'hooks/api/financialAssistance/mappers';
import { CopayFinancialAssistanceFields } from 'pages/ProgramDetails/programTabComponents/FinancialAssistance/tabsConfig/CopayTab/types';
import { FinancialAssistanceFields } from 'pages/ProgramDetails/programTabComponents/FinancialAssistance/tabsConfig/FinancialAssistanceTab/types';
import { showSuccessfullySavedToast } from 'pages/ProgramDetails/utils';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { queryKeys } from 'services/constants';
import { createFinancialAssistance } from 'services/financialAssistance/requests';
import { FinancialAssistanceType } from 'services/financialAssistance/types';
import { FinancialAssistance } from 'types/financialAssistance';
import { Callback } from 'types/helpers';
import { errorHandler } from 'utils/errorHandler';

export const useCreateFinancialAssistance = (type: FinancialAssistanceType, onSuccess?: Callback) => {
  const { programId } = useParams();

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(createFinancialAssistance, {
    mutationKey: queryKeys.createFinancialAssistance,
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKeys.getFinancialAssistanceList);
      showSuccessfullySavedToast();
      onSuccess?.();
    },
    onError: errorHandler,
  });

  return {
    isLoading,
    createCopayFinancialAssistance: (copayAssistance: CopayFinancialAssistanceFields) =>
      mutate({
        programId: programId || '',
        data: toCopayFinancialAssistanceDTO({ ...copayAssistance, type: 'COPAY' }),
      }),
    createFinancialAssistance: (assistance: FinancialAssistanceFields) =>
      mutate({
        programId: programId || '',
        data: toFinancialAssistanceDTO({ ...assistance, type: type as FinancialAssistance['type'] }),
      }),
  };
};
