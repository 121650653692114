import { Row } from 'antd';
import { Button, PermissionWrapper, Spacer } from 'components';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { useChildrenRef } from 'hooks';
import { ProgramData } from 'types/programs';
import { CreateSpecialtyPharmacyModal } from './components/CreatePharmacyModal';
import { stringModifier } from 'utils/strings';
import { text } from 'constants/texts';

type SpecialtyPharmacyTabActionProps = {
  program?: ProgramData;
};
export const SpecialtyPharmaciesTabAction = ({ program }: SpecialtyPharmacyTabActionProps) => {
  const [modalRef, setModalRef] = useChildrenRef<ClosableWrapperRef>();

  return (
    <PermissionWrapper permissionsRequired={['pharmacies.create']}>
      <Row>
        <Button onClick={modalRef?.open}>{stringModifier(text['Add {0}'], 'Specialty Pharmacy')}</Button>
        <Spacer width={16} />
      </Row>
      <CreateSpecialtyPharmacyModal setModalRef={setModalRef} modalRef={modalRef} program={program} />
    </PermissionWrapper>
  );
};
