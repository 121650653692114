import { CreateBenefitFields, UpdateBenefitsFields } from 'pages/ProgramDetails/programTabComponents/Benefits/types';
import { Benefit } from 'types/benefits';

export const getBenefitInitialFieldsValues = (benefit: Benefit): CreateBenefitFields => ({
  name: benefit.name,
  description: benefit.description,
});

export const getBenefitsInitialFieldsValues = (benefits: Benefit[]): UpdateBenefitsFields => ({
  benefits: benefits.map((benefit) => ({
    ...getBenefitInitialFieldsValues(benefit),
    uuid: benefit.uuid || '',
  })),
});
