import { Row } from 'antd';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { Button, Spacer } from 'components/index';
import { ConditionalWrapper } from 'components/ShowWrapper/ConditionalWrapper';
import { text } from 'constants/texts';
import { ConfirmModalType, ProgramDetailsConfirmModal } from 'pages/ProgramDetails/common/ProgramDetailsConfirmModal';
import { Ref } from 'react';
import { Callback } from 'types/helpers';

type EditableButtonsModalConfig = {
  setRef: Ref<ClosableWrapperRef>;
  ref: ClosableWrapperRef | null;
  onConfirm?: Callback;
  onCancel?: Callback;
};

type EditableButtonsProps = {
  isEditMode: boolean;
  isLoading?: boolean;
  onEdit?: Callback;
  disable?: boolean;
  onSave?: Callback;
  onCancel?: Callback;
  saveModal?: EditableButtonsModalConfig;
  cancelModal?: EditableButtonsModalConfig;
};

/** Closing modals works automatically */
export const EditableButtons = ({
  isEditMode,
  isLoading,
  onEdit,
  onCancel,
  disable,
  onSave,
  cancelModal,
  saveModal,
}: EditableButtonsProps) => (
  <>
    <ConditionalWrapper condition={!isEditMode}>
      <Button disabled={disable} loading={isLoading} onClick={onEdit}>
        {text['Edit']}
      </Button>
    </ConditionalWrapper>
    <ConditionalWrapper condition={isEditMode}>
      <Row>
        <Button loading={isLoading} onClick={onSave}>
          {text['Save']}
        </Button>
        <Spacer width={16} />
        <Button type='ghost' onClick={onCancel}>
          {text['Cancel']}
        </Button>
      </Row>
    </ConditionalWrapper>
    {!!saveModal && <EditableButtonsModal type='Save' config={saveModal} />}
    {!!cancelModal && <EditableButtonsModal type='Cancel' config={cancelModal} />}
  </>
);

const EditableButtonsModal = ({ config, type }: { config: EditableButtonsModalConfig; type: ConfirmModalType }) => (
  <ProgramDetailsConfirmModal
    type={type}
    modalRef={config.setRef}
    confirmHandler={() => {
      config.ref?.close();
      config.onConfirm?.();
    }}
    cancelHandler={() => {
      config.ref?.close();
      config.onCancel?.();
    }}
  />
);
