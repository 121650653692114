import { fieldModifier } from 'components/fields/utils';
import {
  questionDescriptionField,
  selectionTypeField,
  surveyCardTitleField,
  surveyDescriptionField,
  surveyQuestionField,
  surveyNameField,
} from 'pages/ProgramDetails/surveysFields';
import { SurveyFields } from 'types/surveys';

type QuestionsListFields = SurveyFields['questions'];

export const initialQuestionValues: QuestionsListFields = [
  {
    question: '',
    questionDescription: '',
    selectionType: 'SINGLE_SELECT',
    answers: [{ text: '' }, { text: '' }],
  },
];

export const getSurveyStepFields = () => [surveyNameField, surveyCardTitleField, surveyDescriptionField];

export const getSecondStepFields = (orderIndex: number) => [
  fieldModifier(surveyQuestionField, { name: [orderIndex, 'question'] }),
  fieldModifier(questionDescriptionField, { name: [orderIndex, 'questionDescription'] }),
  fieldModifier(selectionTypeField, { name: [orderIndex, 'selectionType'] }),
];
