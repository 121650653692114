import { SVGProps } from 'react';

export const BottomArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='10' viewBox='0 0 16 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.342742 0.566284C-0.104482 1.0068 -0.104481 1.72815 0.342742 2.16867L7.21088 8.93374C7.64864 9.36493 8.35145 9.36493 8.78921 8.93374L15.6573 2.16867C16.1046 1.72815 16.1046 1.0068 15.6573 0.566284C15.2196 0.135091 14.5168 0.135091 14.079 0.566284L8.00005 6.55403L1.92108 0.566284C1.48332 0.135091 0.780504 0.135091 0.342742 0.566284Z'
      fill='#787D83'
    />
  </svg>
);
