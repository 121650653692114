import { Button, Dropdown, Menu } from 'antd';
import { ThreeDots } from 'icons';
import { ReactNode } from 'react';
import { Callback } from 'types/helpers';
import './style.scss';

export type MoreButtonOption = {
  label: ReactNode;
  key: string;
  onClick?: Callback;
  hide?: boolean;
  disabled?: boolean;
};

type MoreButtonProps = {
  options: MoreButtonOption[];
  isLoading?: boolean;
};

export const MoreButton = ({ options, isLoading }: MoreButtonProps) => (
  <Dropdown
    overlay={<Menu items={options.filter((option) => !option.hide).map(({ hide, ...option }) => option)} />}
    trigger={['click']}
    placement='bottomRight'
  >
    <Button loading={isLoading} type='ghost' className='pl-more-button'>
      <ThreeDots />
    </Button>
  </Dropdown>
);
