import { ForgotPasswordModal, LoginForm } from './components';

import { useModal } from 'hooks';
import { AuthLayout } from '../../layouts/AuthLayout/AuthLayout';

import './style.scss';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { showCustomToast } from 'components';

type LocationType = {
  forgotPassword: boolean | undefined;
};

export const Login = () => {
  const { visible, open, close } = useModal();
  const location = useLocation();
  const { forgotPassword } = (location.state as LocationType) || {};

  useEffect(() => {
    if (!forgotPassword) return;
    showCustomToast({ type: 'error', message: 'Your registration link has expired' });
    open();
  }, [forgotPassword, open]);

  return (
    <AuthLayout title={'Log In'}>
      <LoginForm openForgotModal={open} />
      <a className='forgot-link' onClick={open}>
        Forgot your password
      </a>
      <ForgotPasswordModal close={close} visible={visible} />
    </AuthLayout>
  );
};
