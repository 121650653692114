import { FormProps, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Spacer } from 'components';
import { text } from 'constants/texts';
import { useProgramData } from 'hooks/api';
import { useMilestoneTrackers } from 'hooks/api/milestoneTrackers/useMilestoneTrackers';
import { useServices } from 'hooks/api/programs/useServices';
import { useBusinessRule } from 'hooks/api/rules/useBusinessRule';
import { useCommunicationTemplates } from 'hooks/api/rules/useCommunicationTemplates';
import { useUpdateBusinessRule } from 'hooks/api/rules/useUpdateBusinessRule';
import { useSurveys } from 'hooks/api/surveys/useSurveys';
import { PageLayout } from 'layouts';
import { BusinessRuleDetailsActions } from 'pages/ProgramDetails/programTabComponents/BusinessRules/BusinessRuleDetails/components/BusinessRuleDetailsActions/BusinessRuleDetailsActions';
import { BusinessRuleDetailsContent } from 'pages/ProgramDetails/programTabComponents/BusinessRules/BusinessRuleDetails/components/BusinessRuleDetailsContent/BusinessRuleDetailsContent';
import { BusinessRuleEditContent } from 'pages/ProgramDetails/programTabComponents/BusinessRules/BusinessRuleDetails/components/BusinessRuleEditContent/BusinessRuleEditContent';
import { fromUpdateBusinessRuleFieldsToBusinessRule } from 'pages/ProgramDetails/programTabComponents/BusinessRules/BusinessRuleDetails/components/BusinessRuleEditContent/utils';
import { UpdateBusinessRuleFields } from 'pages/ProgramDetails/programTabComponents/BusinessRules/components/CreateBusinessRuleModal/types';
import { getProgramPagesBreadCrumb } from 'pages/Programs/breadCrumbs';
import { statusRenderer } from 'pages/shared';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFontClassName } from 'utils/getFontClassName';

export const BusinessRuleDetails = () => {
  const { programId = '' } = useParams();

  const { isLoading: isPushTemplatesLoading } = useCommunicationTemplates('push'); // Initialize milestones for edit mode
  const { isLoading: isNotificationTilesTemplatesLoading } = useCommunicationTemplates('notification'); // Initialize milestones for edit mode
  const { isLoading: isSMSTemplatesLoading } = useCommunicationTemplates('sms'); // Initialize milestones for edit mode
  const { isLoading: isEmailTemplatesLoading } = useCommunicationTemplates('email'); // Initialize milestones for edit mode
  const { isLoading: isMilestoneTrackersLoading } = useMilestoneTrackers(); // Initialize milestones for edit mode
  const { isLoading: isSurveysLoading } = useSurveys(); // Initialize surveys for edit mode
  const { isLoading: isServicesLoading } = useServices(programId);

  const [form] = useForm<UpdateBusinessRuleFields>();

  const [isEditMode, setEditMode] = useState(false);

  const { businessRule, isLoading: isBusinessRuleLoading } = useBusinessRule();
  const { program, isLoading: isProgramDataLoading } = useProgramData();

  const { updateBusinessRule, isLoading: isUpdateBusinessRuleLoading } = useUpdateBusinessRule(() =>
    setEditMode(false)
  );

  const isLoading =
    isBusinessRuleLoading ||
    isProgramDataLoading ||
    isMilestoneTrackersLoading ||
    isSurveysLoading ||
    isServicesLoading ||
    isPushTemplatesLoading ||
    isSMSTemplatesLoading ||
    isNotificationTilesTemplatesLoading ||
    isEmailTemplatesLoading;

  if (!businessRule || !program || isLoading) return <Spin className='pl-page-spinner' size='large' />;

  const onFinish: FormProps<UpdateBusinessRuleFields>['onFinish'] = (values) =>
    updateBusinessRule(fromUpdateBusinessRuleFieldsToBusinessRule(businessRule, values));

  const breadCrumbs = getProgramPagesBreadCrumb({
    name: 'BENEFIT_RULE_DETAILS',
    programName: program.programName,
    programId: program.id,
    businessRuleName: businessRule.name,
  });

  const pageTitleJSX = (
    <>
      {businessRule.name || text['Name not found']}
      {statusRenderer(businessRule.status)}
    </>
  );

  return (
    <PageLayout
      pageTitle={pageTitleJSX}
      crumbs={breadCrumbs}
      rightSideActions={
        <BusinessRuleDetailsActions
          isEditMode={isEditMode}
          onEditModeChange={setEditMode}
          form={form}
          businessRule={businessRule}
        />
      }
    >
      <Spin size='large' spinning={isUpdateBusinessRuleLoading}>
        <h2 className={getFontClassName('h2')}>{text['Business Rule Description']}</h2>
        <Spacer height={6} type='flex' />
        <p className={getFontClassName('paragraph', 'gray2')}>{businessRule.description}</p>
        <Spacer height={24} type='flex' />
        {isEditMode ? (
          <BusinessRuleEditContent onFinish={onFinish} form={form} businessRule={businessRule} />
        ) : (
          <BusinessRuleDetailsContent businessRule={businessRule} />
        )}
      </Spin>
    </PageLayout>
  );
};
