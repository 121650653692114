import { useServices } from 'hooks/api/programs/useServices';

import { AdditionalServicesContent, AdditionalServicesTabActions } from 'pages/ProgramDetails/programTabComponents';
import { useEffect, useRef, useState } from 'react';
import { ProgramData } from 'types/programs';
import { useCoverProgramEditStatusCallbacks } from '../hooks/useCoverProgramEditStatusCallbacks';
import { AdditionalServicesContentRef } from '../programTabComponents/AdditionalServices/AdditionalServicesContent';

import { ProgramDetailsUseTabReturnType } from './types';
import { text } from 'constants/texts';
import { stringModifier } from 'utils/strings';

export const useAdditionalServicesTab = (program?: ProgramData): ProgramDetailsUseTabReturnType => {
  const formRef = useRef<AdditionalServicesContentRef>(null);

  const [isEditMode, setEditMode] = useState(false);
  const { data } = useServices(program?.id);

  const hasServices = !!data?.services.length;

  useEffect(() => {
    if (hasServices && !isEditMode) return;
    // if all services are deleted in edit mode.
    setEditMode(false);
  }, [hasServices]);

  const { onEdit, onClose, isLoading } = useCoverProgramEditStatusCallbacks({
    programId: program?.id,
    onClose: () => {
      formRef.current?.reset();
      setEditMode(false);
    },
    onEdit: () => setEditMode(true),
  });

  const onSave = () => formRef.current?.submit();

  return {
    tab: {
      title: text['Additional Services'],
      description: text['These settings impact the Additional Services segment in the instance of the application'],
      tabData: {
        contentTitle: stringModifier(text['Additional Services {0}'], text['Settings']),
        actionsJSX: (
          <AdditionalServicesTabActions
            isEditMode={isEditMode}
            program={program}
            isLoading={isLoading}
            onEdit={onEdit}
            onSave={onSave}
            onClose={onClose}
            hasServices={hasServices}
          />
        ),
        contentJSX: (
          <AdditionalServicesContent
            onSuccess={() => setEditMode(false)}
            program={program}
            ref={formRef}
            isEditMode={isEditMode}
          />
        ),
      },
      tabKey: 'services',
    },
    extra: {
      isAdditionalServicesEditMode: isEditMode,
    },
  };
};
