import {
  PATIENT_SEARCH_TAB_URL_KEY,
  PROGRAM_CONTENT_TAB_URL_KEY,
  PROGRAM_TAB_URL_KEY,
} from 'pages/ProgramDetails/useTabChangeHandler';
import { NavigateFunction, NavigateOptions, useNavigate, useSearchParams } from 'react-router-dom';
import { DevError } from 'utils/errors';

type DetailsPageCreatorConfig = {
  navigate: NavigateFunction;
  routeUrl: string;
  errorName: string;
  defaultId?: string;
};

export const RULE_ID_PARAM = 'ruleId';
export const SURVEY_ID_PARAM = 'surveyId';
export const MILESTONE_TRACKER_ID_PARAM = 'milestoneTrackerId';
export const SPECIALTY_PHARMACY_ID_PARAM = 'specialtyPharmacyId';
export const NOTIFICATION_TILE_ID_PARAM = 'notificationTileId';
export const PATIENT_SEARCH_ID_PARAMS = 'patientId';
export const COMMUNICATION_SURVEY_ID_PARAM = 'communicationId';

const goToDetailsPageCreator =
  ({ errorName, navigate, routeUrl, defaultId }: DetailsPageCreatorConfig) =>
  (id?: string, options?: NavigateOptions) => {
    if (!defaultId && !id) throw new DevError(`You should set any id of your selected ${errorName}!`);

    navigate({ pathname: routeUrl + (id ?? defaultId) }, options);
  };

export const useGoToAdminPage = (defaultId?: string) => {
  const navigate = useNavigate();

  return goToDetailsPageCreator({ navigate, errorName: 'admin', routeUrl: '/admins/', defaultId });
};

export const useGoToProgramDetailsPage = (defaultId?: string) => {
  const navigate = useNavigate();

  return goToDetailsPageCreator({
    navigate,
    defaultId,
    errorName: 'program',
    routeUrl: '/programs/',
  });
};

export const useGoToBusinessRuleDetailsPage = () => {
  const [, setParams] = useSearchParams();

  return (id?: string) => id && setParams({ [PROGRAM_TAB_URL_KEY]: 'rules', ruleId: id });
};

export const useGoToSpecialtyPharmaciesDetailsPage = () => {
  const [, setParams] = useSearchParams();

  return (id?: string) =>
    id &&
    setParams({
      [PROGRAM_TAB_URL_KEY]: 'specialty-pharmacies',
      specialtyPharmacyId: id,
    });
};

export const useGoToSurveyDetailsPage = () => {
  const [, setParams] = useSearchParams();

  return (id?: string) =>
    id &&
    setParams({
      [PROGRAM_TAB_URL_KEY]: 'content',
      [PROGRAM_CONTENT_TAB_URL_KEY]: 'surveys',
      surveyId: id,
    });
};

export const useGoToMilestoneTrackerDetailsPage = () => {
  const [, setParams] = useSearchParams();

  return (id?: string) =>
    id &&
    setParams({
      [PROGRAM_TAB_URL_KEY]: 'content',
      [PROGRAM_CONTENT_TAB_URL_KEY]: 'milestone-trackers',
      [MILESTONE_TRACKER_ID_PARAM]: id,
    });
};

export const useGoToNotificationTileDetailsPage = () => {
  const [, setParams] = useSearchParams();

  return (id?: string) =>
    id &&
    setParams({
      [PROGRAM_TAB_URL_KEY]: 'content',
      [PROGRAM_CONTENT_TAB_URL_KEY]: 'notification-tiles',
      [NOTIFICATION_TILE_ID_PARAM]: id,
    });
};

export const useGoToCommunicationDetailsPage = () => {
  const [, setParams] = useSearchParams();

  return (id?: string, patientId = '') =>
    id &&
    patientId &&
    setParams({
      [PATIENT_SEARCH_ID_PARAMS]: patientId,
      [PATIENT_SEARCH_TAB_URL_KEY]: 'communications',
      [COMMUNICATION_SURVEY_ID_PARAM]: id,
    });
};
