import { API_BASE_URL, formDataHeader } from 'services/constants';
import {
  CreateProgramResponse,
  GetManufacturersResponse,
  Program,
  ProgramResponse,
  ProgramsResponse,
  ProgramStatusUpdateRequestBody,
} from 'services/programs/types';
import { api } from 'utils/api';

const PROGRAM_ENDPOINT = `${API_BASE_URL}program/`;
const SET_PROGRAM_EDIT_STATUS_ENDPOINT = `${PROGRAM_ENDPOINT}edit-check/`;
const MANUFACTURES_ENDPOINT = `${API_BASE_URL}manufacture/`;
const STATUS_UPDATE_ENDPOINT = `${PROGRAM_ENDPOINT}status/`;
const FETCH_PROGRAM_ENDPOINT = `${PROGRAM_ENDPOINT}details`;

export const getManufactures = () =>
  api.get<GetManufacturersResponse>(MANUFACTURES_ENDPOINT).then((response) => response.data);

export const createProgram = (programData: FormData) =>
  api
    .post<CreateProgramResponse>(PROGRAM_ENDPOINT, programData, { headers: formDataHeader })
    .then((response) => response.data);

export const updateProgram = ({ id, data }: { id: string; data: FormData }) =>
  api.patch(PROGRAM_ENDPOINT + id, data, { headers: formDataHeader });

export const fetchProgram = async (id: string) =>
  api.get<ProgramResponse>(`${FETCH_PROGRAM_ENDPOINT}?program_uuid=${id}`).then((response) => response.data);

export const fetchPrograms = async (searchKey = '') =>
  api.get<ProgramsResponse>(`${PROGRAM_ENDPOINT}?search=${searchKey}`).then((response) => response.data);

export const updateProgramStatus = (data: ProgramStatusUpdateRequestBody) =>
  api.post<Program>(STATUS_UPDATE_ENDPOINT, data);

export const setProgramEditStatus = ({ id, lock }: { id: string; lock: boolean }) =>
  api.post(SET_PROGRAM_EDIT_STATUS_ENDPOINT + id, { lock });

export const getProgramEditStatus = (id: string) =>
  api.get<{ isCanEdit: boolean }>(SET_PROGRAM_EDIT_STATUS_ENDPOINT + id).then((res) => res.data);
