import { FormInstance } from 'antd';
import { AppFields } from 'components/fields/types';
import { emptyField, fieldModifier } from 'components/fields/utils';
import { text } from 'constants/texts';
import { ProgramData } from 'types/programs';
import { stringModifier } from 'utils/strings';
import { RULES } from 'utils/validations';

import {
  answersField,
  descriptionField,
  iconField,
  labelField,
  questionField,
  repeatableField,
  selectionTypeField,
  serviceTypeField,
  sourceField,
  titleField,
} from '../../additionalServicesFields';
import { AdditionalServicesFields, FormRequestFields, ServicesFields } from './types';

const { REQUIRED } = RULES;

type Config = {
  form: FormInstance<ServicesFields>;
  orderIndex: number;
  serviceType: AdditionalServicesFields['type'];
  service?: AdditionalServicesFields;
  program?: ProgramData;
};

export const getAdditionalServicesMainFields = ({
  serviceType,
  orderIndex,
  form,
  program,
  service,
}: Config): AppFields<AdditionalServicesFields> => {
  const isSourceTypeSelected = serviceType === 'RESOURCE';

  const { programName = '' } = program || {};

  return [
    [
      fieldModifier(labelField, { name: [orderIndex, 'label'] }),
      fieldModifier(iconField, { name: [orderIndex, 'icon'] }),
    ],
    [
      fieldModifier(repeatableField, { name: [orderIndex, 'repeatable'] }),
      fieldModifier(serviceTypeField, { name: [orderIndex, 'type'] }),
    ],
    [
      fieldModifier(titleField, { name: [orderIndex, 'title'] }),
      fieldModifier(descriptionField, { name: [orderIndex, 'description'] }),
    ],
    [
      fieldModifier(sourceField, {
        name: [orderIndex, 'source'],
        hidden: !isSourceTypeSelected,
        selectUploadConfig: {
          listName: ['services', orderIndex, 'source'],
          title: stringModifier(text['Add {0}'], 'an Additional Service'),
          description: stringModifier(text['Add an additional service option for the {0} program.'], programName),
          closeButtonText: text['Cancel'],
          fileName: service?.source?.mediaName,
        },
        fileName: service?.source?.mediaName,
        form,
        rules: [
          REQUIRED({
            requiredFieldName: 'Source',
            required: isSourceTypeSelected,
          }),
        ],
      }),
      fieldModifier(emptyField, { emptyKey: 'sourceEmptyField' }),
    ],
  ];
};

export const getFormRequestMainFields = (orderIndex: number): AppFields<FormRequestFields> => [
  fieldModifier(questionField, { name: [orderIndex, 'question'] }),
  fieldModifier(selectionTypeField, { name: [orderIndex, 'selectionType'] }),
];

export const getAnswersField = (parentIndex: number, childIndex: number): AppFields<FormRequestFields> => [
  fieldModifier(answersField, { name: [parentIndex, childIndex, 'answers'] }),
];
