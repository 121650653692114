import { Switch } from 'antd';
import { SwitchProps } from 'antd/es';
import clx from 'classnames';

import './style.scss';

export type AppSwitchProps = {
  labels?: { checked: string; unchecked: string };
} & Pick<SwitchProps, 'className' | 'checked'>;

export const AppSwitch = ({ labels, className, ...rest }: AppSwitchProps) => {
  return (
    <>
      <Switch className={clx('pl-switch', className)} {...rest} />
      {!!labels && <span className='pl-switch-label'>{rest.checked ? labels.checked : labels.unchecked}</span>}
    </>
  );
};
