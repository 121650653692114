import { ColumnsType } from 'antd/es/table';

import { PageLayout } from 'layouts';
import { Button, DataTable, PermissionWrapper, SearchInput } from 'components';
import { useModal, useSearch } from 'hooks';
import { statusRenderer, SuperAdminActions } from 'pages/shared';
import { AdminData } from 'pages/shared/types';
import './style.scss';
import { useEffect } from 'react';
import { useGoToAdminPage } from 'routes/navigators';
import { getAdminPagesBreadCrumb } from './breadCrumbs';
import { useAdmins } from 'hooks/api/index';
import { stopPropagationOnCell } from 'constants/common';
import { text } from 'constants/texts';
import { CreateAdminModal } from './components/CreateAdminModal';
import { stringModifier } from 'utils/strings';

export const columns: ColumnsType<AdminData> = [
  {
    title: text['Last Name'],
    dataIndex: 'lastName',
    sorter: (a, b) => a.lastName.localeCompare(b.lastName),
    defaultSortOrder: 'ascend',
  },
  {
    title: text['First Name'],
    dataIndex: 'firstName',
  },
  {
    title: text['Email'],
    dataIndex: 'email',
  },
  {
    title: text['Status'],
    dataIndex: 'status',
    render: statusRenderer,
  },
  {
    title: '',
    dataIndex: 'showMore',
    render: (_: any, record: AdminData) => <SuperAdminActions {...record} />,
    onCell: stopPropagationOnCell,
  },
];

export const Admins = () => {
  const { keyword, handleSearch, debouncedSearchKeyword, previousDebouncedSearchKeyword } = useSearch();
  const { open, close, visible } = useModal();
  const { admins, isLoading, refetch, isRefetching } = useAdmins({ filter: debouncedSearchKeyword });

  const goToAdminPage = useGoToAdminPage();

  useEffect(() => {
    if (previousDebouncedSearchKeyword !== debouncedSearchKeyword) refetch();
  }, [debouncedSearchKeyword, previousDebouncedSearchKeyword]);

  const breadCrumbs = getAdminPagesBreadCrumb({ name: 'ADMIN_LIST' });

  const handleRowClick = (record: AdminData) => goToAdminPage(record.id);

  const handleRowClassName = (record: AdminData) => (record.status === 'Pending' ? 'row-disabled' : '');

  const rightSideActionsJSX = (
    <PermissionWrapper permissionsRequired={['admins.create']}>
      <Button onClick={open}>{stringModifier(text['Add {0}'], text['User'])}</Button>
    </PermissionWrapper>
  );

  return (
    <PageLayout pageTitle={text['All Admins']} crumbs={breadCrumbs} rightSideActions={rightSideActionsJSX}>
      <div className='admins-wrapper'>
        <SearchInput value={keyword} onChange={handleSearch} />
        <DataTable
          rowKey={'id'}
          loading={isLoading || isRefetching}
          rowClassName={handleRowClassName}
          onRow={(record) => ({ onClick: (_) => handleRowClick(record) })}
          columns={columns}
          dataSource={admins || []}
          noDataContent={stringModifier(
            text['We couldn’t find any {0} that fit your search criteria.'],
            text['Admins']
          )}
        />
      </div>
      <CreateAdminModal visible={visible} onCancel={close} close={close} />
    </PageLayout>
  );
};
