import { ApiService, CreateRequest, DeleteRequest, GetListRequest, UpdateRequest } from 'types/apiTypes';
import { api } from 'utils/api';
import {
  CreateNotificationTileData,
  CreateNotificationTileParams,
  DeleteNotificationTileParams,
  GetNotificationTileListData,
  GetNotificationTileListParams,
  UpdateNotificationTileParams,
} from './types';
import { API_BASE_URL } from '../constants';

const NOTIFICATION_TILES_ENDPOINT = `${API_BASE_URL}notification-tiles/`;

export const {
  getNotificationTileList,
  updateNotificationTile,
  createNotificationTile,
  deleteNotificationTile,
}: ApiService<
  'notificationTile',
  GetListRequest<GetNotificationTileListData, GetNotificationTileListParams> &
    DeleteRequest<unknown, DeleteNotificationTileParams> &
    CreateRequest<CreateNotificationTileData, CreateNotificationTileParams> &
    UpdateRequest<unknown, UpdateNotificationTileParams>
> = {
  getNotificationTileList: (params) => {
    return api
      .get<GetNotificationTileListData>(NOTIFICATION_TILES_ENDPOINT, {
        params: {
          ...(params.search && { search: params.search }),
          ...(params.status && { status: params.status }),
          manufacture_program_uuid: params.programId,
        },
      })
      .then((res) => res.data);
  },
  createNotificationTile: (notificationTile) => {
    return api.post<CreateNotificationTileData>(NOTIFICATION_TILES_ENDPOINT, notificationTile).then((res) => res.data);
  },
  updateNotificationTile: (data) => {
    return api.patch(NOTIFICATION_TILES_ENDPOINT + data.id, data);
  },
  deleteNotificationTile: (id) => {
    return api.delete(NOTIFICATION_TILES_ENDPOINT + id);
  },
};
