import { text } from 'constants/texts';
import { PatientSearchTabs, PatientSearchUseTabsReturnType } from './types';
import { PatientInformationContent } from 'pages/PatientSearch/patientSearchTabComponents/PatientInformation/PatientInformationContent';
import { Patient } from 'services/patientSearch/types';

type GetPatientInformationTabProps = {
  patient?: Patient;
};

export const getPatientInformationTab = ({
  patient,
}: GetPatientInformationTabProps): PatientSearchUseTabsReturnType => ({
  tab: {
    tabKey: 'patient-information' as PatientSearchTabs,
    title: text['Patient Information'],
    tabData: {
      contentJSX: <PatientInformationContent patient={patient} />,
    },
  },
});
