import { MoreOption } from 'components/tableCells/MoreOptionsCell/MoreOptionsCell';
import { text } from 'constants/texts';
import { stringModifier } from 'utils/strings';
import { useDeleteNotificationTile } from 'hooks/api/notificationTiles/useDeleteNotificationTile';
import { useUpdateNotificationTile } from 'hooks/api/notificationTiles/useUpdateNotificationTile';
import { useNotificationTile } from 'hooks/api/notificationTiles/useNotificationTile';
import { usePermissionsHandler } from 'hooks';

export const useNotificationTileOptions = (
  id?: string
): { options: MoreOption[]; isActive: boolean; isLoading: boolean } => {
  const [isEditNotificationTilesAllowed, isDeleteNotificationTileAllowed] = usePermissionsHandler([
    'notification-tiles.edit',
    'notification-tiles.delete',
  ]);

  const { notificationTile, isLoading: isNotificationTileLoading } = useNotificationTile(id);
  const { updateNotificationTileStatus, isLoading: isUpdateNotificationStatusOptionLoading } =
    useUpdateNotificationTile();
  const { deleteNotificationTile, isLoading: isDeleteNotificationTileLoading } = useDeleteNotificationTile(
    notificationTile?.id
  );

  const isLoading =
    isUpdateNotificationStatusOptionLoading || isDeleteNotificationTileLoading || isNotificationTileLoading;

  const isActive = notificationTile?.status === 'IN_USE';

  return {
    isActive,
    isLoading,
    options: [
      {
        key: '1',
        label: text[isActive ? 'Inactive' : 'Activate'],
        disabled: !isEditNotificationTilesAllowed,
        request: () => updateNotificationTileStatus(text[isActive ? 'DRAFT' : 'IN_USE'], notificationTile?.id),
      },
      {
        key: '2',
        label: text['Delete'],
        request: deleteNotificationTile,
        disabled: !isDeleteNotificationTileAllowed,
        modalInfo: {
          title: stringModifier(text['Delete {0}'], notificationTile?.name ?? ''),
          description: stringModifier(text['Are you sure you want to delete this {0}?'], text['Notification Tile']),
        },
      },
    ],
  };
};
