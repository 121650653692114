import { ServerObjectMappers } from 'types/apiTypes';
import { Patient, PatientCommunication, PatientCommunicationDTO, PatientDTO } from 'services/patientSearch/types';
import {
  CommunicationSurvey,
  CommunicationSurveyDTO,
  QuestionsFields,
  QuestionsFieldsDTO,
} from 'pages/PatientSearch/PatientComunicationDetails/types';

export const { toPatient, toPatientDTO }: ServerObjectMappers<'patient', PatientDTO, Patient> = {
  toPatient: (dto) => ({
    email: dto.email ?? '',
    lastName: dto.lastName ?? '',
    firstName: dto.firstName ?? '',
    cognitoId: dto.cognitoId ?? '',
    isActive: dto.isActive ?? false,
  }),
  toPatientDTO: (instance) => instance,
};

export const {
  toCommunication,
  toCommunicationDTO,
}: ServerObjectMappers<'communication', PatientCommunicationDTO, PatientCommunication> = {
  toCommunication: (dto) => ({
    id: dto.id ?? '',
    name: dto.name ?? '',
    channel: dto.channel ?? 'email',
    createdAt: dto.createdAt ?? '',
    surveyData: toCommunicationSurvey(dto?.surveyData ?? {}),
  }),
  toCommunicationDTO: (instance) => instance,
};

export const {
  toCommunicationSurvey,
  toCommunicationSurveyDTO,
}: ServerObjectMappers<'communicationSurvey', CommunicationSurveyDTO, CommunicationSurvey> = {
  toCommunicationSurvey: (dto) => ({
    uuid: dto.uuid ?? '',
    title: dto.title ?? '',
    status: dto.status ?? 'COMPLETED',
    subtext: dto.subtext ?? '',
    logo_url: dto.logo_url ?? '',
    cognito_id: dto.cognito_id ?? '',
    survey_uuid: dto.survey_uuid ?? '',
    program_uuid: dto.program_uuid ?? '',
    survey_send_date: dto.survey_send_date ?? null,
    percent_of_complete: dto.percent_of_complete ?? 0,
    questions: dto.questions?.map(toQuestion) ?? [],
  }),
  toCommunicationSurveyDTO: (instance) => instance,
};

export const { toQuestion, toQuestionDTO }: ServerObjectMappers<'question', QuestionsFieldsDTO, QuestionsFields> = {
  toQuestion: (dto) => ({
    uuid: dto.uuid ?? '',
    title: dto.title ?? '',
    order: dto.order ?? '',
    description: dto.description ?? '',
    is_answered: dto.is_answered ?? false,
    question_type: dto.question_type ?? 'RATING',
    answers: dto.answers?.filter((answer) => answer.is_checked) ?? [],
  }),
  toQuestionDTO: (instance) => instance,
};
