import { Form, Row } from 'antd';
import clx from 'classnames';
import { AppInput, Button, ClosableWrapper, Spacer } from 'components';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { DraggerConfig } from 'components/fields/AppUpload/components/DraggerUpload/DraggerUpload';
import { PickFileOrMediaModalContent } from 'components/PickFileOrMediaModalContent/PickFileOrMediaModalContent';
import { text } from 'constants/texts';
import { useRef, useState } from 'react';
import { getSelectPlaceholder } from 'utils/strings';
import { AppUploadProps } from '../../AppUpload';

export type SelectUploadConfig = Partial<{
  title: string;
  listName: string | (number | string)[];
  description: string;
  closeButtonText: string;
  confirmButtonText: string;
  fileName: string;
  placeholder: string;
  draggerProps: DraggerConfig;
}>;

type SelectUploadProps = { selectUploadConfig: SelectUploadConfig } & Pick<
  AppUploadProps,
  'loadFileType' | 'fileTypes' | 'form' | 'field' | 'className' | 'cropper'
>;

export const SelectUpload = ({
  field: { rules, tooltip, label, name, hidden } = {},
  className,
  form,
  selectUploadConfig: { fileName = '', listName, placeholder = getSelectPlaceholder(text['File']), ...configRest },
  ...rest
}: SelectUploadProps) => {
  const modal = useRef<ClosableWrapperRef>(null);
  const [inputText, setInputText] = useState(fileName);

  const openModal = () => modal.current?.open();

  return (
    <Form.Item
      tooltip={tooltip}
      hidden={hidden}
      label={label}
      name={name}
      className={clx({ 'required-star': !!rules?.some((rule) => rule?.required) })}
      rules={rules?.map((rule) => (rule.required ? { ...rule, type: 'object' } : rule))}
    >
      <>
        <Row wrap={false}>
          <AppInput onClick={openModal} value={inputText} withoutWriting placeholder={placeholder} />
          <Spacer type='flex' width={16} />
          <Button onClick={openModal} type='ghost'>
            Select
          </Button>
        </Row>
        <ClosableWrapper ref={modal}>
          <PickFileOrMediaModalContent
            {...configRest}
            {...rest}
            onClose={modal.current?.close}
            onSuccess={(fileName, media) => {
              if (name && form) {
                form.setFields([{ name: listName || name, value: media }]);
                form.validateFields([name]);
              }
              modal.current?.close();
              setInputText(fileName);
            }}
          />
        </ClosableWrapper>
      </>
    </Form.Item>
  );
};
