import { showCustomToast } from 'components';
import { MoreButtonOption } from 'components/MoreButton/MoreButton';
import { text } from 'constants/texts';
import { useProgramData, useUpdateProgramStatus } from 'hooks/api';
import { UniversalSettingsFields } from 'pages/ProgramDetails/programTabComponents/UniversalSettings/types';
import { ProgramData } from 'types/programs';
import { ProgramStatus } from 'types/union-types';
import { usePermissionsHandler } from 'hooks';

export const showSaveEditedProgramToast = () =>
  showCustomToast({
    type: 'success',
    message: 'Your changes have been saved.',
    duration: 2.5,
  });

export const showSuccessfullySavedToast = () =>
  showCustomToast({
    type: 'success',
    message: text['Your changes were saved.'],
    duration: 2.5,
  });

export const showSuccessfullyDeletedToast = () =>
  showCustomToast({
    type: 'success',
    message: text['This item was successfully deleted'],
    duration: 2.5,
  });

export const showWasNotImplementedToast = () =>
  showCustomToast({
    type: 'warning',
    message: text['This logic is not implemented yet'],
    duration: 2,
  });

export const showCancelEditedToast = () =>
  showCustomToast({
    type: 'warning',
    message: text['No changes were saved.'],
    duration: 2.5,
  });

export const showBlockProgramEditingToast = () =>
  showCustomToast({
    type: 'warning',
    message: text['This program is currently being edited by another Admin. Please try again later.'],
    duration: 4,
  });

const programActionText = (status: ProgramStatus) => {
  if (status === 'DRAFT') return 'Activate';
  if (status === 'INACTIVE') return 'Draft';
  return 'Inactivate';
};

const getAvailableProgramAction = (status: ProgramStatus): ProgramStatus => {
  if (status === 'DRAFT') return 'LIVE';
  if (status === 'INACTIVE') return 'DRAFT';
  return 'INACTIVE';
};

export const getUniversalSettingsFieldByProgramData = ({
  programName,
  programType,
  details: {
    universalSettings: {
      brandEmblem,
      brandLogo,
      manufacturerId,
      welcomeCardImage,
      welcomeCardHeader,
      welcomeCardTextBlock,
      contactHoursText,
      contactNumber,
      termsAndConditionsLink,
      prescriberInformationLink,
      contactPopupDescription,
      contactPopupTitle,
      safetyInformationLink,
      primaryColor,
      secondaryColor,
    },
  },
}: ProgramData): UniversalSettingsFields => ({
  manufacturerId,
  programType,
  programLogo: brandLogo,
  brandEmblem: brandEmblem,
  welcomeCardImage: welcomeCardImage,
  contactPopupDescription,
  contactPopupTitle,
  primaryColor,
  programName,
  secondaryColor,
  contactNumber,
  contactHoursText,
  importantSafetyInformationLink: safetyInformationLink,
  prescriberInformationLink,
  termsAndConditionsLink,
  welcomeCardHeader,
  welcomeCardText: welcomeCardTextBlock,
});

export const useProgramMoreButtonActions = (program?: ProgramData): MoreButtonOption[] => {
  const { program: fetchedProgram } = useProgramData(program?.id);
  const [isEditProgramAllowed] = usePermissionsHandler(['programs.edit']);

  const selectedProgram = program || fetchedProgram;

  const { updateStatus, isLoading } = useUpdateProgramStatus();

  if (!selectedProgram) return [];

  const programStatusUpdateHandler = () => {
    updateStatus({
      programUUID: selectedProgram.id,
      status: getAvailableProgramAction(selectedProgram.status),
    });
  };

  return [
    {
      label: isLoading ? 'Updating...' : programActionText(selectedProgram.status),
      onClick: programStatusUpdateHandler,
      key: '0',
      disabled: isLoading || !isEditProgramAllowed,
    },
  ];
};
