import { showSuccessfullySavedToast } from 'pages/ProgramDetails/utils';
import { useMutation, useQueryClient } from 'react-query';
import { errorHandler } from 'utils/errorHandler';
import { Callback } from 'types/helpers';
import { queryKeys } from 'services/constants';
import { updateNotificationTile } from 'services/notificationTiles/requests';
import { NotificationTileStatus } from 'services/notificationTiles/types';
import { UpdateNotificationTileFields } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/NotificationTiles/types';
import { NotificationTile } from 'types/notificationTile';
import { toNotificationTileDTO } from './mappers';

export const useUpdateNotificationTile = (onSuccess?: Callback) => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(updateNotificationTile, {
    mutationKey: queryKeys.updateNotificationTile,
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKeys.getNotificationTiles);
      showSuccessfullySavedToast();
      onSuccess?.();
    },
    onError: errorHandler,
  });

  return {
    isLoading,
    updateNotificationTile: (fields: UpdateNotificationTileFields, notificationTile: NotificationTile) =>
      mutateAsync(toNotificationTileDTO({ ...notificationTile, ...fields })),
    updateNotificationTileStatus: (status: NotificationTileStatus, id?: string) => id && mutateAsync({ id, status }),
  };
};
