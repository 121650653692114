import { Form } from 'antd';
import { ClosableWrapper, Spacer } from 'components';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { ModalActionButtons } from 'components/fields/AppActionButtons/ModalActionButtons';
import { getFieldsJSX } from 'components/fields/utils';
import { text } from 'constants/texts';
import { useCreateBusinessRule } from 'hooks/api/rules/useCreateBusinessRule';
import { getCreateBusinessRuleFields } from 'pages/ProgramDetails/programTabComponents/BusinessRules/components/CreateBusinessRuleModal/fields';
import { CreateBaseBusinessRuleFields } from 'pages/ProgramDetails/programTabComponents/BusinessRules/components/CreateBusinessRuleModal/types';
import { ProgramData } from 'types/programs';
import { stringModifier } from 'utils/strings';

type CreateBusinessRuleProps = {
  modalRef?: ClosableWrapperRef;
  program?: ProgramData;
  setModalRef: (ref: ClosableWrapperRef) => void;
};

export const CreateBusinessRuleModal = ({ setModalRef, modalRef, program }: CreateBusinessRuleProps) => {
  const [form] = Form.useForm<CreateBaseBusinessRuleFields>();

  const { createBusinessRule, isLoading } = useCreateBusinessRule(modalRef?.close);

  return (
    <ClosableWrapper ref={setModalRef}>
      <h1 className='modal-title'>{stringModifier(text['Add {0}'], 'a Business Rule')}</h1>
      <Spacer height={16} type='flex' />
      <h2 className='modal-description'>
        {stringModifier(text['Add a new business rule to the {0} program.'], program?.programName || '')}
      </h2>
      <Form<CreateBaseBusinessRuleFields>
        requiredMark={false}
        preserve={false}
        layout='vertical'
        className='form'
        form={form}
        onFinish={createBusinessRule}
      >
        {getFieldsJSX(getCreateBusinessRuleFields())}
        <Spacer height={8} type='flex' />
        <ModalActionButtons isLoading={isLoading} onCancel={modalRef?.close} />
      </Form>
    </ClosableWrapper>
  );
};
