import { Image } from 'antd';

import clx from 'classnames';
import { ClosableWrapper, ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { PdfViewer } from 'components/PdfViewer/PdfViewer';
import { VideoViewer } from 'components/VideoViewer/VideoViewer';
import { useChildrenRef } from 'hooks';
import 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Media/components/style.scss';
import { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useState } from 'react';
import { Media } from 'types/programs';

export type ViewMediaModalRef = {
  open: (media: Media) => void;
};

const ViewMediaModalComponent: ForwardRefRenderFunction<ViewMediaModalRef> = (_, ref) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [modalRef, setModalRef] = useChildrenRef<ClosableWrapperRef>();
  const [previewItem, setPreviewItem] = useState<Media>();

  const openHandler = (mediaItem: Media) => {
    setPreviewItem(mediaItem);
    mediaItem.mediaType === 'IMAGE' ? setPreviewVisible(true) : modalRef?.open();
  };

  useImperativeHandle(
    ref,
    () => ({
      open: openHandler,
    }),
    [modalRef]
  );

  const { mediaUrl, mediaName, mediaType } = previewItem || {};

  return (
    <>
      <Image
        preview={{
          title: mediaName,
          animation: false,
          visible: previewVisible,
          src: mediaUrl,
          onVisibleChange: () => setPreviewVisible(false),
        }}
      />
      <ClosableWrapper
        ref={setModalRef}
        className={clx({
          'preview-modal-wrapper': mediaType === 'PDF',
          'preview-video-modal-wrapper': mediaType === 'VIDEO',
        })}
      >
        {mediaType === 'PDF' && !!mediaUrl && <PdfViewer source={mediaUrl} />}
        {mediaType === 'VIDEO' && !!mediaUrl && <VideoViewer source={mediaUrl} />}
      </ClosableWrapper>
    </>
  );
};

export const ViewMediaModal = forwardRef(ViewMediaModalComponent);
