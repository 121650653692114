import { useQuery } from 'react-query';
import { queryKeys } from 'services/constants';
import { fetchServicesByProgram } from 'services/programs/services';
import { serverToClientServiceMapper } from './mappers';

export const useServices = (programId = '') => {
  const {
    isLoading,
    data,
    refetch: refetchServices,
  } = useQuery([queryKeys.getServices, programId], () => fetchServicesByProgram(programId), {
    enabled: !!programId,
    select: (response) => serverToClientServiceMapper(response.data.results),
  });

  return { isLoading, data, refetchServices };
};
