import { useRef } from 'react';
import { FormInstance, Row } from 'antd';
import { MoreOptionsCell, Spacer } from 'components';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { EditableButtons } from 'components/EditableButtons/EditableButtons';
import { ConditionalWrapper } from 'components/ShowWrapper/ConditionalWrapper';
import { useCoverProgramEditStatusCallbacks } from 'pages/ProgramDetails/hooks/useCoverProgramEditStatusCallbacks';
import { showCancelEditedToast } from 'pages/ProgramDetails/utils';
import { AppReturnStateProps } from 'types/helpers';
import { UpdateNotificationTileFields } from '../types';
import { useNotificationTileOptions } from '../hooks/useNotificationTileOptions';

type NotificationTileDetailsActionsProps = {
  form: FormInstance<UpdateNotificationTileFields>;
} & AppReturnStateProps<'editMode', boolean>;

export const NotificationTileDetailsActions = ({
  form,
  setEditMode,
  isEditMode,
}: NotificationTileDetailsActionsProps) => {
  const cancelModalRef = useRef<ClosableWrapperRef>(null);
  const saveModalRef = useRef<ClosableWrapperRef>(null);

  const {
    isLoading: isUpdateEditStatusLoading,
    onEdit,
    onClose,
  } = useCoverProgramEditStatusCallbacks({
    onEdit: () => setEditMode(true),
    onClose: () => {
      showCancelEditedToast();
      form.resetFields();
      setEditMode(false);
    },
  });

  const { options, isActive, isLoading: isNotificationTileOptions } = useNotificationTileOptions();

  const isLoading = isUpdateEditStatusLoading || isNotificationTileOptions;

  return (
    <Row>
      <EditableButtons
        onEdit={onEdit}
        disable={isActive}
        isLoading={isLoading}
        isEditMode={isEditMode}
        onSave={saveModalRef.current?.open}
        onCancel={cancelModalRef.current?.open}
        saveModal={{
          ref: saveModalRef.current,
          setRef: saveModalRef,
          onConfirm: form.submit,
        }}
        cancelModal={{
          ref: cancelModalRef.current,
          setRef: cancelModalRef,
          onConfirm: onClose,
        }}
      />
      <ConditionalWrapper hideVisually condition={!isEditMode}>
        <Spacer width={16} />
        <MoreOptionsCell options={options} withButton />
      </ConditionalWrapper>
    </Row>
  );
};
