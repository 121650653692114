import { useState } from 'react';
import { useQuery } from 'react-query';
import { checkRegistrationLink } from 'services/auth/auth';
import { queryKeys } from 'services/constants';

export const useCheckCodeRequest = (code: string) => {
  const [isCodeExpired, setCodeExpired] = useState(false);

  const { isLoading } = useQuery(queryKeys.checkRegistrationLink, () => checkRegistrationLink(code), {
    onError: () => setCodeExpired(true),
  });

  return {
    isLoading,
    isCodeExpired,
  };
};
