import { Form, FormProps, Spin } from 'antd';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { EditableButtons } from 'components/EditableButtons/EditableButtons';
import { ConditionalWrapper } from 'components/ShowWrapper/ConditionalWrapper';
import { useFinancialAssistance, useUpdateFinancialAssistance } from 'hooks/api/financialAssistance';
import { useCreateFinancialAssistance } from 'hooks/api/financialAssistance/useCreateFinancialAssistance';
import { useUpdateStateEffect } from 'hooks/useUpdateEffect';
import { useUpdateFormEffect } from 'hooks/useUpdateFormEffect';
import { useCoverProgramEditStatusCallbacks } from 'pages/ProgramDetails/hooks/useCoverProgramEditStatusCallbacks';
import { FinancialAssistanceTab } from 'pages/ProgramDetails/programTabComponents/FinancialAssistance/tabsConfig/FinancialAssistanceTab/FinancialAssistanceTab';
import { FinancialAssistanceFields } from 'pages/ProgramDetails/programTabComponents/FinancialAssistance/tabsConfig/FinancialAssistanceTab/types';
import { toFinancialAssistanceFields } from 'pages/ProgramDetails/programTabComponents/FinancialAssistance/tabsConfig/FinancialAssistanceTab/utils';
import { showCancelEditedToast } from 'pages/ProgramDetails/utils';
import { useRef, useState } from 'react';
import { FinancialAssistanceType } from 'services/financialAssistance/types';
import { FinancialAssistanceTabs, FinancialAssistanceUseTabsReturnType } from '../types';
import { useFATabPermissions } from './useFATabPermissions';

type UseFinancialAssistanceCardsTabConfig = {
  title: string;
  type: FinancialAssistanceType;
  tabKey: FinancialAssistanceTabs;
};

export const useFinancialAssistanceCardsTab = ({
  title,
  type,
  tabKey,
}: UseFinancialAssistanceCardsTabConfig): FinancialAssistanceUseTabsReturnType<{ isEditMode: boolean }> => {
  const cancelModalRef = useRef<ClosableWrapperRef>(null);
  const saveModalRef = useRef<ClosableWrapperRef>(null);

  const { onSave, onEdit, onClose, isLoading } = useCoverProgramEditStatusCallbacks({
    onEdit: () => setEditMode(true),
    onClose: () => setEditMode(false),
    onSave: () => setEditMode(false),
  });

  const [form] = Form.useForm<FinancialAssistanceFields>();

  const { tabHasPermissionEditFAProgramMapper } = useFATabPermissions();

  const { financialAssistance, isLoading: isGetLoading, isEmpty } = useFinancialAssistance<false>(type);

  const { createFinancialAssistance, isLoading: isCreateLoading } = useCreateFinancialAssistance(type, onSave);
  const { updateFinancialAssistance, isLoading: isUpdateLoading } = useUpdateFinancialAssistance(type, onSave);

  const [isEditMode, setEditMode] = useState(false);
  const [isFormHidden, setFormHidden] = useState(isEmpty);

  useUpdateFormEffect(form, toFinancialAssistanceFields(financialAssistance), [financialAssistance]);
  useUpdateStateEffect(setFormHidden, isEmpty, [isEmpty]);

  const onFinish: FormProps<FinancialAssistanceFields>['onFinish'] = (values) => {
    (isEmpty ? createFinancialAssistance : updateFinancialAssistance)(values, financialAssistance.uuid);
  };

  const isFinancialAssistanceLoading = isCreateLoading || isUpdateLoading || isGetLoading;

  return {
    tab: {
      title,
      tabKey,
      tabData: {
        content: (
          <Spin spinning={isFinancialAssistanceLoading}>
            <FinancialAssistanceTab
              isEditMode={isEditMode}
              setEditMode={setEditMode}
              uuid={financialAssistance.uuid}
              isFormHidden={isFormHidden}
              setFormHidden={setFormHidden}
              onFinish={onFinish}
              initialValues={toFinancialAssistanceFields(financialAssistance)}
              key={title}
              form={form}
            />
          </Spin>
        ),
        actions: (
          <ConditionalWrapper hideVisually condition={!isFormHidden && tabHasPermissionEditFAProgramMapper[tabKey]}>
            <EditableButtons
              isEditMode={isEditMode}
              onEdit={onEdit}
              isLoading={isLoading}
              onSave={saveModalRef.current?.open}
              onCancel={cancelModalRef.current?.open}
              saveModal={{
                ref: saveModalRef.current,
                setRef: (ref) => (saveModalRef.current = ref),
                onConfirm: form.submit,
              }}
              cancelModal={{
                ref: cancelModalRef.current,
                setRef: (ref) => (cancelModalRef.current = ref),
                onConfirm: () => {
                  showCancelEditedToast();
                  form.resetFields();
                  onClose();
                  if (isEmpty) setFormHidden(true);
                },
              }}
            />
          </ConditionalWrapper>
        ),
      },
    },
    extra: { isEditMode },
  };
};
