import { AnyObject } from 'types/helpers';
import { DevError } from './errors';
import Cookies from 'js-cookie';
import cookieKeys from 'constants/cookieKeys';

const authPages = ['/login', '/set-password', '/reset-password'];

export const isAuthPage = (): boolean => {
  const { pathname } = window.location;
  return authPages.some((page) => pathname.includes(page));
};

export const isDev = () => !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const search = <T extends AnyObject>(data: T[], field: keyof T, query: string) =>
  data.filter((item) => {
    const value = item[field];

    if (typeof value !== 'string') throw new DevError('Search field should be a string');

    return value.toLowerCase().includes(query.toLowerCase());
  });

export const getStorageItem = <T>(key: string, initialValue: T) => {
  try {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : initialValue;
  } catch (error) {
    return initialValue;
  }
};

export const setCookies = (cookieValues: Record<string, string>) =>
  Object.entries(cookieValues).forEach(([key, value]) => {
    Cookies.set(key, value);
  });

export const removeCookies = () => Object.values(cookieKeys).forEach((key) => Cookies.remove(key));
