import { useMutation, useQueryClient } from 'react-query';
import { updateAdminStatus } from 'services/users/users';
import { queryKeys } from 'services/constants';
import { showCustomToast } from 'components';
import { text } from 'constants/texts';
import { stringModifier } from 'utils/strings';

export const useUpdateAdminStatus = (adminFullName: string) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(updateAdminStatus, {
    mutationKey: queryKeys.updateAdminStatus,
    onSuccess: (response) => {
      const active = response.data.status === 'Active';
      showCustomToast({
        type: active ? 'success' : 'missing',
        message: stringModifier(
          text['{0}`s account has been {1}.'],
          adminFullName,
          text[active ? 'activated' : 'deactivated']
        ),
      });
      queryClient.invalidateQueries(queryKeys.getAdmins);
    },
  });

  return { isLoading, updateAdminStatus: mutate };
};
