import { text } from 'constants/texts';
import { ProgramData } from 'types/programs';
import { MediaTabActions } from '../ContentTabComponents/Media/MediaTabActions';
import { MediaTabContent } from '../ContentTabComponents/Media/MediaTabContent';
import { ContentUseTabsReturnType } from './types';

export const useMediaTab = (program?: ProgramData): ContentUseTabsReturnType => ({
  tab: {
    title: text['Media'],
    tabData: {
      actionsJSX: <MediaTabActions program={program} />,
      contentJSX: <MediaTabContent program={program} />,
    },
    tabKey: 'medias',
  },
});
