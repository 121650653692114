import { useMutation, useQueryClient } from 'react-query';
import { createAdmin } from 'services/users/users';
import { queryKeys } from 'services/constants';
import { errorHandler } from 'utils/errorHandler';
import { Callback } from 'types/helpers';

export const useCreateAdmin = (onSuccess?: Callback) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(createAdmin, {
    mutationKey: queryKeys.createAdmin,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.getAdmins);
      onSuccess?.();
    },
    onError: errorHandler,
  });

  return {
    isLoading,
    createAdmin: mutate,
  };
};
