import { Spin } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { DataTable, MoreOptionsCell, Spacer, StatusCell, TextCell } from 'components';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { EmptyDataListComponent } from 'components/EmptyDataListComponent/EmptyDataListComponent';
import { stopPropagationOnCell } from 'constants/common';
import { text } from 'constants/texts';
import { useChildrenRef, usePermissionsHandler } from 'hooks';
import { useBusinessRules } from 'hooks/api/rules/useBusinessRules';
import { useDeleteBusinessRule } from 'hooks/api/rules/useDeleteBusinessRule';
import { useUpdateBusinessRule } from 'hooks/api/rules/useUpdateBusinessRule';
import { CreateBusinessRuleModal } from 'pages/ProgramDetails/programTabComponents/BusinessRules/components/CreateBusinessRuleModal/CreateBusinessRuleModal';
import { canActivateBusinessRule } from 'pages/ProgramDetails/programTabComponents/BusinessRules/utils';
import { useGoToBusinessRuleDetailsPage } from 'routes/navigators';
import { BusinessRule } from 'types/rules';
import { getFontClassName } from 'utils/getFontClassName';
import { stringModifier } from 'utils/strings';
import { usePagination } from 'hooks/usePagination';
import { useEffect } from 'react';

const BusinessRuleStatusColumn = ({ rule }: { rule: BusinessRule }) => {
  const { updateBusinessRule, isLoading } = useUpdateBusinessRule();
  const [isEditRulesAllowed] = usePermissionsHandler(['rules.edit']);

  return (
    <Spin size='small' spinning={isLoading}>
      <StatusCell
        disabled={!isEditRulesAllowed}
        status={rule.status || text['Inactive']}
        statuses={[
          { key: text['Active'], label: text['Activate'], disabled: !canActivateBusinessRule(rule) },
          { key: text['Inactive'], label: text['Deactivate'] },
        ]}
        changeStatus={(newStatus) => updateBusinessRule(rule, newStatus === 'Active')}
      />
    </Spin>
  );
};

const BusinessRuleMoreButtonColumn = ({ rule }: { rule: BusinessRule }) => {
  const { deleteBusinessRule, isLoading } = useDeleteBusinessRule(rule.uuid || '');

  const [isDeleteRulesAllowed] = usePermissionsHandler(['rules.delete']);

  return (
    <Spin size='small' spinning={isLoading}>
      <MoreOptionsCell
        options={[
          {
            label: text['Delete'],
            key: '0',
            request: deleteBusinessRule,
            disabled: !isDeleteRulesAllowed,
            modalInfo: {
              title: stringModifier(text['Delete {0}'], rule.name),
              description: stringModifier(text['Are you sure you want to delete this {0}?'], text['Business Rule']),
            },
          },
        ]}
      />
    </Spin>
  );
};

const columns: ColumnsType<BusinessRule> = [
  {
    width: '55%',
    title: text['Business Rule Name'],
    dataIndex: 'name',
    render: (value) => <TextCell color='blue' className={getFontClassName('button')} text={value} />,
  },
  {
    width: '25%',
    title: text['Users in Event'],
    dataIndex: 'usersInEvent',
    render: (value) => (
      <TextCell
        color='gray2'
        className={getFontClassName('paragraph')}
        text={stringModifier(text['{0} Users'], value)}
      />
    ),
  },
  {
    width: '20%',
    title: text['Status'],
    dataIndex: 'status',
    render: (_, record) => <BusinessRuleStatusColumn rule={record} />,
    onCell: stopPropagationOnCell,
  },
  {
    title: '',
    dataIndex: 'showMore',
    render: (_, record) => <BusinessRuleMoreButtonColumn rule={record} />,
    onCell: stopPropagationOnCell,
  },
];

export const BusinessRulesTabContent = () => {
  const [isCreateRuleAllowed] = usePermissionsHandler(['rules.create']);
  const [modalRef, setModalRef] = useChildrenRef<ClosableWrapperRef>();
  const { pageNumber, pageSize, updateItemCount, paginationConfig } = usePagination({
    tab: 'businessRules',
  });
  const { businessRules, totalCount, isLoading, hasData } = useBusinessRules({ pageNumber, pageSize });

  useEffect(() => {
    if (!totalCount) return;
    updateItemCount(totalCount);
  }, [totalCount, updateItemCount]);

  const goToBusinessRule = useGoToBusinessRuleDetailsPage();

  return (
    <Spin size='large' spinning={isLoading}>
      <Spacer height={8} />
      {hasData ? (
        <DataTable
          loading={isLoading}
          rowKey='id'
          columns={columns}
          dataSource={businessRules}
          paginationConfig={paginationConfig}
          onRow={(record) => ({ onClick: () => goToBusinessRule(record.uuid) })}
        />
      ) : (
        <EmptyDataListComponent
          title={stringModifier(text["We couldn't find any {0} here."], 'Business Rules')}
          description={stringModifier(text['Click the button below to add new {0}'], 'Business Rule')}
          addButtonText={stringModifier(text['Add {0}'], 'New Rule')}
          addButtonDisabled={!isCreateRuleAllowed}
          onButtonPress={modalRef?.open}
        />
      )}
      <CreateBusinessRuleModal setModalRef={setModalRef} modalRef={modalRef} />
    </Spin>
  );
};
