import { Form } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { Button } from 'components';
import { AnswerField } from './AnswerField';
import { Answer } from '../programTabComponents/AdditionalServices/types';

export type DynamicAnswersFieldsProps = {
  name: NamePath;
  isEditMode?: boolean;
};

export const DynamicAnswersFields = ({ name, isEditMode = true }: DynamicAnswersFieldsProps) => {
  return (
    <Form.List name={name}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name }) => (
            <AnswerField key={key} childIndex={name} remove={remove} isEditMode={isEditMode} />
          ))}
          {isEditMode && fields.length < 5 && (
            <Button type='ghost' onClick={() => add({ text: '' })}>
              Add Answer
            </Button>
          )}
        </>
      )}
    </Form.List>
  );
};
