import React from 'react';

import { Modal, ModalProps } from 'antd';
import cn from 'classnames';

import './style.scss';
import { CloseVector } from 'icons';

export const ModalAsync = ({ children, ...modalProps }: ModalProps) => {
  return (
    <Modal
      {...modalProps}
      footer={null}
      title={null}
      className={cn('pl-modal', modalProps.className)}
      closeIcon={<CloseVector stroke='#1D242D' strokeWidth={2} />}
      centered
    >
      {children}
    </Modal>
  );
};
