import { Layout } from 'antd';

import './style.scss';

const AppFooter = () => (
  <Layout.Footer
    style={{
      padding: 0,
      height: 32,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#fff',
      borderTopWidth: '1px',
      borderColor: '#C9C9C9',
      borderStyle: 'solid',
    }}
  >
    Powered by Zealic Solutions, INC.
  </Layout.Footer>
);

export default AppFooter;
