import { toBenefitDTO } from 'hooks/api/benefits/mappers';
import { UpdateBenefitsFields } from 'pages/ProgramDetails/programTabComponents/Benefits/types';
import { useMutation, useQueryClient } from 'react-query';
import { updateBenefit } from 'services/benefits/requests';
import { queryKeys } from 'services/constants';
import { errorHandler } from 'utils/errorHandler';

export const useUpdateBenefits = () => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(updateBenefit, {
    mutationKey: queryKeys.updateBenefit,
    onSuccess: () => queryClient.invalidateQueries(queryKeys.getBenefits),
    onError: errorHandler,
  });

  return {
    isLoading,
    updateBenefits: (data: UpdateBenefitsFields) =>
      data.benefits.forEach((benefit) => {
        mutate(toBenefitDTO(benefit));
      }),
  };
};
