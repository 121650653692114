interface Limit
  extends Readonly<{
    MAX: number;
    MIN: number;
    isMin: (count: number) => boolean;
    isMax: (count: number) => boolean;
  }> {}

export const MILESTONE_TRACKER_STEPS_LIMIT: Limit = {
  MAX: 5,
  MIN: 2,
  isMax: (count) => 5 > count,
  isMin: (count) => 2 <= count,
};

export const BUSINESS_RULES_CONDITIONS_LIMIT: Limit = {
  MAX: 5,
  MIN: 2,
  isMax: (count) => 5 > count,
  isMin: (count) => 2 <= count,
};

export const BUSINESS_RULES_ACTIONS_LIMIT: Limit = {
  MAX: 5,
  MIN: 0,
  isMax: (count) => 5 > count,
  isMin: (count) => 0 <= count,
};
