import { useState } from 'react';
import { Dropdown, Menu } from 'antd';

import { statusRenderer } from 'pages/shared';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { usePermissionsHandler } from 'hooks';

export const MediaStatusActions = () => {
  const [isEditMediaAllowed] = usePermissionsHandler(['media_data.edit']);
  const [status, setStatus] = useState<'Active' | 'Inactive'>('Active');

  const statusButtonText = status === 'Active' ? 'Deactivate' : 'Activate';

  const changeStatusButtonAction: ItemType[] = [
    {
      label: statusButtonText,
      onClick: () => setStatus(status === 'Active' ? 'Inactive' : 'Active'),
      key: '0',
      disabled: !isEditMediaAllowed,
    },
  ];

  const items = <Menu items={changeStatusButtonAction} />;

  return (
    <Dropdown overlay={items} placement='topRight'>
      {statusRenderer(status)}
    </Dropdown>
  );
};
