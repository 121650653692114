import { AppField, AppFields } from 'components/fields/types';
import { text } from 'constants/texts';
import { CreateMilestoneTrackerFields } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/MilestoneTrackers/types';
import { getInputPlaceholder, stringModifier } from 'utils/strings';
import { RULES } from 'utils/validations';

const { REQUIRED, MAX } = RULES;

export const getCreateMilestoneTrackerFields = (): AppFields<CreateMilestoneTrackerFields> => [
  milestoneTrackerNameField,
  milestoneTrackerDescriptionField,
];

export const milestoneTrackerNameField: AppField<Pick<CreateMilestoneTrackerFields, 'name'>> = {
  type: 'input',
  name: 'name',
  label: text['Milestone Tracker Name'],
  placeholder: stringModifier(text['{0} Characters Max'], '50'),
  rules: [REQUIRED({ requiredFieldName: text['Milestone Tracker Name'] }), MAX({ max: 75 })],
};

export const milestoneTrackerDescriptionField: AppField<Pick<CreateMilestoneTrackerFields, 'description'>> = {
  type: 'input',
  name: 'description',
  renderType: 'TextArea',
  rows: 2,
  label: text['Milestone Tracker Description'],
  placeholder: getInputPlaceholder(text['Description']),
  rules: [MAX({ max: 100 })],
};
