import { Row } from 'antd';
import { AppDatePickerProps } from 'components/fields/AppDatePicker/AppDatePicker';
import { AppSelectProps, withSelectRequestMock } from 'components/fields/AppSelect/AppSelect';
import { AppSwitchProps } from 'components/fields/AppSwitch/AppSwitch';
import { SelectUploadConfig } from 'components/fields/AppUpload/components/SelectUpload/SelectUpload';
import { format as dateFnsFormat } from 'date-fns';
import { Fragment, useMemo } from 'react';
import { useQuery } from 'react-query';
import { hexColorRegex } from 'utils/regulars';

import '../style.scss';

export type AppFieldInfoProps<T = unknown> = Partial<{
  id: string;
  value: any;
  checked: boolean;
  withRequest: AppSelectProps<T>['withRequest'];
  options: AppSelectProps<T>['options'];
  labels: AppSwitchProps['labels'];
  fileName: SelectUploadConfig['fileName'];
  format: AppDatePickerProps['format'];
}>;

export const AppFieldInfo = <T extends unknown>({
  id,
  value = '',
  withRequest: { request, optionsMapper, queryKey } = withSelectRequestMock,
  options,
  checked,
  labels,
  fileName,
  format = 'MMMM dd, yyyy',
}: AppFieldInfoProps<T>) => {
  // Initial value for each of fields ->

  const { data } = useQuery(queryKey, request); // SELECT
  const selectOptions = queryKey ? optionsMapper(data as T) : options; // SELECT

  const dataJSX = useMemo(() => {
    if (selectOptions) return selectOptions.find((option) => option.value === value)?.label || ''; // SELECT

    if (labels) return labels[checked ? 'checked' : 'unchecked']; // SWITCH

    if (hexColorRegex.test(value))
      return (
        <Row align='middle'>
          <div
            style={{
              marginRight: 6,
              width: 16,
              height: 16,
              backgroundColor: value,
            }}
          />
          {value}
        </Row>
      ); // COLOR-PICKER

    if (typeof fileName === 'string') return <span className='app-view-text-blue'>{fileName}</span>; // FILE-PICKER

    if (value instanceof Date) return dateFnsFormat(value, format); // DATE-PICKER

    // -> Next will be value for Input fields

    const separatedValuesByEnter = `${value}`.split('\n');

    return separatedValuesByEnter.map((value, i) => (
      <Fragment key={i}>
        {value}
        {/* br will need to simulate \n functionality */}
        <br />
      </Fragment>
    ));
  }, [value, checked, selectOptions]);
  // <-

  return (
    <span id={id} className='app-view-text'>
      {dataJSX}
    </span>
  );
};
