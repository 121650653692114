import { FormInstance } from 'antd';
import {
  getBrandingFields,
  getProgramCardFields,
  legalTermsLinkFields,
  programContactInformationFields,
} from 'pages/ProgramDetails/programTabComponents/UniversalSettings/fields';
import {
  UniversalSettingsFields,
  UniversalSettingsSection,
} from 'pages/ProgramDetails/programTabComponents/UniversalSettings/types';
import { ProgramData } from 'types/programs';

export const getUniversalSettingsSections = (
  program: ProgramData,
  form: FormInstance<UniversalSettingsFields>
): UniversalSettingsSection[] => [
  {
    title: 'Branding',
    fields: getBrandingFields(program, form),
  },
  {
    title: 'Program Contact Information',
    fields: programContactInformationFields,
  },
  {
    title: 'Legal Terms Links',
    fields: legalTermsLinkFields,
  },
  {
    title: 'About the Program Card',
    fields: getProgramCardFields(program, form),
  },
];
