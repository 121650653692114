import { toMilestoneTrackerDTO } from 'hooks/api/milestoneTrackers/mappers';
import { CreateMilestoneTrackerFields } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/MilestoneTrackers/types';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useGoToMilestoneTrackerDetailsPage } from 'routes/navigators';
import { queryKeys } from 'services/constants';
import { createMilestoneTracker } from 'services/milestoneTrackers/requests';
import { Callback } from 'types/helpers';
import { errorHandler } from 'utils/errorHandler';

export const useCreateMilestoneTracker = (onSuccess?: Callback) => {
  const queryClient = useQueryClient();
  const { programId = '' } = useParams();

  const goToMilestoneTrackerDetailsPage = useGoToMilestoneTrackerDetailsPage();

  const { mutate, isLoading } = useMutation(createMilestoneTracker, {
    mutationKey: queryKeys.createMilestoneTracker,
    onSuccess: async (res) => {
      await queryClient.invalidateQueries(queryKeys.getMilestoneTrackers);
      onSuccess?.();
      goToMilestoneTrackerDetailsPage(res.uuid);
    },
    onError: errorHandler,
  });

  return {
    isLoading,
    createMilestoneTracker: (tracker: CreateMilestoneTrackerFields) =>
      mutate(
        toMilestoneTrackerDTO({
          ...tracker,
          manufactureProgramUuid: programId,
        })
      ),
  };
};
