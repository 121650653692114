import cookieKeys from 'constants/cookieKeys';
import { usePrevious } from 'hooks';
import Cookies from 'js-cookie';
import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { AdminRoleName, Permissions, UserData } from 'types';
import { AuthContext } from './AuthContext';
import { ManufacturerDTO } from 'services/programs/types';
import { removeCookies } from 'utils/helpers';

export type AuthProviderProps = PropsWithChildren<{
  isAuthenticated?: boolean;
  userId?: string;
  userData?: UserData;
  permissionsNames?: string;
  manufacturersIds?: string;
  manufacturersNames?: string;
}>;

export const AuthProvider = ({
  isAuthenticated = !!Cookies.get(cookieKeys.TOKEN),
  userId = Cookies.get(cookieKeys.USER_ID) || '',
  permissionsNames = Cookies.get(cookieKeys.PERMISSION) || '',
  manufacturersIds = Cookies.get(cookieKeys.USER_MANUFACTURERS_IDS) || '',
  manufacturersNames = Cookies.get(cookieKeys.USER_MANUFACTURERS_NAMES) || '',
  userData = {
    email: Cookies.get(cookieKeys.USER_EMAIL || ''),
    lastName: Cookies.get(cookieKeys.USER_LAST_NAME || ''),
    firstName: Cookies.get(cookieKeys.USER_FIRST_NAME || ''),
    role: {
      id: Cookies.get(cookieKeys.USER_ROLE_ID || ''),
      name: Cookies.get(cookieKeys.USER_ROLE_NAME || '') as AdminRoleName,
    },
  },
  children,
}: AuthProviderProps) => {
  const [authenticated, setAuthenticated] = useState(isAuthenticated);
  const previousAuthenticated = usePrevious(authenticated);

  const permissions = permissionsNames?.split(',') as unknown as Permissions[];
  const adminManufacturersIds = manufacturersIds?.split(',') as unknown as ManufacturerDTO['id'][];
  const adminManufacturersNames = manufacturersNames?.split(',') as unknown as ManufacturerDTO['name'][];
  const manufacturers = adminManufacturersIds?.map((id, i) => ({
    id: id ?? '',
    name: adminManufacturersNames[i] ?? '',
  }));

  const isSuperAdmin = permissions.some((perm) => perm === 'admins.create');

  useEffect(() => {
    // Log out case
    if (previousAuthenticated && !authenticated) removeCookies();
  }, [previousAuthenticated, authenticated]);

  const contextValue = useMemo(
    () => ({
      authenticated,
      setAuthenticated,
      isSuperAdmin,
      userData: { ...userData, manufacturers },
      userId,
      permissions,
    }),
    [isSuperAdmin, manufacturers, permissions, userData, authenticated, userId]
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};
