import { toBenefit } from 'hooks/api/benefits/mappers';
import { useQuery } from 'react-query';
import { getBenefitList } from 'services/benefits/requests';
import { queryKeys } from 'services/constants';

export const useBenefits = () => {
  const { data, isLoading } = useQuery(queryKeys.getBenefits, getBenefitList, {
    select: (response) => response.data?.map(toBenefit),
  });

  return {
    isLoading,
    benefits: data || [],
    hasData: !!data?.length || isLoading,
  };
};
