import clx from 'classnames';

export type Color = 'gray1' | 'gray2';
export type FontType = 'paragraph' | 'button' | 'label' | 'h2';

const fontClassNames: Record<FontType, string> = {
  paragraph: 'font-paragraph',
  button: 'font-button',
  label: 'font-label',
  h2: 'font-h2',
};

const colorClassNames: Record<Color, string> = {
  gray1: 'font-color-gray1',
  gray2: 'font-color-gray2',
};

export const getFontClassName = (font: FontType, color?: Color) =>
  clx(fontClassNames[font], color && colorClassNames[color]);
