import { Form } from 'antd';
import { ClosableWrapper, Spacer } from 'components';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { ModalActionButtons } from 'components/fields/AppActionButtons/ModalActionButtons';
import { getFieldsJSX } from 'components/fields/utils';
import { text } from 'constants/texts';
import { useCreateBenefit } from 'hooks/api/benefits/useCreateBenefit';
import { getCreateBenefitFields } from 'pages/ProgramDetails/programTabComponents/Benefits/fields';
import { CreateBaseBusinessRuleFields } from 'pages/ProgramDetails/programTabComponents/BusinessRules/components/CreateBusinessRuleModal/types';
import { ProgramData } from 'types/programs';
import { stringModifier } from 'utils/strings';

type CreateBenefitProps = {
  modalRef?: ClosableWrapperRef;
  program?: ProgramData;
  setModalRef: (ref: ClosableWrapperRef) => void;
};

export const CreateBenefitModal = ({ setModalRef, modalRef, program }: CreateBenefitProps) => {
  const [form] = Form.useForm<CreateBaseBusinessRuleFields>();

  const { createBenefit, isLoading } = useCreateBenefit(modalRef?.close);

  return (
    <ClosableWrapper ref={setModalRef}>
      <h1 className='modal-title'>{stringModifier(text['Add {0}'], 'a Benefit Phase')}</h1>
      <Spacer height={16} type='flex' />
      <h2 className='modal-description'>
        {stringModifier(
          text['Add a new phase for the benefit approval process for the {0} program.'],
          program?.programName || ''
        )}
      </h2>
      <Form<CreateBaseBusinessRuleFields>
        requiredMark={false}
        preserve={false}
        layout='vertical'
        className='form'
        form={form}
        onFinish={createBenefit}
      >
        {getFieldsJSX(getCreateBenefitFields())}
        <Spacer height={8} type='flex' />
        <ModalActionButtons isLoading={isLoading} onCancel={modalRef?.close} />
      </Form>
    </ClosableWrapper>
  );
};
