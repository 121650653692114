import { Breadcrumb } from 'antd';
import { RightArrow } from 'icons/RightArrow';
import { Link, useLocation } from 'react-router-dom';

import { BreadCrumb } from 'types';

type BreadCrumbProps = {
  crumbs?: BreadCrumb[];
};

const BreadCrumbs = ({ crumbs }: BreadCrumbProps) => {
  const { search } = useLocation();
  const searchParams = search.substring(0, search.lastIndexOf('&'));

  const renderBreadCrumbs = () => {
    return crumbs?.map(({ to, name, withParams = false }) => (
      <Breadcrumb.Item key={name}>
        {to ? <Link to={withParams ? to + searchParams : to}>{name}</Link> : name}
      </Breadcrumb.Item>
    ));
  };

  return (
    <Breadcrumb
      separator={
        <span style={{ position: 'relative', top: 3 }}>
          <RightArrow />
        </span>
      }
      style={{ marginTop: 32 }}
    >
      {renderBreadCrumbs()}
    </Breadcrumb>
  );
};

export default BreadCrumbs;
