import { Form } from 'antd';
import { ClosableWrapper, Spacer } from 'components';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { ModalActionButtons } from 'components/fields/AppActionButtons/ModalActionButtons';
import { getFieldsJSX } from 'components/fields/utils';
import { getSpecialtyPharmaciesFields } from './constants';

import { ProgramData } from 'types/programs';
import { useCreateSpecialtyPharmacy } from 'hooks/api/specialtyPharmacy/useCreateSpecialtyPharmacy';
import { SpecialtyPharmacy } from 'types/specialtyPharmacy';
import { SpecialtyPharmaciesFields } from 'pages/ProgramDetails/specialtyPharmaciesFields';
import { scrollToFirstErrorProps } from 'constants/common';

const { useForm } = Form;

type CreateSpecialtyPharmacyModalProps = {
  setModalRef?: (ref: ClosableWrapperRef) => void;
  modalRef?: ClosableWrapperRef;
  program?: ProgramData;
};

export const CreateSpecialtyPharmacyModal = ({ setModalRef, modalRef, program }: CreateSpecialtyPharmacyModalProps) => {
  const [form] = useForm<SpecialtyPharmaciesFields>();

  const { createSpecialtyPharmacy, isLoading } = useCreateSpecialtyPharmacy(modalRef?.close);

  if (!program) return null;

  return (
    <ClosableWrapper ref={setModalRef}>
      <h1 className='modal-title'>Add a Specialty Pharmacy</h1>
      <Spacer height={16} type='flex' />
      <h2 className='modal-description'>
        {`Add a new specialty pharmacy to the ${program?.programName || ''} program.`}
      </h2>
      <Spacer height={16} type='flex' />
      <Form<SpecialtyPharmacy>
        form={form}
        layout='vertical'
        preserve={false}
        requiredMark={false}
        onFinish={createSpecialtyPharmacy}
        scrollToFirstError={scrollToFirstErrorProps}
      >
        {getFieldsJSX(getSpecialtyPharmaciesFields())}
        <Spacer height={8} type='flex' />
        <ModalActionButtons isLoading={isLoading} onCancel={modalRef?.close} />
      </Form>
    </ClosableWrapper>
  );
};
