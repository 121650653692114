import { Spin } from 'antd';
import clx from 'classnames';
import { FileType } from 'components/fields/AppUpload/AppUpload';
import { getSearchFileParamFromFileTypes } from 'components/fields/AppUpload/utils';
import { useMedias } from 'hooks/api/programs/useMedias';
import { DocFileIcon, ImageFileIcon, VideoFileIcon } from 'icons';
import { forwardRef, ForwardRefRenderFunction, ReactNode, useEffect, useImperativeHandle, useState } from 'react';
import { MetaTypes } from 'services/medias/types';
import { Callback } from 'types/helpers';
import { Media } from 'types/programs';
import { getFontClassName } from 'utils/getFontClassName';
import './styles.scss';

type PickMediaListProps = {
  programId?: string;
  fileTypes?: FileType[];
  onChange: (media?: Media) => void;
};

export type PickMediaListRef = {
  reset: Callback;
};

export const fileIconsMapping: Record<MetaTypes, ReactNode> = {
  IMAGE: <ImageFileIcon />,
  PDF: <DocFileIcon />,
  VIDEO: <VideoFileIcon />,
};

const PickMediaListComponent: ForwardRefRenderFunction<PickMediaListRef, PickMediaListProps> = (
  { programId, onChange, fileTypes },
  ref
) => {
  const { isLoading, data } = useMedias({
    programId: programId || '',
    mediaType: getSearchFileParamFromFileTypes(fileTypes),
  });

  const [selectedMedia, setSelectedMedia] = useState<Media>();

  useImperativeHandle(ref, () => ({
    reset: () => setSelectedMedia(undefined),
  }));

  useEffect(() => onChange(selectedMedia), [selectedMedia]);

  return (
    <div className='pl-media-list'>
      <Spin spinning={isLoading} className='pl-media-list-spin'>
        {data?.map((media) => (
          <button
            onClick={() => setSelectedMedia(media)}
            key={media.id}
            className={clx({
              'pl-media-list-item': true,
              'pl-media-list-item-selected': selectedMedia?.id === media.id,
            })}
          >
            <div className='pl-media-list-item-icon'>{fileIconsMapping[media.mediaType]}</div>
            <div className={getFontClassName('paragraph')}>{media.mediaName}</div>
          </button>
        ))}
      </Spin>
    </div>
  );
};

export const PickMediaList = forwardRef(PickMediaListComponent);
