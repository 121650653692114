import { Button, PermissionWrapper } from 'components';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { useChildrenRef } from 'hooks';
import { CreateMediaModal } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Media/components/CreateMediaModal/CreateMediaModal';
import { ProgramData } from 'types/programs';
import { text } from 'constants/texts';
import { stringModifier } from 'utils/strings';

type TabRightSectionsProps = {
  program?: ProgramData;
};

export const MediaTabActions = ({ program }: TabRightSectionsProps) => {
  const [modalRef, setModalRef] = useChildrenRef<ClosableWrapperRef>();

  if (!program) return null;

  return (
    <PermissionWrapper permissionsRequired={['media_data.create']}>
      <Button onClick={modalRef?.open}>{stringModifier(text['Add {0}'], text['Media'])}</Button>
      <CreateMediaModal setModalRef={setModalRef} modalRef={modalRef} program={program} />
    </PermissionWrapper>
  );
};
