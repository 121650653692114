import { Form, FormInstance, FormProps, Spin } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { Button, DeleteConfirmModal, Spacer } from 'components';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { EmptyDataListComponent } from 'components/EmptyDataListComponent/EmptyDataListComponent';
import { getFieldsJSX } from 'components/fields/utils';
import { scrollToFirstErrorProps } from 'constants/common';
import { text } from 'constants/texts';
import { useDeleteFinancialAssistance } from 'hooks/api/financialAssistance/useDeleteFinancialAssistance';
import { useMountEffect } from 'hooks/useMountEffect';
import { getFinancialAssistanceSections } from 'pages/ProgramDetails/programTabComponents/FinancialAssistance/tabsConfig/FinancialAssistanceTab/sections';
import { FinancialAssistanceFields } from 'pages/ProgramDetails/programTabComponents/FinancialAssistance/tabsConfig/FinancialAssistanceTab/types';
import { Fragment, useRef } from 'react';
import { AppReturnStateProps } from 'types/helpers';
import { ConditionalWrapper } from 'components/ShowWrapper/ConditionalWrapper';
import { useSearchParams } from 'react-router-dom';
import { PROGRAM_CONTENT_TAB_URL_KEY } from 'pages/ProgramDetails/useTabChangeHandler';
import { useFATabPermissions } from './useFATabPermissions';

type FinancialAssistanceTabProps = {
  uuid?: string;
  form: FormInstance<FinancialAssistanceFields>;
  initialValues: FinancialAssistanceFields;
  onFinish: FormProps<FinancialAssistanceFields>['onFinish'];
} & AppReturnStateProps<'editMode', boolean> &
  AppReturnStateProps<'formHidden', boolean>;

export const FinancialAssistanceTab = ({
  isEditMode,
  setEditMode,
  form,
  onFinish,
  uuid,
  setFormHidden,
  isFormHidden,
  initialValues,
}: FinancialAssistanceTabProps) => {
  const deleteModalRef = useRef<ClosableWrapperRef>();

  const [params] = useSearchParams();
  const faTabKey = params.get(PROGRAM_CONTENT_TAB_URL_KEY) || 'copay';

  const { tabHasPermissionCreateFAProgramMapper, tabHasPermissionDeleteFAProgramMapper } = useFATabPermissions();

  const { deleteFinancialAssistance, isLoading } = useDeleteFinancialAssistance(uuid, () => {
    setFormHidden(true);
    setEditMode(false);
    deleteModalRef.current?.close();
  });

  const selectedCriteria = useWatch('terminationCriteria', form);
  const name = useWatch('name', form);

  useMountEffect(
    () => {
      form.setFields([{ name: 'terminationPayload', value: undefined, errors: [] }]);
    },
    [selectedCriteria],
    [!selectedCriteria]
  );

  const addHandler = () => {
    form.setFieldsValue({
      name: '',
      description: '',
      link: '',
      introductionTitle: '',
      introductionDescription: '',
      terminationCriteria: null,
      terminationPayload: '',
      image: null,
    });
    setFormHidden(false);
    setEditMode(true);
  };

  return (
    <Spin spinning={isLoading}>
      <Spacer height={16} />
      <ConditionalWrapper condition={isFormHidden}>
        <EmptyDataListComponent
          title={text['This Financial Program does not exist in this instance.']}
          description={
            text['If you would like to add this type of Financial Program to this instance, click the button below.']
          }
          addButtonText={text['Add Financial Program']}
          addButtonDisabled={!tabHasPermissionCreateFAProgramMapper[faTabKey]}
          onButtonPress={addHandler}
        />
      </ConditionalWrapper>
      <Form<FinancialAssistanceFields>
        layout='vertical'
        className='form'
        scrollToFirstError={scrollToFirstErrorProps}
        form={form}
        onFinish={onFinish}
        initialValues={initialValues}
        hidden={isFormHidden}
        style={{ position: 'relative', marginTop: 0 }}
      >
        <Spacer height={12} type='flex' />
        {getFinancialAssistanceSections(form, initialValues, selectedCriteria).map(({ title, fields }) => (
          <Fragment key={title}>
            <h1 className='form-title'>{title}</h1>
            <Spacer height={24} type='flex' />
            {getFieldsJSX(fields, isEditMode)}
          </Fragment>
        ))}
        <ConditionalWrapper condition={isEditMode && tabHasPermissionDeleteFAProgramMapper[faTabKey]}>
          <Button
            style={{ position: 'absolute', top: 0, right: 0 }}
            onClick={deleteModalRef.current?.open}
            type='text'
            colorType='negative'
          >
            {text['Delete']}
          </Button>
        </ConditionalWrapper>
        <DeleteConfirmModal
          titleName={name || text['Financial Assistance']}
          descriptionName={text['Financial Assistance']}
          onConfirm={deleteFinancialAssistance}
          deleteModalRef={deleteModalRef}
          isLoading={isLoading}
        />
      </Form>
    </Spin>
  );
};
