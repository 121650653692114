import { Form } from 'antd';
import { ContentSteps, ModalActionButtons, showCustomToast, Spacer } from 'components';
import { ContentStepData, ContentStepsRef } from 'components/ContentSteps/ContentSteps';
import { getFieldsJSX } from 'components/fields/utils';
import { PickFileOrMediaModalContent } from 'components/PickFileOrMediaModalContent/PickFileOrMediaModalContent';
import { text } from 'constants/texts';

import { useProgramData } from 'hooks/api';

import { clientToServerServiceMapper } from 'hooks/api/programs/mappers';
import { useCreateService } from 'hooks/api/programs/useCreateService';
import { DynamicAnswersFields } from 'pages/ProgramDetails/common';
import { getCreateAdditionalServiceDefaultValues } from 'pages/ProgramDetails/common/constants';
import { useCallback, useRef, useState } from 'react';
import { CreateServiceData } from 'services/programs/types';

import { Callback } from 'types/helpers';
import { stringModifier } from 'utils/strings';
import { AdditionalServicesFields } from '../../types';

import { getCreateServiceFields } from './constants';
import './style.scss';
import { ConditionalWrapper } from 'components/ShowWrapper/ConditionalWrapper';

const { useForm, useWatch } = Form;

type CreateServiceStepConfig = {
  closeModal?: Callback;
};

export const CreateServiceForm = ({ closeModal }: CreateServiceStepConfig) => {
  const [stepData, setStepData] = useState<ContentStepData>();

  const [form] = useForm<AdditionalServicesFields>();
  const serviceType = useWatch('type', form);
  const stepsRef = useRef<ContentStepsRef>(null);
  const { program: { programName = '', id: programId } = {} } = useProgramData();
  const { createService, isLoading } = useCreateService(() => {
    closeModal?.();
    showCustomToast({ type: 'success', message: 'Your Additional Service has been created.' });
  });

  const hasNextContent = serviceType && serviceType !== 'PHYSICAL_SHIPMENT';

  const stepChangeHandler = useCallback(setStepData, []);

  const contentSteps = [
    <>{getFieldsJSX(getCreateServiceFields())}</>,
    <>{getFieldsJSX(getCreateServiceFields(serviceType))}</>,
  ];

  const { canGoBack, canGoNext } = stepData || {};
  const { next, goBack } = stepsRef.current || {};

  const showFormList = serviceType === 'FORM_REQUEST' && !canGoNext;
  const showMediaPickerModal = serviceType === 'RESOURCE' && !canGoNext;
  const canGoToNextStep = hasNextContent && canGoNext;

  const handleSubmit = (values: AdditionalServicesFields) => {
    if (canGoToNextStep) return next?.();
    if (programId)
      createService(clientToServerServiceMapper({ ...values, programUuid: programId }) as CreateServiceData);
  };

  return (
    <div className='create-service-form'>
      <h1 className='modal-title'>{text['Add an Additional Service']}</h1>
      <Spacer height={16} type='flex' />
      <h2 className='modal-description'>
        {stringModifier(text['Add an additional service option for the {0} program.'], programName)}
      </h2>
      <Form<AdditionalServicesFields>
        requiredMark={false}
        preserve={false}
        layout='vertical'
        className='form'
        form={form}
        onFinish={handleSubmit}
        initialValues={getCreateAdditionalServiceDefaultValues()}
      >
        <ContentSteps ref={stepsRef} steps={contentSteps} onStateChange={stepChangeHandler} />
        <ConditionalWrapper condition={showFormList}>
          <DynamicAnswersFields name='answers' />
        </ConditionalWrapper>
        <ConditionalWrapper condition={!showMediaPickerModal}>
          <Spacer height={32} />
          <ModalActionButtons
            onCancel={canGoBack ? goBack : closeModal}
            submitText={text[canGoNext ? 'Next' : 'Create']}
            cancelText={text[canGoBack ? 'Back' : 'Cancel']}
            isLoading={isLoading}
            onSubmit={form.submit}
            htmlType='button'
          />
        </ConditionalWrapper>
      </Form>
      <ConditionalWrapper condition={showMediaPickerModal}>
        <PickFileOrMediaModalContent
          withoutTextInfo
          closeButtonText={text['Back']}
          confirmButtonText={text['Create']}
          onClose={stepsRef.current?.goBack}
          onSuccess={(_, media) => {
            form.setFieldsValue({ source: media });
            form.submit();
          }}
          fileTypes={['PDF', 'MP4', 'PNG', 'JPG']}
          maxSize={10}
        />
      </ConditionalWrapper>
    </div>
  );
};
