import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { showCustomToast } from 'components/CustomToast/showCustomToast';
import { useAuth, usePermissionsHandler } from 'hooks';
import { useGoToAdminPage } from 'routes/navigators';
import { text } from 'constants/texts';
import { stringModifier } from 'utils/strings';

export const useSettingsMenuItems = (): ItemType[] => {
  const { isSuperAdmin, setAuthenticated, userId } = useAuth();
  const goToAdminDetails = useGoToAdminPage();

  const [isViewUserInfoAllowed, isLogoutAllowed] = usePermissionsHandler(['user_info.view', 'logout']);

  const handleViewAccountDetails = () => {
    if (isSuperAdmin) {
      showCustomToast({ type: 'warning', message: text['Super admin has no account details!'] });
      return;
    }
    goToAdminDetails(userId);
  };

  const handleLogout = () => setAuthenticated(false);

  return [
    {
      key: '1',
      title: '',
      disabled: !isViewUserInfoAllowed,
      onClick: handleViewAccountDetails,
      label: <a href='#'>{stringModifier(text['{0} Details'], text['View Account'])}</a>,
    },
    {
      key: '2',
      title: '',
      onClick: handleLogout,
      disabled: !isLogoutAllowed,
      label: <a href='#'>{text['Logout']}</a>,
    },
  ];
};
