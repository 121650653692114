import { Row } from 'antd';
import { ButtonHTMLType } from 'antd/lib/button/button';
import { text } from 'constants/texts';
import { MouseEventHandler } from 'react';
import { Callback } from 'types/helpers';
import { Button, Spacer } from '../../index';

type AppActionButtonsProps = Partial<{
  onCancel: Callback;
  isLoading: boolean;
  cancelText: string;
  submitText: string;
  isSubmitButtonDisabled?: boolean;
  htmlType?: ButtonHTMLType;
  onSubmit?: MouseEventHandler<HTMLElement>;
}>;

export const ModalActionButtons = ({
  onCancel,
  isLoading,
  isSubmitButtonDisabled,
  cancelText = text['Cancel'],
  submitText = text['Add'],
  htmlType = 'submit',
  onSubmit,
}: AppActionButtonsProps) => (
  <Row>
    <Button disabled={isLoading} type='default' onClick={onCancel}>
      {cancelText}
    </Button>
    <Spacer width={30} />
    <Button loading={isLoading} disabled={isSubmitButtonDisabled} type='primary' htmlType={htmlType} onClick={onSubmit}>
      {submitText}
    </Button>
  </Row>
);
