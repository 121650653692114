import { Spin } from 'antd';
import { PageLayout } from 'layouts';
import { CommunicationSurveyDetailsContent } from './CommunicationSurveyDetailsContent';
import { getPatientsPagesBreadCrumb } from '../breadCrumbs';
import { usePatientCommunicationById } from 'hooks/api/patientSearch/usePatientCommunicationById';
import { text } from 'constants/texts';

export const PatientCommunicationDetails = () => {
  const { hasData, isLoading, communicationSurveyById } = usePatientCommunicationById();

  if (isLoading) return <Spin className='pl-page-spinner' size='large' />;

  if (!hasData) return <span>{text['Survey is not available']}</span>;

  const breadCrumbs = getPatientsPagesBreadCrumb({
    name: 'COMMUNICATIONS_DETAILS',
    communicationName: communicationSurveyById.title,
  });

  const pageTitleJSX = <div className='answer-field'>{communicationSurveyById.title}</div>;

  return (
    <PageLayout pageTitle={pageTitleJSX} crumbs={breadCrumbs}>
      <CommunicationSurveyDetailsContent survey={communicationSurveyById} />
    </PageLayout>
  );
};
