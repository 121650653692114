import { Spin } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { DataTable, SearchInput, Spacer, TextCell } from 'components';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { EmptyDataListComponent } from 'components/EmptyDataListComponent/EmptyDataListComponent';
import { text } from 'constants/texts';
import { useChildrenRef, usePermissionsHandler, useSearch } from 'hooks';
import { useSpecialtyPharmacyList } from 'hooks/api/specialtyPharmacy/useSpecialtyPharmacyList';
import { SpecialtyPharmaciesFields } from 'pages/ProgramDetails/specialtyPharmaciesFields';
import { useEffect } from 'react';
import { useGoToSpecialtyPharmaciesDetailsPage } from 'routes/navigators';
import { ProgramData } from 'types/programs';
import { getFontClassName } from 'utils/getFontClassName';
import { stringModifier } from 'utils/strings';
import { CreateSpecialtyPharmacyModal } from './components/CreatePharmacyModal';
import { ConditionalWrapper } from 'components/ShowWrapper/ConditionalWrapper';

import './style.scss';

const columns: ColumnsType<SpecialtyPharmaciesFields> = [
  {
    width: '55%',
    title: text['Specialty Pharmacy Name'],
    dataIndex: 'specialtyPharmacyName',
    render: (value) => <TextCell color='blue' className={getFontClassName('button')} text={value} />,
  },
  {
    width: '45%',
    title: text['Phone Number'],
    dataIndex: 'phoneNumber',
    render: (value) => (
      <TextCell color='gray2' className={getFontClassName('paragraph')} text={stringModifier(value)} />
    ),
  },
];

type SpecialtyPharmacyContentProps = {
  program?: ProgramData;
};

export const SpecialtyPharmacyContent = ({ program }: SpecialtyPharmacyContentProps) => {
  const [modalRef, setModalRef] = useChildrenRef<ClosableWrapperRef>();

  const [isCreatePharmacyAllowed] = usePermissionsHandler(['pharmacies.create']);

  const { keyword, handleSearch, debouncedSearchKeyword, previousDebouncedSearchKeyword } = useSearch();
  const { isLoading, isRefetching, hasData, specialtyPharmacyList, refetchSpecialtyPharmacy } =
    useSpecialtyPharmacyList(debouncedSearchKeyword);

  const goToSpecialtyPharmacy = useGoToSpecialtyPharmaciesDetailsPage();

  useEffect(() => {
    if (previousDebouncedSearchKeyword !== debouncedSearchKeyword) refetchSpecialtyPharmacy();
  }, [debouncedSearchKeyword, previousDebouncedSearchKeyword]);

  if (!program) return null;

  return (
    <Spin size='large' spinning={isLoading || isRefetching}>
      <Spacer height={8} />
      <ConditionalWrapper condition={hasData}>
        <SearchInput value={keyword} onChange={handleSearch} className='pharmacy-content-search' />
        <DataTable
          loading={isLoading}
          rowKey='id'
          columns={columns}
          dataSource={specialtyPharmacyList}
          onRow={(record) => ({ onClick: () => goToSpecialtyPharmacy(record.uuid) })}
        />
      </ConditionalWrapper>
      <ConditionalWrapper condition={!hasData}>
        <EmptyDataListComponent
          title={'This program doesn’t have any Specialty Pharmacies configured.'}
          description={stringModifier(text['Click the button below to add new {0}'], 'Specialty Pharmacies')}
          addButtonText={stringModifier(text['Add {0}'], 'Specialty Pharmacy')}
          addButtonDisabled={!isCreatePharmacyAllowed}
          onButtonPress={modalRef?.open}
        />
      </ConditionalWrapper>
      <CreateSpecialtyPharmacyModal program={program} setModalRef={setModalRef} modalRef={modalRef} />
    </Spin>
  );
};
