import { SVGProps } from 'react';

export const TrashVector = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='18' viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1.33301 4.26318H2.81449H14.6663'
      stroke='#F33938'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.95271 4.53001V3.06335C4.95271 2.67436 5.11325 2.30131 5.39902 2.02626C5.68479 1.7512 6.07238 1.59668 6.47652 1.59668H9.52413C9.92827 1.59668 10.3159 1.7512 10.6016 2.02626C10.8874 2.30131 11.0479 2.67436 11.0479 3.06335V4.53001M13.3337 4.53001V14.7967C13.3337 15.1857 13.1731 15.5587 12.8873 15.8338C12.6016 16.1088 12.214 16.2633 11.8098 16.2633H4.1908C3.78666 16.2633 3.39908 16.1088 3.11331 15.8338C2.82754 15.5587 2.66699 15.1857 2.66699 14.7967V4.53001H13.3337Z'
      stroke='#F33938'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
