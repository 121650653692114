import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { Callback } from 'types/helpers';

export const useToggle = (initialState = false): [boolean, Callback, Dispatch<SetStateAction<boolean>>] => {
  const [state, setState] = useState(initialState);

  const toggle = useCallback(() => setState((prev) => !prev), []);

  return [state, toggle, setState];
};
