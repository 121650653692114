import { FormInstance } from 'antd';
import { AppFields } from 'components/fields/types';
import { fieldModifier } from 'components/fields/utils';
import { text } from 'constants/texts';
import {
  brandEmblemField,
  primaryColorField,
  programLogoField,
  programManufacturerField,
  programNameField,
  programTypeField,
  secondaryColorField,
} from 'pages/ProgramDetails/universalSettingsFields';
import { CreateProgramFields } from './types';

export const getCreateProgramFields = (form: FormInstance<CreateProgramFields>): AppFields<CreateProgramFields> => [
  [programManufacturerField, programTypeField],
  programNameField,
  fieldModifier(programLogoField, {
    form,
    draggerConfig: {
      title: text['Drag and drop image file here'],
    },
  }),
  fieldModifier(brandEmblemField, {
    form,
    draggerConfig: {
      title: text['Drag and drop image file here'],
    },
  }),
  [primaryColorField, secondaryColorField],
];
