import { WithSelectRequest } from 'components/fields/AppSelect/AppSelect';
import { AppField, AppFields } from 'components/fields/types';
import { emptyField, fieldModifier } from 'components/fields/utils';
import { text } from 'constants/texts';
import {
  getActionPayloadFields,
  getAllowedCompareOperators,
  getConditionValueFieldProps,
} from 'pages/ProgramDetails/programTabComponents/BusinessRules/BusinessRuleDetails/components/BusinessRuleEditContent/utils';
import {
  ruleDescriptionField,
  ruleNameField,
} from 'pages/ProgramDetails/programTabComponents/BusinessRules/components/CreateBusinessRuleModal/fields';
import {
  ActionBusinessRuleFields,
  ConditionBusinessRuleFields,
  UpdateBaseBusinessRuleFields,
} from 'pages/ProgramDetails/programTabComponents/BusinessRules/components/CreateBusinessRuleModal/types';
import { queryKeys } from 'services/constants';
import { getMilestoneTrackerList } from 'services/milestoneTrackers/requests';
import { GetMilestoneTrackerListData } from 'services/milestoneTrackers/types';
import { fetchServicesByProgram } from 'services/programs/services';
import { ServicesResponse } from 'services/programs/types';
import { getCommunicationTemplateList } from 'services/rules/communicationTemplates/requests';
import { CommunicationTemplateType, GetCommunicationTemplateData } from 'services/rules/communicationTemplates/types';
import { getSurveyList } from 'services/surveys/requests';
import { GetSurveysData } from 'services/surveys/types';
import { getNotificationTileList } from 'services/notificationTiles/requests';
import { GetNotificationTileListData } from 'services/notificationTiles/types';
import { Action, ActionMetaField, ActionType, Attribute, BusinessRulesMeta } from 'types/businessRuleMeta';
import { MilestoneTracker } from 'types/milestoneTracker';
import { BusinessRule, TimeMetricsType } from 'types/rules';
import { getInputPlaceholder, getSelectPlaceholder } from 'utils/strings';
import { RULES } from 'utils/validations';
import { getSpecialtyPharmacyList } from 'services/specialtyPharmacies/requests';
import { GetSpecialtyPharmacyListData } from 'services/specialtyPharmacies/types';

const { REQUIRED, MAX } = RULES;

type GetInitActionsFieldsConfig = {
  actions: Action[];
  orderIndex: number;
  programId: string;
  actionsMetaField: ActionMetaField[];
  selectedAction?: ActionType;
  selectedTracker?: MilestoneTracker;
  rulesForTrigger?: Attribute[];
  selectedRuleForTrigger?: Attribute;
  selectedTimeMetricsForTrigger?: TimeMetricsType | null;
};

export const getBusinessRuleActionFields = ({
  actions,
  orderIndex,
  ...payloadFieldsConfig
}: GetInitActionsFieldsConfig): AppFields<ActionBusinessRuleFields> => {
  const { rightSideField, extraFields } = getActionPayloadFields({
    orderIndex,
    ...payloadFieldsConfig,
  });

  return [
    [
      fieldModifier(ruleActionField, {
        withoutMargin: true,
        name: [orderIndex, 'name'],
        options: actions.map(({ label, key }) => ({ label, value: key })),
      }),
      rightSideField,
    ],
    ...(extraFields || []),
  ];
};

type GetInitConditionsFieldsConfig = {
  selectedAttributeKey?: string;
  meta?: BusinessRulesMeta;
  orderIndex: number;
  businessRule: BusinessRule;
  programId: string;
};

export const getBusinessRuleConditionFields = ({
  meta,
  selectedAttributeKey,
  orderIndex,
  programId,
  businessRule,
}: GetInitConditionsFieldsConfig): AppFields<ConditionBusinessRuleFields> => {
  if (!meta) return [];

  const selectedAttribute = meta.attributes.find((attribute) => attribute.key === selectedAttributeKey);

  const operators = getAllowedCompareOperators(
    meta.operators.compare || [],
    meta.operatorLabels,
    selectedAttribute?.type
  );

  const attributes = meta.attributes.filter(({ key }) => key !== businessRule.uuid) || [];

  return [
    [
      fieldModifier(ruleAttributeField, {
        name: [orderIndex, 'attribute'],
        withoutMargin: true,
        groupOptions: [
          {
            name: text['Data Fields'],
            options: attributes
              .filter((attribute) => attribute.type !== 'rule')
              .map((attribute) => ({ label: attribute.label, value: attribute.key })),
          },
          {
            name: text['Business Rules'],
            options: attributes
              .filter((attribute) => attribute.type === 'rule')
              .map((attribute) => ({ label: attribute.label, value: attribute.key })),
          },
        ],
      }),
      fieldModifier(ruleOperatorField, {
        name: [orderIndex, 'operator'],
        withoutMargin: true,
        disabled: !selectedAttribute,
        options: operators.map((operator) => ({ label: operator.label, value: operator.key })),
      }),
      fieldModifier(ruleValueField, {
        name: [orderIndex, 'value'],
        disabled: !selectedAttribute,
        withoutMargin: true,
        ...getConditionValueFieldProps({
          type: selectedAttribute?.type ?? 'boolean',
          selectedAttributeKey,
          programId,
        }),
      }),
    ],
  ];
};

export const getTriggerNameBusinessRuleFields = (meta?: BusinessRulesMeta): AppFields<UpdateBaseBusinessRuleFields> => [
  [
    fieldModifier(ruleTriggerField, {
      options: meta?.triggers.map((trigger) => ({ value: trigger.key, label: trigger.label })) || [],
    }),
    fieldModifier(emptyField, { emptyKey: 'ruleTriggerEmptyField' }),
  ],
];

export const getUpdateBaseBusinessRuleFields = (): AppFields<UpdateBaseBusinessRuleFields> => [
  [ruleNameField, ruleDescriptionField],
];

// REQUESTS
export const getSurveyRequestData = (programId: string): WithSelectRequest<GetSurveysData> => ({
  queryKey: [queryKeys.getSurveys, programId],
  optionsMapper: (data) => data?.results?.map((item) => ({ label: item.title || '', value: item.uuid || '' })) || [],
  request: () => getSurveyList({ programId }),
});

export const getAdditionalServiceRequestData = (programId: string): WithSelectRequest<ServicesResponse> => ({
  queryKey: [queryKeys.getSurveys, programId],
  optionsMapper: (data) => data?.results?.map((item) => ({ label: item.label || '', value: item.uuid || '' })) || [],
  request: () => fetchServicesByProgram(programId).then((res) => res.data),
});

export const getMilestoneTrackerListRequestData = (
  programId: string
): WithSelectRequest<GetMilestoneTrackerListData> => ({
  queryKey: [queryKeys.getMilestoneTrackers, programId],
  optionsMapper: (data) => data?.results?.map((item) => ({ label: item.name || '', value: item.uuid || '' })) || [],
  request: () => getMilestoneTrackerList({ programId }),
});

export const getCommunicationTemplatesListRequestData = (
  channel: CommunicationTemplateType
): WithSelectRequest<GetCommunicationTemplateData> => ({
  queryKey: [queryKeys.getCommunicationTemplates, channel],
  optionsMapper: (data) => data?.map((item) => ({ label: item.label || item.name || '', value: item.id || '' })) || [],
  request: () => getCommunicationTemplateList({ channel }),
});

export const getActiveNotificationTilesList = (programId: string): WithSelectRequest<GetNotificationTileListData> => ({
  queryKey: [queryKeys.getNotificationTiles, programId],
  optionsMapper: (data) => data?.map((item) => ({ label: item.display_name || '', value: item.id || '' })) || [],
  request: () => getNotificationTileList({ status: 'IN_USE', programId }),
});

export const getPharmacyList = (programId: string): WithSelectRequest<GetSpecialtyPharmacyListData> => ({
  queryKey: [queryKeys.getSpecialtyPharmacies, programId],
  optionsMapper: (data) => data?.items?.map((item) => ({ label: item?.name ?? '', value: item?.uuid ?? '' })) || [],
  request: () => getSpecialtyPharmacyList({ programId, search: '' }),
});

// FIELDS
export const ruleAttributeField: AppField<Pick<ConditionBusinessRuleFields, 'attribute'>> = {
  type: 'select',
  name: 'attribute',
  placeholder: text['Criteria'],
  rules: [REQUIRED({ requiredFieldName: text['Criteria'] })],
};

export const ruleOperatorField: AppField<Pick<ConditionBusinessRuleFields, 'operator'>> = {
  type: 'select',
  name: 'operator',
  placeholder: text['Operator'],
  rules: [REQUIRED({ requiredFieldName: text['Operator'] })],
};

export const ruleValueField: AppField<Pick<ConditionBusinessRuleFields, 'value'>> = {
  type: 'input',
  name: 'value',
  placeholder: text['Empty'],
};

export const ruleTriggerField: AppField<Pick<UpdateBaseBusinessRuleFields, 'trigger'>> = {
  type: 'select',
  name: 'trigger',
  label: text['Detected Message'],
  placeholder: getSelectPlaceholder(text['Detected Message']),
  rules: [REQUIRED({ requiredFieldName: text['Detected Message'] })],
};

// ACTION FIELDS
export const ruleActionField: AppField<Pick<ActionBusinessRuleFields, 'name'>> = {
  type: 'select',
  name: 'name',
  placeholder: text['Action'],
  label: text['Action'],
  rules: [REQUIRED({ requiredFieldName: text['Action'] })],
};

export const emailTemplateField: AppField<Pick<ActionBusinessRuleFields, 'emailTemplateId'>> = {
  type: 'select',
  name: 'emailTemplateId',
  label: text['Email Template'],
  placeholder: getSelectPlaceholder(text['Email Template']),
  withRequest: getCommunicationTemplatesListRequestData('email'),
  rules: [REQUIRED({ requiredFieldName: text['Email Template'] })],
};

export const smsTemplateField: AppField<Pick<ActionBusinessRuleFields, 'smsTemplateId'>> = {
  type: 'select',
  name: 'smsTemplateId',
  label: text['SMS Message'],
  placeholder: getSelectPlaceholder(text['SMS Message']),
  withRequest: getCommunicationTemplatesListRequestData('sms'),
  rules: [REQUIRED({ requiredFieldName: text['SMS Message'] })],
};

export const contactCardTemplateField: AppField<Pick<ActionBusinessRuleFields, 'pharmacyId'>> = {
  type: 'select',
  name: 'pharmacyId',
  label: text['SMS Message'],
  placeholder: getSelectPlaceholder(text['Contact Card']),
  rules: [REQUIRED({ requiredFieldName: text['SMS Message'] })],
};

export const smsKeywordTemplateField: AppField<Pick<ActionBusinessRuleFields, 'keywordTemplateId'>> = {
  type: 'select',
  name: 'keywordTemplateId',
  label: text['SMS Message'],
  placeholder: getSelectPlaceholder(text['SMS Keyword']),
  withRequest: getCommunicationTemplatesListRequestData('sms_keyword'),
  rules: [REQUIRED({ requiredFieldName: text['SMS Message'] })],
};

export const pushNotificationTemplateField: AppField<Pick<ActionBusinessRuleFields, 'pushNotificationTemplateId'>> = {
  type: 'select',
  name: 'pushNotificationTemplateId',
  label: text['Push Notification Message'],
  placeholder: getSelectPlaceholder(text['Push Notification Message']),
  withRequest: getCommunicationTemplatesListRequestData('push'),
  rules: [REQUIRED({ requiredFieldName: text['Push Notification Message'] })],
};

export const notificationTileField: AppField<Pick<ActionBusinessRuleFields, 'notificationTileId'>> = {
  type: 'select',
  name: 'notificationTileId',
  label: text['Notification'],
  placeholder: getSelectPlaceholder(text['Notification']),
  rules: [REQUIRED({ requiredFieldName: text['Notification'] })],
};

export const additionalServicesField: AppField<Pick<ActionBusinessRuleFields, 'additionalServiceUuid'>> = {
  type: 'select',
  name: 'additionalServiceUuid',
  label: text['Additional Service'],
  placeholder: getSelectPlaceholder(text['Additional Service']),
  rules: [REQUIRED({ requiredFieldName: text['Additional Service'] })],
};
export const timeBaseTriggerActionsField: AppField<Pick<ActionBusinessRuleFields, 'timeBaseTriggerActions'>> = {
  type: 'select',
  name: 'timeBaseTriggerActions',
  label: text['Scheduled Actions'],
  placeholder: getSelectPlaceholder(text['Scheduled Actions']),
  rules: [REQUIRED({ requiredFieldName: text['Scheduled Actions'] })],
};
export const timeMetricsTriggerField: AppField<Pick<ActionBusinessRuleFields, 'timeMetricsTrigger'>> = {
  type: 'select',
  name: 'timeMetricsTrigger',
  label: text['Schedule Type'],
  placeholder: getSelectPlaceholder(text['Schedule Type']),
  rules: [REQUIRED({ requiredFieldName: text['Schedule Type'] })],
};
export const timeTriggerField: AppField<Pick<ActionBusinessRuleFields, 'timeTrigger'>> = {
  type: 'select',
  name: 'timeTrigger',
  label: text['Schedule Value'],
  placeholder: getSelectPlaceholder(text['Schedule Value']),
  rules: [REQUIRED({ requiredFieldName: text['Schedule Value'] })],
};

export const surveyField: AppField<Pick<ActionBusinessRuleFields, 'surveyId'>> = {
  name: 'surveyId',
  type: 'select',
  label: text['Survey'],
  placeholder: getSelectPlaceholder(text['Survey']),
  rules: [REQUIRED({ requiredFieldName: text['Survey'] })],
};

export const milestoneTrackerField: AppField<Pick<ActionBusinessRuleFields, 'milestoneTrackerId'>> = {
  name: 'milestoneTrackerId',
  type: 'select',
  label: text['Milestone Tracker'],
  placeholder: getSelectPlaceholder(text['Milestone Tracker']),
  rules: [REQUIRED({ requiredFieldName: text['Milestone Tracker'] })],
};

export const milestoneField: AppField<Pick<ActionBusinessRuleFields, 'milestoneId'>> = {
  name: 'milestoneId',
  type: 'select',
  label: text['Step'],
  tooltip: text['This is the step in a milestone tracker'],
  placeholder: getSelectPlaceholder(text['Milestone Tracker Step']),
  rules: [REQUIRED({ requiredFieldName: text['Milestone Tracker Step'] })],
};

export const milestoneNameField: AppField<Pick<ActionBusinessRuleFields, 'milestoneName'>> = {
  name: 'milestoneName',
  type: 'input',
  label: text['Title Text'],
  tooltip: text['This is the title text that appears on the milestone tracker card for a selected step'],
  placeholder: getInputPlaceholder(text['Title Text']),
  rules: [REQUIRED({ requiredFieldName: text['Title Text'] }), MAX({ max: 255 })],
};

export const milestoneDescriptionField: AppField<Pick<ActionBusinessRuleFields, 'milestoneDescription'>> = {
  name: 'milestoneDescription',
  type: 'input',
  label: text['Description Text'],
  tooltip: text['This is the description text that appears on the milestone tracker card for a selected step'],
  placeholder: getInputPlaceholder(text['Description Text']),
  rules: [REQUIRED({ requiredFieldName: text['Description Text'] }), MAX({ max: 500 })],
};

export const milestoneMetaDataFieldName: AppField<Pick<ActionBusinessRuleFields, 'milestoneMetaDataFieldName'>> = {
  name: 'milestoneMetaDataFieldName',
  type: 'select',
  label: text['Field Name'],
  tooltip: text['Field Name'],
  placeholder: getSelectPlaceholder(text['Field Name']),
};

export const milestoneMetaDataFieldDescription: AppField<
  Pick<ActionBusinessRuleFields, 'milestoneMetaDataFieldDescription'>
> = {
  name: 'milestoneMetaDataFieldDescription',
  type: 'input',
  label: text['Field Description'],
  tooltip: text['Field Description'],
  placeholder: getInputPlaceholder(text['Field Description']),
  rules: [MAX({ max: 300 })],
};
