import { useMutation } from 'react-query';
import { queryKeys } from 'services/constants';
import { getSurveyValidate } from 'services/surveys/requests';
import { GetSurveyValidateParams } from 'services/surveys/types';
import { errorHandler } from 'utils/errorHandler';

export const useValidateSurvey = () => {
  const { mutateAsync, isLoading } = useMutation(getSurveyValidate, {
    mutationKey: queryKeys.createSurvey,
    onError: errorHandler,
  });

  return { validateSurvey: (params: GetSurveyValidateParams) => mutateAsync(params), isLoading };
};
