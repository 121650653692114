import { useParams } from 'react-router-dom';
import { useAdmins } from './useAdmins';

export const useAdmin = () => {
  const { adminId } = useParams();
  const { admins, isLoading } = useAdmins();

  return {
    isLoading,
    adminData: admins?.find((admin) => admin.id === adminId),
  };
};
