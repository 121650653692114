import { Form, FormInstance } from 'antd';
import { Button, Spacer } from 'components';
import { ConditionalWrapper } from 'components/ShowWrapper/ConditionalWrapper';
import { BUSINESS_RULES_CONDITIONS_LIMIT } from 'constants/limits';
import { text } from 'constants/texts';
import { BusinessRuleConditionDynamicForm } from 'pages/ProgramDetails/programTabComponents/BusinessRules/BusinessRuleDetails/components/BusinessRuleEditContent/BusinessRuleConditionDynamicForm';
import { UpdateBusinessRuleFields } from 'pages/ProgramDetails/programTabComponents/BusinessRules/components/CreateBusinessRuleModal/types';
import { BusinessRulesMeta } from 'types/businessRuleMeta';
import { BusinessRule } from 'types/rules';

type BusinessConditionsFormListProps = {
  form: FormInstance<UpdateBusinessRuleFields>;
  meta?: BusinessRulesMeta;
  businessRule: BusinessRule;
};

export const BusinessConditionsFormList = ({ meta, form, businessRule }: BusinessConditionsFormListProps) => (
  <Form.List name='conditions'>
    {(fields, { add, remove }) => (
      <>
        {fields.map(({ key, name }) => (
          <BusinessRuleConditionDynamicForm
            businessRule={businessRule}
            key={key}
            remove={remove}
            form={form}
            meta={meta}
            orderIndex={name}
          />
        ))}
        <Spacer height={14} type='flex' />
        <ConditionalWrapper condition={BUSINESS_RULES_CONDITIONS_LIMIT.isMax(fields.length)}>
          <Button type='ghost' onClick={add}>
            {text['Add']}
          </Button>
          <Spacer width={16} />
        </ConditionalWrapper>
      </>
    )}
  </Form.List>
);
