import { Row } from 'antd';
import { Spacer } from 'components';
import { useAppTabs } from 'hooks/appTabs/useAppTabs';
import { useContentTabs } from 'pages/ProgramDetails/programTabComponents/Content/tabsConfig/root';
import { useContentTabChangeHandler } from 'pages/ProgramDetails/useTabChangeHandler';
import React from 'react';

export const Content = () => {
  const { tabs, extra } = useContentTabs();

  const { selectedTab, onTabChange } = useContentTabChangeHandler();

  const {
    tabsJSX,
    selectedTabData: { contentJSX, actionsJSX },
  } = useAppTabs({
    tabs,
    disabled: extra.isTabsDisabled,
    initialTabKey: selectedTab || 'medias',
    onTabChange,
  });

  return (
    <>
      <Spacer height={32} />
      <Row justify='space-between' align='middle'>
        {tabsJSX}
        {actionsJSX}
      </Row>
      {contentJSX}
    </>
  );
};
