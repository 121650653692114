import { UploadFile } from 'antd/es/upload/interface';
import { showCustomToast } from 'components/CustomToast/showCustomToast';
import { Media } from 'types/programs';

export const showCloseLoadMediaModalToast = () =>
  showCustomToast({
    message: 'File wasn’t uploaded',
    type: 'warning',
    duration: 3,
  });

export const showSuccessUploadedMediaToast = () =>
  showCustomToast({
    message: 'File has been uploaded',
    type: 'success',
    duration: 3,
  });

export const determineIfIsUploadFile = (file: Media | UploadFile): file is UploadFile =>
  !!(file as UploadFile).originFileObj;
