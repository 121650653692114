import { useCallback, useState } from 'react';
import { Callback } from 'types/helpers';

export type UseModalReturnType = {
  visible: boolean;
  open: Callback;
  close: Callback;
};

export type UseModalProps = {
  defaultVisible?: boolean;
};

export const useModal = ({ defaultVisible = false }: UseModalProps = {}): UseModalReturnType => {
  const [visible, setVisible] = useState(defaultVisible);

  const open = useCallback(() => setVisible(true), []);
  const close = useCallback(() => setVisible(false), []);

  return {
    visible,
    open,
    close,
  };
};
