import { useMutation, useQueryClient } from 'react-query';
import { deleteAdmin } from 'services/users/users';
import { queryKeys } from 'services/constants';
import { Callback } from 'types/helpers';

export const useDeleteAdmin = (onSuccess?: Callback) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(deleteAdmin, {
    mutationKey: queryKeys.deleteAdmin,
    onSuccess: () => {
      onSuccess?.();
      queryClient.invalidateQueries(queryKeys.getAdmins);
    },
  });

  return { isLoading, deleteAdmin: mutate };
};
