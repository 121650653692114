import { AppFields } from 'components/fields/types';
import { fieldModifier, emptyField } from 'components/fields/utils';
import {
  surveyCardTitleField,
  surveyNameField,
  surveyDescriptionField,
  surveyQuestionField,
  questionDescriptionField,
  selectionTypeField,
} from 'pages/ProgramDetails/surveysFields';
import { QuestionsFields, SurveyFields } from 'types/surveys';

type MainQuestionFields = Pick<QuestionsFields, 'question' | 'questionDescription' | 'selectionType'>;

export const getSurveyNameField = (): AppFields<Pick<SurveyFields, 'name'>> => [
  [surveyNameField, fieldModifier(emptyField, { emptyKey: 'surveyNameEmptyField' })],
];

export const getSurveyCardFields = () => [[surveyCardTitleField, surveyDescriptionField]];

export const getMainQuestionFields = (orderIndex: number): AppFields<MainQuestionFields> => [
  fieldModifier(surveyQuestionField, { name: [orderIndex, 'question'] }),
  fieldModifier(questionDescriptionField, { name: [orderIndex, 'questionDescription'] }),
  fieldModifier(selectionTypeField, { name: [orderIndex, 'selectionType'] }),
];
