import { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle, useState } from 'react';
import { Callback } from 'types/helpers';
import { DevError } from 'utils/errors';

export type ContentStepData = {
  canGoBack: boolean;
  canGoNext: boolean;
  currentIndex: number;
};

export type ContentStepsProps = {
  steps: JSX.Element[];
  initialIndex?: number;
  isLoop?: boolean;
  onStateChange?: (data: ContentStepData) => void;
};

export type ContentStepsRef = {
  next: Callback;
  goBack: Callback;
  reset: Callback;
};

const ContentStepsComponent: ForwardRefRenderFunction<ContentStepsRef, ContentStepsProps> = (
  { initialIndex = 0, isLoop, steps, onStateChange },
  ref
) => {
  if (initialIndex >= steps.length)
    throw new DevError(
      `Initial index can't be more then steps count.\nIndex: ${initialIndex};\nSteps: ${steps.length - 1}.`
    );
  if (!steps.length) throw new DevError('Please provide one step jsx at least');

  const [stepIndex, setStepIndex] = useState(0);

  useEffect(() => {
    if (!onStateChange) return;

    const lastStepIndex = steps.length - 1;

    onStateChange({
      currentIndex: stepIndex,
      canGoBack: stepIndex !== 0,
      canGoNext: stepIndex < lastStepIndex,
    });
  }, [stepIndex, onStateChange, steps.length]);

  useImperativeHandle(ref, () => {
    const lastStepIndex = steps.length - 1;

    return {
      reset: () => setStepIndex(initialIndex),
      goBack: () => setStepIndex((prev) => (prev === 0 ? (isLoop ? lastStepIndex : 0) : prev - 1)), // if loop -> last step; else -> first step or previous
      next: () => setStepIndex((prev) => (prev === lastStepIndex ? (isLoop ? 0 : lastStepIndex) : prev + 1)), // if loop -> first step; else -> last step or next
    };
  });
  return steps[stepIndex];
};

export const ContentSteps = forwardRef(ContentStepsComponent);
