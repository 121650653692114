import { Form } from 'antd';
import { useEffect } from 'react';
import { ClosableWrapper, Spacer } from 'components';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { ModalActionButtons } from 'components/fields/AppActionButtons/ModalActionButtons';
import { useCreateNotificationTile } from 'hooks/api/notificationTiles/useCreateNotificationTile';
import { useProgramData } from 'hooks/api';
import { getFieldsJSX } from 'components/fields/utils';
import { text } from 'constants/texts';
import { stringModifier } from 'utils/strings';
import { getCreateNotificationTileFields } from '../../fields';
import { CreateNotificationTileFields } from '../../types';
import { isLinkValid } from 'utils/validations';

const { useForm, useWatch } = Form;

type CreateNotificationTileModalProps = {
  setModalRef: (ref: ClosableWrapperRef) => void;
  modalRef: ClosableWrapperRef | undefined;
};

export const CreateNotificationTileModal = ({ modalRef, setModalRef }: CreateNotificationTileModalProps) => {
  const [form] = useForm<CreateNotificationTileFields>();
  const externalLink = useWatch('externalLink', form);
  const { program } = useProgramData();

  useEffect(() => {
    !externalLink && form.setFields([{ name: 'externalLinkName', value: '' }]);
  }, [form, externalLink]);

  const { createNotificationTile, isLoading } = useCreateNotificationTile(modalRef?.close);

  return (
    <ClosableWrapper ref={setModalRef}>
      <h1 className='modal-title'>{stringModifier(text['Add a {0}'], text['Notification Tile'])}</h1>
      <Spacer height={16} type='flex' />
      <h2 className='modal-description'>
        {stringModifier(text['Add a new notification tile to the {0} program.'], program?.programName || '')}
      </h2>
      <Form<CreateNotificationTileFields>
        requiredMark={false}
        preserve={false}
        layout='vertical'
        className='form'
        form={form}
        onFinish={createNotificationTile}
      >
        {getFieldsJSX(getCreateNotificationTileFields({ externalLinkAdded: isLinkValid(externalLink) }))}
        <ModalActionButtons isLoading={isLoading} onCancel={modalRef?.close} />
      </Form>
    </ClosableWrapper>
  );
};
