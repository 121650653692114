import { useMutation, useQueryClient } from 'react-query';
import { queryKeys } from 'services/constants';
import { deleteMedia } from 'services/medias/requests';
import { DeleteMediaParams } from 'services/medias/types';
import { errorHandler } from 'utils/errorHandler';

export const useDeleteMedia = () => {
  const queryClient = useQueryClient();
  const { isLoading, mutateAsync } = useMutation(deleteMedia, {
    mutationKey: queryKeys.deleteMedia,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.getMedias);
    },
    onError: errorHandler,
  });

  return { isLoading, deleteMediaById: (params: DeleteMediaParams) => mutateAsync(params) };
};
