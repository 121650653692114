import { clientToServerServiceMapper } from 'hooks/api/programs/mappers';
import { useCoverProgramEditStatusCallbacks } from 'pages/ProgramDetails/hooks/useCoverProgramEditStatusCallbacks';
import { ServicesFields } from 'pages/ProgramDetails/programTabComponents/AdditionalServices/types';
import { useMutation, useQueryClient } from 'react-query';
import { queryKeys } from 'services/constants';
import { updateService } from 'services/programs/services';
import { Callback } from 'types/helpers';
import { errorHandler } from 'utils/errorHandler';

export const useUpdateServices = (onSuccess?: Callback) => {
  const queryClient = useQueryClient();

  const { onSave } = useCoverProgramEditStatusCallbacks();

  const { mutate, isLoading } = useMutation(updateService, {
    mutationKey: queryKeys.updateService,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.getServices);
      onSuccess?.();
      onSave();
    },
    onError: errorHandler,
  });

  return {
    isLoading,
    updateServices: (clientData: ServicesFields) =>
      clientData.services.forEach((data) => mutate(clientToServerServiceMapper(data))),
  };
};
