import { SVGProps } from 'react';

export const PatientInfo = ({ color = '#787D83', ...props }: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox='0 0 26 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M25 12H20.2L16.6 22.8L9.4 1.20001L5.8 12H1'
      stroke={color}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
