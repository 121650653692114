import { showCustomToast } from 'components';
import { text } from 'constants/texts';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { queryKeys } from 'services/constants';
import { deleteSurvey } from 'services/surveys/requests';
import { Callback } from 'types/helpers';
import { Survey } from 'types/surveys';
import { errorHandler } from 'utils/errorHandler';
import { stringModifier } from 'utils/strings';
import { SURVEY_ID_PARAM } from 'routes/navigators';

export const useDeleteSurvey = (survey: Survey, onSuccess?: Callback) => {
  const queryClient = useQueryClient();
  const [params] = useSearchParams();
  const navigate = useNavigate();

  const { programId = '', id } = survey;

  const { mutate, isLoading } = useMutation(deleteSurvey, {
    mutationKey: queryKeys.deleteSurvey,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.getSurveys);
      showCustomToast({
        duration: 3,
        type: 'success',
        message: stringModifier(text['{0} deleted successfully'], `${survey.name} survey`),
      });
      onSuccess?.();
      // If user is in detail page, navigate user to the listing surveys
      if (params.get(SURVEY_ID_PARAM) === id) navigate(`/programs/${programId}?tab=surveys`);
    },
    onError: errorHandler,
  });

  return {
    isLoading,
    deleteSurvey: () => id && mutate({ uuid: id, programId }),
  };
};
