import { useMilestoneTrackers } from 'hooks/api/milestoneTrackers/useMilestoneTrackers';
import { useSearchParams } from 'react-router-dom';
import { MILESTONE_TRACKER_ID_PARAM } from 'routes/navigators';

export const useMilestoneTracker = (uuid?: string) => {
  const [params] = useSearchParams();

  const { milestoneTrackers, isLoading } = useMilestoneTrackers();

  return {
    isLoading,
    milestoneTracker: milestoneTrackers.find(
      (tracker) => tracker.uuid === (uuid || params.get(MILESTONE_TRACKER_ID_PARAM))
    ),
  };
};
