import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { queryKeys } from 'services/constants';
import { getSpecialtyPharmacyList } from 'services/specialtyPharmacies/requests';
import { toSpecialtyPharmacy } from './mappers';

export const useSpecialtyPharmacyList = (search = '') => {
  const { programId = '' } = useParams();

  const {
    data,
    isLoading,
    refetch: refetchSpecialtyPharmacy,
    isRefetching,
  } = useQuery(
    [queryKeys.getSpecialtyPharmacies, programId, search],
    () => getSpecialtyPharmacyList({ programId, search }),
    {
      select: (response) => response.items?.map(toSpecialtyPharmacy),
    }
  );

  return {
    isLoading,
    isRefetching,
    refetchSpecialtyPharmacy,
    specialtyPharmacyList: data || [],
    hasData: !!data?.length || !!search || isRefetching || isLoading,
  };
};
