import { storageKeys, defaultValues } from 'constants/localStorageKeys';
import { programMapper } from 'hooks/api/programs/mappers';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { queryKeys } from 'services/constants';
import { fetchProgram } from 'services/programs/programs';
import { DevError } from 'utils/errors';

export const useProgramData = (id?: string) => {
  const { programId } = useParams();
  const [_, setManufacturerId] = useLocalStorage(storageKeys.MANUFACTURER_ID, defaultValues.MANUFACTURER_ID);

  const { data: program, isLoading } = useQuery(
    [queryKeys.getProgram, programId],
    () => fetchProgram(id || programId || ''),
    {
      refetchOnMount: false,
      select: ({ programDetails }) => programMapper(programDetails),
    }
  );

  useEffect(() => {
    if (!program) return;
    setManufacturerId(program.details.universalSettings.manufacturerId);
  }, [program]);

  if (!id && !programId)
    throw new DevError('You should set "programId" param in url or "useProgramData" hook to get selected program data');

  return {
    program,
    isLoading,
  };
};
