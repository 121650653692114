import { api } from 'utils/api';
import { ApiService, GetListRequest, GetRequest } from 'types/apiTypes';
import { API_BASE_URL } from 'services/constants';
import { GetListPatientCommunicationsDTO, PatientDTO } from './types';

const PATIENT_SEARCH_ENDPOINT = `${API_BASE_URL}search/user_by_email/`;
const PATIENT_SEARCH_COMMUNICATION_ENDPOINT = `${API_BASE_URL}communications/user`;

export const {
  getPatient,
  getPatientList,
}: ApiService<'patient', GetRequest<PatientDTO, string> & GetListRequest<GetListPatientCommunicationsDTO, string>> = {
  getPatient: (email) => {
    return api
      .get<PatientDTO>(PATIENT_SEARCH_ENDPOINT, {
        params: { email },
      })
      .then((res) => res.data);
  },
  getPatientList: (email) => {
    return api
      .get<GetListPatientCommunicationsDTO>(PATIENT_SEARCH_COMMUNICATION_ENDPOINT, {
        params: { email },
      })
      .then((res) => res.data);
  },
};
