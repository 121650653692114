import React from 'react';

import { Layout } from 'antd';

import { SideMenu } from 'components';

import AppFooter from './AppFooter';

import './style.scss';

const { Content } = Layout;

export const AppLayout = ({ children }: React.PropsWithChildren<any>) => {
  return (
    <Layout className='pl-layout'>
      <SideMenu />
      <Layout className='site-layout'>
        <Content style={{ margin: '0 40px' }}>{children}</Content>
        <AppFooter />
      </Layout>
    </Layout>
  );
};
