import { useSearchParams } from 'react-router-dom';
import { useSurveys } from './useSurveys';
import { SURVEY_ID_PARAM } from 'routes/navigators';

export const useSurvey = (uuid?: string) => {
  const [params] = useSearchParams();

  const { surveys, isLoading } = useSurveys();

  return {
    isLoading,
    survey: surveys.find((survey) => survey.id === (uuid || params.get(SURVEY_ID_PARAM))),
  };
};
