import { storageKeys } from 'constants/localStorageKeys';
import { useState } from 'react';
import { getStorageItem } from 'utils/helpers';

export const useLocalStorage = <T>(
  key: typeof storageKeys[keyof typeof storageKeys],
  initialValue: T
): [T, (value: T | ((val: T) => T)) => void] => {
  const [storedValue, setStoredValue] = useState<T>(() => getStorageItem<T>(key, initialValue));

  const setValue = (value: T | ((val: T) => T)) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {}
  };

  return [storedValue, setValue];
};
