import { ColumnsType } from 'antd/es/table';
import { DataTable, Spacer, TextCell } from 'components';
import { getFontClassName } from 'utils/getFontClassName';
import { format, parseISO } from 'date-fns';
import { PATIENT_SEARCH_ID_PARAMS, useGoToCommunicationDetailsPage } from 'routes/navigators';
import { PatientCommunication } from 'services/patientSearch/types';
import { text } from 'constants/texts';
import { useSearchParams } from 'react-router-dom';

type CommunicationsContentProps = {
  patientCommunications?: PatientCommunication[];
};

export type Communications = Pick<PatientCommunication, 'id' | 'name' | 'channel' | 'createdAt' | 'surveyData'>;

const columns: ColumnsType<Communications> = [
  {
    width: '46%',
    title: text['Communication Name'],
    dataIndex: 'name',
    render: (_, { channel, name }) => (
      <TextCell color={channel === 'survey' ? 'blue' : 'gray2'} className={getFontClassName('button')} text={name} />
    ),
  },
  {
    width: '27%',
    title: text['Communication Type'],
    dataIndex: 'channel',
    render: (value) => <TextCell color='gray2' className={getFontClassName('paragraph')} text={value} />,
  },
  {
    width: '27%',
    title: text['Sent Date'],
    dataIndex: 'createdAt',
    render: (value) => (
      <TextCell
        color='gray2'
        className={getFontClassName('paragraph')}
        text={value ? format(parseISO(value), 'MMM dd,yyyy') : '-'}
      />
    ),
  },
];

export const CommunicationsContent = ({ patientCommunications }: CommunicationsContentProps) => {
  const [params] = useSearchParams();
  const patientEmail = params.get(PATIENT_SEARCH_ID_PARAMS) || '';
  const goToCommunicationsDetailsPage = useGoToCommunicationDetailsPage();

  if (!patientCommunications) return null;

  return (
    <div>
      <h1 className='page-content-title'>{text['Communications']}</h1>
      <h3 className='page-content-description'>{text['This is a list of the communications sent to this patient.']}</h3>
      <Spacer height={24} />
      <DataTable<Communications>
        loading={!patientCommunications}
        rowKey='id'
        columns={columns}
        dataSource={patientCommunications}
        onRow={({ channel, ...record }) => ({
          onClick: () => (channel === 'survey' ? goToCommunicationsDetailsPage(record?.id, patientEmail) : null),
        })}
      />
    </div>
  );
};
