import { UploadFile } from 'antd/lib/upload/interface';

import clx from 'classnames';
import { Button } from 'components';
import { fileIconsMapping } from 'components/fields/PickMediaList/PickMediaList';
import { MetaTypes } from 'services/medias/types';
import { Callback } from 'types/helpers';
import { getFontClassName } from 'utils/getFontClassName';
import './style.scss';

type UploadItemProps = {
  file: UploadFile;
  remove: Callback;
  fileType: MetaTypes;
};

export const UploadItem = ({ fileType, file, remove }: UploadItemProps) => (
  <div className='pl-upload-item'>
    <div className='info'>
      {fileIconsMapping[fileType]}
      <div className={clx(getFontClassName('paragraph'), 'info-name')}>{file.name}</div>
    </div>
    <Button colorType='negative' type='text' onClick={remove}>
      Delete
    </Button>
  </div>
);
