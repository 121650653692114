import { SpecialtyPharmacyDTO } from 'services/specialtyPharmacies/types';
import { ServerObjectMappers } from 'types/apiTypes';
import { SpecialtyPharmacy } from 'types/specialtyPharmacy';

export const {
  toSpecialtyPharmacy,
  toSpecialtyPharmacyDTO,
}: ServerObjectMappers<'specialtyPharmacy', SpecialtyPharmacyDTO, SpecialtyPharmacy> = {
  toSpecialtyPharmacy: (dto) => ({
    uuid: dto.uuid ?? '',
    specialtyPharmacyName: dto.name ?? '',
    allowShipmentTracking: dto.isAllowShipmentTracking ?? false,
    phoneNumber: dto.phoneNumber ?? '',
    emailAddress: dto.email ?? '',
    streetAddressLine1: dto.addressLine1 ?? '',
    streetAddressLine2: dto.addressLine2 ?? '',
    city: dto.city ?? '',
    state: dto.state ?? '',
    zipCode: dto.zipCode ?? '',
  }),
  toSpecialtyPharmacyDTO: (instance) => ({
    uuid: instance.uuid ?? '',
    name: instance.specialtyPharmacyName ?? '',
    isAllowShipmentTracking: instance.allowShipmentTracking ?? false,
    phoneNumber: instance.phoneNumber ?? '',
    email: instance.emailAddress ?? '',
    addressLine1: instance.streetAddressLine1 ?? '',
    addressLine2: instance.streetAddressLine2 ?? '',
    city: instance.city ?? '',
    state: instance.state ?? '',
    zipCode: instance.zipCode ?? '',
  }),
};
