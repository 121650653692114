import axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';
import { removeCookies } from 'utils/helpers';

const axiosConfig: AxiosRequestConfig = {
  baseURL: '',
  timeout: 30000,
};

const axiosClient = axios.create(axiosConfig);

axiosClient.interceptors.request.use(
  async (config) => {
    const token = Cookies.get('token');
    config.headers = {
      ...(!!token && { Authorization: `Bearer ${token}` }),
      Accept: 'application/json',
    };
    return config;
  },
  (error) => Promise.reject(error)
);

axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      window.location.href = '/login';
      removeCookies();
      // [Backend does not support refreshToken yet]
      // const access_token = Cookies.get('refreshToken');
      // axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
      return axiosClient(originalRequest);
    }
    return Promise.reject(error);
  }
);

export default axiosClient;
