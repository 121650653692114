import { Row } from 'antd';
import { FormListOperation } from 'antd/lib/form/FormList';
import { Button, Spacer } from 'components';
import { getFieldsJSX } from 'components/fields/utils';
import { MILESTONE_TRACKER_STEPS_LIMIT } from 'constants/limits';
import { text } from 'constants/texts';
import { getUpdateMilestoneTrackerStepFields } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/MilestoneTrackers/MilestoneTrackerDetails/fields';
import { getFontClassName } from 'utils/getFontClassName';
import { stringModifier } from 'utils/strings';

type MilestoneTrackerDynamicFormProps = {
  orderIndex: number;
  isEditMode: boolean;
} & Pick<FormListOperation, 'remove'>;

export const MilestoneTrackerDynamicForm = ({ remove, isEditMode, orderIndex }: MilestoneTrackerDynamicFormProps) => (
  <>
    <Row justify='space-between'>
      <h2 className={getFontClassName('h2')}>{stringModifier(text['Step {0}'], `${orderIndex + 1}`)}</h2>
      {isEditMode && MILESTONE_TRACKER_STEPS_LIMIT.isMin(orderIndex) && (
        <Button onClick={() => remove(orderIndex)} type='text' layoutType='no-styles' colorType='negative'>
          {text['Delete']}
        </Button>
      )}
    </Row>
    <Spacer height={24} type='flex' />
    {getFieldsJSX(getUpdateMilestoneTrackerStepFields(orderIndex), isEditMode)}
    <hr />
    <Spacer height={26} type='flex' />
  </>
);
