import { text } from 'constants/texts';
import { useProgramData } from 'hooks/api';
import { MilestoneTrackersActions } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/MilestoneTrackers/MilestoneTrackersActions';
import { MilestoneTrackersContent } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/MilestoneTrackers/MilestoneTrackersContent';
import { ContentUseTabsReturnType } from 'pages/ProgramDetails/programTabComponents/Content/tabsConfig/types';

export const useMilestoneTrackersTab = (): ContentUseTabsReturnType => {
  const { program } = useProgramData();

  return {
    tab: {
      tabKey: 'milestone-trackers',
      title: text['Milestone Trackers'],
      tabData: {
        actionsJSX: <MilestoneTrackersActions program={program} />,
        contentJSX: <MilestoneTrackersContent program={program} />,
      },
    },
  };
};
