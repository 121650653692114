import { mediaDTOMapper } from 'hooks/api/programs/mappers';
import { API_BASE_URL, formDataHeader } from 'services/constants';
import {
  CreateMediaWithFormData,
  DeleteMediaParams,
  GetMediasParams,
  MediaDTO,
  MediasDTO,
  UpdateMediaDataWithFormData,
} from 'services/medias/types';
import { ApiService, CreateRequest, DeleteRequest, GetListRequest, UpdateRequest } from 'types/apiTypes';
import { Media } from 'types/programs';
import { api } from 'utils/api';

const MEDIA_ENDPOINT = `${API_BASE_URL}media/`;

export const {
  createMedia,
  updateMedia,
  getMediaList,
  deleteMedia,
}: ApiService<
  'media',
  CreateRequest<Media, CreateMediaWithFormData> &
    UpdateRequest<unknown, UpdateMediaDataWithFormData> &
    GetListRequest<MediasDTO, GetMediasParams> &
    DeleteRequest<unknown, DeleteMediaParams>
> = {
  createMedia: ({ data, programId, setProgress, cancelToken }: CreateMediaWithFormData) => {
    return api
      .post<MediaDTO>(MEDIA_ENDPOINT + programId, data, {
        headers: formDataHeader,
        onUploadProgress: (progressEvent: ProgressEvent) => {
          if (!setProgress) return;
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(percentCompleted);
        },
        cancelToken,
      })
      .then((response) => mediaDTOMapper(response.data));
  },
  updateMedia: ({ programId, mediaId, data }) => {
    return api
      .patch(MEDIA_ENDPOINT, data, { params: { program_uuid: programId, media_uuid: mediaId } })
      .then((response) => response.data);
  },
  deleteMedia: ({ programId, mediaId }: DeleteMediaParams) => {
    return api.delete(`${MEDIA_ENDPOINT}?program_uuid=${programId}&media_uuid=${mediaId}`);
  },
  getMediaList: ({ programId, search, mediaType }) => {
    return api
      .get<MediasDTO>(MEDIA_ENDPOINT + programId, { params: { search, media_type: mediaType } })
      .then((res) => res.data);
  },
};
