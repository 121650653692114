import { Button, ButtonProps } from 'antd';
import cx from 'classnames';

import './style.scss';

type PlButtonProps = {
  colorType?: 'negative' | 'default';
  layoutType?: 'no-styles';
} & Pick<ButtonProps, 'loading' | 'style' | 'onClick' | 'type' | 'className' | 'children' | 'htmlType' | 'disabled'>;

export const PlButton = ({ className, type, layoutType, colorType = 'default', ...rest }: PlButtonProps) => (
  <Button
    size='large'
    type={type || 'primary'}
    className={cx(['pl-button', className, colorType, layoutType])}
    {...rest}
  />
);
