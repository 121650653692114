import { Form } from 'antd';
import { getPatientInformationFields, PatientInfoFields } from './patientInfoFields';
import { getFieldsJSX } from 'components/fields/utils';
import { Spacer } from 'components';
import { text } from 'constants/texts';
import { Patient } from 'services/patientSearch/types';

const { useForm } = Form;

type PatientInformationContentProps = {
  patient?: Patient;
};

export const PatientInformationContent = ({ patient }: PatientInformationContentProps) => {
  const [form] = useForm<PatientInfoFields>();

  return (
    <div className='patient-details-wrapper'>
      <h1 className='page-content-title'> {text['Patient Information']}</h1>
      <h3 className='page-content-description'>{text['These are the fields for Patient Information']}</h3>
      <Spacer height={24} />
      <Form<PatientInfoFields>
        form={form}
        layout='vertical'
        autoComplete='on'
        initialValues={patient}
        className='patient-details-form'
      >
        {getFieldsJSX(getPatientInformationFields())}
      </Form>
    </div>
  );
};
