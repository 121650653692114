export default {
  TOKEN: 'token',
  USER_ID: 'userId',
  USER_EMAIL: 'userEmail',
  PERMISSION: 'permission',
  USER_LAST_NAME: 'userLastName',
  USER_FIRST_NAME: 'userFirsName',
  USER_ROLE_ID: 'userRoleId',
  USER_ROLE_NAME: 'userRoleName',
  USER_MANUFACTURERS_IDS: 'userManufacturersIds',
  USER_MANUFACTURERS_NAMES: 'userManufacturersNames',
};
