import {
  getActionDTOMeta,
  getActionPayloadData,
} from 'pages/ProgramDetails/programTabComponents/BusinessRules/BusinessRuleDetails/components/BusinessRuleEditContent/utils';
import { CommunicationTemplateDTO } from 'services/rules/communicationTemplates/types';
import { ActionDTO, AttributeDTO, BusinessRulesMetaDTO, OperatorDTO, TriggerDTO } from 'services/rules/meta/types';
import { BusinessRuleConditionDTO, BusinessRuleDTO } from 'services/rules/types';
import { ServerObjectMappers } from 'types/apiTypes';
import { Action, Attribute, BusinessRulesMeta, Operator, Trigger } from 'types/businessRuleMeta';
import { CommunicationTemplate } from 'types/communicationTemplate';
import { BusinessRule, BusinessRuleCondition } from 'types/rules';
import { getDateFromUnknown } from 'utils/date';
import { determineIfIsBusinessRule } from 'utils/determinations';
import { getHardcodedNumberId, getHardcodedStringId } from 'utils/hardcodes';
import { removeEmpty } from 'utils/object';

// BUSINESS RULE
export const {
  toCondition,
  toConditionDTO,
}: ServerObjectMappers<
  'condition',
  BusinessRuleConditionDTO,
  BusinessRuleCondition,
  Pick<BusinessRulesMeta, 'attributes' | 'operatorLabels' | 'triggers' | 'actionsMetaFields'>
> = {
  toCondition: (dto, params) => {
    const key = dto.key?.[0] ?? '';
    const value = dto.value?.[0];
    const actionsMetaFields = dto.actionsMetaFields?.[0];
    const attribute = params.attributes.find((attribute) => attribute.key === key);

    const labels = params.operatorLabels[attribute?.type ?? 'boolean'];

    return {
      key,
      value: value === null ? undefined : value ?? false,
      actionsMetaField: actionsMetaFields ?? [],
      operator: dto.operator ?? '',
      label: attribute?.label ?? '',
      type: attribute?.type ?? 'boolean',
      operatorLabels: labels,
    };
  },
  toConditionDTO: (instance) => ({
    key: [instance.key],
    operator: instance.operator,
    value: [instance.value === undefined || instance.value === '' ? null : instance.value],
  }),
};

export const {
  toBusinessRule,
  toBusinessRuleDTO,
}: ServerObjectMappers<'businessRule', BusinessRuleDTO, BusinessRule, BusinessRulesMeta> = {
  toBusinessRule: (dto, params) => {
    const actionsMeta: BusinessRule['actionsMeta'] = {
      emailTemplateId: dto.actions_meta?.email_data?.template_id ?? null,
      smsMessage: dto.actions_meta?.sms_data?.template_id ?? null,
      surveyId: dto.actions_meta?.survey_data?.survey_uuid ?? null,
      pushNotificationTemplateId: dto.actions_meta?.push_data?.template_id ?? null,
      notificationTileId: dto.actions_meta?.notification_tile_data?.notification_tile_uuid ?? null,
      additionalServiceUuid: dto.actions_meta?.additional_service_data?.additional_service_uuid ?? null,
      milestoneData: {
        milestoneTrackerId: dto.actions_meta?.milestone_data?.milestone_tracker_uuid ?? null,
        milestoneId: dto.actions_meta?.milestone_data?.milestone_uuid ?? null,
        milestoneDescription: dto.actions_meta?.milestone_data?.milestone_description ?? null,
        milestoneName: dto.actions_meta?.milestone_data?.milestone_name ?? null,
        milestoneMetaFields:
          dto.actions_meta?.milestone_data?.meta_fields?.map((metaField) => ({
            field: metaField.field,
            description: metaField.description,
          })) ?? null,
      },
      postponedEventData: {
        postponed_rule_uuid: dto.actions_meta?.postponed_event_data?.postponed_rule_uuid ?? null,
        postponed_count: dto.actions_meta?.postponed_event_data?.postponed_count ?? null,
        postponed_value: dto.actions_meta?.postponed_event_data?.postponed_value ?? null,
      },
      // Contact Card Fields for Send Contact Action
      contactCardData: {
        templateId: dto.actions_meta?.contact_card_data?.template_uuid ?? null,
        pharmacyId: dto.actions_meta?.contact_card_data?.pharmacy_uuid ?? null,
      },
      smsKeywordData: {
        keywordTemplateId: dto.actions_meta?.sms_keyword_data?.template_id ?? null,
      },
    };

    return {
      name: dto.name ?? '',
      description: dto.description ?? '',
      programId: dto.program_id ?? '',
      id: dto.id ?? getHardcodedNumberId(),
      uuid: dto.uuid ?? getHardcodedStringId(),
      trigger: dto.trigger ?? '',
      triggerLabel: params.triggers.find((trigger) => trigger.key === dto.trigger)?.label ?? '',
      actions:
        dto.actions?.map((key) => ({
          key,
          label: params.actions.find((action) => action.key === key)?.label || '',
          payload: getActionPayloadData(actionsMeta),
        })) || [],
      actionsMeta,
      isActive: dto.is_active ?? false,
      status: dto.is_active ? 'Active' : 'Inactive',
      createdAt: getDateFromUnknown(dto.created_at),
      conditions: {
        and: dto.conditions?.and?.map((condition) => toCondition(condition, params)) ?? [],
      },
      usersInEvent: dto.users_count ?? 0,
    };
  },
  toBusinessRuleDTO: (instance) => ({
    name: instance.name ?? '',
    trigger: instance.trigger,
    description: instance.description ?? '',
    ...(removeEmpty<BusinessRuleDTO | {}>(
      determineIfIsBusinessRule(instance)
        ? {
            id: instance.id,
            uuid: instance.uuid,
            name: instance.name,
            description: instance.description,
            is_active: instance.isActive,
            created_at: instance.createdAt,
            users_count: instance.usersInEvent,
            actions: instance.actions.map((action) => action.key),
            conditions: {
              and: instance.conditions.and.map(toConditionDTO),
            },
            actions_meta: getActionDTOMeta(instance),
          }
        : {},
      [undefined, null]
    ) as Omit<BusinessRuleDTO, 'name' | 'description'>),
    program_id: instance.programId ?? '',
  }),
};

// META
const { toAttribute }: ServerObjectMappers<'attribute', AttributeDTO, Attribute> = {
  toAttribute: (dto) => ({
    key: dto.key ?? '',
    label: dto.label ?? '',
    type: dto.type ?? 'boolean',
    trigger_type: dto.trigger_type ?? null,
  }),
  toAttributeDTO: () => ({}),
};

const { toTrigger }: ServerObjectMappers<'trigger', TriggerDTO, Trigger> = {
  toTrigger: (dto) => ({
    key: dto.key ?? '',
    label: dto.label ?? '',
    attributes: dto.attributes?.map(toAttribute) ?? [],
  }),
  toTriggerDTO: () => ({}),
};

const { toOperator }: ServerObjectMappers<'operator', OperatorDTO, Operator> = {
  toOperator: (dto) => ({
    key: dto.key ?? '',
  }),
  toOperatorDTO: () => ({}),
};

const { toAction }: ServerObjectMappers<'action', ActionDTO, Action> = {
  toAction: (dto) => ({
    key: dto.key ?? 'EMAIL',
    label: dto.label ?? '',
  }),
  toActionDTO: () => ({}),
};

export const { toBusinessRuleMeta }: ServerObjectMappers<'businessRuleMeta', BusinessRulesMetaDTO, BusinessRulesMeta> =
  {
    toBusinessRuleMeta: (dto) => ({
      triggers: dto.triggers?.map(toTrigger) ?? [],
      attributes: dto.attributes?.map(toAttribute) ?? [],
      operators: {
        compare: dto.operators?.compare?.map(toOperator) ?? [],
        logic: dto.operators?.logic?.map(toOperator) ?? [],
      },
      actions: dto.actions?.map(toAction) ?? [],
      actionsMetaFields: dto.actions_meta_fields ?? [],
      operatorLabels: dto.operators_by_type ?? { string: {}, rule: {}, boolean: {}, date: {}, integer: {} },
      attributeTypes: Object.fromEntries(
        dto.triggers
          ?.map(
            (trigger) =>
              trigger.attributes?.map((attribute) => [
                attribute.key,
                { type: attribute.type ?? 'boolean', label: attribute.label ?? '' },
              ]) ?? []
          )
          .flat() ?? []
      ),
    }),
    toBusinessRuleMetaDTO: () => ({}),
  };

// COMMUNICATION TEMPLATES

export const {
  toCommunicationTemplate,
}: ServerObjectMappers<'communicationTemplate', CommunicationTemplateDTO, CommunicationTemplate> = {
  toCommunicationTemplate: (dto) => ({
    id: dto.id ?? '',
    label: dto.label ?? dto.name ?? '',
  }),
  toCommunicationTemplateDTO: () => ({}),
};
