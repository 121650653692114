import { Spin } from 'antd';
import { MoreOptionsCell, showCustomToast } from 'components';
import { usePermissionsHandler } from 'hooks';
import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { forgotPassword } from 'services/auth/auth';
import { AdminStatus } from 'types/union-types';
import { modal } from './constants';
import { AdminData } from './types';
import { getAdminFullName } from './utils';
import { text } from 'constants/texts';
import { MoreOption } from 'components/tableCells/MoreOptionsCell/MoreOptionsCell';
import { useUpdateAdminStatus } from 'hooks/api/admins/useUpdateAdminStatus';
import { useDeleteAdmin } from 'hooks/api/admins/useDeleteAdmin';
import { stringModifier } from 'utils/strings';

export const SuperAdminActions = ({
  id,
  status,
  email,
  children,
  withButton,
  ...adminRest
}: PropsWithChildren<Partial<AdminData>> & { withButton?: boolean }) => {
  const navigate = useNavigate();
  const [isAdminsDeleteAllowed, isAdminStatusEditAllowed, isAdminResetPasswordAllowed] = usePermissionsHandler([
    'admins.delete',
    'admin_status.edit',
    'admins.reset_password',
  ]);

  const adminFullName = getAdminFullName(adminRest);

  const { updateAdminStatus, isLoading: isUpdateStatusLoading } = useUpdateAdminStatus(adminFullName);
  const { deleteAdmin, isLoading: isDeleteAdminLoading } = useDeleteAdmin(() => {
    navigate('/admins');
    showCustomToast({
      type: 'success',
      message: stringModifier(text['{0}`s account has been {1}.'], adminFullName, text['deleted']),
    });
  });

  const isActive = status === text['Active'];
  const isPending = status === text['Pending'];

  const isLoading = isUpdateStatusLoading || isDeleteAdminLoading;

  const statusUpdateHandler = () => {
    const targetStatus: AdminStatus = text[isActive ? 'Inactive' : 'Active'];
    if (!id) return;
    updateAdminStatus({ id, status: targetStatus });
  };

  const deleteHandler = () => {
    if (!id) return;
    deleteAdmin(id);
  };

  const resetPasswordHandler = async () => {
    if (!email) return;
    try {
      await forgotPassword(email);
      showCustomToast({ type: 'success', message: text['Reset-password email send successfully'] });
    } catch (error) {
      showCustomToast({ type: 'error', message: text["Ops, there's a issue with sending reset-password email"] });
    }
  };

  const options: MoreOption[] = [
    {
      key: '0',
      request: resetPasswordHandler,
      label: text['Reset Password'],
      disabled: isPending || !isAdminResetPasswordAllowed,
    },
    {
      key: '1',
      label: text[isActive ? 'Deactivate' : 'Activate'],
      disabled: isPending || !isAdminStatusEditAllowed,
      request: statusUpdateHandler,
    },
    {
      key: '2',
      label: text['Delete'],
      disabled: !isAdminsDeleteAllowed,
      request: deleteHandler,
      modalInfo: {
        title: modal.title,
        description: modal.description,
        cancelTextBtn: modal.cancelText,
        confirmTextBtn: modal.confirmText,
      },
    },
  ];

  return (
    <Spin size='small' spinning={isLoading}>
      <MoreOptionsCell options={options} withButton={withButton} />
    </Spin>
  );
};
