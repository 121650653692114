import { Form, ModalProps } from 'antd';

import { Button, ModalAsync, showCustomToast, Spacer } from 'components';
import { useEffect, useState } from 'react';
import './style.scss';
import { getFieldsJSX } from 'components/fields/utils';
import { getUserDetailsFields, UserDetailsFields } from '../../shared/formFields';
import { useManufacturers } from 'hooks/api/programs/useManufacturers';
import { useAdminRoles } from 'hooks/api/admins/useAdminRoles';
import { text } from 'constants/texts';
import { stringModifier } from 'utils/strings';
import { AppCheckboxGroup } from 'components/fields/AppCheckboxGroup/AppCheckboxGroup';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { showManufactureRequiredErrorMessage } from '../helpers';
import { useCreateAdmin } from 'hooks/api/admins/useCreateAdmin';
import { toManufacturerSelectOptions } from 'hooks/api/programs/mappers';

type CreateAdminModalProps = ModalProps & {
  close: () => void;
};

export const CreateAdminModal = ({ close, ...modalProps }: CreateAdminModalProps) => {
  const [form] = Form.useForm();
  const [selectedManufacturers, setSelectedManufacturers] = useState<[] | string[]>([]);
  const { adminRoles, isLoading: isUserRolesLoading } = useAdminRoles();
  const { manufacturers, isLoading: isManufacturersLoading } = useManufacturers();
  const { createAdmin, isLoading: isAdminCreateLoading } = useCreateAdmin(() => {
    close();
    showCustomToast({
      message: text['User successfully created!'],
      type: 'success',
    });
  });

  useEffect(() => {
    if (!modalProps.visible) {
      form.resetFields();
      setSelectedManufacturers([]);
    }
  }, [form, modalProps.visible]);

  const onCheckboxGroupChange = (checkedValues: CheckboxValueType[]) => {
    setSelectedManufacturers(checkedValues as string[]);
  };

  const handleSubmit = (values: UserDetailsFields) => {
    if (!selectedManufacturers.length) showManufactureRequiredErrorMessage();
    else
      createAdmin({
        ...values,
        manufacturerIds: selectedManufacturers,
      });
  };

  const isLoading = isAdminCreateLoading || isManufacturersLoading || isUserRolesLoading;

  return (
    <ModalAsync className='create-admin-modal' onOk={close} onCancel={close} {...modalProps}>
      <h1 className='modal-title'>{stringModifier(text['Add {0}'], text['User'])}</h1>
      <Spacer height={16} type={'flex'} />
      <h2 className='modal-description'>{text['Add a new User to PatientLink']}</h2>
      <Spacer height={24} type={'flex'} />
      <h3 className='modal-form-title'>{text['Basic Information']}</h3>
      <Form<UserDetailsFields> form={form} className='form' layout='vertical' onFinish={handleSubmit} autoComplete='on'>
        {getFieldsJSX(getUserDetailsFields(adminRoles))}
        <h1 className='page-content-title'>{text['Manufacturer Permissions']}</h1>
        <Spacer height={18} type='flex' />
        <AppCheckboxGroup
          options={manufacturers.map(toManufacturerSelectOptions)}
          onChange={onCheckboxGroupChange}
          value={selectedManufacturers}
        />
        <Form.Item className='action-buttons'>
          <Button type='ghost' onClick={close}>
            {text['Cancel']}
          </Button>
          <Spacer width={16} />
          <Button loading={isLoading} htmlType='submit'>
            {text['Create']}
          </Button>
        </Form.Item>
      </Form>
    </ModalAsync>
  );
};
