export const AUTH_BASE_URL = '/authapi/v1/admin/';
export const API_BASE_URL = '/api/v1/';

export const formDataHeader = { 'Content-Type': 'multipart/form-data;' };

export const queryKeys = {
  checkRegistrationLink: 'checkRegistrationLink',
  confirmPassword: 'confirmPassword',
  confirmResetPassword: 'confirmResetPassword',
  createAdmin: 'createAdmin',
  createBenefit: 'createBenefit',
  createBusinessRule: 'createBusinessRule',
  createFinancialAssistance: 'createFinancialAssistance',
  createMedia: 'createMedia',
  createMilestoneTracker: 'createMilestoneTracker',
  createProgram: 'createProgram',
  createService: 'createService',
  createSurvey: 'createSurvey',
  deleteAdmin: 'deleteAdmin',
  deleteBusinessRule: 'deleteBusinessRule',
  deleteFinancialAssistance: 'deleteFinancialAssistance',
  deleteMedia: 'deleteMedia',
  deleteMilestoneTracker: 'deleteMilestoneTracker',
  deleteService: 'deleteService',
  deleteSurvey: 'deleteSurvey',
  getAdmins: 'getAdmins',
  getBenefits: 'getBenefits',
  getBusinessRules: 'getBusinessRules',
  getBusinessRulesMeta: 'getBusinessRulesMeta',
  getCommunicationTemplates: 'getCommunicationTemplates',
  getFinancialAssistanceList: 'getFinancialAssistanceList',
  getManufactures: 'getManufactures',
  getMedias: 'getMedias',
  getMilestoneTrackers: 'getMilestoneTrackers',
  getProgram: 'getProgram',
  getProgramEditStatus: 'getProgramEditStatus',
  getPrograms: 'getPrograms',
  getServices: 'getServices',
  getSurvey: 'getSurvey',
  getSurveys: 'getSurveys',
  login: 'login',
  resetPassword: 'resetPassword',
  setProgramEditStatus: 'setProgramEditStatus',
  updateAdmin: 'updateAdmin',
  updateAdminStatus: 'updateAdminStatus',
  updateBenefit: 'updateBenefit',
  updateBusinessRule: 'updateBusinessRule',
  updateFinancialAssistance: 'updateFinancialAssistance',
  updateMedia: 'updateMedia',
  updateMilestoneTracker: 'updateMilestoneTracker',
  updateProgram: 'updateProgram',
  updateProgramStatus: 'updateProgramStatus',
  updateService: 'updateService',
  updateSurvey: 'updateSurvey',
  validateSurvey: 'validateSurvey',
  getSpecialtyPharmacy: 'getSpecialtyPharmacy',
  getSpecialtyPharmacies: 'getSpecialtyPharmacies',
  createSpecialtyPharmacy: 'createSpecialtyPharmacy',
  updateSpecialtyPharmacy: 'updateSpecialtyPharmacy',
  deleteSpecialtyPharmacy: 'deleteSpecialtyPharmacy',
  getNotificationTiles: 'getNotificationTiles',
  createNotificationTile: 'createNotificationTile',
  updateNotificationTile: 'updateNotificationTile',
  deleteNotificationTile: 'deleteNotificationTile',
  getPatient: 'getPatient',
  getPatientCommunications: 'getPatientCommunications',
  getUserRoles: 'getUserRoles',
};
