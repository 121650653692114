import { FormInstance, Row } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { FormListOperation } from 'antd/es/form/FormList';
import { Spacer } from 'components';
import { getFieldsJSX } from 'components/fields/utils';
import { IconButton } from 'components/IconButton/IconButton';
import { ConditionalWrapper } from 'components/ShowWrapper/ConditionalWrapper';
import { text } from 'constants/texts';
import { useMountEffect } from 'hooks/useMountEffect';
import { TrashVector } from 'icons/TrashVector';
import { getBusinessRuleConditionFields } from 'pages/ProgramDetails/programTabComponents/BusinessRules/BusinessRuleDetails/components/BusinessRuleEditContent/fields';
import { UpdateBusinessRuleFields } from 'pages/ProgramDetails/programTabComponents/BusinessRules/components/CreateBusinessRuleModal/types';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { BusinessRulesMeta } from 'types/businessRuleMeta';
import { BusinessRule } from 'types/rules';
import { getFontClassName } from 'utils/getFontClassName';

type BusinessRuleConditionDynamicFormProps = {
  form: FormInstance<UpdateBusinessRuleFields>;
  remove: FormListOperation['remove'];
  orderIndex: number;
  businessRule: BusinessRule;
  meta?: BusinessRulesMeta;
};

export const BusinessRuleConditionDynamicForm = ({
  form,
  meta,
  remove,
  orderIndex,
  businessRule,
}: BusinessRuleConditionDynamicFormProps) => {
  const { programId = '' } = useParams();
  const selectedAttributeKey = useWatch(['conditions', orderIndex, 'attribute'], form);
  const selectedValue = useWatch(['conditions', orderIndex, 'value'], form);

  useMountEffect(
    () =>
      form.setFields([
        {
          name: ['conditions', orderIndex, 'operator'],
          errors: [],
          value: undefined,
        },
        {
          name: ['conditions', orderIndex, 'value'],
          errors: [],
          value: undefined,
        },
      ]),
    [selectedAttributeKey],
    [!selectedAttributeKey]
  );

  useEffect(() => {
    const isRule = meta?.attributes.find(({ key }) => key === selectedAttributeKey)?.type === 'rule';
    const ruleValueWasSelected = typeof selectedValue === 'boolean';

    isRule && !ruleValueWasSelected && form.setFields([{ name: ['conditions', orderIndex, 'value'], value: true }]);
  }, [orderIndex, form, meta?.attributes, selectedAttributeKey, selectedValue]);

  return (
    <>
      <ConditionalWrapper condition={!!orderIndex}>
        <h2 className={getFontClassName('h2', 'gray2')}>{text['AND']}</h2>
        <Spacer height={14} type='flex' />
      </ConditionalWrapper>
      <label className={getFontClassName('label')}>{text['Criteria']}</label>
      <Spacer height={12} type='flex' />
      <Row justify='space-between' align='middle'>
        <div style={{ flex: 1 }}>
          {getFieldsJSX(
            getBusinessRuleConditionFields({
              orderIndex,
              selectedAttributeKey,
              meta,
              businessRule,
              programId,
            })
          )}
        </div>
        <ConditionalWrapper condition={!!orderIndex}>
          <Spacer width={12} />
          <IconButton onPress={() => remove(orderIndex)} icon={<TrashVector />} />
        </ConditionalWrapper>
      </Row>
      <Spacer height={14} type='flex' />
    </>
  );
};
