import { BreadCrumb } from 'types';
import { text } from 'constants/texts';

type PatientsSearchPage = {
  name: 'PATIENTS_SEARCH';
};

type CommunicationDetailsPage = {
  name: 'COMMUNICATIONS_DETAILS';
  communicationName: string;
};

type Config = PatientsSearchPage | CommunicationDetailsPage;

export const patientsSearchPageBreadCrumbs: BreadCrumb[] = [
  {
    name: text['Patient Search'],
    to: '/patient-search/',
    withParams: true,
  },
];

export const getPatientsPagesBreadCrumb = (config: Config): BreadCrumb[] => {
  switch (config.name) {
    case 'PATIENTS_SEARCH':
      return patientsSearchPageBreadCrumbs;
    case 'COMMUNICATIONS_DETAILS':
      return [
        ...patientsSearchPageBreadCrumbs,
        {
          name: config.communicationName || text['Name not found'],
        },
      ];
  }
};
