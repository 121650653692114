import { useQuery } from 'react-query';
import { queryKeys } from 'services/constants';
import { fetchPrograms } from 'services/programs/programs';

import { programMapper } from './mappers';

export const usePrograms = (searchKey?: string) =>
  useQuery(queryKeys.getPrograms, () => fetchPrograms(searchKey), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (response) => response.allPrograms.map(programMapper),
  });
