import { Button, Spacer } from 'components';
import { getFieldsJSX } from 'components/fields/utils';
import { DynamicAnswersFields } from 'pages/ProgramDetails/common';
import { getSecondStepFields } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Surveys/components/CreateSurveyModal/constants';
import { Callback } from 'types/helpers';

type QuestionsFormProps = {
  orderIndex: number;
  onAdd: Callback;
  visible: boolean;
};

export const QuestionsForm = ({ orderIndex, onAdd, visible }: QuestionsFormProps) => {
  return (
    <div style={{ display: visible ? 'block' : 'none' }} className='create-survey-form-questions'>
      <span className='page-content-title'>Question {orderIndex + 1}</span>
      <Spacer height={16} type='flex' />
      {getFieldsJSX(getSecondStepFields(orderIndex))}
      <DynamicAnswersFields name={[orderIndex, 'answers']} />
      <Button className='add-question-button' type='ghost' onClick={onAdd}>
        Add Another Question
      </Button>
    </div>
  );
};
