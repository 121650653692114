import { PatientSearchTabsReturnType } from './types';
import { getPatientInformationTab } from './getPatientInformationTab';
import { getCommunicationsTab } from './getCommunicationsTab';
import { Patient, PatientCommunication } from 'services/patientSearch/types';

type GetPatientSearchTabsProps = {
  patient?: Patient;
  patientCommunications?: PatientCommunication[];
};

export const getPatientSearchTabs = ({
  patient,
  patientCommunications,
}: GetPatientSearchTabsProps): PatientSearchTabsReturnType => {
  const patientInformationTab = getPatientInformationTab({ patient });
  const communicationsTab = getCommunicationsTab({ patientCommunications });

  return {
    tabs: [patientInformationTab.tab, communicationsTab.tab],
  };
};
