import { useMutation, useQueryClient } from 'react-query';
import { updateAdmin } from 'services/users/users';
import { queryKeys } from 'services/constants';
import { errorHandler } from 'utils/errorHandler';
import { Callback } from 'types/helpers';

export const useUpdateAdmin = (onSuccess: Callback) => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(updateAdmin, {
    mutationKey: queryKeys.updateAdmin,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.getAdmins);
      onSuccess?.();
    },
    onError: errorHandler,
  });

  return {
    isLoading,
    updateAdmin: mutateAsync,
  };
};
