import { ClosableWrapper } from 'components';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { Ref } from 'react';
import { Callback } from 'types/helpers';
import { CreateServiceForm } from './CreateServiceForm';

type CreateServiceModalProps = {
  modalRef: Ref<ClosableWrapperRef>;
  closeHandler?: Callback;
};

export const CreateServiceModal = ({ modalRef, closeHandler }: CreateServiceModalProps) => (
  <ClosableWrapper ref={modalRef}>
    <CreateServiceForm closeModal={closeHandler} />
  </ClosableWrapper>
);
