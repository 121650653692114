import { API_BASE_URL } from 'services/constants';
import {
  GetCommunicationTemplateData,
  GetCommunicationTemplateParams,
} from 'services/rules/communicationTemplates/types';
import { ApiService, GetListRequest } from 'types/apiTypes';
import { api } from 'utils/api';

const COMMUNICATION_TEMPLATE_ENDPOINT = `${API_BASE_URL}communication/`;

export const {
  getCommunicationTemplateList,
}: ApiService<'communicationTemplate', GetListRequest<GetCommunicationTemplateData, GetCommunicationTemplateParams>> = {
  getCommunicationTemplateList: (params) => {
    return api.get<GetCommunicationTemplateData>(COMMUNICATION_TEMPLATE_ENDPOINT, { params }).then((res) => res.data);
  },
};
