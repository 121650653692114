import { Input, InputNumber, InputNumberProps, InputProps } from 'antd';
import { TextAreaProps } from 'antd/es/input';
import { GroupProps } from 'antd/lib/avatar';
import { PasswordProps, SearchProps } from 'antd/lib/input';
import clx from 'classnames';
import { ReactNode } from 'react';

import './style.scss';

const { Group, Search, TextArea, Password } = Input;

type InputRenderType = 'NUMBER' | 'DEFAULT' | 'Group' | 'Search' | 'TextArea' | 'Password';

type CompoundedInputProps = InputNumberProps & InputProps & TextAreaProps & PasswordProps & GroupProps & SearchProps;

export type AppInputProps = {
  withoutBorder?: boolean;
  withoutWriting?: boolean;
  renderType?: InputRenderType;
  numberInputConfig?: {
    canBeNegative?: boolean;
    canBeFloat?: boolean;
  };
  value?: string | number | string[] | readonly string[];
} & Pick<
  CompoundedInputProps,
  | 'onBlur'
  | 'placeholder'
  | 'className'
  | 'disabled'
  | 'defaultValue'
  | 'prefix'
  | 'maxLength'
  | 'onChange'
  | 'rows'
  | 'onClick'
>;

export const AppInput = ({
  className,
  withoutBorder,
  withoutWriting,
  disabled,
  renderType = 'DEFAULT',
  onClick,
  numberInputConfig,
  ...rest
}: AppInputProps) => {
  let jsx: ReactNode;

  const classNames = clx(['pl-input', { 'no-border': withoutBorder, 'no-disabled-styles': withoutWriting }, className]);

  switch (renderType) {
    case 'DEFAULT':
      jsx = <Input {...rest} size='large' className={classNames} disabled={disabled || withoutWriting} />;
      break;
    case 'NUMBER':
      jsx = (
        <InputNumber
          {...rest}
          min={numberInputConfig?.canBeNegative ? undefined : 0}
          parser={numberInputConfig?.canBeFloat ? undefined : (value) => (value || '').replace(/\.|,/g, '')}
          size='large'
          className={classNames}
          disabled={disabled || withoutWriting}
          value={typeof rest.value === 'number' ? +rest.value : undefined}
        />
      );
      break;
    default:
      const Component = AppInput[renderType];

      jsx = (
        <Component
          style={
            rest.rows && rest.rows !== 1
              ? {
                  minHeight: (rest.rows * 27.5) / 2, // min height = half of initial height
                  maxHeight: rest.rows * 27.5 * 3, // max height = 3 * initial height
                }
              : undefined
          }
          className={clx(['pl-input', className])}
          {...rest}
        />
      );
      break;
  }

  if (onClick)
    return (
      <div style={{ display: 'contents' }} onClick={onClick}>
        {jsx}
      </div>
    );

  return jsx;
};

AppInput.Password = Password;
AppInput.Group = Group;
AppInput.TextArea = TextArea;
AppInput.Search = Search;
