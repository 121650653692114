import { useMutation, useQueryClient } from 'react-query';
import { queryKeys } from 'services/constants';
import { updateSurvey } from 'services/surveys/requests';
import { Callback } from 'types/helpers';
import { Survey } from 'types/surveys';
import { errorHandler } from 'utils/errorHandler';
import { toSurveyDTO } from './mappers';

export const useUpdateSurvey = (onSuccess?: Callback) => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(updateSurvey, {
    mutationKey: queryKeys.updateSurvey,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.getSurveys);
      onSuccess?.();
    },
    onError: errorHandler,
  });

  return {
    isLoading,
    updateSurvey: (data: Survey) => mutateAsync(toSurveyDTO(data)),
  };
};
