import { Form, FormProps, Spin } from 'antd';
import { Spacer } from 'components';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { getFieldsJSX } from 'components/fields/utils';
import { scrollToFirstErrorProps } from 'constants/common';
import { useChildrenRef } from 'hooks';
import { useUpdateProgram } from 'hooks/api';
import { ProgramDetailsConfirmModal } from 'pages/ProgramDetails/common/ProgramDetailsConfirmModal';
import { getUniversalSettingsSections } from 'pages/ProgramDetails/programTabComponents/UniversalSettings/sections';
import { getUniversalSettingsFieldByProgramData, showSaveEditedProgramToast } from 'pages/ProgramDetails/utils';
import { forwardRef, ForwardRefRenderFunction, Fragment, useImperativeHandle } from 'react';
import { Callback } from 'types/helpers';
import { ProgramData } from 'types/programs';
import { UniversalSettingsFields } from './types';

export type UniversalSettingsContentProps = {
  isEditMode: boolean;
  onSuccess: Callback;
  program?: ProgramData;
};

export type UniversalSettingsContentRef = {
  submit: Callback;
  reset: Callback;
};

const UniversalSettingsContentComponent: ForwardRefRenderFunction<
  UniversalSettingsContentRef,
  UniversalSettingsContentProps
> = ({ isEditMode, program, onSuccess }, ref) => {
  const [form] = Form.useForm<UniversalSettingsFields>();

  const [modalRef, setModalRef] = useChildrenRef<ClosableWrapperRef>();

  const { updateProgram, isLoading } = useUpdateProgram(program, () => {
    showSaveEditedProgramToast();
    onSuccess();
    modalRef?.close();
  });

  useImperativeHandle(ref, () => ({ submit: form.submit, reset: form.resetFields }));

  if (!program) return null;

  const handleSubmit: FormProps<UniversalSettingsFields>['onFinish'] = () => modalRef?.open();

  return (
    <Spin size='large' spinning={isLoading}>
      <Form<UniversalSettingsFields>
        layout='vertical'
        className='form'
        scrollToFirstError={scrollToFirstErrorProps}
        form={form}
        onFinish={handleSubmit}
        initialValues={getUniversalSettingsFieldByProgramData(program)}
      >
        {getUniversalSettingsSections(program, form).map(({ title, fields }) => (
          <Fragment key={title}>
            <h1 className='form-title'>{title}</h1>
            <Spacer height={24} type='flex' />
            {getFieldsJSX(fields, isEditMode)}
          </Fragment>
        ))}
      </Form>
      <ProgramDetailsConfirmModal
        type='Save'
        modalRef={setModalRef}
        confirmHandler={() => updateProgram(form.getFieldsValue())}
        cancelHandler={modalRef?.close}
      />
    </Spin>
  );
};

export const UniversalSettingsContent = forwardRef(UniversalSettingsContentComponent);
