import { OptionType } from 'components/fields/AppSelect/AppSelect';
import { FinancialAssistanceTerminationCriteriaType } from 'services/financialAssistance/types';

export const criteriaOptions: OptionType<FinancialAssistanceTerminationCriteriaType>[] = [
  { value: null, label: 'None' },
  { value: 'DATE_BASED', label: 'Date-Based' },
  { value: 'MONEY_BASED', label: 'Money-Based' },
  { value: 'ELAPSED_TIME', label: 'Elapsed Time' },
  { value: 'FILL_BASED', label: 'Fill-Based' },
  { value: 'API_BASED', label: 'API-Based' },
];
