import { Dropdown, Menu } from 'antd';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { useChildrenRef, usePermissionsHandler } from 'hooks';

import { ThreeDots } from 'icons';
import { DeleteMediaModal } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Media/components/DeleteMediaModal/DeleteMediaModal';
import { RenameMediaModal } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Media/components/RenameMediaModal/RenameMediaModal';

import { Media } from 'types/programs';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

export type MediaRowActionsProps = {
  media: Media;
};

export const MediaUpdateActions = ({ media }: MediaRowActionsProps) => {
  const [isDeleteMediaAllowed, isEditMediaAllowed] = usePermissionsHandler(['media_data.delete', 'media_data.edit']);
  const [deleteModalRef, setDeleteModalRef] = useChildrenRef<ClosableWrapperRef>();
  const [renameModalRef, setRenameModalRef] = useChildrenRef<ClosableWrapperRef>();

  const mediaMoreButtonActions: ItemType[] = [
    {
      label: 'Delete',
      onClick: deleteModalRef?.open,
      key: '0',
      disabled: !isDeleteMediaAllowed,
    },
    {
      label: 'Rename',
      onClick: renameModalRef?.open,
      key: '1',
      disabled: !isEditMediaAllowed,
    },
  ];

  const items = <Menu items={mediaMoreButtonActions} />;

  return (
    <>
      <Dropdown overlay={items} placement='bottomRight'>
        <ThreeDots />
      </Dropdown>
      <DeleteMediaModal media={media} onClose={deleteModalRef?.close} setDeleteModalRef={setDeleteModalRef} />
      <RenameMediaModal media={media} onClose={renameModalRef?.close} setRenameModalRef={setRenameModalRef} />
    </>
  );
};
