import { Form } from 'antd';
import { ClosableWrapper, ContentSteps } from 'components';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { ContentStepsRef } from 'components/ContentSteps/ContentSteps';
import { useCreateProgram } from 'hooks/api/';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { useGoToProgramDetailsPage } from 'routes/navigators';

import './style.scss';
import { CreateProgramFields } from './types';
import { getCreatedProgramStepJSX, getCreateProgramStepJSX } from './utils';

const { useForm } = Form;

type CreateProgramModalProps = {
  onModalRef: Dispatch<SetStateAction<ClosableWrapperRef>>;
  modalRef: ClosableWrapperRef;
};

export const CreateProgramModal = ({ onModalRef, modalRef }: CreateProgramModalProps) => {
  const [form] = useForm<CreateProgramFields>();

  const [createdProgramId, setCreatedProgramId] = useState('');

  const goToProgramDetailsPage = useGoToProgramDetailsPage(createdProgramId);

  const stepsRef = useRef<ContentStepsRef>(null);

  const { createProgram, isLoading } = useCreateProgram(({ uuid }) => {
    stepsRef.current?.next();
    setCreatedProgramId(uuid);
  });

  return (
    <ClosableWrapper ref={(ref) => !modalRef && ref && onModalRef(ref)}>
      <ContentSteps
        ref={stepsRef}
        steps={[
          getCreateProgramStepJSX({ onFinish: createProgram, form, closeModal: modalRef?.close, isLoading }),
          getCreatedProgramStepJSX({ toProgramPage: () => goToProgramDetailsPage(createdProgramId) }),
        ]}
      />
    </ClosableWrapper>
  );
};
