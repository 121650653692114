import { toUpdateProgramMapper } from 'hooks/api/programs/mappers';
import { useCoverProgramEditStatusCallbacks } from 'pages/ProgramDetails/hooks/useCoverProgramEditStatusCallbacks';
import { UniversalSettingsFields } from 'pages/ProgramDetails/programTabComponents/UniversalSettings/types';
import { useMutation, useQueryClient } from 'react-query';
import { queryKeys } from 'services/constants';
import { updateProgram } from 'services/programs/programs';
import { ProgramData } from 'types/programs';
import { errorHandler } from 'utils/errorHandler';

export const useUpdateProgram = (program?: ProgramData, onSuccess?: (program: UniversalSettingsFields) => void) => {
  const queryClient = useQueryClient();

  const { onSave } = useCoverProgramEditStatusCallbacks();

  const { mutate, isLoading } = useMutation(updateProgram, {
    mutationKey: queryKeys.updateProgram,
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKeys.getProgram);
      await queryClient.invalidateQueries(queryKeys.getPrograms);

      onSave();
      onSuccess?.({} as UniversalSettingsFields);
    },
    onError: errorHandler,
  });

  return {
    isLoading,
    updateProgram: (programDetails: UniversalSettingsFields) =>
      mutate({ id: program?.id || '', data: toUpdateProgramMapper(programDetails) }),
  };
};
