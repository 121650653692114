import { BenefitDTO, CreateBenefitData, GetBenefitsData, UpdateBenefitData } from 'services/benefits/types';
import { API_BASE_URL } from 'services/constants';
import { ApiService, CreateRequest, GetListRequest, UpdateRequest } from 'types/apiTypes';
import { getHardcodedStringId } from 'utils/hardcodes';

export const benefitsMock: BenefitDTO[] = [
  {
    name: 'Phase 1 Name',
    uuid: getHardcodedStringId(),
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  },
];

const BENEFITS_ENDPOINT = `${API_BASE_URL}benefits/`;

export const {
  getBenefitList,
  createBenefit,
  updateBenefit,
}: ApiService<
  'benefit',
  GetListRequest<GetBenefitsData> &
    UpdateRequest<unknown, UpdateBenefitData> &
    CreateRequest<unknown, CreateBenefitData>
> = {
  getBenefitList: () => {
    // TODO: Update it in the future
    return new Promise<GetBenefitsData>((res) => setTimeout(() => res({ data: benefitsMock }), 1000));
    // return api.get<GetBenefitsData>(BENEFITS_ENDPOINT).then((res) => res.data);
  },
  createBenefit: (params) => {
    // TODO: Update it in the future
    return new Promise<GetBenefitsData>((res) => {
      benefitsMock.push({ ...params, uuid: getHardcodedStringId() });
      setTimeout(() => {
        res({ data: benefitsMock });
      }, 1000);
    });
    // return api.put(BENEFITS_ENDPOINT, params);
  },
  updateBenefit: (params) => {
    // TODO: Update it in the future
    return new Promise((res) => setTimeout(() => res(console.log(params)), 1000));
    // return api.post(BENEFITS_ENDPOINT);
  },
};
