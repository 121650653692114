import { Form } from 'antd';
import { ModalActionButtons, Spacer } from 'components';

import { ContentSteps } from 'components/ContentSteps/ContentSteps';
import { text } from 'constants/texts';
import { useProgramData } from 'hooks/api';
import { useCreateSurvey, useValidateSurvey } from 'hooks/api/surveys';
import { FirstStepForm } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Surveys/components/CreateSurveyModal/FirstStepForm';
import { SecondStepForm } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Surveys/components/CreateSurveyModal/SecondStepForm';
import {
  DynamicSurveyFields,
  MainSurveyFields,
} from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Surveys/components/CreateSurveyModal/types';

import { useContentStepsData } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Surveys/components/CreateSurveyModal/useContentStepsData';

import 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Surveys/styles.scss';
import { useState } from 'react';
import { Callback } from 'types/helpers';
import { stringModifier } from 'utils/strings';

type CreateSurveyFormProps = {
  closeModal?: Callback;
};

const { useForm } = Form;

export const CreateSurveyForm = ({ closeModal }: CreateSurveyFormProps) => {
  const [firstStepForm] = useForm<MainSurveyFields>();
  const [secondStepForm] = useForm<DynamicSurveyFields>();
  const { program: { programName = '', id: programId = '' } = {} } = useProgramData();
  const { stepsRef, stepChangeHandler, stepData } = useContentStepsData();
  const { canGoNext, canGoBack, currentIndex, goBack, next } = stepData;
  const { createSurvey, isLoading } = useCreateSurvey(closeModal);
  const { validateSurvey, isLoading: isValidating } = useValidateSurvey();

  const [firstStepInitialFormValue, setFirstStepInitialFormValue] = useState<MainSurveyFields>();
  const [secondStepInitialFormValue, setSecondStepInitialFormValue] = useState<DynamicSurveyFields>();

  const handleGoBack = () => {
    /* We save the second step's values, to prevent losing the form values due to conditional rendering */
    setSecondStepInitialFormValue(secondStepForm.getFieldsValue());
    goBack?.();
  };

  const handleSubmit = async () => {
    /* We save the first step's values, to prevent losing the form values due to conditional rendering */
    setFirstStepInitialFormValue(firstStepForm.getFieldsValue());
    await validateSurvey({ program_uuid: programId, title: firstStepForm.getFieldValue('name') });
    if (canGoNext) return next?.();
    const formValues = { ...firstStepInitialFormValue!, ...secondStepForm.getFieldsValue() };
    createSurvey({ ...formValues, programId, status: 'Inactive' });
  };

  const contentSteps = [
    <FirstStepForm
      key={currentIndex}
      initialValues={firstStepInitialFormValue}
      handleSubmit={handleSubmit}
      form={firstStepForm}
    />,
    <SecondStepForm
      key={currentIndex}
      initialValues={secondStepInitialFormValue}
      handleSubmit={handleSubmit}
      form={secondStepForm}
      goBack={handleGoBack}
      isLoading={isLoading}
    />,
  ];

  return (
    <div className='create-service-form'>
      <h1 className='modal-title'>Add a Survey</h1>
      <Spacer height={16} type='flex' />
      <h2 className='modal-description'>{stringModifier(text['Add a Survey to the {0} program.'], programName)}</h2>
      <ContentSteps ref={stepsRef} steps={contentSteps} onStateChange={stepChangeHandler} />
      {currentIndex === 0 && (
        <>
          <Spacer height={32} type='flex' />
          <ModalActionButtons
            onCancel={canGoBack ? goBack : closeModal}
            submitText={canGoNext ? text['Next'] : text['Create']}
            cancelText={canGoBack ? text['Back'] : text['Cancel']}
            onSubmit={firstStepForm.submit}
            htmlType='button'
            isLoading={isValidating}
          />
        </>
      )}
    </div>
  );
};
