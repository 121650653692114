import { AppField, AppFields } from 'components/fields/types';
import { text } from 'constants/texts';
import { Option } from 'types/common';
import { getInputPlaceholder, getSelectPlaceholder, stringModifier } from 'utils/strings';
import { RULES } from 'utils/validations';
import { CreateNotificationTileFields } from './types';

const { REQUIRED, MAX, LINK } = RULES;

export type DestinationPageType =
  | 'FINANCIAL_ASSISTANCE'
  | 'BENEFITS'
  | 'RESOURCES'
  | 'REBATE_CENTER'
  | 'PROFILE'
  | 'MEDICINE_CABINET'
  | 'TRACKER';

interface DestinationPageOptions extends Option {
  value: DestinationPageType;
}

export const selectionTypeOptions: DestinationPageOptions[] = [
  {
    label: text['Financial Assistance'],
    value: 'FINANCIAL_ASSISTANCE',
  },
  {
    label: text['Benefits'],
    value: 'BENEFITS',
  },
  {
    label: text['Resources'],
    value: 'RESOURCES',
  },
  {
    label: text['Rebate Center'],
    value: 'REBATE_CENTER',
  },
  {
    label: text['Profile'],
    value: 'PROFILE',
  },
  {
    label: text['Medicine Cabinet'],
    value: 'MEDICINE_CABINET',
  },
  {
    label: text['Medication Tracker'],
    value: 'TRACKER',
  },
];

export const getCreateNotificationTileFields = ({
  externalLinkAdded,
}: {
  externalLinkAdded: boolean;
}): AppFields<CreateNotificationTileFields> => [
  notificationTileNameField,
  notificationTileTitleField,
  notificationTileDescriptionField,
  notificationTileDestinationPageField,
  notificationTileExternalLinkField,
  {
    ...notificationTileExternalLinkNameField,
    disabled: !externalLinkAdded,
    rules: [
      { ...(externalLinkAdded && REQUIRED({ requiredFieldName: text['External Link Name'] })) },
      MAX({ max: 100 }),
    ],
  } as AppField<Pick<CreateNotificationTileFields, 'externalLinkName'>>,
];

export const notificationTileNameField: AppField<Pick<CreateNotificationTileFields, 'name'>> = {
  type: 'input',
  name: 'name',
  label: text['Notification Tile Name'],
  placeholder: stringModifier(text['{0} Characters Max'], '50'),
  rules: [REQUIRED({ requiredFieldName: text['Notification Tile Name'] }), MAX({ max: 75 })],
};

export const notificationTileTitleField: AppField<Pick<CreateNotificationTileFields, 'title'>> = {
  type: 'input',
  name: 'title',
  label: text['Notification Tile Title'],
  placeholder: getInputPlaceholder(text['Notification Tile Title']),
  rules: [REQUIRED({ requiredFieldName: text['Notification Tile Title'] }), MAX({ max: 100 })],
};

export const notificationTileDescriptionField: AppField<Pick<CreateNotificationTileFields, 'description'>> = {
  type: 'input',
  name: 'description',
  label: text['Notification Tile Description'],
  placeholder: getInputPlaceholder(text['Description']),
  rules: [REQUIRED({ requiredFieldName: text['Notification Tile Description'] }), MAX({ max: 100 })],
};

export const notificationTileDestinationPageField: AppField<Pick<CreateNotificationTileFields, 'destinationPage'>> = {
  type: 'select',
  name: 'destinationPage',
  label: text['Destination Page'],
  options: selectionTypeOptions,
  placeholder: getSelectPlaceholder(text['Destination Page']),
};

export const notificationTileExternalLinkField: AppField<Pick<CreateNotificationTileFields, 'externalLink'>> = {
  type: 'input',
  name: 'externalLink',
  label: text['External Link'],
  placeholder: getInputPlaceholder(text['External Link']),
  rules: [LINK()],
};

export const notificationTileExternalLinkNameField: AppField<Pick<CreateNotificationTileFields, 'externalLinkName'>> = {
  type: 'input',
  name: 'externalLinkName',
  label: text['External Link Name'],
  placeholder: getInputPlaceholder(text['External Link Name']),
};
