import { text } from 'constants/texts';
import { PROGRAM_CONTENT_TAB_URL_KEY, PROGRAM_TAB_URL_KEY } from 'pages/ProgramDetails/useTabChangeHandler';
import { BreadCrumb } from 'types';

type ProgramListPage = {
  name: 'PROGRAM_LIST';
};

type ProgramDetailsPage = {
  name: 'PROGRAM_DETAILS';
  programName?: string;
};

type BenefitRuleDetailsPage = {
  name: 'BENEFIT_RULE_DETAILS';
  programName?: string;
  programId?: string;
  businessRuleName?: string;
};

type SpecialtyPharmacyDetailsPage = {
  name: 'SPECIALTY_PHARMACY_DETAILS';
  programName?: string;
  programId?: string;
  specialtyPharmacyName?: string;
};

type SurveyDetailsPage = {
  name: 'SURVEY_DETAILS';
  programName?: string;
  programId?: string;
  surveyName?: string;
};

type MilestoneTrackerDetailsPage = {
  name: 'MILESTONE_TRACKER_DETAILS';
  programName: string;
  programId: string;
  milestoneTrackerName: string;
};

type NotificationTileDetailsPage = {
  name: 'NOTIFICATION_TILE_DETAILS';
  programName: string;
  programId: string;
  notificationTileName: string;
};

type Config =
  | ProgramListPage
  | ProgramDetailsPage
  | BenefitRuleDetailsPage
  | SpecialtyPharmacyDetailsPage
  | SurveyDetailsPage
  | MilestoneTrackerDetailsPage
  | NotificationTileDetailsPage;

export const getProgramPagesBreadCrumb = (config: Config): BreadCrumb[] => {
  switch (config.name) {
    case 'PROGRAM_LIST':
      return programListPageBreadCrumbs;
    case 'PROGRAM_DETAILS':
      return [...programListPageBreadCrumbs, createBreadCrumb({ name: config.programName })];
    case 'BENEFIT_RULE_DETAILS':
      return [
        ...programListPageBreadCrumbs,
        createBreadCrumb({
          name: config.programName,
          url: `/programs/${config.programId}?${PROGRAM_TAB_URL_KEY}=rules`,
        }),
        createBreadCrumb({ name: config.businessRuleName }),
      ];
    case 'SPECIALTY_PHARMACY_DETAILS':
      return [
        ...programListPageBreadCrumbs,
        createBreadCrumb({
          name: config.programName,
          url: `/programs/${config.programId}?${PROGRAM_TAB_URL_KEY}=specialty-pharmacies`,
        }),
        createBreadCrumb({ name: config.specialtyPharmacyName }),
      ];
    case 'SURVEY_DETAILS':
      return [
        ...programListPageBreadCrumbs,
        createBreadCrumb({
          name: config.programName,
          url: `/programs/${config.programId}?${PROGRAM_TAB_URL_KEY}=content&${PROGRAM_CONTENT_TAB_URL_KEY}=surveys`,
        }),
        createBreadCrumb({ name: config.surveyName }),
      ];
    case 'MILESTONE_TRACKER_DETAILS':
      return [
        ...programListPageBreadCrumbs,
        createBreadCrumb({
          name: config.programName,
          url: `/programs/${config.programId}?${PROGRAM_TAB_URL_KEY}=content&${PROGRAM_CONTENT_TAB_URL_KEY}=milestone-trackers`,
        }),
        createBreadCrumb({ name: config.milestoneTrackerName }),
      ];
    case 'NOTIFICATION_TILE_DETAILS':
      return [
        ...programListPageBreadCrumbs,
        createBreadCrumb({
          name: config.programName,
          url: `/programs/${config.programId}?${PROGRAM_TAB_URL_KEY}=content&${PROGRAM_CONTENT_TAB_URL_KEY}=notification-tiles`,
        }),
        createBreadCrumb({ name: config.notificationTileName }),
      ];
  }

  return [];
};

// Helpers
type CreateBreadCrumbConfig = {
  name?: string;
  url?: string;
};

export const programListPageBreadCrumbs: BreadCrumb[] = [
  {
    name: text['All Programs'],
    to: '/programs/',
  },
];

const createBreadCrumb = ({ url, name }: CreateBreadCrumbConfig): BreadCrumb => ({
  name: name || text['Name not found'],
  ...(url && { to: url }),
});
