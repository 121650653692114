import { toCreateProgramMapper } from 'hooks/api/programs/mappers';
import { CreateProgramFields } from 'pages/Programs/components/CreateProgramModal/types';
import { useMutation, useQueryClient } from 'react-query';
import { queryKeys } from 'services/constants';
import { createProgram } from 'services/programs/programs';
import { Program } from 'services/programs/types';
import { errorHandler } from 'utils/errorHandler';
import { showErrorMessage } from 'utils/errors';

export const useCreateProgram = (onSuccess?: (program: Program) => void) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(createProgram, {
    mutationKey: queryKeys.createProgram,
    onSuccess: (res) => {
      if (res.name instanceof Array && res.name.length) return res.name.forEach(showErrorMessage);

      queryClient.invalidateQueries(queryKeys.getPrograms);

      onSuccess?.(res as Program);
    },
    onError: errorHandler,
  });

  return {
    isLoading,
    createProgram: (program: CreateProgramFields) => mutate(toCreateProgramMapper(program)),
  };
};
