import { AxiosRequestConfig } from 'axios';
import { storageKeys } from 'constants/localStorageKeys';
import { API_BASE_URL } from 'services/constants';
import { AnyObject } from 'types/helpers';
import axiosClient from './axiosClient';
import { getStorageItem } from './helpers';

export const api = {
  get: <T, D = unknown>(url: string, config?: AxiosRequestConfig<D>) => axiosClient.get<T>(url, config),
  post: <T, D = unknown>(url: string, data: unknown, config?: AxiosRequestConfig<D>) =>
    axiosClient.post<T>(url, data, config),
  patch: <T, D = unknown>(url: string, data: D, config?: AxiosRequestConfig<D>) =>
    axiosClient.patch<T>(url, data, config),
  put: <T, D = unknown>(url: string, data: unknown, config?: AxiosRequestConfig<D>) =>
    axiosClient.put<T>(url, data, config),
  delete: <T, D = unknown>(url: string, config?: AxiosRequestConfig<D>) => axiosClient.delete<T>(url, config),
};

// apiPrefix = '/api/v1/'
export const getApiUrlWithProxy = (apiRoute: string, apiPrefix = API_BASE_URL) => {
  const manufacturerId = getStorageItem(storageKeys.MANUFACTURER_ID, '');
  if (!manufacturerId) return apiPrefix + apiRoute;
  return `${apiPrefix}proxy/${manufacturerId}${apiRoute}`;
};

export const toFormData = <T extends AnyObject>(values: T): FormData => {
  const data = new FormData();

  Object.entries(values).forEach(([key, value]) => {
    if (value === undefined) return;
    data.append(key, value as Blob);
  });

  return data;
};
