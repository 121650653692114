import { AuthLayout } from '../../layouts/AuthLayout/AuthLayout';

import './style.scss';
import { SetPasswordForm } from './components/SetPasswordForm/SetPasswordForm';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Spin } from 'antd';
import { useCheckCodeRequest } from './useCheckCodeRequest';
import { useEffect } from 'react';

type SetPasswordProps = {
  registration?: boolean;
};

export const SetPassword = ({ registration }: SetPasswordProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code') || '';
  const { isLoading, isCodeExpired } = useCheckCodeRequest(code);

  useEffect(() => {
    if (!isCodeExpired) return;
    navigate('/login', { state: { forgotPassword: true } });
  }, [isCodeExpired, navigate]);

  return (
    <AuthLayout title={'Set Password'}>
      <Spin spinning={isLoading} size='large'>
        <SetPasswordForm registration={!!registration} />
      </Spin>
    </AuthLayout>
  );
};
