import { Input, InputProps, InputRef } from 'antd';
import clx from 'classnames';

import { Button } from 'components';
import { useRef } from 'react';
import { AppInput } from '../AppInput/AppInput';

import './style.scss';

export type AppColorPickerProps = Pick<InputProps, 'placeholder' | 'className' | 'value'>;

export const AppColorPicker = ({ className, ...rest }: AppColorPickerProps) => {
  const colorInputRef = useRef<InputRef>(null);

  const handleOpenColorPicker = () => colorInputRef.current?.input?.click();

  return (
    <div className={clx('pl-color-picker', className)}>
      <div
        onClick={handleOpenColorPicker}
        style={{ background: (rest.value as string) || '#000000' }}
        className='color-box'
      />
      <AppInput {...rest} />
      <Button onClick={handleOpenColorPicker} type='ghost'>
        Select
      </Button>
      <Input ref={colorInputRef} className='pl-color-picker-input' type={'color'} {...rest} />
    </div>
  );
};
