import { text } from 'constants/texts';
import { PatientSearchTabs, PatientSearchUseTabsReturnType } from './types';
import { CommunicationsContent } from 'pages/PatientSearch/patientSearchTabComponents/Communications/CommunicationsContent';
import { PatientCommunication } from 'services/patientSearch/types';

type GetCommunicationsTabProps = {
  patientCommunications?: PatientCommunication[];
};

export const getCommunicationsTab = ({
  patientCommunications,
}: GetCommunicationsTabProps): PatientSearchUseTabsReturnType => ({
  tab: {
    tabKey: 'communications' as PatientSearchTabs,
    title: text['Communications'],
    tabData: {
      contentJSX: <CommunicationsContent patientCommunications={patientCommunications} />,
    },
  },
});
