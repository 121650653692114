import { Modal, ModalProps } from 'antd';
import cx from 'classnames';
import { useModal } from 'hooks';
import { UseModalReturnType } from 'hooks/useModal';
import { CloseIcon } from 'icons';
import React, { forwardRef, ForwardRefRenderFunction, PropsWithChildren, useImperativeHandle } from 'react';
import { Callback } from 'types/helpers';

import './style.scss';

type ClosableWrapperProps = PropsWithChildren<
  {
    onClose?: Callback;
    onOpen?: Callback;
  } & Pick<ModalProps, 'destroyOnClose' | 'className'>
>;

export type ClosableWrapperRef = Pick<UseModalReturnType, 'close' | 'open'> | null;

const ClosableWrapperComponent: ForwardRefRenderFunction<ClosableWrapperRef, ClosableWrapperProps> = (
  { children, className, onClose, onOpen, destroyOnClose = true },
  ref
) => {
  const { close, visible, open } = useModal();

  const closeHandler = () => {
    close();
    onClose?.();
  };

  const openHandler = () => {
    open();
    onOpen?.();
  };

  useImperativeHandle(ref, () => ({
    close: closeHandler,
    open: openHandler,
  }));

  return (
    <Modal
      footer={null}
      title={null}
      className={cx(['pl-modal', className])}
      destroyOnClose={destroyOnClose}
      visible={visible}
      onCancel={close}
      closeIcon={<CloseIcon onClick={closeHandler} />}
    >
      {children}
    </Modal>
  );
};

export const ClosableWrapper = forwardRef(ClosableWrapperComponent);
