import { AuthResponse, ConfirmPasswordData, Credentials } from 'services/auth/types';
import { AUTH_BASE_URL } from 'services/constants';
import { api } from 'utils/api';

const LOGIN_ENDPOINT = `${AUTH_BASE_URL}login/`;
const CHECK_AUTH_ENDPOINT = `${AUTH_BASE_URL}token-check/`;
const CHECK_REGISTRATION_LINK = `${AUTH_BASE_URL}check-code`;
const RESET_PASSWORD_ENDPOINT = `${AUTH_BASE_URL}reset_password/`;
const CONFIRM_PASSWORD_ENDPOINT = `${AUTH_BASE_URL}confirm/`;
const CONFIRM_RESET_PASSWORD_ENDPOINT = `${AUTH_BASE_URL}confirm_password/`;

export const login = (credentials: Credentials): Promise<AuthResponse> => api.post(LOGIN_ENDPOINT, credentials);

export const checkAuth = () => api.get(CHECK_AUTH_ENDPOINT);

export const checkRegistrationLink = (code: string) => api.get(`${CHECK_REGISTRATION_LINK}?code=${code}`);

export const forgotPassword = (email: string) => api.post(RESET_PASSWORD_ENDPOINT, { email });

export const confirmPassword = (data: ConfirmPasswordData): Promise<AuthResponse> =>
  api.post(CONFIRM_PASSWORD_ENDPOINT, data);

export const confirmResetPassword = (data: ConfirmPasswordData): Promise<AuthResponse> =>
  api.post(CONFIRM_RESET_PASSWORD_ENDPOINT, data);
