import { fieldModifier, emptyField } from 'components/fields/utils';
import {
  allowShipmentTrackingField,
  cityField,
  emailAddressField,
  phoneNumberField,
  selectStateField,
  specialtyPharmacyNameField,
  streetAddressLine1Field,
  streetAddressLine2Field,
  zipCodeField,
} from 'pages/ProgramDetails/specialtyPharmaciesFields';

export const getTopContentFields = () => [[specialtyPharmacyNameField, allowShipmentTrackingField]];

export const getTopContactInformationFields = () => [
  [phoneNumberField, emailAddressField],
  [streetAddressLine1Field, streetAddressLine2Field],
  [cityField, selectStateField],
  [zipCodeField, fieldModifier(emptyField, { emptyKey: 'zipCodeEmptyField' })],
];
