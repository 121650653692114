import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { queryKeys } from 'services/constants';
import { getSpecialtyPharmacy } from 'services/specialtyPharmacies/requests';
import { toSpecialtyPharmacy } from './mappers';

export const useSpecialtyPharmacy = (pharmacyId = '') => {
  const { programId = '' } = useParams();

  const { data, isLoading, refetch, isRefetching } = useQuery(
    [queryKeys.getSpecialtyPharmacy, pharmacyId],
    () => getSpecialtyPharmacy({ programId, pharmacyId }),
    {
      select: toSpecialtyPharmacy,
    }
  );

  return {
    refetch,
    isLoading,
    isRefetching,
    specialtyPharmacy: data,
    hasData: !!data || isLoading,
  };
};
