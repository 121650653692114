import { Form, FormInstance, FormProps } from 'antd';
import { Spacer } from 'components';
import { getFieldsJSX } from 'components/fields/utils';
import { scrollToFirstErrorProps } from 'constants/common';
import { text } from 'constants/texts';
import { BusinessConditionsFormList } from 'pages/ProgramDetails/programTabComponents/BusinessRules/BusinessRuleDetails/components/BusinessRuleEditContent/BusinessConditionsFormList';
import { BusinessRuleActionsFormList } from 'pages/ProgramDetails/programTabComponents/BusinessRules/BusinessRuleDetails/components/BusinessRuleEditContent/BusinessRuleActionsFormList';
import {
  getTriggerNameBusinessRuleFields,
  getUpdateBaseBusinessRuleFields,
} from 'pages/ProgramDetails/programTabComponents/BusinessRules/BusinessRuleDetails/components/BusinessRuleEditContent/fields';
import { updateBusinessRuleInitialValues } from 'pages/ProgramDetails/programTabComponents/BusinessRules/BusinessRuleDetails/components/BusinessRuleEditContent/utils';
import { UpdateBusinessRuleFields } from 'pages/ProgramDetails/programTabComponents/BusinessRules/components/CreateBusinessRuleModal/types';
import { BusinessRule } from 'types/rules';
import { getFontClassName } from 'utils/getFontClassName';
import { useBusinessRulesMeta } from 'hooks/api';

type BusinessRuleEditContentProps = {
  businessRule: BusinessRule;
  form: FormInstance<UpdateBusinessRuleFields>;
  onFinish: FormProps<UpdateBusinessRuleFields>['onFinish'];
};

export const BusinessRuleEditContent = ({ businessRule, form, onFinish }: BusinessRuleEditContentProps) => {
  const { businessRulesMeta } = useBusinessRulesMeta();

  return (
    <Form<UpdateBusinessRuleFields>
      scrollToFirstError={scrollToFirstErrorProps}
      initialValues={updateBusinessRuleInitialValues(businessRule)}
      onFinish={onFinish}
      layout='vertical'
      form={form}
    >
      {getFieldsJSX(getUpdateBaseBusinessRuleFields())}
      <h2 className={getFontClassName('h2')}>{text['Trigger']}</h2>
      <Spacer height={16} />
      {getFieldsJSX(getTriggerNameBusinessRuleFields(businessRulesMeta))}
      <h2 className={getFontClassName('h2')}>{text['Condition']}</h2>
      <Spacer height={12} type='flex' />
      <BusinessConditionsFormList businessRule={businessRule} meta={businessRulesMeta} form={form} />
      <Spacer height={24} />
      <h2 className={getFontClassName('h2')}>{text['Action']}</h2>
      <Spacer height={24} />
      <BusinessRuleActionsFormList form={form} />
    </Form>
  );
};
