import { Spin } from 'antd';
import { useRef, useState } from 'react';

import { useProgramData } from 'hooks/api';
import { PageLayout } from 'layouts';
import { useCoverProgramEditStatusCallbacks } from 'pages/ProgramDetails/hooks/useCoverProgramEditStatusCallbacks';

import { getProgramPagesBreadCrumb } from 'pages/Programs/breadCrumbs';
import { SpecialtyPharmacyDetailsContent, SpecialtyPharmacyDetailsContentRef } from './SpecialtyPharmacyDetailsContent';
import { SpecialtyPharmacyDetailsTabActions } from './SpecialtyPharmacyDetailsTabActions';
import { useSpecialtyPharmacy } from 'hooks/api/specialtyPharmacy/useSpecialtyPharmacy';
import { useSearchParams } from 'react-router-dom';
import { SPECIALTY_PHARMACY_ID_PARAM } from 'routes/navigators';

export const SpecialtyPharmacyDetails = () => {
  const [isEditMode, setEditMode] = useState(false);
  const formRef = useRef<SpecialtyPharmacyDetailsContentRef>(null);

  const [params] = useSearchParams();
  const pharmacyId = params.get(SPECIALTY_PHARMACY_ID_PARAM) || '';

  const { program, isLoading: isProgramLoading } = useProgramData();
  const {
    refetch,
    isLoading: isGetSpecialtyPharmacyData,
    isRefetching,
    hasData,
    specialtyPharmacy,
  } = useSpecialtyPharmacy(pharmacyId);

  const {
    onEdit,
    onClose,
    isLoading: isEditStatusLoading,
  } = useCoverProgramEditStatusCallbacks({
    programId: program?.id,
    onClose: () => {
      formRef.current?.reset?.();
      setEditMode(false);
    },
    onEdit: () => setEditMode(true),
  });

  const isLoading = isGetSpecialtyPharmacyData || isProgramLoading;

  if (!program || isLoading || !hasData) return <Spin className='pl-page-spinner' size='large' />;

  if (!specialtyPharmacy) return null;

  const onSave = () => formRef.current?.submit();

  const breadCrumbs = getProgramPagesBreadCrumb({
    name: 'SPECIALTY_PHARMACY_DETAILS',
    programId: program?.id,
    programName: program.programName,
    specialtyPharmacyName: specialtyPharmacy.specialtyPharmacyName,
  });

  const rightSideActionsJSX = (
    <SpecialtyPharmacyDetailsTabActions
      isEditMode={isEditMode}
      isLoading={isLoading}
      onClose={onClose}
      onEdit={onEdit}
      onSave={onSave}
      program={program}
      pharmacyId={pharmacyId}
      pharmacyName={specialtyPharmacy.specialtyPharmacyName}
    />
  );

  return (
    <PageLayout
      pageTitle={specialtyPharmacy?.specialtyPharmacyName}
      crumbs={breadCrumbs}
      rightSideActions={rightSideActionsJSX}
    >
      <SpecialtyPharmacyDetailsContent
        ref={formRef}
        isLoading={isEditStatusLoading}
        isEditMode={isEditMode}
        specialtyPharmacy={specialtyPharmacy}
        onSuccess={() => setEditMode(false)}
        refetch={refetch}
        isRefetching={isRefetching}
      />
    </PageLayout>
  );
};
