import { Form, FormInstance, FormProps } from 'antd';
import { Button, ModalActionButtons, Spacer } from 'components';
import { getFieldsJSX } from 'components/fields/utils';
import { getCreateProgramFields } from 'pages/Programs/components/CreateProgramModal/fields';
import { Callback } from 'types/helpers';
import { CreateProgramFields } from './types';

type CreateProgramStepConfig = {
  onFinish: FormProps<CreateProgramFields>['onFinish'];
  form: FormInstance<CreateProgramFields>;
  isLoading: boolean;
  closeModal?: Callback;
};

export const getCreateProgramStepJSX = ({ closeModal, form, onFinish, isLoading }: CreateProgramStepConfig) => (
  <>
    <h1 className='modal-title'>Add New Program</h1>
    <Spacer height={16} type='flex' />
    <h2 className='modal-description'>Add a new program to the PatientLink Authoring Tool</h2>
    <Form<CreateProgramFields>
      requiredMark={false}
      preserve={false} //  preserve(false) will reset fields values when modal will close
      layout='vertical'
      className='form'
      form={form}
      onFinish={onFinish}
    >
      {getFieldsJSX(getCreateProgramFields(form))}
      <ModalActionButtons onCancel={closeModal} isLoading={isLoading} />
    </Form>
  </>
);

type CreatedProgramStepConfig = {
  toProgramPage: Callback;
};

export const getCreatedProgramStepJSX = ({ toProgramPage }: CreatedProgramStepConfig) => (
  <>
    <h1 className='modal-title'>Your Program Was Created</h1>
    <Spacer height={16} type='flex' />
    <h2 className='modal-description'>
      There is still information need in order for you to publish this program. You can edit and add this information in
      the detail pages of the program.
    </h2>
    <Spacer height={34} type='flex' />
    <Button type='primary' onClick={toProgramPage}>
      I Understand
    </Button>
  </>
);
