import { useSearchParams } from 'react-router-dom';
import { COMMUNICATION_SURVEY_ID_PARAM, PATIENT_SEARCH_ID_PARAMS } from 'routes/navigators';
import { usePatientCommunication } from './usePatientCommunications';
import { CommunicationSurvey } from 'pages/PatientSearch/PatientComunicationDetails/types';
import { toCommunicationSurvey } from './mappers';

export const usePatientCommunicationById = (comId = '') => {
  const [params] = useSearchParams();
  const patientEmail = params.get(PATIENT_SEARCH_ID_PARAMS) || '';
  const communicationId = params.get(COMMUNICATION_SURVEY_ID_PARAM) || comId;

  const { patientCommunications, isLoading } = usePatientCommunication(patientEmail);

  const communicationSurveyById: CommunicationSurvey = toCommunicationSurvey(
    patientCommunications.find((com) => com.id === communicationId)?.surveyData || {}
  );

  return {
    isLoading,
    communicationSurveyById,
    hasData: !!communicationSurveyById?.survey_uuid,
  };
};
