import { Ref } from 'react';
import { Form } from 'antd';
import { Callback } from 'types/helpers';
import { Survey, SurveyFields } from 'types/surveys';
import { FormProps } from 'antd/lib/form/Form';
import { getFieldJSX } from 'components/fields/utils';
import { ModalActionButtons, showCustomToast, Spacer } from 'components';
import { ClosableWrapper, ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { surveyNameField } from 'pages/ProgramDetails/surveysFields';
import { text } from 'constants/texts';
import { stringModifier } from 'utils/strings';
import { useUpdateSurvey } from 'hooks/api/surveys';

type RenameSurveyModalProps = {
  survey: Survey;
  onClose?: Callback;
  setRenameModalRef: Ref<ClosableWrapperRef>;
};

type RenameSurveyFormFields = Pick<SurveyFields, 'name'>;

export const RenameSurveyModal = ({ survey, onClose, setRenameModalRef }: RenameSurveyModalProps) => {
  const [form] = Form.useForm<RenameSurveyFormFields>();

  const { isLoading, updateSurvey } = useUpdateSurvey();

  const onFinish: FormProps<RenameSurveyFormFields>['onFinish'] = async (values) => {
    await updateSurvey({ ...survey, name: values.name });
    showCustomToast({
      type: 'success',
      message: stringModifier(text['{0} has been updated to {1}'], 'Survey name', values.name),
      duration: 3,
    });
    onClose?.();
  };

  return (
    <ClosableWrapper ref={setRenameModalRef}>
      <h1 className='modal-title'>{stringModifier(text['Rename {0}'], 'Survey')}</h1>
      <Spacer height={16} type='flex' />
      <h2 className='modal-description'>
        {stringModifier(text['Change the name of this {0} in the text box below.'], 'survey')}
      </h2>
      <Form<RenameSurveyFormFields>
        form={form}
        className='form'
        layout='vertical'
        preserve={false}
        requiredMark={false}
        onFinish={onFinish}
        initialValues={{ name: survey.name }}
      >
        {getFieldJSX(surveyNameField)}
        <ModalActionButtons isLoading={isLoading} onCancel={onClose} submitText='Save' />
      </Form>
    </ClosableWrapper>
  );
};
