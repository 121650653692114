import { toBusinessRuleDTO } from 'hooks/api/rules/mappers';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useGoToBusinessRuleDetailsPage } from 'routes/navigators';
import { queryKeys } from 'services/constants';
import { createBusinessRule } from 'services/rules/requests';
import { Callback } from 'types/helpers';
import { BaseBusinessRule, BusinessRule } from 'types/rules';
import { errorHandler } from 'utils/errorHandler';

export const useCreateBusinessRule = (onSuccess?: Callback) => {
  const queryClient = useQueryClient();

  const { programId } = useParams();

  const goToBusinessRuleDetailsPage = useGoToBusinessRuleDetailsPage();

  const { mutate, isLoading } = useMutation(createBusinessRule, {
    mutationKey: queryKeys.createBusinessRule,
    onSuccess: async (rule) => {
      await queryClient.invalidateQueries(queryKeys.getBusinessRules);
      await queryClient.invalidateQueries(queryKeys.getBusinessRulesMeta);
      goToBusinessRuleDetailsPage(rule?.uuid);
      onSuccess?.();
    },
    onError: errorHandler,
  });

  return {
    isLoading,
    createBusinessRule: (businessRule: BusinessRule | BaseBusinessRule) =>
      mutate(toBusinessRuleDTO({ ...businessRule, programId } as BusinessRule)),
  };
};
