import { toCopayFinancialAssistance, toFinancialAssistance } from 'hooks/api/financialAssistance/mappers';
import { useFinancialAssistances } from 'hooks/api/financialAssistance/useFinancialAssistances';
import { useMemo } from 'react';
import { FinancialAssistanceType } from 'services/financialAssistance/types';
import { CopayFinancialAssistance, FinancialAssistance } from 'types/financialAssistance';

type ReturnType<IsCopay> = {
  isLoading: boolean;
  isEmpty: boolean;
  financialAssistance: IsCopay extends true ? CopayFinancialAssistance : FinancialAssistance;
};

export const useFinancialAssistance = <IsCopay extends boolean>(type: FinancialAssistanceType): ReturnType<IsCopay> => {
  const { isLoading, data } = useFinancialAssistances();

  const financialAssistance = useMemo(
    () => data?.find((assistance) => assistance.type === type),
    [type, JSON.stringify(data)]
  );

  return {
    isLoading,
    isEmpty: !financialAssistance,
    // @ts-ignore -> IsCopay blocked
    financialAssistance: useMemo(
      () => financialAssistance || (type === 'COPAY' ? toCopayFinancialAssistance : toFinancialAssistance)({}),
      [financialAssistance]
    ), // data or empty assistance(undefined protection)
  };
};
