import { SVGProps } from 'react';

export const CheckVector = (props: SVGProps<SVGSVGElement>) => (
  <svg width='18' height='13' viewBox='0 0 18 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M16.9999 1L6.33327 11.6667L0.999939 6.33333'
      stroke='white'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
