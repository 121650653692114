import { Row } from 'antd';
import { Button, MoreButton, PermissionWrapper, Spacer } from 'components';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { EditableButtons } from 'components/EditableButtons/EditableButtons';
import { ConditionalWrapper } from 'components/ShowWrapper/ConditionalWrapper';
import { useChildrenRef, usePermissionsHandler } from 'hooks';
import { TabActionsProps } from 'pages/ProgramDetails/tabsConfig/types';
import { showCancelEditedToast, useProgramMoreButtonActions } from 'pages/ProgramDetails/utils';
import { useRef } from 'react';
import { CreateServiceModal } from './components/CreateServiceModal/CreateServiceModal';
import { text } from 'constants/texts';
import { stringModifier } from 'utils/strings';

export type AdditionalServicesTabActionsProps = TabActionsProps & { hasServices: boolean };

export const AdditionalServicesTabActions = ({
  program,
  isEditMode,
  isLoading,
  onClose,
  onSave,
  onEdit,
  hasServices,
}: AdditionalServicesTabActionsProps) => {
  const cancelModalRef = useRef<ClosableWrapperRef>(null);

  const [isEditServicesAllowed] = usePermissionsHandler(['additional_services.edit']);

  const [modalRef, setModalRef] = useChildrenRef<ClosableWrapperRef>();
  const programMoreButtonActions = useProgramMoreButtonActions(program);

  if (!program) return null;

  const addServiceJSX = (
    <PermissionWrapper permissionsRequired={['additional_services.create']}>
      <Button onClick={modalRef?.open}>{stringModifier(text['Add {0}'], text['Service'])}</Button>
      <Spacer width={16} />
    </PermissionWrapper>
  );

  const onCancelModalConfirm = () => {
    showCancelEditedToast();
    cancelModalRef.current?.close();
    onClose();
  };

  return (
    <Row>
      {!isEditMode && addServiceJSX}
      <ConditionalWrapper hideVisually condition={hasServices}>
        <EditableButtons
          isEditMode={isEditMode}
          onEdit={onEdit}
          onSave={onSave}
          onCancel={cancelModalRef.current?.open}
          isLoading={isLoading}
          disable={!isEditServicesAllowed}
          cancelModal={{
            ref: cancelModalRef.current,
            setRef: cancelModalRef,
            onConfirm: onCancelModalConfirm,
          }}
        />
      </ConditionalWrapper>
      <ConditionalWrapper condition={!isEditMode}>
        <Spacer width={16} />
        <MoreButton options={programMoreButtonActions} />
      </ConditionalWrapper>
      <CreateServiceModal closeHandler={modalRef?.close} modalRef={setModalRef} />
    </Row>
  );
};
