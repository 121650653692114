import { FormInstance } from 'antd';
import { UploadProps } from 'antd/lib/upload/interface';
import { AppField } from 'components/fields/types';
import { DevError } from 'utils/errors';
import { DraggerConfig, DraggerUpload } from './components/DraggerUpload/DraggerUpload';
import { SelectUpload, SelectUploadConfig } from './components/SelectUpload/SelectUpload';

import './style.scss';

export type FileType = 'PNG' | 'JPG' | 'PDF' | 'MP4';

export type AppUploadProps = Partial<{
  cropper: {
    sizes: { width: number; height: number };
  };
  form: FormInstance;
  draggerConfig: DraggerConfig;
  selectUploadConfig: SelectUploadConfig;
  fileTypes: FileType[];
  loadFileType: 'images';
  filesCount: number;
  className: string;
  maxSize: number;
  field: Pick<AppField, 'rules' | 'hidden' | 'name' | 'tooltip' | 'label'>;
  createdFromSelectUpload: boolean;
}> &
  Pick<UploadProps, 'onChange'>;

export const AppUpload = ({
  draggerConfig,
  selectUploadConfig,
  createdFromSelectUpload,
  filesCount,
  ...rest
}: AppUploadProps) => {
  if (draggerConfig && selectUploadConfig) throw new DevError('You should select only one type of upload field.');
  if (!draggerConfig && !selectUploadConfig) throw new DevError('You should select a type of upload field.');

  if (selectUploadConfig) return <SelectUpload selectUploadConfig={selectUploadConfig} {...rest} />;

  if (draggerConfig)
    return (
      <DraggerUpload
        draggerConfig={draggerConfig}
        createdFromSelectUpload={createdFromSelectUpload}
        filesCount={filesCount}
        {...rest}
      />
    );

  return null;
};
