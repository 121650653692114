import { toBusinessRule } from 'hooks/api/rules/mappers';
import { useBusinessRulesMeta } from 'hooks/api';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { queryKeys } from 'services/constants';
import { getBusinessRuleList } from 'services/rules/requests';

export const useBusinessRules = ({ pageSize, pageNumber }: { pageSize?: number; pageNumber?: number }) => {
  const { programId } = useParams();
  const { businessRulesMeta, isLoading: isBusinessRuleMetaLoading } = useBusinessRulesMeta();

  const { data, isLoading: isBusinessRulesLoading } = useQuery(
    [queryKeys.getBusinessRules, programId, pageSize, pageNumber],
    () => getBusinessRuleList({ programId, page_number: pageNumber, page_size: pageSize }),

    {
      enabled: !!businessRulesMeta,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      select: (response) => ({
        total_count: response.total_count,
        result: businessRulesMeta ? response.results?.map((rule) => toBusinessRule(rule, businessRulesMeta)) : [],
      }),
    }
  );

  const isLoading = isBusinessRulesLoading || isBusinessRuleMetaLoading;

  return {
    isLoading,
    businessRules: data?.result || [],
    totalCount: data?.total_count || 0,
    hasData: !!data?.result?.length || isLoading,
  };
};
