import { PropsWithChildren } from 'react';
import { Row } from 'antd';
import './style.scss';
import clx from 'classnames';
import { DevError } from 'utils/errors';

type RowFieldsGroupProps = PropsWithChildren<{}>;

export const RowFieldsGroup = ({ children }: RowFieldsGroupProps) => {
  const length = children instanceof Array ? children.length : 1;

  if (length > 4)
    throw new DevError(
      'We supported just 1, 2, 3 and 4 items if you what add another please update styles in RowFieldsGroup component'
    );

  return (
    <Row
      className={clx('group-wrapper', {
        'width-100': length === 1,
        'width-50': length === 2,
        'width-33': length === 3,
        'width-25': length === 4,
      })}
    >
      {children}
    </Row>
  );
};
