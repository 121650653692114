import { Row } from 'antd';
import { Button, MoreButton, PermissionWrapper, Spacer } from 'components';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { text } from 'constants/texts';
import { useChildrenRef } from 'hooks';
import { CreateBusinessRuleModal } from 'pages/ProgramDetails/programTabComponents/BusinessRules/components/CreateBusinessRuleModal/CreateBusinessRuleModal';
import { useProgramMoreButtonActions } from 'pages/ProgramDetails/utils';
import { ProgramData } from 'types/programs';
import { stringModifier } from 'utils/strings';

type BusinessRulesTabActionsProps = {
  program?: ProgramData;
};

export const BusinessRulesTabActions = ({ program }: BusinessRulesTabActionsProps) => {
  const [modalRef, setModalRef] = useChildrenRef<ClosableWrapperRef>();

  const programMoreButtonActions = useProgramMoreButtonActions(program);

  return (
    <>
      <Row>
        <PermissionWrapper permissionsRequired={['rules.create']}>
          <Button onClick={modalRef?.open}>{stringModifier(text['Add {0}'], 'New Rule')}</Button>
        </PermissionWrapper>
        <Spacer width={16} />
        <MoreButton options={programMoreButtonActions} />
      </Row>
      <CreateBusinessRuleModal setModalRef={setModalRef} modalRef={modalRef} program={program} />
    </>
  );
};
