import { text } from 'constants/texts';
import { ContentUseTabsReturnType } from 'pages/ProgramDetails/programTabComponents/Content/tabsConfig/types';
import { NotificationTilesActions } from '../ContentTabComponents/NotificationTiles/NotificationTilesActions';
import { NotificationTilesContent } from '../ContentTabComponents/NotificationTiles/NotificationTilesContent';

export const getNotificationTilesTab = (): ContentUseTabsReturnType => ({
  tab: {
    tabKey: 'notification-tiles',
    title: text['Notification Tiles'],
    tabData: {
      actionsJSX: <NotificationTilesActions />,
      contentJSX: <NotificationTilesContent />,
    },
  },
});
