import { Form } from 'antd';
import clx from 'classnames';
import { AppInput, AppSelect } from 'components';
import { AppDatePicker, AppDatePickerProps } from 'components/fields/AppDatePicker/AppDatePicker';
import { getValueFromUploadEvent } from 'components/fields/AppUpload/utils';
import { InfoIcon } from 'icons/InfoIcon';
import { Fragment, ReactNode } from 'react';
import { AnyObject } from 'types/helpers';

import { DevError } from 'utils/errors';
import { AppColorPicker, AppColorPickerProps } from './AppColorPicker/AppColorPicker';
import { AppFieldInfo, AppFieldInfoProps } from './AppFieldInfo/AppFieldInfo';
import { AppInputProps } from './AppInput/AppInput';
import { AppSelectProps } from './AppSelect/AppSelect';
import { AppSwitch, AppSwitchProps } from './AppSwitch/AppSwitch';
import { AppUpload, AppUploadProps } from './AppUpload/AppUpload';
import { FieldMapper } from './FieldMapper/FieldMapper';
import './style.scss';
import { AppField, AppFields, FieldTypes } from './types';

const getValuePropName = (type: FieldTypes['type']) => {
  if (type === 'switch') return 'checked';
};

export const getFieldJSX = <T extends AnyObject>(field: AppField<T>, isEditMode = true) => {
  let fieldJSX: ReactNode = null;

  const { withoutMargin, type, label, tooltip, name, rules = [], wrapperCol, labelCol, ...rest } = field;

  if (!isEditMode) fieldJSX = <AppFieldInfo {...(rest as AppFieldInfoProps)} />;
  else
    switch (type) {
      case 'select':
        fieldJSX = <AppSelect {...(rest as AppSelectProps<unknown>)} />;
        break;
      case 'input':
        fieldJSX = <AppInput {...(rest as AppInputProps)} />;
        break;
      case 'color-picker':
        fieldJSX = <AppColorPicker {...(rest as AppColorPickerProps)} />;
        break;
      case 'date-picker':
        fieldJSX = <AppDatePicker {...(rest as AppDatePickerProps)} />;
        break;
      case 'switch':
        fieldJSX = <AppSwitch {...(rest as AppSwitchProps)} />;
        break;
      case 'upload':
        fieldJSX = (
          <AppUpload {...(rest as AppUploadProps)} field={field as AppUploadProps['field']} key={getFieldKey(field)} />
        );
        if (field.selectUploadConfig) return fieldJSX;
        break;
      case 'empty':
        return <Fragment key={getFieldKey(field)} />;
    }

  const isDraggerUploadField = type === 'upload' && !!field.draggerConfig;

  return (
    <Form.Item
      key={getFieldKey(field)}
      rules={rules}
      name={(rest as AppUploadProps)?.selectUploadConfig?.listName || (name as string)}
      hidden={rest.hidden}
      {...(isDraggerUploadField
        ? { getValueFromEvent: getValueFromUploadEvent }
        : {
            className: clx({ 'required-star': isEditMode && !!rules?.some((rule) => rule?.required) }, rest.className),
            label,
            style: withoutMargin ? { marginBottom: 0 } : undefined,
            tooltip: tooltip
              ? {
                  title: tooltip,
                  icon: (
                    <div>
                      <InfoIcon />
                    </div>
                  ),
                }
              : undefined,
            wrapperCol,
            labelCol,
            valuePropName: getValuePropName(type),
          })}
    >
      {fieldJSX}
    </Form.Item>
  );
};

export const getFieldsJSX = <T extends AnyObject>(fields: AppFields<T>, isEditMode = true) => {
  return fields.map((field) => <FieldMapper isEditMode={isEditMode} key={getFieldKey(field)} field={field} />);
};

const getFieldKey = <T extends AnyObject>(field: AppField<T> | AppField<T>[]): string => {
  if (field instanceof Array) {
    if (!field.length) throw new DevError('You should set 1 field at least in your horizontal field group');

    return field[0].name as string;
  }

  if (field.type === 'empty') return field.emptyKey || '';

  return field.name as string;
};

export const emptyField: AppField<{}> = {
  type: 'empty',
};

export const fieldModifier = <T extends AppField>(field: T, modifier: Partial<T>): T => ({
  ...field,
  ...modifier,
  rules: [...(field.rules || []), ...(modifier.rules ?? [])],
});
