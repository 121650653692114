import { Form, FormProps, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { Spacer } from 'components';

import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { EmptyDataListComponent } from 'components/EmptyDataListComponent/EmptyDataListComponent';
import { text } from 'constants/texts';
import { useChildrenRef, usePermissionsHandler } from 'hooks';
import { useBenefits } from 'hooks/api/benefits/useBenefits';
import { useUpdateBenefits } from 'hooks/api/benefits/useUpdateBenefits';
import { BenefitItem } from 'pages/ProgramDetails/programTabComponents/Benefits/components/BenefitItem';
import { CreateBenefitModal } from 'pages/ProgramDetails/programTabComponents/Benefits/components/CreateBenefitModal/CreateBenefitModal';
import { UpdateBenefitsFields } from 'pages/ProgramDetails/programTabComponents/Benefits/types';
import { getBenefitsInitialFieldsValues } from 'pages/ProgramDetails/programTabComponents/Benefits/utils';
import { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle } from 'react';
import { Callback } from 'types/helpers';
import { stringModifier } from 'utils/strings';
import { ConditionalWrapper } from 'components/ShowWrapper/ConditionalWrapper';

type BenefitsContentProps = {
  isEditMode: boolean;
  onFinish: Callback;
};

export type BenefitsContentRef = {
  reset: Callback;
  submit: Callback;
};

const BenefitsContentComponent: ForwardRefRenderFunction<BenefitsContentRef, BenefitsContentProps> = (
  { isEditMode, onFinish },
  ref
) => {
  const [form] = useForm<UpdateBenefitsFields>();

  const [isCreateBenefitsAllowed] = usePermissionsHandler(['benefits.create']);

  useImperativeHandle(ref, () => ({
    submit: form.submit,
    reset: form.resetFields,
  }));

  const [modalRef, setModalRef] = useChildrenRef<ClosableWrapperRef>();

  const { isLoading: isGetBenefitsLoading, hasData, benefits } = useBenefits();

  const { updateBenefits, isLoading: isUpdateBenefitLoading } = useUpdateBenefits();

  useEffect(() => {
    benefits && form.setFieldsValue(getBenefitsInitialFieldsValues(benefits));
  }, [benefits]);

  const isLoading = isGetBenefitsLoading || isUpdateBenefitLoading;

  const onFinishHandler: FormProps<UpdateBenefitsFields>['onFinish'] = (values) => {
    updateBenefits(values);
    onFinish();
  };

  return (
    <Spin size='large' spinning={isLoading}>
      <Spacer height={8} />
      <ConditionalWrapper condition={hasData}>
        <Form
          layout='vertical'
          form={form}
          initialValues={getBenefitsInitialFieldsValues(benefits)}
          onFinish={onFinishHandler}
        >
          <Form.List name='benefits'>
            {(fields) =>
              fields.map(({ key, name }) => <BenefitItem key={key} orderIndex={name} isEditMode={isEditMode} />)
            }
          </Form.List>
        </Form>
      </ConditionalWrapper>
      <ConditionalWrapper condition={!hasData}>
        <EmptyDataListComponent
          title={stringModifier(text["We couldn't find any {0} here."], 'Benefits')}
          description={stringModifier(text['Click the button below to add new {0}'], 'Benefit')}
          addButtonText={stringModifier(text['Add {0}'], 'New Benefit')}
          addButtonDisabled={!isCreateBenefitsAllowed}
          onButtonPress={modalRef?.open}
        />
      </ConditionalWrapper>
      <CreateBenefitModal setModalRef={setModalRef} modalRef={modalRef} />
    </Spin>
  );
};

export const BenefitsContent = forwardRef(BenefitsContentComponent);
