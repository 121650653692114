import { ServerObjectMappers } from 'types/apiTypes';
import { Admin, AdminDTO, AdminRole, AdminRoleDTO } from './types';

export const { toAdmin, toAdminDTO }: ServerObjectMappers<'admin', AdminDTO, Admin> = {
  toAdmin: (dto) => ({
    id: dto.id ?? '',
    email: dto.email ?? '',
    lastName: dto.lastName ?? '',
    firstName: dto.firstName ?? '',
    status: dto.status ?? 'Inactive',
    role: dto.role?.id ?? '',
    manufacturers: dto.manufacturers?.map((manufacturer) => manufacturer?.id ?? '') ?? [''],
  }),
  toAdminDTO: ({}) => ({}),
};

export const { toAdminRole, toAdminRoleDTO }: ServerObjectMappers<'adminRole', AdminRoleDTO, AdminRole> = {
  toAdminRole: (dto) => ({
    value: dto.id ?? '',
    label: dto.name ?? 'Admin',
  }),
  toAdminRoleDTO: ({}) => ({}),
};
