import { AdminData, AdminResponse } from 'pages/shared/types';
import { API_BASE_URL } from 'services/constants';
import {
  CreateAdminRequestBody,
  GetAdminsParams,
  UpdateAdminRequestBody,
  UpdateAdminStatusBody,
} from 'services/users/types';
import { Response } from 'types';
import { api } from 'utils/api';
import { GetAdminRolesResponse } from 'hooks/api/admins/types';

const ADMINS_ENDPOINT = `${API_BASE_URL}admin`;
const USER_ROLES_ENDPOINT = `${API_BASE_URL}admin/roles`;
const UPDATE_ADMIN_STATUS_ENDPOINT = `${API_BASE_URL}admin/status`;

const defaultQueryParams: GetAdminsParams = {
  filter: '',
  page: '0',
  pageSize: '100',
};

export const getAdmins = async (params?: GetAdminsParams) => {
  const searchParams = new URLSearchParams({ ...defaultQueryParams, ...params });
  return api.get<AdminResponse>(`${ADMINS_ENDPOINT}?${searchParams.toString()}`).then((response) => response.data);
};

export const getAdminRoles = async () =>
  api.get<GetAdminRolesResponse>(USER_ROLES_ENDPOINT).then((response) => response.data);

export const createAdmin = (data: CreateAdminRequestBody) => api.post(ADMINS_ENDPOINT + '/', data);

export const updateAdmin = (data: UpdateAdminRequestBody) => api.put(ADMINS_ENDPOINT + '/', data);

export const deleteAdmin = (id: string) => api.delete(ADMINS_ENDPOINT + '/' + id);

export const updateAdminStatus = (data: UpdateAdminStatusBody): Promise<Response<AdminData>> =>
  api.put(UPDATE_ADMIN_STATUS_ENDPOINT, data);
