import { Form, FormInstance, FormProps } from 'antd';
import { scrollToFirstErrorProps } from 'constants/common';
import { initialQuestionValues } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Surveys/components/CreateSurveyModal/constants';
import { SurveyDynamicForm } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Surveys/components/CreateSurveyModal/SurveyDynamicForm';
import { DynamicSurveyFields } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Surveys/components/CreateSurveyModal/types';
import { Callback } from 'types/helpers';

export type SecondStepFormProps = {
  form: FormInstance<DynamicSurveyFields>;
  goBack?: Callback;
  handleSubmit: FormProps['onFinish'];
  initialValues?: FormProps<DynamicSurveyFields>['initialValues'];
  isLoading: boolean;
};

export const SecondStepForm = ({
  form,
  goBack,
  handleSubmit,
  initialValues = { questions: initialQuestionValues },
  isLoading,
}: SecondStepFormProps) => {
  return (
    <Form<DynamicSurveyFields>
      preserve={false}
      layout='vertical'
      className='form'
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
      scrollToFirstError={scrollToFirstErrorProps}
    >
      <SurveyDynamicForm isLoading={isLoading} form={form} parentGoBack={goBack} />
    </Form>
  );
};
