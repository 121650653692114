import { createContext } from 'react';
import { Permissions, UserData } from 'types';

export type IAuthContext = {
  authenticated: boolean;
  setAuthenticated: (isAuthenticated: boolean) => void;
  isSuperAdmin: boolean;
  userData: UserData;
  permissions: Permissions[];
  userId: string;
};

export const AuthContext = createContext<IAuthContext>({
  authenticated: false,
  setAuthenticated: () => null,
  isSuperAdmin: false,
  permissions: ['logout'],
  userData: {},
  userId: '',
});
