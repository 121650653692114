import { Button } from 'components/index';
import { Spacer } from 'components/Spacer/Spacer';
import { Callback } from 'types/helpers';
import { getFontClassName } from 'utils/getFontClassName';
import { ConditionalWrapper } from '../ShowWrapper/ConditionalWrapper';

type EmptyDataListComponentProps = {
  title: string;
  description: string;
  addButtonText?: string;
  onButtonPress?: Callback;
  addButtonDisabled?: boolean;
};

export const EmptyDataListComponent = ({
  title,
  description,
  addButtonText,
  onButtonPress,
  addButtonDisabled,
}: EmptyDataListComponentProps) => (
  <div>
    <h1 className={getFontClassName('h2')}>{title}</h1>
    <Spacer height={8} />
    <p className={getFontClassName('paragraph')}>{description}</p>
    <Spacer height={20} />
    <ConditionalWrapper condition={!!addButtonText}>
      <Button disabled={addButtonDisabled} onClick={onButtonPress} type='ghost'>
        {addButtonText}
      </Button>
    </ConditionalWrapper>
  </div>
);
