import { useQuery } from 'react-query';
import { queryKeys } from 'services/constants';
import { getAdminRoles } from 'services/users/users';
import { toAdminRole } from './mappers';
import { useAuth } from 'hooks/useAuth';

export const useAdminRoles = () => {
  const { isSuperAdmin } = useAuth();

  const { data: adminRoles = [], isLoading } = useQuery(queryKeys.getUserRoles, getAdminRoles, {
    enabled: isSuperAdmin,
    refetchOnMount: false,
    select: (data) => data?.map(toAdminRole),
  });

  return { adminRoles, isLoading };
};
