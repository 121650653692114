import { FormItemProps, Upload } from 'antd';
import { UploadChangeParam, UploadProps } from 'antd/lib/upload/interface';
import { showCustomToast } from 'components/CustomToast/showCustomToast';
import { FileType } from 'components/fields/AppUpload/AppUpload';
import { MetaTypes } from 'services/medias/types';
import { DevError } from 'utils/errors';

/** @description  Will return all uploaded files from fileList. Attention: return value should be as array */
export const getValueFromUploadEvent: FormItemProps['getValueFromEvent'] = (e: UploadChangeParam) =>
  (Array.isArray(e) ? e : e.fileList) || [];

/** @description Disable library logic with request after file uploading */
export const uploadMockRequest: UploadProps['customRequest'] = ({ onSuccess }) =>
  setTimeout(() => onSuccess?.('ok'), 0);

export const getMetaFileTypeFromFileTypes = (types?: FileType[]): MetaTypes => {
  if (!types) return 'PDF';
  if (types.includes('PNG') || types.includes('JPG')) return 'IMAGE';
  if (types.includes('PDF')) return 'PDF';
  if (types.includes('MP4')) return 'VIDEO';

  throw new DevError(`You need to add new condition to the ${types.join(', ')} file types`);
};

export const getSearchFileParamFromFileTypes = (types?: FileType[]): MetaTypes | undefined => {
  if (!types) return;

  if ((['PDF', 'PDF', 'MP4', 'JPG'] as FileType[]).every((type) => types.includes(type))) return undefined;

  if (types.includes('PNG') || types.includes('JPG')) return 'IMAGE';
  if (types.includes('PDF')) return 'PDF';
  if (types.includes('MP4')) return 'VIDEO';

  throw new DevError(`You need to add new condition to the ${types.join(', ')} file types`);
};

// Before Upload
const showErrorMessage = (message?: string) =>
  showCustomToast({
    message: message || 'Wrong type of file, please try upload another file.',
    type: 'error',
  });

export const getDraggerUploadDescription = (types: FileType[] = []) => {
  const supportedFilesText = types.join(', ').replace(/, ([^,]*)$/, ' and $1'); // A, B and C

  return `Supported files: ${supportedFilesText || 'All'}`;
};

export const beforeUpload = (file, fileTypes?: FileType[], maxSize?: number) => {
  // this scope has to return true only once
  if (maxSize !== undefined) {
    const isFileSizeCorrect = file.size <= maxSize * 1e6; // n * 1MB
    if (!isFileSizeCorrect) {
      showErrorMessage(`File must be smaller than ${maxSize}MB!`);

      return Upload.LIST_IGNORE;
    }
  }

  if (fileTypes) {
    const isPNG = fileTypes.includes('PNG') && file.type === 'image/png';
    const isJPG = fileTypes.includes('JPG') && file.type === 'image/jpeg';
    const isPDF = fileTypes.includes('PDF') && file.type === 'application/pdf';
    const isMP4 = fileTypes.includes('MP4') && file.type === 'video/mp4';

    const rules: Record<FileType, boolean> = {
      PNG: isPNG,
      JPG: isJPG,
      MP4: isMP4,
      PDF: isPDF,
    };

    if (!Object.values(rules).some(Boolean)) {
      showErrorMessage();

      return Upload.LIST_IGNORE;
    }
  }

  return true;
};
