import { Form, Spin } from 'antd';
import { Button, showCustomToast, Spacer } from 'components';
import { useAuth, useToggle } from 'hooks';

import { PageLayout } from 'layouts';
import { statusRenderer, SuperAdminActions } from 'pages/shared';
import { AdminDetailsFormType, getUserDetailsFields } from 'pages/shared/formFields';
import { getAdminFullName } from 'pages/shared/utils';
import { getAdminPagesBreadCrumb } from '../breadCrumbs';

import './style.scss';
import { useEffect, useState } from 'react';
import { getFieldsJSX } from 'components/fields/utils';
import { useManufacturers } from 'hooks/api/programs/useManufacturers';
import { useAdminRoles } from 'hooks/api/admins/useAdminRoles';
import { text } from 'constants/texts';
import { PermissionWrapper } from 'components/PermissionWrapper';
import { useUpdateAdmin } from 'hooks/api/admins/useUpdateAdmin';
import { useForm } from 'antd/es/form/Form';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { showManufactureRequiredErrorMessage } from '../helpers';
import { AppCheckboxGroup } from 'components/fields/AppCheckboxGroup/AppCheckboxGroup';
import { toManufacturerSelectOptions } from 'hooks/api/programs/mappers';
import { useAdmin } from 'hooks/api/admins/useAdmin';
import { toAdmin, toAdminRole } from 'hooks/api/admins/mappers';

export const AdminDetails = () => {
  const { userData, isSuperAdmin } = useAuth();
  const [form] = useForm<AdminDetailsFormType>();
  const { submit, resetFields } = form;
  const [editMode, toggleEditMode] = useToggle();
  const [selectedManufacturers, setSelectedManufacturers] = useState(['']);
  const { adminRoles, isLoading: isUserRolesLoading } = useAdminRoles();
  const { manufacturers, isLoading: isManufacturersLoading } = useManufacturers();
  const { updateAdmin, isLoading: isUpdatingAdminLoading } = useUpdateAdmin(() => {
    toggleEditMode();
    showCustomToast({ type: 'success', message: text['Your changes has been saved.'] });
  });
  const { adminData, isLoading: isAdminLoading } = useAdmin();

  const roleOptions = isSuperAdmin ? adminRoles : [toAdminRole(userData.role ?? {})];
  const manufacturersOptions = isSuperAdmin
    ? manufacturers.map(toManufacturerSelectOptions)
    : userData.manufacturers?.map(toManufacturerSelectOptions);

  const adminOrUserData = adminData || toAdmin(userData);

  useEffect(() => {
    setSelectedManufacturers(
      adminData ? adminData.manufacturers : userData.manufacturers?.map((manufacture) => manufacture.id || '') ?? ['']
    );
  }, [adminData, userData]);

  useEffect(resetFields, [adminOrUserData, resetFields]);

  if (!adminOrUserData) return null;

  const adminOrUserTitle = adminOrUserData ? getAdminFullName(adminOrUserData) : undefined;

  const breadCrumbs = getAdminPagesBreadCrumb({
    name: adminData ? 'ADMIN_DETAILS' : 'NURSE_COORDINATOR_DETAILS',
    userName: adminOrUserTitle,
  });

  const handleSubmit = async (values: AdminDetailsFormType) => {
    if (!adminData?.id) return;
    else if (!selectedManufacturers.length) showManufactureRequiredErrorMessage();
    else
      await updateAdmin({
        id: adminData?.id,
        ...values,
        manufacturerIds: selectedManufacturers,
      });
  };

  const onCheckBoxChange = (checkedValues: CheckboxValueType[]) => {
    setSelectedManufacturers(checkedValues as string[]);
  };

  const isLoading = isAdminLoading || isUpdatingAdminLoading || isManufacturersLoading || isUserRolesLoading;

  const rightSideActionsJSX = (
    <PermissionWrapper permissionsRequired={['admins.edit']}>
      <div className='admin-details-wrapper-actions'>
        <Button loading={isUpdatingAdminLoading} onClick={editMode ? submit : toggleEditMode}>
          {text[editMode ? 'Save' : 'Edit']}
        </Button>
        <Spacer width={16} />
        <SuperAdminActions {...adminData} withButton />
      </div>
    </PermissionWrapper>
  );

  const pageTitleJSX = (
    <>
      {adminOrUserTitle}
      {adminData ? statusRenderer(adminData.status) : null}
    </>
  );

  return (
    <PageLayout
      pageTitle={pageTitleJSX}
      crumbs={breadCrumbs}
      rightSideActions={isSuperAdmin ? rightSideActionsJSX : null}
    >
      <Spin size='large' spinning={isLoading}>
        <h1 className='page-content-title'>{text['Basic Information']}</h1>
        <div className='admin-details-wrapper'>
          <Form<AdminDetailsFormType>
            form={form}
            layout='vertical'
            autoComplete='on'
            onFinish={handleSubmit}
            initialValues={adminOrUserData}
            className='form'
          >
            {getFieldsJSX(getUserDetailsFields(roleOptions, editMode), editMode)}
          </Form>
          <h1 className='page-content-title'>{text['Manufacturer Permissions']}</h1>
          <Spacer height={24} type='flex' />
          <AppCheckboxGroup
            disabled={!editMode}
            onChange={onCheckBoxChange}
            options={manufacturersOptions}
            value={selectedManufacturers}
          />
        </div>
      </Spin>
    </PageLayout>
  );
};
