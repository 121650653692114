import cx from 'classnames';
import { MetaTypes } from 'services/medias/types';
import { MilestoneTrackerStatus } from 'services/milestoneTrackers/types';

import { AdminStatus, ProgramStatus, SurveyStatus } from 'types/union-types';
import { capitalize } from 'utils/str-utils';

const getStatusClassName = (status: AdminStatus | ProgramStatus | MilestoneTrackerStatus) => {
  if (['LIVE', 'Active', 'IN_USE'].includes(status)) return 'active';
  if (['Pending', 'DRAFT'].includes(status)) return 'pending';

  return null;
};

export const textFormatter = (status: string) => capitalize(status.toLowerCase());

export const mediaTypeTextFormatter = (mediaType: MetaTypes) =>
  mediaType === 'PDF' ? mediaType.toUpperCase() : textFormatter(mediaType);

export const statusRenderer = (status: AdminStatus | ProgramStatus | SurveyStatus | MilestoneTrackerStatus) => (
  <span className={cx('status-provider', getStatusClassName(status))}>{textFormatter(status)}</span>
);

export const statusRendererWithCustomText = (
  status: AdminStatus | ProgramStatus | SurveyStatus | MilestoneTrackerStatus,
  text?: string,
  disabled?: boolean
) => (
  <span className={cx('status-provider', getStatusClassName(status), { disabled })}>
    {text || textFormatter(status)}
  </span>
);
