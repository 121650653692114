import { Empty, Table, TablePaginationConfig, TableProps } from 'antd';
import { text } from 'constants/texts';

import './style.scss';

const defaultPaginationConfig: TablePaginationConfig = {
  position: ['bottomCenter'],
};

export type DataTableProps<T> = {
  noDataContent?: string;
  paginationConfig?: TablePaginationConfig;
} & TableProps<T>;

// Need use object type (as in the library)
export const DataTable = <T extends object>({
  noDataContent = text["We couldn't find anything that matches your search terms."],
  paginationConfig,
  ...props
}: DataTableProps<T>) => {
  const emptyTextJSX = <Empty description={<span className='no-data-text'>{noDataContent}</span>} />;
  return (
    <Table
      className='pl-table'
      locale={{ emptyText: emptyTextJSX }}
      pagination={paginationConfig || defaultPaginationConfig}
      {...props}
    />
  );
};
