import { Form } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import useForm from 'antd/es/form/hooks/useForm';
import { ModalActionButtons } from 'components/fields/AppActionButtons/ModalActionButtons';
import { AppInput } from 'components/fields/AppInput/AppInput';
import { AppUpload, AppUploadProps } from 'components/fields/AppUpload/AppUpload';
import { PickMediaList, PickMediaListRef } from 'components/fields/PickMediaList/PickMediaList';
import {
  determineIfIsUploadFile,
  showCloseLoadMediaModalToast,
  showSuccessUploadedMediaToast,
} from 'components/PickFileOrMediaModalContent/utils';
import { Spacer } from 'components/Spacer/Spacer';
import { text } from 'constants/texts';
import { useCreateMedia } from 'hooks/api/programs/useCreateMedia';
import { useAppTabs } from 'hooks/appTabs/useAppTabs';
import { PlUploadFile } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Media/components/CreateMediaModal/types';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Media } from 'types/programs';

type PickFileOrMediaModalContentProps = {
  title?: string;
  description?: string;
  confirmButtonText?: string;
  closeButtonText?: string;
  withoutTextInfo?: boolean;
  onSuccess: (fileName: string, file: Media) => void;
  onClose?: () => void;
} & Pick<AppUploadProps, 'cropper' | 'maxSize' | 'fileTypes' | 'loadFileType' | 'draggerConfig'>;

export const PickFileOrMediaModalContent = ({
  fileTypes,
  title = text['Select a file from this programs media section or upload a new file'],
  description = text['Add an Image File'],
  confirmButtonText = text['Save'],
  closeButtonText = text['Cancel'],
  onSuccess,
  onClose,
  withoutTextInfo,
  ...rest
}: PickFileOrMediaModalContentProps) => {
  const { createMedia, isLoading } = useCreateMedia();

  const [form] = useForm<{ name: string }>();
  const name = useWatch('name', form);

  const { programId } = useParams();

  const mediaListRef = useRef<PickMediaListRef>(null);

  const [selectedFile, setSelectedFile] = useState<Media | PlUploadFile>();

  const { tabsJSX, selectedTabData, selectedTabKey } = useAppTabs<ReactNode>({
    tabs: [
      {
        title: text['Program media'],
        tabData: (
          <PickMediaList fileTypes={fileTypes} ref={mediaListRef} programId={programId} onChange={setSelectedFile} />
        ),
        tabKey: 'program-media',
      },
      {
        title: text['Upload'],
        tabData: (
          <AppUpload
            filesCount={1}
            onChange={(e) => setSelectedFile(e.fileList[0] as PlUploadFile)}
            fileTypes={fileTypes}
            draggerConfig={{}}
            createdFromSelectUpload
            {...rest}
          />
        ),
        tabKey: 'upload',
      },
    ],
    initialTabKey: 'program-media',
    withTabsScrolling: false,
  });

  // Clear data when changed upload file logic(media, upload file)
  useEffect(() => {
    setSelectedFile(undefined);
    form.resetFields();
    mediaListRef.current?.reset();
  }, [selectedTabKey]);

  // Update source name input when file will change
  useEffect(() => {
    if (!selectedFile) return;

    const name = determineIfIsUploadFile(selectedFile) ? selectedFile.name : selectedFile.mediaName;

    form.setFieldsValue({ name });
  }, [selectedFile]);

  // If media file -> onSuccess; If upload file -> create media request -> get response -> onSuccess;
  const onSubmit = async () => {
    if (!selectedFile || !name) return;

    if (!determineIfIsUploadFile(selectedFile)) return onSuccess(name, selectedFile);

    const createdMedia = await createMedia({
      programId: programId || '',
      resource: [selectedFile],
      resourceName: name,
    });

    showSuccessUploadedMediaToast();
    onSuccess(name, createdMedia);
  };

  // Show warning toast when file was uploaded
  const onCancel = () => {
    if (selectedFile) showCloseLoadMediaModalToast();
    onClose?.();
  };

  return (
    <>
      {!withoutTextInfo && (
        <>
          <h1 className='modal-title'>{title}</h1>
          <Spacer height={16} type='flex' />
          <h2 className='modal-description'>{description}</h2>
          <Spacer height={24} type='flex' />
        </>
      )}
      {/* Form was used for tooltip, label and useWatch  */}
      <Form layout='vertical' form={form}>
        <Form.Item name='name' label='Selected Resource' tooltip='Selected Resource'>
          <AppInput disabled={!selectedFile} placeholder='Select a Resource' />
        </Form.Item>
      </Form>
      {tabsJSX}
      {selectedTabData}
      <Spacer height={36} type='flex' />
      <ModalActionButtons
        isLoading={isLoading}
        isSubmitButtonDisabled={!selectedFile || !name}
        submitText={confirmButtonText}
        cancelText={closeButtonText}
        onCancel={onCancel}
        onSubmit={onSubmit}
      />
    </>
  );
};
