import { showCustomToast } from 'components';
import { text } from 'constants/texts';
import { toBusinessRuleDTO } from 'hooks/api/rules/mappers';
import { useMutation, useQueryClient } from 'react-query';
import { queryKeys } from 'services/constants';
import { updateBusinessRule } from 'services/rules/requests';
import { Callback } from 'types/helpers';
import { BusinessRule } from 'types/rules';
import { errorHandler } from 'utils/errorHandler';
import { stringModifier } from 'utils/strings';

export const useUpdateBusinessRule = (onSuccess?: Callback) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(updateBusinessRule, {
    mutationKey: queryKeys.updateBusinessRule,
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKeys.getBusinessRules);
      await queryClient.invalidateQueries(queryKeys.getBusinessRulesMeta);

      showCustomToast({
        message: stringModifier(text['{0} updated successfully'], text['Business Rule']),
        type: 'success',
        duration: 3,
      });

      onSuccess?.();
    },
    onError: errorHandler,
  });

  return {
    isLoading,
    updateBusinessRule: (businessRule: BusinessRule, status?: boolean) => {
      let newRule: BusinessRule = { ...businessRule };

      if (typeof status === 'boolean') newRule.isActive = status;

      mutate(toBusinessRuleDTO(newRule));
    },
  };
};
