import { AppField, AppFields } from 'components/fields/types';
import { emptyField, fieldModifier } from 'components/fields/utils';
import { text } from 'constants/texts';
import {
  milestoneTrackerDescriptionField,
  milestoneTrackerNameField,
} from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/MilestoneTrackers/fields';
import {
  StepMilestoneTrackerField,
  UpdateMilestoneTrackerFields,
} from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/MilestoneTrackers/types';
import { stringModifier } from 'utils/strings';
import { RULES } from 'utils/validations';

const { MAX, REQUIRED } = RULES;

export const updateMilestoneTrackerBaseFields: AppFields<UpdateMilestoneTrackerFields> = [
  [milestoneTrackerNameField, milestoneTrackerDescriptionField],
];

export const getUpdateMilestoneTrackerStepFields = (orderIndex: number): AppFields<StepMilestoneTrackerField> => [
  [
    fieldModifier(milestoneTrackerTitleNameField, { name: [orderIndex, 'title'] }),
    fieldModifier(emptyField, { emptyKey: 'milestoneTrackerStepNameEmptyField' }),
  ],
];

// FIELDS

const milestoneTrackerTitleNameField: AppField<Pick<StepMilestoneTrackerField, 'title'>> = {
  type: 'input',
  name: ['title'],
  label: text['Milestone Name'],
  placeholder: stringModifier(text['{0} Characters Max'], '25'),
  rules: [REQUIRED({ requiredFieldName: text['Milestone Tracker Name'] }), MAX({ max: 75 })],
};
