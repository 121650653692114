import { ContentStepData, ContentStepsRef } from 'components/ContentSteps/ContentSteps';
import { useCallback, useRef, useState } from 'react';

export const useContentStepsData = () => {
  const [stepData, setStepData] = useState<ContentStepData>();
  const stepsRef = useRef<ContentStepsRef>(null);

  const stepChangeHandler = useCallback(setStepData, []);

  const { canGoBack, canGoNext, currentIndex } = stepData || {};
  const { next, goBack } = stepsRef.current || {};

  return {
    stepsRef,
    stepChangeHandler,
    stepData: {
      canGoBack,
      canGoNext,
      currentIndex,
      next,
      goBack,
    },
  };
};
