export const text = {
  "AND": "AND",
  "Action": "Action",
  "Activate": "Activate",
  "activated": "activated",
  "Active": "Active",
  "Add Another Action": "Add Another Action",
  "Add Financial Program": "Add Financial Program",
  "Add Media": "Add Media",
  "Add Milestone Tracker": "Add Milestone Tracker",
  "Add Notification Tile": "Add Notification Tile",
  "Add a Survey to the {0} program.": "Add a Survey to the {0} program.",
  "Add a new Admin to PatientLink": "Add a new Admin to PatientLink",
  "Add a new User to PatientLink": "Add a new User to PatientLink",
  "Add a new business rule to the {0} program.": "Add a new business rule to the {0} program.",
  "Add a new milestone tracker to the {0} program.": "Add a new milestone tracker to the {0} program.",
  "Add a new notification tile to the {0} program.": "Add a new notification tile to the {0} program.",
  "Add a new phase for the benefit approval process for the {0} program.": "Add a new phase for the benefit approval process for the {0} program.",
  "Add a {0}": "Add a {0}",
  "Add an Additional Service": "Add an Additional Service",
  "Add an Image File": "Add an Image File",
  "Add an additional service option for the {0} program.": "Add an additional service option for the {0} program.",
  "Add {0}": "Add {0}",
  "Add": "Add",
  "Additional Service": "Additional Service Message",
  "Additional Services {0}": "Additional Services {0}",
  "Additional Services": "Additional Services",
  "Admins": "Admins",
  "All Admins": "All Admins",
  "All Business Rules": "All Business Rules",
  "All Programs": "All Programs",
  "Allow Shipment Tracking?": "Allow Shipment Tracking?",
  "Amount of Money": "Amount of Money",
  "An external link for more information around the Financial Program": "An external link for more information around the Financial Program",
  "An image for the Financial Program. If you don't add an image, no image will appear to the patient": "An image for the Financial Program. If you don’t add an image, no image will appear to the patient",
  "Answer choice": "Answer choice",
  "Are you sure you want to cancel the changes you've made?": "Are you sure you want to cancel the changes you've made?",
  "Are you sure you want to delete this {0}?": "Are you sure you want to delete this {0}?",
  "Are you sure you want to save your changes?": "Are you sure you want to save your changes?",
  "Attribute": "Attribute",
  "Back": "Back",
  "Basic Information": "Basic Information",
  "Below is a list of the programs associated with this client in the system": "Below is a list of the programs associated with this client in the system",
  "Benefits Remaining": "Benefits Remaining",
  "Benefits Settings": "Benefits Settings",
  "Benefits Used": "Benefits Used",
  "Benefits": "Benefits",
  "Bridge": "Bridge",
  "Business Rule Description": "Business Rule Description",
  "Business Rule Name": "Business Rule Name",
  "Business Rule": "Business Rule",
  "Business Rules": "Business Rules",
  "By canceling these changes, your program will be reverted to it's last save state and no changes will be saved.": "By canceling these changes, your program will be reverted to it’s last save state and no changes will be saved.",
  "Cancel": "Cancel",
  "Change the name of this media in the text box below.": "Change the name of this media in the text box below.",
  "Change the name of this {0} in the text box below.": "Change the name of this {0} in the text box below.",
  "City": "City",
  "Click the button below to add new {0}": "Click the button below to add new {0}",
  "Co-pay": "Co-pay",
  "Communication Name": "Communication Name",
  "Communication Type": "Communication Type",
  "Communications": "Communications",
  "Condition": "Condition",
  "Confirm": "Confirm",
  "Contact Information": "Contact Information",
  "Content": "Content",
  "Copay Renewal": "Copay Renewal",
  "Create Admin": "Create Admin",
  "Create New Program": "Create New Program",
  "Create User": "Create User",
  "Create": "Create",
  "Criteria": "Criteria",
  "DRAFT": "DRAFT",
  "Data Fields": "Data Fields",
  "Date": "Date",
  "Deactivate": "Deactivate",
  "deactivated": "deactivated",
  "Delete {0}": "Delete {0}",
  "Delete": "Delete",
  "deleted": "deleted",
  "Description Text": "Description Text",
  "Description": "Description",
  "Destination Page": "Destination Page",
  "Detected Message": "Detected Message",
  "Draft": "Draft",
  "Drag and drop image file here": "Drag and drop image file here",
  "Edit": "Edit",
  "Email Address": "Email Address",
  "Email Template": "Email Template",
  "Email": "Email",
  "Empty": "Empty",
  "Enter a Patient’s Email Address": "Enter a Patient’s Email Address",
  "Enter {0} {1}": "Enter {0} {1}",
  "Enter {0}": "Enter {0}",
  "External Link Name": "External Link Name",
  "External Link": "External Link",
  "False": "False",
  "Field Description": "Field Description",
  "Field Name": "Field Name",
  "File": "File",
  "Fills Remaining": "Fills Remaining",
  "Fills Used": "Fills Used",
  "Financial Assistance Program": "Financial Assistance Program",
  "Financial Assistance Settings": "Financial Assistance Settings",
  "Financial Assistance": "Financial Assistance",
  "Financial Program Description": "Financial Program Description",
  "Financial Program Image": "Financial Program Image",
  "Financial Program Link": "Financial Program Link",
  "Financial Program Name": "Financial Program Name",
  "Financial Program Type": "Financial Program Type",
  "First Name": "First Name",
  "Forms": "Forms",
  "Foundation": "Foundation",
  "Graphics": "Graphics",
  "I'm Sure": "I'm Sure",
  "IN_USE": "IN_USE",
  "If you would like to add this type of Financial Program to this instance, click the button below.": "If you would like to add this type of Financial Program to this instance, click the button below.",
  "In Use": "In Use",
  "Inactive": "Inactive",
  "Introduction Description": "Introduction Description",
  "Introduction Title": "Introduction Title",
  "Landing Card": "Landing Card",
  "Last Dispense Date": "Last Dispense Date",
  "Last Name": "Last Name",
  "Logout": "Logout",
  "Manufacturer Permissions": "Manufacturer Permissions",
  "Manufacturer": "Manufacturer",
  "Max {0} Characters are allowed": "Max {0} Characters are allowed",
  "Media Name": "Media Name",
  "Media Type": "Media Type",
  "Media": "Media",
  "Medical Co-pay Card Image": "Medical Co-pay Card Image",
  "Medical Co-pay Card Patient Instructions Body": "Medical Co-pay Card Patient Instructions Body",
  "Medical Co-pay Card Terms and Conditions Link": "Medical Co-pay Card Terms and Conditions Link",
  "Medical Patient Instructions": "Medical Patient Instructions",
  "Medication Tracker": "Medication Tracker",
  "Medicine Cabinet": "Medicine Cabinet",
  "Metrics": "Metrics",
  "Milestone Name": "Milestone Name",
  "Milestone Tracker Description": "Milestone Tracker Description",
  "Milestone Tracker Name": "Milestone Tracker Name",
  "Milestone Tracker Step": "Milestone Tracker Step",
  "Milestone Tracker": "Milestone Tracker",
  "Milestone Trackers": "Milestone Trackers",
  "Milestone": "Milestone",
  "Money Spent": "Money Spent",
  "Name not found": "Name not found",
  "Name of Phase": "Name of Phase",
  "Never mind": "Never mind",
  "Next": "Next",
  "No changes were saved.": "No changes were saved.",
  "No data": "No data",
  "No": "No",
  "Notification Tile Description": "Notification Tile Description",
  "Notification Tile Name": "Notification Tile Name",
  "Notification Tile Title": "Notification Tile Title",
  "Notification Tile": "Notification Tile",
  "Notification Tiles Details": "Notification Tiles Details",
  "Notification Tiles": "Notification Tiles",
  "Notification": "Notification",
  "Number of Days": "Number of Days",
  "Number of Fills": "Number of Fills",
  "Numbers and Symbols are not available": "Numbers and Symbols are not available",
  "Oops, something went wrong": "Oops, something went wrong",
  "Operator": "Operator",
  "Ops, there's a issue with sending reset-password email": "Ops, there's a issue with sending reset-password email",
  "PAP": "PAP",
  "Patient Information": "Patient Information",
  "Patient Instructions": "Patient Instructions",
  "Patient Search": "Patient Search",
  "Pending": "Pending",
  "Pharmacy Co-pay Card Image": "Pharmacy Co-pay Card Image",
  "Pharmacy Co-pay Card Patient Instructions Body": "Pharmacy Co-pay Card Patient Instructions Body",
  "Pharmacy Co-pay Card Terms and Conditions Link": "Pharmacy Co-pay Card Terms and Conditions Link",
  "Pharmacy Patient Instructions": "Pharmacy Patient Instructions",
  "Phase Description": "Phase Description",
  "Phase {0}": "Phase {0}",
  "Phone Number": "Phone Number",
  "Profile": "Profile",
  "Program Information": "Program Information",
  "Program Introduction": "Program Introduction",
  "Program Name": "Program Name",
  "Program Termination Criteria": "Program Termination Criteria",
  "Program media": "Program media",
  "Programs": "Programs",
  "Push Notification Message": "Push Notification Message",
  "Question {0}": "Question {0}",
  "Question": "Question",
  "Quickstart": "Quickstart",
  "Rebate Center": "Rebate Center",
  "Registered Patients": "Registered Patients",
  "Rename Media": "Rename Media",
  "Rename {0}": "Rename {0}",
  "Reset Password": "Reset Password",
  "Reset-password email send successfully": "Reset-password email send successfully",
  "Resources": "Resources",
  "Role": "Role",
  "Rule": "Rule",
  "SMS Message": "SMS Message",
  "SMS Keyword": "SMS Keyword",
  "Save": "Save",
  "Schedule Type": "Schedule Type",
  "Schedule Value": "Schedule Value",
  "Scheduled Actions": "Scheduled Actions",
  "Search": "Search",
  "Select Milestone": "Select Milestone",
  "Select a file from this programs media section or upload a new file": "Select a file from this programs media section or upload a new file",
  "Select {0} {1}": "Select {0} {1}",
  "Select {0}": "Select {0}",
  "Selection Type": "Selection Type",
  "Sent Date": "Sent Date",
  "Service": "Service",
  "Settings": "Settings",
  "Should be as Email Address": "Should be as Email Address",
  "Should be as Phone Number": "Should be as Phone Number",
  "Should be as Url Link": "Should be as Url Link",
  "Should be as Zip Code": "Should be as Zip Code",
  "Source": "Source",
  "Specialty Pharmacies": "Specialty Pharmacies",
  "Specialty Pharmacy Name": "Specialty Pharmacy Name",
  "Specialty Pharmacy": "Specialty Pharmacy",
  "SpecialtyPharmacy": "SpecialtyPharmacy",
  "Start Date": "Start Date",
  "State": "State",
  "Status": "Status",
  "Step {0}": "Step {0}",
  "Step": "Step",
  "Steps": "Steps",
  "Street Address Line {0}": "Street Address Line {0}",
  "Street Address": "Street Address",
  "Super admin has no account details!": "Super admin has no account details!",
  "Survey Name": "Survey Name",
  "Survey Question Description": "Survey Question Description",
  "Survey Question": "Survey Question",
  "Survey is not available": "Survey is not available",
  "Survey": "Survey",
  "Surveys": "Surveys",
  "Termination Criteria": "Termination Criteria",
  "Terms and Conditions": "Terms and Conditions",
  "The amount of money spent by a patient that will terminate a financial program": "The amount of money spent by a patient that will terminate a financial program",
  "The criteria that will cause the Financial Program to end for an enrolled patient": "The criteria that will cause the Financial Program to end for an enrolled patient",
  "The date of termination for a financial program for a patient": "The date of termination for a financial program for a patient",
  "The description that will appear on this Financial Programs introduction pop up and notification card": "The description that will appear on this Financial Programs introduction pop up and notification card",
  "The name of the Financial Program that will appear to the patient": "The name of the Financial Program that will appear to the patient",
  "The number of days to elapse before a program is terminated for a patient": "The number of days to elapse before a program is terminated for a patient",
  "The number of prescription fills a patient can received before the financial program is terminated for that patient": "The number of prescription fills a patient can received before the financial program is terminated for that patient",
  "The title that will appear on this Financial Programs introduction pop up and notification card": "The title that will appear on this Financial Programs introduction pop up and notification card",
  "The value of this field can't be zero.": "The value of this field can’t be zero.",
  "These are the details of the {0}": "These are the details of the {0}",
  "These are the fields for Patient Information": "These are the fields for Patient Information",
  "These changes will be saved and this cannot be undone.": "These changes will be saved and this cannot be undone.",
  "These settings impact the Additional Services segment in the instance of the application": "These settings impact the Additional Services segment in the instance of the application",
  "These settings impact the Benefits segment in the instance of the application": "These settings impact the Benefits segment in the instance of the application",
  "These settings impact the Financial Assistance segment in the instance of the application": "These settings impact the Financial Assistance segment in the instance of the application",
  "This Financial Program does not exist in this instance.": "This Financial Program does not exist in this instance.",
  "This is a list of the communications sent to this patient.": "This is a list of the communications sent to this patient.",
  "This is the description text that appears on the milestone tracker card for a selected step": "This is the description text that appears on the milestone tracker card for a selected step",
  "This is the repository for all of the content in this program": "This is the repository for all of the content in this program",
  "This is the repository of all Specialty Pharmacies for this program": "This is the repository of all Specialty Pharmacies for this program",
  "This is the repository of all business rules for this program": "This is the repository of all business rules for this program",
  "This is the step in a milestone tracker": "This is the step in a milestone tracker",
  "This is the title text that appears on the milestone tracker card for a selected step": "This is the title text that appears on the milestone tracker card for a selected step",
  "This item was successfully deleted": "This item was successfully deleted",
  "This logic is not implemented yet": "This logic is not implemented yet",
  "This program doesn't have any {0} configured.": "This program doesn’t have any {0} configured.",
  "This program doesn't have any {0} created": "This program doesn’t have any {0} created",
  "This program doesn't have any {0} uploaded.": "This program doesn’t have any {0} uploaded.",
  "This program is currently being edited by another Admin. Please try again later.": "This program is currently being edited by another Admin. Please try again later.",
  "This": "This",
  "Title Text": "Title Text",
  "To search for a patient’s information, enter their email address in the search box above.": "To search for a patient’s information, enter their email address in the search box above.",
  "Trigger": "Trigger",
  "True": "True",
  "Upload": "Upload",
  "User successfully created!": "User successfully created!",
  "User": "User",
  "Users in Event": "Users in Event",
  "Value": "Value",
  "View Account": "View Account",
  "We couldn't find any {0} here.": "We couldn’t find any {0} here.",
  "We couldn't find anything that matches your search terms.": "We couldn’t find anything that matches your search terms.",
  "We couldn’t find any accounts with that email address.": "We couldn’t find any accounts with that email address.",
  "We couldn’t find any {0} that fit your search criteria.": "We couldn’t find any {0} that fit your search criteria.",
  "We don’t see an account associated with that email. Please check the email address you entered in the search bar.": "We don’t see an account associated with that email. Please check the email address you entered in the search bar.",
  "Yes": "Yes",
  "You can rename this Media": "You can rename this Media",
  "Your changes has been saved.": "Your changes has been saved.",
  "Your changes were saved.": "Your changes were saved.",
  "Your {0} has been created": "Your {0} has been created",
  "Zip Code": "Zip Code",
  "a": "a",
  "es": "es",
  "of": "of",
  "s": "s",
  "{0} Characters Max": "{0} Characters Max",
  "{0} Characters Maximum": "{0} Characters Maximum",
  "{0} Details": "{0} Details",
  "{0} Step": "{0} Step",
  "{0} Users": "{0} Users",
  "{0} deleted successfully": "{0} deleted successfully",
  "{0} field is required": "{0} field is required",
  "{0} has been updated to {1}": "{0} has been updated to {1}",
  "{0}`s account has been {1}.": "{0}`s account has been {1}.",
  "{0} updated successfully": "{0} updated successfully",
  "A description of the Financial Program that will appear to the patient": "A description of the Financial Program that will appear to the patient",
} as const;

export type AppText = typeof text[keyof typeof text];
