import { useQuery } from 'react-query';
import { queryKeys } from 'services/constants';
import { getPatient } from 'services/patientSearch/requests';
import { toPatient } from './mappers';

export const usePatient = (email = '') => {
  const {
    data: patient,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery([queryKeys.getPatient, email], () => getPatient(email), {
    enabled: !!email,
    select: toPatient,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  return {
    patient,
    refetch,
    isLoading,
    isRefetching,
    hasData: !!patient?.cognitoId && !!email,
  };
};
