import { SVGProps } from 'react';

export const ImageFileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M16.3332 12.6667C16.3332 13.0203 16.1927 13.3594 15.9426 13.6095C15.6926 13.8595 15.3535 14 14.9998 14H2.99984C2.64622 14 2.30708 13.8595 2.05703 13.6095C1.80698 13.3594 1.6665 13.0203 1.6665 12.6667V5.33333C1.6665 4.97971 1.80698 4.64057 2.05703 4.39052C2.30708 4.14048 2.64622 4 2.99984 4H5.6665L6.99984 2H10.9998L12.3332 4H14.9998C15.3535 4 15.6926 4.14048 15.9426 4.39052C16.1927 4.64057 16.3332 4.97971 16.3332 5.33333V12.6667Z'
      stroke='#1D242D'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.00016 11.3333C10.4729 11.3333 11.6668 10.1394 11.6668 8.66667C11.6668 7.19391 10.4729 6 9.00016 6C7.5274 6 6.3335 7.19391 6.3335 8.66667C6.3335 10.1394 7.5274 11.3333 9.00016 11.3333Z'
      stroke='#1D242D'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
