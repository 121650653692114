// Hardcoded number
type GetHardcodedNumberConfig = {
  hardcodedNumber?: number;
  range?: { min: number; max: number };
  float?: {
    maxSymbolAfterDot?: number;
  };
  cleverHardcodedNumber?: {
    number: number;
    id: string;
  };
};

const hardcodedNumbers: [number, string][] = [];

export const getHardcodedNumber = ({
  hardcodedNumber,
  cleverHardcodedNumber,
  range,
  float,
}: GetHardcodedNumberConfig = {}): number => {
  if (cleverHardcodedNumber) {
    const { number, id } = cleverHardcodedNumber;

    const foundNumber = hardcodedNumbers.find((num) => num[1] === id);

    if (foundNumber) return foundNumber[0];

    hardcodedNumbers.push([number, id]);

    return number;
  }

  if (typeof hardcodedNumber === 'number') return hardcodedNumber;

  let random = Math.random() * 10001;

  if (range) {
    const { min, max } = range;
    random = Math.random() * (max - min) + min;
  }

  return float ? +random.toFixed(float.maxSymbolAfterDot || 2) : Math.floor(random);
};

// Hardcoded id
type GetHardcodedIdConfig<T extends string | number> = {
  hardcodedId?: T;
  type: 'string' | 'number';
};

const ids = new Set();
/** @description I hope you will never create 8999999999 ids. If your will create - Stack Overflow Error appears */
const getHardcodedId = <T extends string | number>({ hardcodedId, type }: GetHardcodedIdConfig<T>): T => {
  if (hardcodedId !== undefined) return hardcodedId;

  const random = getHardcodedNumber({ range: { min: 1000000000, max: 9999999999 } });

  const id = (type === 'string' ? `${random}` : random) as T;

  if (ids.has(id)) return getHardcodedId<T>({ hardcodedId, type });

  ids.add(id);

  return id;
};

export const getHardcodedNumberId = (config: Omit<GetHardcodedIdConfig<number>, 'type'> = {}) =>
  getHardcodedId<number>({ type: 'number', ...config });

export const getHardcodedStringId = (config: Omit<GetHardcodedIdConfig<string>, 'type'> = {}) =>
  getHardcodedId<string>({ type: 'string', ...config });
