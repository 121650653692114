import { DeleteConfirmModal } from 'components';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { text } from 'constants/texts';
import { useDeleteSurvey } from 'hooks/api/surveys';
import { MutableRefObject } from 'react';
import { Survey } from 'types/surveys';

type DeleteConfirmModalProps = {
  survey: Survey;
  deleteModalRef: MutableRefObject<ClosableWrapperRef | undefined>;
};

export const DeleteSurveyConfirmModal = ({ survey, deleteModalRef }: DeleteConfirmModalProps) => {
  const { isLoading, deleteSurvey } = useDeleteSurvey(survey, () => deleteModalRef.current?.close);

  return (
    <DeleteConfirmModal
      titleName={survey.name || text['Survey']}
      descriptionName={text['Survey']}
      onConfirm={deleteSurvey}
      deleteModalRef={deleteModalRef}
      isLoading={isLoading}
    />
  );
};
