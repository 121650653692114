import { FormInstance } from 'antd/es';
import { useWatch } from 'antd/lib/form/Form';
import clx from 'classnames';

import { Button, Spacer } from 'components';
import { getFieldsJSX } from 'components/fields/utils';
import { useProgramData } from 'hooks/api';
import { useDeleteService } from 'hooks/api/programs/useDeleteService';
import { DynamicAnswersFields } from 'pages/ProgramDetails/common';
import { getAdditionalServicesMainFields, getFormRequestMainFields } from '../../constants';
import { ServicesFields } from '../../types';

export type ServicesDynamicFormProps = {
  form: FormInstance<ServicesFields>;
  orderIndex: number;
  remove: (index: number | number[]) => void;
  isEditMode: boolean;
};

export const ServicesDynamicForm = ({ remove, orderIndex, isEditMode, form }: ServicesDynamicFormProps) => {
  const service = form.getFieldValue(['services', orderIndex]);
  const { program } = useProgramData();
  const serviceType = useWatch(['services', orderIndex, 'type'], form);
  const additionalServicesFields = getAdditionalServicesMainFields({ orderIndex, serviceType, service, program, form });
  const mainFormRequestFields = getFormRequestMainFields(orderIndex);
  const { isLoading, deleteServiceById } = useDeleteService();

  const isFormRequestOptionSelected = serviceType === 'FORM_REQUEST';

  const deleteHandler = async () => {
    await deleteServiceById(service.id);
    remove(orderIndex);
  };

  return (
    <div className='service-block'>
      <h1 className='page-content-title'>{`Additional Service ${orderIndex + 1}`}</h1>
      <Spacer height={24} />
      {getFieldsJSX(additionalServicesFields, isEditMode)}
      {isFormRequestOptionSelected && (
        <div className='service-block-dynamic-form'>
          <div className='service-block-50'>{getFieldsJSX(mainFormRequestFields, isEditMode)}</div>
          <Spacer width={24} height='initial' type='flex' className={clx({ 'block-divider': !isEditMode })} />
          <div className='service-block-50'>
            <DynamicAnswersFields name={[orderIndex, 'answers']} isEditMode={isEditMode} />
          </div>
        </div>
      )}
      {isEditMode && (
        <Button loading={isLoading} className='delete-service' type='text' onClick={deleteHandler}>
          Delete
        </Button>
      )}
    </div>
  );
};
