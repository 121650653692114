import { Option } from 'types/common';
import { SelectionType, ServiceIconType, ServiceType } from './types';

interface ServiceTypeOption extends Option {
  value: ServiceType;
}

interface ServiceIconOption extends Option {
  value: ServiceIconType;
}

interface SelectionTypeOption extends Option {
  value: SelectionType;
}

export const serviceTypeOptions: ServiceTypeOption[] = [
  {
    label: 'Form Request',
    value: 'FORM_REQUEST',
  },
  {
    label: 'Physical Shipment',
    value: 'PHYSICAL_SHIPMENT',
  },
  {
    label: 'Resource',
    value: 'RESOURCE',
  },
];

export const serviceIconOptions: ServiceIconOption[] = [
  {
    label: 'Video',
    value: 'VIDEO',
  },
  {
    label: 'Clipboard',
    value: 'CLIPBOARD',
  },
  {
    label: 'Injection',
    value: 'INJECTION',
  },
  {
    label: 'Package',
    value: 'PACKAGE',
  },
  {
    label: 'Phone',
    value: 'PHONE',
  },
];

export const selectionTypeOptions: SelectionTypeOption[] = [
  {
    label: 'Multi Select',
    value: 'MULTI_SELECT',
  },
  {
    label: 'Single Select',
    value: 'SINGLE_SELECT',
  },
];
