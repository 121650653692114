export const filterMediaType = [
  {
    text: 'PDF',
    value: 'PDF',
  },
  {
    text: 'Video',
    value: 'Video',
  },
  {
    text: 'Image',
    value: 'Image',
  },
];
