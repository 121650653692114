import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { ConfirmModal } from 'components/ConfirmModal/ConfirmModal';
import { text } from 'constants/texts';
import { MutableRefObject } from 'react';
import { Callback } from 'types/helpers';
import { stringModifier } from 'utils/strings';

type DeleteConfirmModalProps = {
  onCancel?: Callback;
  onConfirm: Callback;
  deleteModalRef: MutableRefObject<ClosableWrapperRef | null | undefined>;
  titleName?: string;
  titleText?: string;
  descriptionName?: string;
  descriptionText?: string;
  isLoading?: boolean;
};

export const DeleteConfirmModal = ({
  onConfirm,
  descriptionName,
  descriptionText,
  deleteModalRef,
  titleText,
  titleName,
  onCancel,
  isLoading,
}: DeleteConfirmModalProps) => (
  <ConfirmModal
    title={titleText || stringModifier(text['Delete {0}'], titleName || '')}
    description={
      descriptionText || stringModifier(text['Are you sure you want to delete this {0}?'], descriptionName || '')
    }
    cancelTextBtn={text['Cancel']}
    confirmTextBtn={text['Delete']}
    modalRef={(ref) => (deleteModalRef.current = ref)}
    confirmHandler={onConfirm}
    cancelHandler={() => {
      deleteModalRef.current?.close();
      onCancel?.();
    }}
    isLoading={isLoading}
  />
);
