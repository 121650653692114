import { showSuccessfullyDeletedToast } from 'pages/ProgramDetails/utils';
import { useMutation, useQueryClient } from 'react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import { SPECIALTY_PHARMACY_ID_PARAM } from 'routes/navigators';
import { queryKeys } from 'services/constants';
import { deleteSpecialtyPharmacy } from 'services/specialtyPharmacies/requests';
import { Callback } from 'types/helpers';
import { errorHandler } from 'utils/errorHandler';

export const useDeleteSpecialtyPharmacy = (pharmacyId = '', onSuccess?: Callback) => {
  const queryClient = useQueryClient();

  const { programId = '' } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const { mutateAsync, isLoading } = useMutation(deleteSpecialtyPharmacy, {
    mutationKey: queryKeys.deleteSpecialtyPharmacy,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.getSpecialtyPharmacies);
      showSuccessfullyDeletedToast();
      onSuccess?.();
      // If user is in detail page, navigate user to the listing specialtyPharmacies
      if (searchParams.get(SPECIALTY_PHARMACY_ID_PARAM) === pharmacyId) {
        searchParams.delete(SPECIALTY_PHARMACY_ID_PARAM);
        setSearchParams(searchParams);
      }
    },
    onError: errorHandler,
  });

  return {
    isLoading,
    deleteSpecialtyPharmacy: () =>
      pharmacyId &&
      mutateAsync({
        pharmacyId,
        programId,
      }),
  };
};
