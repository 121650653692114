import { showCustomToast } from 'components';
import { AnyObject } from 'types/helpers';
import { axiosErrorHandler } from 'utils/errors';

const getErrorObjectMessages = (error?: AnyObject) => {
  if (!error) return '';
  return Object.keys(error)
    .map((errKey) => error[errKey])
    .join('\r\n');
};

const getParsedMessage = (message: string) => {
  try {
    const err = JSON.parse(message);

    return err.message || err.detail || err.details || '';
  } catch (e) {
    return message;
  }
};

export const errorHandler = (error: unknown) =>
  axiosErrorHandler<{ message: string | AnyObject; detail: string }>(error, (err) => {
    // detail is a new error structure which comes from fast api.
    const { message = '', detail = '' } = err.response?.data || {};

    const parsedError = typeof message === 'string' ? getParsedMessage(message) : getErrorObjectMessages(message);

    const errorMessage = Array.isArray(parsedError)
      ? parsedError.reduce((prev, curr) => `${prev} \n${curr.msg}`, '')
      : parsedError;

    showCustomToast({
      message: errorMessage || detail || 'Oops something went wrong',
      type: 'error',
    });
  });
