import { useAppTabs } from '../../hooks/appTabs/useAppTabs';
import { getPatientSearchTabs } from './tabsConfig/root';
import { statusRenderer } from '../shared';
import React from 'react';
import { Patient, PatientCommunication } from 'services/patientSearch/types';
import { text } from 'constants/texts';

type PatientSearchContentProps = {
  patient?: Patient;
  patientCommunications?: PatientCommunication[];
  selectedTab: string | null;
  onTabChange: (key: string) => void;
};

export const PatientSearchContent = ({
  patient,
  patientCommunications,
  selectedTab,
  onTabChange,
}: PatientSearchContentProps) => {
  const { tabs } = getPatientSearchTabs({ patient, patientCommunications });

  const {
    tabsJSX,
    selectedTabData: { contentJSX },
  } = useAppTabs({
    tabs,
    initialTabKey: selectedTab || 'patient-information',
    onTabChange,
  });

  if (!patient) return null;

  const title = patient.email;

  return (
    <>
      <h1 className='page-title pl-layout-title'>
        <div className='patient-wrapper-title'>
          {title}
          {statusRenderer(text[patient.isActive ? 'Active' : 'Inactive'])}
        </div>
      </h1>
      {tabsJSX}
      {contentJSX}
    </>
  );
};
