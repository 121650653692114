import { usePermissionsHandler } from '../usePermissionsHandler';
import { AppTabs } from './types';

export const useAppTabsPermissions = () => {
  const [
    isViewReportsAllowed,
    isViewReportsCommunicationAllowed,
    isViewUniversalAllowed,
    isViewFinancialProgramsAllowed,
    isViewCopayAllowed,
    isViewPapAllowed,
    isViewQuickstartAllowed,
    isViewBridgeAllowed,
    isViewFoundationAllowed,
    isViewBenefitsAllowed,
    isViewRebateCenterAllowed,
    isViewAdditionalServicesAllowed,
    isViewRulesAllowed,
    isViewPharmaciesAllowed,
    isViewContentAllowed,
    isViewMediaDataAllowed,
    isViewFormsAllowed,
    isViewSurveysAllowed,
    isViewMilestoneTrackersAllowed,
    isViewNotificationTilesAllowed,
    isCreateMediaAllowed,
    isEditProgramAllowed,
  ] = usePermissionsHandler([
    'reports.view',
    'reports.view_communications',
    'universal_details.view',
    'financial_programs.view',
    'copay.view',
    'pap.view',
    'quickstart.view',
    'bridge.view',
    'foundation.view',
    'benefits.view',
    'rebate-center.view',
    'additional_services.view',
    'rules.view',
    'pharmacies.view',
    'content.view',
    'media_data.view',
    'forms.view',
    'surveys.view',
    'milestone_trackers.view',
    'notification-tiles.view',
    'media_data.create',
    'programs.edit',
  ]);

  /** @description if you haven't permission from BE for the tab, set "tabKey: true" here. */
  const tabHasPermissionMapper: Record<AppTabs, boolean> = {
    communications: isViewReportsAllowed,
    'patient-information': isViewReportsCommunicationAllowed,
    universal: isViewUniversalAllowed,
    'financial-assistance': isViewFinancialProgramsAllowed,
    copay: isViewCopayAllowed,
    pap: isViewPapAllowed,
    quickstart: isViewQuickstartAllowed,
    bridge: isViewBridgeAllowed,
    foundation: isViewFoundationAllowed,
    benefits: isViewBenefitsAllowed,
    'rebate-center': isViewRebateCenterAllowed,
    services: isViewAdditionalServicesAllowed,
    rules: isViewRulesAllowed,
    'specialty-pharmacies': isViewPharmaciesAllowed,
    content: isViewContentAllowed,
    medias: isViewMediaDataAllowed,
    forms: isViewFormsAllowed,
    surveys: isViewSurveysAllowed,
    'milestone-trackers': isViewMilestoneTrackersAllowed,
    'notification-tiles': isViewNotificationTilesAllowed,
    'program-media': isEditProgramAllowed,
    upload: isCreateMediaAllowed,
  };

  return { tabHasPermissionMapper };
};
