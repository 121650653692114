import { usePermissionsHandler } from 'hooks';
import { AppTabs } from 'hooks/appTabs/types';

export const useFATabPermissions = () => {
  const [
    isCreateCopayAllowed,
    isCreatePapAllowed,
    isCreateQuickstartAllowed,
    isCreateBridgeAllowed,
    isCreateFoundationAllowed,
    isEditCopayAllowed,
    isEditPapAllowed,
    isEditQuickstartAllowed,
    isEditBridgeAllowed,
    isEditFoundationAllowed,
    isDeleteCopayAllowed,
    isDeletePapAllowed,
    isDeleteQuickstartAllowed,
    isDeleteBridgeAllowed,
    isDeleteFoundationAllowed,
  ] = usePermissionsHandler([
    'copay.create',
    'pap.create',
    'quickstart.create',
    'bridge.create',
    'foundation.create',
    'copay.edit',
    'pap.edit',
    'quickstart.edit',
    'bridge.edit',
    'foundation.edit',
    'copay.delete',
    'pap.delete',
    'quickstart.delete',
    'bridge.delete',
    'foundation.delete',
  ]);

  const tabHasPermissionCreateFAProgramMapper: Record<
    Extract<AppTabs, 'copay' | 'pap' | 'quickstart' | 'bridge' | 'foundation'>,
    boolean
  > = {
    copay: isCreateCopayAllowed,
    pap: isCreatePapAllowed,
    quickstart: isCreateQuickstartAllowed,
    bridge: isCreateBridgeAllowed,
    foundation: isCreateFoundationAllowed,
  };

  const tabHasPermissionEditFAProgramMapper: Record<
    Extract<AppTabs, 'copay' | 'pap' | 'quickstart' | 'bridge' | 'foundation'>,
    boolean
  > = {
    copay: isEditCopayAllowed,
    pap: isEditPapAllowed,
    quickstart: isEditQuickstartAllowed,
    bridge: isEditBridgeAllowed,
    foundation: isEditFoundationAllowed,
  };

  const tabHasPermissionDeleteFAProgramMapper: Record<
    Extract<AppTabs, 'copay' | 'pap' | 'quickstart' | 'bridge' | 'foundation'>,
    boolean
  > = {
    copay: isDeleteCopayAllowed,
    pap: isDeletePapAllowed,
    quickstart: isDeleteQuickstartAllowed,
    bridge: isDeleteBridgeAllowed,
    foundation: isDeleteFoundationAllowed,
  };

  return {
    tabHasPermissionCreateFAProgramMapper,
    tabHasPermissionDeleteFAProgramMapper,
    tabHasPermissionEditFAProgramMapper,
  };
};
