import { AppField, AppFields } from 'components/fields/types';
import { text } from 'constants/texts';
import { stringModifier } from 'utils/strings';
import { RULES } from 'utils/validations';
import { CreateBaseBusinessRuleFields } from '../CreateBusinessRuleModal/types';

const { REQUIRED, MAX } = RULES;

export const getCreateBusinessRuleFields = (): AppFields<CreateBaseBusinessRuleFields> => [
  ruleNameField,
  ruleDescriptionField,
];

// FIELDS
export const ruleNameField: AppField<Pick<CreateBaseBusinessRuleFields, 'name'>> = {
  type: 'input',
  name: 'name',
  label: text['Business Rule Name'],
  placeholder: stringModifier(text['{0} Characters Max'], '30'),
  rules: [REQUIRED({ requiredFieldName: text['Business Rule Name'] }), MAX({ max: 30 })],
};

export const ruleDescriptionField: AppField<Pick<CreateBaseBusinessRuleFields, 'description'>> = {
  type: 'input',
  name: 'description',
  label: text['Business Rule Description'],
  placeholder: stringModifier(text['{0} Characters Maximum'], '60'),
  rules: [REQUIRED({ requiredFieldName: text['Business Rule Description'] }), MAX({ max: 60 })],
};
