import { toMilestoneTracker } from 'hooks/api/milestoneTrackers/mappers';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { queryKeys } from 'services/constants';
import { getMilestoneTrackerList } from 'services/milestoneTrackers/requests';
import { GetMilestoneTrackerListParams } from 'services/milestoneTrackers/types';
import { errorHandler } from 'utils/errorHandler';

export const useMilestoneTrackers = (params?: GetMilestoneTrackerListParams) => {
  const { programId = '' } = useParams();

  const { data, isLoading } = useQuery(
    [queryKeys.getMilestoneTrackers, params?.name, programId],
    () =>
      getMilestoneTrackerList({
        ...params,
        programId,
      }),
    {
      onError: errorHandler,
      select: (data) => data.results.map(toMilestoneTracker),
    }
  );

  return {
    milestoneTrackers: data || [],
    hasData: !!data?.length || isLoading || !!params?.name,
    isLoading: isLoading,
  };
};
