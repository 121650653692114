import { FormInstance } from 'antd';
import { AppFields } from 'components/fields/types';
import { fieldModifier } from 'components/fields/utils';
import {
  benefitRemainingField,
  benefitUsedField,
  copayRenewalField,
  fillsRemainingField,
  fillsUsedField,
  mediaCoPayCardImageField,
  medicalPatientInstructionsField,
  medicalTermsAndConditionsField,
  pharmacyCopayCardImageField,
  pharmacyPatientInstructionsField,
  pharmacyTermsAndConditionsField,
  prescriptionRefillField,
} from 'pages/ProgramDetails/financialAssistanceFields';
import {
  CopayFinancialAssistanceFields,
  GraphicFields,
  MetricsFields,
  PatientInstructionsFields,
  TermsAndConditionsFields,
} from 'pages/ProgramDetails/programTabComponents/FinancialAssistance/tabsConfig/CopayTab/types';
import { CopayFinancialAssistance } from 'types/financialAssistance';

export const getGraphicsFields = (
  assistance: CopayFinancialAssistance,
  form: FormInstance<CopayFinancialAssistanceFields>
): AppFields<GraphicFields> => [
  [
    fieldModifier(mediaCoPayCardImageField, {
      form,
      fileName: assistance.medicalImage?.mediaName || '',
      selectUploadConfig: { fileName: assistance.medicalImage?.mediaName || '' },
    }),
    fieldModifier(pharmacyCopayCardImageField, {
      form,
      fileName: assistance.pharmacyImage?.mediaName || '',
      selectUploadConfig: { fileName: assistance.pharmacyImage?.mediaName || '' },
    }),
  ],
];

export const metricsFields: AppFields<MetricsFields> = [
  [benefitRemainingField, fillsRemainingField],
  [benefitUsedField, fillsUsedField],
  [copayRenewalField, prescriptionRefillField],
];

export const patientInstructionsFields: AppFields<PatientInstructionsFields> = [
  medicalPatientInstructionsField,
  pharmacyPatientInstructionsField,
];

export const termsAndConditionsFields: AppFields<TermsAndConditionsFields> = [
  [medicalTermsAndConditionsField, pharmacyTermsAndConditionsField],
];
