import { text } from 'constants/texts';

import { ProgramData } from 'types/programs';
import { BusinessRulesTabActions } from '../programTabComponents/BusinessRules/BusinessRulesTabActions';
import { BusinessRulesTabContent } from '../programTabComponents/BusinessRules/BusinessRulesTabContent';
import { ProgramDetailsUseTabReturnType } from './types';

export const useBusinessRulesTab = (program?: ProgramData): ProgramDetailsUseTabReturnType => {
  return {
    tab: {
      title: text['Business Rules'],
      description: text['This is the repository of all business rules for this program'],
      tabData: {
        contentTitle: text['All Business Rules'],
        actionsJSX: <BusinessRulesTabActions program={program} />,
        contentJSX: <BusinessRulesTabContent />,
      },
      tabKey: 'rules',
    },
  };
};
