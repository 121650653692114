import { SVGProps } from 'react';

export const DocFileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M9.5 1.66675H3.5C3.10218 1.66675 2.72064 1.82127 2.43934 2.09632C2.15804 2.37138 2 2.74443 2 3.13341V14.8667C2 15.2557 2.15804 15.6288 2.43934 15.9038C2.72064 16.1789 3.10218 16.3334 3.5 16.3334H12.5C12.8978 16.3334 13.2794 16.1789 13.5607 15.9038C13.842 15.6288 14 15.2557 14 14.8667V6.06675L9.5 1.66675Z'
      stroke='#1D242D'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.3335 1.66675V6.33342H14.0002'
      stroke='#1D242D'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M11.3332 9.66675H4.6665' stroke='#1D242D' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M11.3332 12.3333H4.6665' stroke='#1D242D' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M5.99984 7H5.33317H4.6665' stroke='#1D242D' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);
