import { Form, FormInstance } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { Button, Spacer } from 'components';
import { BUSINESS_RULES_ACTIONS_LIMIT } from 'constants/limits';
import { text } from 'constants/texts';
import { useBusinessRulesMeta } from 'hooks/api';
import { BusinessRuleActionDynamicForm } from 'pages/ProgramDetails/programTabComponents/BusinessRules/BusinessRuleDetails/components/BusinessRuleEditContent/BusinessRuleActionDynamicForm';
import { UpdateBusinessRuleFields } from 'pages/ProgramDetails/programTabComponents/BusinessRules/components/CreateBusinessRuleModal/types';

type BusinessRuleActionsFormListProps = {
  form: FormInstance<UpdateBusinessRuleFields>;
};

export const BusinessRuleActionsFormList = ({ form }: BusinessRuleActionsFormListProps) => {
  const { businessRulesMeta } = useBusinessRulesMeta();

  const selectedActions = useWatch('actions', form);

  return (
    <Form.List name='actions'>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name }) => (
            <BusinessRuleActionDynamicForm
              key={key}
              form={form}
              orderIndex={name}
              meta={businessRulesMeta}
              remove={remove}
              selectedActions={selectedActions}
            />
          ))}
          <Spacer height={24} />
          {BUSINESS_RULES_ACTIONS_LIMIT.isMax(fields.length) && (
            <Button type='ghost' onClick={add}>
              {text['Add Another Action']}
            </Button>
          )}
        </>
      )}
    </Form.List>
  );
};
