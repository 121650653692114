import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { queryKeys } from 'services/constants';
import { getProgramEditStatus, setProgramEditStatus } from 'services/programs/programs';
import { errorHandler } from 'utils/errorHandler';
import { DevError } from 'utils/errors';

export const useProgramEditStatusConfig = (id?: string) => {
  const { programId: programParamsId } = useParams();

  const programId = id || programParamsId;

  const { mutate: setStatusMutate, isLoading: isSetStatusLoading } = useMutation(
    queryKeys.setProgramEditStatus,
    setProgramEditStatus,
    { onError: errorHandler }
  );

  const { isLoading: isGetStatusLoading, refetch: fetchProgramEditStatus } = useQuery(
    [queryKeys.getProgramEditStatus, programId],
    () => getProgramEditStatus(programId || ''),
    {
      enabled: !!programId,
    }
  );

  if (!programId)
    throw new DevError(
      'You should set "programId" param in url or "useProgramEditStatus" hook to get selected program data'
    );

  const isLoading = isGetStatusLoading || isSetStatusLoading;

  return {
    isLoading,
    setProgramEditStatus: (lock: boolean) => setStatusMutate({ id: programId!, lock }),
    getProgramEditStatus: fetchProgramEditStatus,
  };
};
