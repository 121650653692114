import { useProgramEditStatusConfig } from 'hooks/api/programs/useProgramEditStatusConfig';
import { showBlockProgramEditingToast } from 'pages/ProgramDetails/utils';
import { Callback } from 'types/helpers';

type Callbacks = Partial<{
  programId: string;
  onClose: Callback;
  onEdit: Callback;
  onSave: (() => Promise<void>) | Callback;
}>;

type ReturnType = Required<Omit<Callbacks, 'programId'>> & { isLoading: boolean };

export const useCoverProgramEditStatusCallbacks = ({
  onEdit,
  onSave,
  onClose,
  programId,
}: Callbacks = {}): ReturnType => {
  const { getProgramEditStatus, setProgramEditStatus, isLoading } = useProgramEditStatusConfig(programId);

  return {
    isLoading,
    onClose: () => {
      setProgramEditStatus(false);
      onClose?.();
    },
    onEdit: async () => {
      const { data } = await getProgramEditStatus();

      if (!data?.isCanEdit) return showBlockProgramEditingToast();

      setProgramEditStatus(true);
      onEdit?.();
    },
    onSave: () => {
      setProgramEditStatus(false);
      onSave?.();
    },
  };
};
