import { Form, Spin } from 'antd';
import useForm from 'antd/es/form/hooks/useForm';
import { Spacer } from 'components';
import { getFieldsJSX } from 'components/fields/utils';
import { scrollToFirstErrorProps } from 'constants/common';
import { text } from 'constants/texts';
import { useProgramData } from 'hooks/api';
import { useMilestoneTracker } from 'hooks/api/milestoneTrackers/useMilestoneTracker';
import { useUpdateMilestoneTracker } from 'hooks/api/milestoneTrackers/useUpdateMilestoneTracker';
import { PageLayout } from 'layouts';
import { useCoverProgramEditStatusCallbacks } from 'pages/ProgramDetails/hooks/useCoverProgramEditStatusCallbacks';
import { updateMilestoneTrackerBaseFields } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/MilestoneTrackers/MilestoneTrackerDetails/fields';
import { MilestoneTrackerDetailsActions } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/MilestoneTrackers/MilestoneTrackerDetails/MilestoneTrackerDetailsActions';
import { MilestoneTrackerStepsFormList } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/MilestoneTrackers/MilestoneTrackerDetails/MilestoneTrackerStepsFormList';
import { getUpdateMilestoneTrackerInitialValues } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/MilestoneTrackers/MilestoneTrackerDetails/utils';
import { UpdateMilestoneTrackerFields } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/MilestoneTrackers/types';
import { getProgramPagesBreadCrumb } from 'pages/Programs/breadCrumbs';
import { useState } from 'react';
import { getFontClassName } from 'utils/getFontClassName';
import { stringModifier } from 'utils/strings';

export const MilestoneTrackerDetails = () => {
  const [form] = useForm<UpdateMilestoneTrackerFields>();

  const [isEditMode, setEditMode] = useState(false);

  const { milestoneTracker, isLoading: isGetMilestoneTrackerLoading } = useMilestoneTracker();

  const { program, isLoading: isProgramLoading } = useProgramData();

  const { onSave } = useCoverProgramEditStatusCallbacks({ onSave: () => setEditMode(false) });

  const { updateMilestoneTracker, isLoading: isUpdateMilestoneTrackerLoading } = useUpdateMilestoneTracker(onSave);

  const isLoading = isGetMilestoneTrackerLoading || isProgramLoading;

  if (!program || !milestoneTracker || isLoading) return <Spin className='pl-page-spinner' size='large' />;

  const breadCrumbs = getProgramPagesBreadCrumb({
    name: 'MILESTONE_TRACKER_DETAILS',
    programName: program.programName,
    programId: program.id,
    milestoneTrackerName: milestoneTracker.name,
  });

  const rightSideActionsJSX = (
    <MilestoneTrackerDetailsActions
      milestoneTracker={milestoneTracker}
      isEditMode={isEditMode}
      setEditMode={setEditMode}
      form={form}
    />
  );

  return (
    <PageLayout
      pageTitle={milestoneTracker.name || text['Name not found']}
      rightSideActions={rightSideActionsJSX}
      crumbs={breadCrumbs}
    >
      <Spin size='large' spinning={isUpdateMilestoneTrackerLoading}>
        <h2 className={getFontClassName('h2')}>{stringModifier(text['{0} Details'], text['Milestone Tracker'])}</h2>
        <Spacer height={6} type='flex' />
        <p className={getFontClassName('paragraph', 'gray2')}>
          {stringModifier(text['These are the details of the {0}'], text['Milestone Tracker'])}
        </p>
        <Spacer height={22} type='flex' />
        <Form<UpdateMilestoneTrackerFields>
          scrollToFirstError={scrollToFirstErrorProps}
          initialValues={getUpdateMilestoneTrackerInitialValues(milestoneTracker)}
          layout='vertical'
          form={form}
          onFinish={(values) => updateMilestoneTracker(values, milestoneTracker)}
        >
          {getFieldsJSX(updateMilestoneTrackerBaseFields, isEditMode)}
          <hr />
          <MilestoneTrackerStepsFormList isEditMode={isEditMode} />
        </Form>
      </Spin>
    </PageLayout>
  );
};
