import { Dropdown, Menu } from 'antd';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { useChildrenRef, usePermissionsHandler } from 'hooks';

import { ThreeDots } from 'icons';
import { DeleteSurveyConfirmModal } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Surveys/components/DeleteSurveyConfirmModal';
import { RenameSurveyModal } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Surveys/components/RenameSurveyModal';
import { useRef } from 'react';
import { Survey } from 'types/surveys';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

export type SurveyRowActionsProps = {
  survey: Survey;
};

export const SurveyRowActions = ({ survey }: SurveyRowActionsProps) => {
  const [isDeleteSurveyAllowed, isEditSurveyAllowed] = usePermissionsHandler(['surveys.delete', 'surveys.edit']);
  const deleteModalRef = useRef<ClosableWrapperRef>();
  const [renameModalRef, setRenameModalRef] = useChildrenRef<ClosableWrapperRef>();

  const surveyMoreButtonActions: ItemType[] = [
    {
      label: 'Delete',
      onClick: deleteModalRef.current?.open,
      key: '0',
      disabled: !isDeleteSurveyAllowed,
    },
    {
      label: 'Rename',
      onClick: renameModalRef?.open,
      key: '1',
      disabled: !isEditSurveyAllowed,
    },
  ];

  const items = <Menu items={surveyMoreButtonActions} />;

  return (
    <>
      <Dropdown overlay={items} placement='bottomRight'>
        <ThreeDots />
      </Dropdown>
      <DeleteSurveyConfirmModal survey={survey} deleteModalRef={deleteModalRef} />
      <RenameSurveyModal survey={survey} onClose={renameModalRef?.close} setRenameModalRef={setRenameModalRef} />
    </>
  );
};
