import { Form } from 'antd';
import { Button, Spacer } from 'components';
import { MILESTONE_TRACKER_STEPS_LIMIT } from 'constants/limits';
import { text } from 'constants/texts';
import { MilestoneTrackerDynamicForm } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/MilestoneTrackers/MilestoneTrackerDetails/MilestoneTrackerStepDynamicForm';
import { stringModifier } from 'utils/strings';

type MilestoneTrackerStepsFormListProps = {
  isEditMode: boolean;
};

export const MilestoneTrackerStepsFormList = ({ isEditMode }: MilestoneTrackerStepsFormListProps) => (
  <Form.List name='milestones'>
    {(fields, { add, remove }) => (
      <>
        <Spacer height={26} type='flex' />
        {fields.map(({ key, name }) => (
          <MilestoneTrackerDynamicForm key={key} orderIndex={name} isEditMode={isEditMode} remove={remove} />
        ))}
        <Spacer height={8} type='flex' />
        {isEditMode && MILESTONE_TRACKER_STEPS_LIMIT.isMax(fields.length) && (
          <Button type='ghost' onClick={add}>
            {stringModifier(text['Add {0}'], text['Step'])}
          </Button>
        )}
      </>
    )}
  </Form.List>
);
