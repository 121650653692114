import { Input, InputProps } from 'antd';
import classNames from 'classnames';
import { text } from 'constants/texts';

import { Search } from 'icons';

import './style.scss';

export const SearchInput = ({ className, ...rest }: InputProps) => (
  <Input
    allowClear
    className={classNames(['pl-search-input', className])}
    size='large'
    placeholder={text['Search']}
    {...rest}
    prefix={<Search />}
  />
);
