import { API_BASE_URL } from 'services/constants';
import { GetSurveysData } from 'services/surveys/types';
import { CreateRequest, DeleteRequest, GetListRequest, UpdateRequest, ApiService, GetRequest } from 'types/apiTypes';
import { api } from 'utils/api';
import {
  CreateSurveyData,
  UpdateSurveyParams,
  GetSurveyData,
  DeleteSurveyParams,
  GetSurveysParams,
  GetSurveyValidateParams,
} from './types';

const SURVEY_ENDPOINT = `${API_BASE_URL}surveys/`;

export const {
  createSurvey,
  deleteSurvey,
  updateSurvey,
  getSurveyList,
  getSurvey,
}: ApiService<
  'survey',
  CreateRequest<GetSurveyData, CreateSurveyData> &
    GetListRequest<GetSurveysData, GetSurveysParams> &
    UpdateRequest<GetSurveyData, UpdateSurveyParams> &
    DeleteRequest<unknown, DeleteSurveyParams> &
    GetRequest<GetSurveyData, string>
> = {
  createSurvey: (params) => {
    return api.post<GetSurveyData>(SURVEY_ENDPOINT, params).then((res) => res.data);
  },
  getSurveyList: ({ search = '', programId = '' } = {}) => {
    return api
      .get<GetSurveysData>(SURVEY_ENDPOINT + `?search=${search}&program_uuid=${programId}`)
      .then((res) => res.data);
  },
  getSurvey: (id: string) => {
    return api.get<GetSurveyData>(`${SURVEY_ENDPOINT}/${id}`).then((res) => res.data);
  },
  updateSurvey: (params) => {
    return api.patch(SURVEY_ENDPOINT + params.uuid, params);
  },
  deleteSurvey: (params) => {
    return api.patch(SURVEY_ENDPOINT + params.uuid, { isDeleted: true, programUuid: params.programId });
  },
};

export const {
  getSurveyValidate,
}: ApiService<'surveyValidate', GetRequest<unknown, GetSurveyValidateParams>> = {
  getSurveyValidate: (params) => {
    return api.get(`${SURVEY_ENDPOINT}validate/title/`, { params });
  },
};
