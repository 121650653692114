import { AppField } from 'components/fields/types';
import { text } from 'constants/texts';
import { additionalServicesTooltips } from 'constants/tooltipTexts';
import { RULES } from 'utils/validations';
import {
  selectionTypeOptions,
  serviceIconOptions,
  serviceTypeOptions,
} from './programTabComponents/AdditionalServices/selectOptions';
import { AdditionalServicesFields } from './programTabComponents/AdditionalServices/types';

const { label, icon, repeatable, type, title, description, question, answers, source, selectionType } =
  additionalServicesTooltips;

const { REQUIRED } = RULES;

export const labelField: AppField<Pick<AdditionalServicesFields, 'label'>> = {
  type: 'input',
  name: 'label',
  label: 'Label',
  tooltip: label,
  rules: [REQUIRED({ requiredFieldName: 'Label' })],
};

export const iconField: AppField<Pick<AdditionalServicesFields, 'icon'>> = {
  type: 'select',
  name: 'icon',
  label: 'Icon',
  tooltip: icon,
  options: serviceIconOptions,
  rules: [REQUIRED({ requiredFieldName: 'Icon' })],
};

export const repeatableField: AppField<Pick<AdditionalServicesFields, 'repeatable'>> = {
  type: 'switch',
  name: 'repeatable',
  label: 'Repeatable',
  tooltip: repeatable,
  labels: {
    checked: 'Yes',
    unchecked: 'No',
  },
};

export const serviceTypeField: AppField<Pick<AdditionalServicesFields, 'type'>> = {
  type: 'select',
  name: 'type',
  label: 'Service Type',
  tooltip: type,
  options: serviceTypeOptions,
  rules: [REQUIRED({ requiredFieldName: 'Service Type' })],
};

export const titleField: AppField<Pick<AdditionalServicesFields, 'title'>> = {
  type: 'input',
  name: 'title',
  label: 'Title',
  placeholder: 'Enter pop up title',
  tooltip: title,
  rules: [REQUIRED({ requiredFieldName: 'Title' })],
};

export const descriptionField: AppField<Pick<AdditionalServicesFields, 'description'>> = {
  type: 'input',
  name: 'description',
  label: 'Description',
  placeholder: 'Enter pop up description text',
  tooltip: description,
  rules: [REQUIRED({ requiredFieldName: 'Description' })],
};

// To configure this file picker -> go to ....AdditionalServices/components/CreateServiceModal/CreateServiceForm.tsx
export const sourceField: AppField<Pick<AdditionalServicesFields, 'source'>> = {
  type: 'upload',
  name: 'source',
  maxSize: 10,
  label: text['Source'],
  tooltip: source,
};

export const questionField: AppField<Pick<AdditionalServicesFields, 'question'>> = {
  type: 'input',
  name: 'question',
  label: text['Question'],
  renderType: 'TextArea',
  tooltip: question,
};

export const selectionTypeField: AppField<Pick<AdditionalServicesFields, 'selectionType'>> = {
  type: 'select',
  name: 'selectionType',
  label: text['Selection Type'],
  tooltip: selectionType,
  options: selectionTypeOptions,
};

export const answersField: AppField<Pick<AdditionalServicesFields, 'answers'>> = {
  type: 'input',
  name: 'answers',
  label: 'Answer Choice',
  tooltip: answers,
};
