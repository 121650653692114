import { ServicesFields } from 'pages/ProgramDetails/programTabComponents/AdditionalServices/types';
import {
  ClientMediaData,
  ClientUpdateMediaData,
  PlUploadFile,
} from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Media/components/CreateMediaModal/types';
import { UniversalSettingsFields } from 'pages/ProgramDetails/programTabComponents/UniversalSettings/types';
import { CreateProgramFields } from 'pages/Programs/components/CreateProgramModal/types';
import {
  CreateMediaDTO,
  CreateMediaWithFormData,
  MediaDTO,
  UpdateMediaDataWithFormData,
  UpdateMediaDTO,
} from 'services/medias/types';
import {
  CreateProgramData,
  Manufacturer,
  ManufacturerDTO,
  Program,
  ServiceData,
  UniversalSettingsData,
  UpdateServiceData,
} from 'services/programs/types';
import { Media, ProgramData } from 'types/programs';
import { toFormData } from 'utils/api';
import { getDateFromUnknown } from 'utils/date';
import { determineIfIsMediaFile } from 'utils/determinations';
import { ClientService, MediaTypes } from './types';
import { ServerObjectMappers } from 'types/apiTypes';

export const programMapper = ({
  uuid,
  name,
  status,
  whiteLabelingConfiguration,
  externalName,
  phoneNumber,
  type,
  supportHours,
  safetyInformationLink,
  prescriberInformationLink,
  welcomeCardHeader,
  manufacturerId,
  welcomeCardImage,
  termsAndConditionsLink,
  contactPopUpDescription,
  contactPopUpTitle,
  welcomeCardTextBlock,
  patientCount,
  startedAt,
  manufacturerName,
}: Partial<Program>): ProgramData => ({
  id: uuid || '',
  programName: name || '',
  programType: type || '',
  registeredPatients: patientCount || 0,
  startDate: getDateFromUnknown(startedAt, null),
  status: status || 'DRAFT',
  manufacturerName: manufacturerName || '',
  details: {
    universalSettings: {
      manufacturerId: manufacturerId || '',
      brandEmblem: whiteLabelingConfiguration?.brandEmblem || null,
      brandLogo: whiteLabelingConfiguration?.logo || null,
      primaryColor: whiteLabelingConfiguration?.primaryColor || '#0f4445',
      secondaryColor: whiteLabelingConfiguration?.secondaryColor || '#00aac6',
      externalName: externalName || '',
      contactNumber: phoneNumber || '',
      contactHoursText: supportHours || '',
      safetyInformationLink: safetyInformationLink || '',
      prescriberInformationLink: prescriberInformationLink || '',
      welcomeCardHeader: welcomeCardHeader || '',
      welcomeCardImage: welcomeCardImage || null,
      welcomeCardTextBlock: welcomeCardTextBlock || '',
      contactPopupTitle: contactPopUpTitle || '',
      contactPopupDescription: contactPopUpDescription || '',
      termsAndConditionsLink: termsAndConditionsLink || '',
      // medicalCopayCard (We don't receive this field from backend)
      // pharmacyCopaycard (We don't receive this field from backend)
    },
  },
});

export const toCreateProgramMapper = ({
  programName,
  manufacturerId,
  primaryColor,
  programLogo,
  programType,
  brandEmblem,
  secondaryColor,
}: CreateProgramFields): FormData =>
  toFormData<CreateProgramData>({
    program_name: programName,
    manufacture: manufacturerId,
    program_type: programType,
    primaryColor: primaryColor || '',
    secondaryColor: secondaryColor || '',
    brandEmblem: brandEmblem?.[0]?.originFileObj,
    logo: programLogo?.[0]?.originFileObj,
  });

export const toUpdateProgramMapper = ({
  programName,
  manufacturerId,
  primaryColor,
  programLogo,
  brandEmblem,
  secondaryColor,
  welcomeCardImage,
  programType,
  contactNumber,
  contactHoursText,
  importantSafetyInformationLink,
  contactPopupTitle,
  contactPopupDescription,
  welcomeCardHeader,
  termsAndConditionsLink,
  welcomeCardText,
  prescriberInformationLink,
}: UniversalSettingsFields): FormData =>
  toFormData<UniversalSettingsData>({
    program_name: programName,
    brandEmblemUuid: determineIfIsMediaFile(brandEmblem) ? brandEmblem.id : undefined,
    logoUuid: determineIfIsMediaFile(programLogo) ? programLogo.id : undefined,
    welcomeCardImageUuid: determineIfIsMediaFile(welcomeCardImage) ? welcomeCardImage.id : undefined,
    type: programType,
    phoneNumber: contactNumber,
    supportHours: contactHoursText,
    safetyInformationLink: importantSafetyInformationLink,
    contactPopUpTitle: contactPopupTitle,
    contactPopUpDescription: contactPopupDescription,
    welcomeCardTextBlock: welcomeCardText,
    manufacturerId,
    primaryColor,
    secondaryColor,
    welcomeCardHeader,
    prescriberInformationLink,
    termsAndConditionsLink,
  });

export const serverToClientServiceMapper = (serviceData: ServiceData[]): ServicesFields => ({
  services: serviceData.map(({ type, formRequest, resource, uuid, id, physicalShipment, ...rest }) => ({
    ...(type === 'FORM_REQUEST' && {
      question: formRequest?.question.text,
      selectionType: formRequest?.question.selectType,
      answers: formRequest?.question.answers,
    }),
    ...(type === 'RESOURCE' && {
      source: {
        mediaName: resource?.media?.name || '',
        id: resource?.media?.uuid || '',
      },
    }),
    id: uuid,
    type,
    ...rest,
  })),
});

export const clientToServerServiceMapper = ({
  answers = [],
  selectionType = 'MULTI_SELECT',
  type,
  question = '',
  source,
  sourceName,
  repeatable,
  id = '',
  programUuid = '',
  ...rest
}: ClientService): UpdateServiceData => ({
  id: id,
  uuid: id,
  programUuid,
  ...rest,
  type,
  repeatable,
  ...(type === 'RESOURCE' &&
    source && {
      resource: {
        media: { uuid: source.id },
      },
    }),
  ...(type === 'FORM_REQUEST' && {
    formRequest: {
      question: {
        answers: answers,
        selectType: selectionType,
        text: question,
      },
    },
  }),
});

const fileTypeMapper = (type: PlUploadFile['type']): MediaTypes => {
  switch (type) {
    case 'image/jpeg':
    case 'image/png':
      return { type: 'IMAGE', fileType: 'png' };
    case 'application/pdf':
      return { type: 'PDF', fileType: 'pdf' };
    case 'video/mp4':
      return { type: 'VIDEO', fileType: 'mp4' };
    default:
      return { type: 'IMAGE', fileType: 'img' };
  }
};

export const mediaMapper = ({ resource, resourceName, programId }: ClientMediaData): CreateMediaWithFormData => ({
  programId,
  data: toFormData<CreateMediaDTO>({
    name: resourceName,
    description: resourceName,
    ...fileTypeMapper(resource[0].type!),
    mediaFile: resource[0].originFileObj!,
  }),
});

export const mediaDTOMapper = ({ uuid, url, name, type, programUuid }: MediaDTO): Media => ({
  id: uuid ?? '',
  programId: programUuid ?? '',
  mediaUrl: url ?? '',
  mediaName: name ?? '',
  mediaType: type ?? 'IMAGE',
});

export const serverToClientMediaMapper = (data: MediaDTO[]): Media[] => data.map(mediaDTOMapper);
export const updateMediaMapper = ({
  programId,
  mediaId,
  resourceName,
}: ClientUpdateMediaData): UpdateMediaDataWithFormData => ({
  programId,
  mediaId,
  data: toFormData<UpdateMediaDTO>({
    name: resourceName,
  }),
});

export const {
  toManufacturerSelectOptions,
  toManufacturerSelectOptionsDTO,
}: ServerObjectMappers<'manufacturerSelectOptions', ManufacturerDTO, Manufacturer> = {
  toManufacturerSelectOptions: (dto) => ({
    value: dto.id ?? '',
    label: dto.name ?? '',
  }),
  toManufacturerSelectOptionsDTO: ({}) => ({}),
};
