import { AppFields } from 'components/fields/types';
import { fieldModifier } from 'components/fields/utils';
import { text } from 'constants/texts';
import { RULES } from 'utils/validations';
import {
  descriptionField,
  iconField,
  labelField,
  questionField,
  repeatableField,
  selectionTypeField,
  serviceTypeField,
  sourceField,
  titleField,
} from '../../../../additionalServicesFields';
import { AdditionalServicesFields } from '../../types';

const { MAX, REQUIRED } = RULES;

export const getCreateServiceFields = (
  serviceType?: AdditionalServicesFields['type']
): AppFields<AdditionalServicesFields> => {
  const isFormRequestSelected = serviceType === 'FORM_REQUEST';
  const isSourceTypeSelected = serviceType === 'RESOURCE';
  const hideMainFields = isFormRequestSelected || isSourceTypeSelected;

  return [
    fieldModifier(labelField, {
      hidden: hideMainFields,
      placeholder: '255 Characters Max',
      rules: [MAX({ max: 255 })],
      maxLength: 256,
    }),
    fieldModifier(iconField, {
      label: 'Additional Service Icon',
      placeholder: 'Select an icon',
      hidden: hideMainFields,
    }),
    fieldModifier(repeatableField, { label: 'Is this item repeatable?', hidden: hideMainFields }),
    fieldModifier(serviceTypeField, {
      placeholder: 'Select a service type',
      label: 'Service Type',
      hidden: hideMainFields,
    }),
    fieldModifier(titleField, { hidden: hideMainFields }),
    fieldModifier(descriptionField, { hidden: hideMainFields }),
    fieldModifier(sourceField, {
      hidden: true,
      selectUploadConfig: {},
      rules: [REQUIRED({ requiredFieldName: text['Source'], required: hideMainFields && isSourceTypeSelected })],
    }),
    fieldModifier(questionField, {
      hidden: !isFormRequestSelected,
      renderType: 'TextArea',
      rows: 5,
      rules: [REQUIRED({ requiredFieldName: text['Question'], required: hideMainFields && isFormRequestSelected })],
    }),
    fieldModifier(selectionTypeField, {
      hidden: !isFormRequestSelected,
      rules: [
        REQUIRED({ requiredFieldName: text['Selection Type'], required: hideMainFields && isFormRequestSelected }),
      ],
    }),
  ];
};
