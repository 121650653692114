import { useMutation, useQueryClient } from 'react-query';
import { queryKeys } from 'services/constants';
import { deleteBusinessRule } from 'services/rules/requests';
import { Callback } from 'types/helpers';
import { errorHandler } from 'utils/errorHandler';

export const useDeleteBusinessRule = (id: string, onSuccess?: Callback) => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(() => deleteBusinessRule(id), {
    mutationKey: [queryKeys.deleteBusinessRule, id],
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKeys.getBusinessRules);
      onSuccess?.();
    },
    onError: errorHandler,
  });

  return {
    isLoading,
    deleteBusinessRule: mutateAsync,
  };
};
