import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { queryKeys } from 'services/constants';
import { errorHandler } from 'utils/errorHandler';
import { getNotificationTileList } from 'services/notificationTiles/requests';
import { GetNotificationTileListParams } from 'services/notificationTiles/types';
import { toNotificationTile } from './mappers';

export const useNotificationTiles = (params?: GetNotificationTileListParams) => {
  const { programId = '' } = useParams();

  const { data, isLoading } = useQuery(
    [queryKeys.getNotificationTiles, params?.search, params?.status, programId],
    () =>
      getNotificationTileList({
        ...params,
        programId,
      }),
    {
      onError: errorHandler,
      select: (data) => data.map(toNotificationTile),
    }
  );

  return {
    notificationTiles: data || [],
    hasData: !!data?.length || isLoading || !!params?.search,
    isLoading: isLoading,
  };
};
