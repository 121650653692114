import { SVGProps } from 'react';

export const RightArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4.57129 14.8572L11.4284 8.00004L4.57129 1.14289'
      stroke='#787D83'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
