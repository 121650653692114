import { Row } from 'antd';

import { Button, ConfirmModal, MoreButton, PermissionWrapper, Spacer } from 'components';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { useChildrenRef } from 'hooks';
import { showCancelEditedToast, useProgramMoreButtonActions } from 'pages/ProgramDetails/utils';
import { Callback } from 'types/helpers';
import { ProgramData } from 'types/programs';

import texts from './constants';
import { text } from 'constants/texts';
import { ConditionalWrapper } from 'components/ShowWrapper/ConditionalWrapper';

type UniversalSettingsTabActionsProps = {
  program?: ProgramData;
  onSave: Callback;
  onClose: Callback;
  onEdit: Callback;
  isEditMode: boolean;
  isLoading: boolean;
};

export const UniversalSettingsTabActions = ({
  program,
  isEditMode,
  isLoading,
  onClose,
  onSave,
  onEdit,
}: UniversalSettingsTabActionsProps) => {
  const [modalRef, setModalRef] = useChildrenRef<ClosableWrapperRef>();

  const programMoreButtonActions = useProgramMoreButtonActions(program);

  if (!program) return null;

  const onModalConfirm = () => {
    showCancelEditedToast();
    modalRef?.close();
    onClose();
  };

  return (
    <Row>
      <PermissionWrapper permissionsRequired={['universal_details.edit']}>
        <Button onClick={isEditMode ? onSave : onEdit} loading={isLoading}>
          {text[isEditMode ? 'Save' : 'Edit']}
        </Button>
      </PermissionWrapper>
      <Spacer width={16} />
      <ConditionalWrapper condition={isEditMode}>
        <Button type='ghost' onClick={modalRef?.open}>
          {text['Cancel']}
        </Button>
      </ConditionalWrapper>
      <ConditionalWrapper condition={!isEditMode}>
        <MoreButton options={programMoreButtonActions} />
      </ConditionalWrapper>
      <ConfirmModal
        title={texts.cancelModalTitle}
        description={texts.cancelModalDescription}
        cancelTextBtn={texts.cancelCancelButtonText}
        confirmTextBtn={texts.cancelConfirmButtonText}
        modalRef={setModalRef}
        cancelHandler={modalRef?.close}
        confirmHandler={onModalConfirm}
      />
    </Row>
  );
};
