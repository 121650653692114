import { fieldModifier } from 'components/fields/utils';
import { text } from 'constants/texts';
import { payloadTerminationCriteriaField } from 'pages/ProgramDetails/programTabComponents/FinancialAssistance/tabsConfig/FinancialAssistanceTab/fields';
import { FinancialAssistanceFields } from 'pages/ProgramDetails/programTabComponents/FinancialAssistance/tabsConfig/FinancialAssistanceTab/types';
import { FinancialAssistanceTerminationCriteriaType } from 'services/financialAssistance/types';
import { FinancialAssistance } from 'types/financialAssistance';
import { getInputPlaceholder, getSelectPlaceholder, stringModifier } from 'utils/strings';
import { disablePastDaysValidator, RULES } from 'utils/validations';

const { REQUIRED, NON_ZERO } = RULES;

export const getPayloadTerminationCriteriaField = (criteria: FinancialAssistanceTerminationCriteriaType) => {
  switch (criteria) {
    case 'DATE_BASED':
      return fieldModifier(payloadTerminationCriteriaField, {
        type: 'date-picker',
        rules: [REQUIRED()],
        format: 'MMMM dd, yyyy',
        disabledDate: disablePastDaysValidator,
        tooltip: text['The date of termination for a financial program for a patient'],
        label: text['Date'],
        placeholder: getSelectPlaceholder(text['Date'], 'a'),
      });
    case 'MONEY_BASED':
      return fieldModifier(payloadTerminationCriteriaField, {
        type: 'input',
        prefix: '$',
        renderType: 'NUMBER',
        numberInputConfig: { canBeFloat: true },
        rules: [REQUIRED(), NON_ZERO()],
        tooltip: text['The amount of money spent by a patient that will terminate a financial program'],
        label: text['Money Spent'],
        placeholder: stringModifier(text['Enter {0}'], text['Amount of Money']),
      });
    case 'ELAPSED_TIME':
      return fieldModifier(payloadTerminationCriteriaField, {
        type: 'input',
        rules: [REQUIRED(), NON_ZERO()],
        renderType: 'NUMBER',
        numberInputConfig: { canBeFloat: true },
        tooltip: text['The number of days to elapse before a program is terminated for a patient'],
        label: text['Number of Days'],
        placeholder: getInputPlaceholder(text['Number of Days']),
      });
    case 'FILL_BASED':
      return fieldModifier(payloadTerminationCriteriaField, {
        type: 'input',
        renderType: 'NUMBER',
        rules: [REQUIRED(), NON_ZERO()],
        tooltip:
          text[
            'The number of prescription fills a patient can received before the financial program is terminated for that patient'
          ],
        label: text['Number of Fills'],
        placeholder: getInputPlaceholder(text['Number of Fills']),
      });
    default:
      return fieldModifier(payloadTerminationCriteriaField, { hidden: true });
  }
};

export const toFinancialAssistanceFields = (assistance: FinancialAssistance): FinancialAssistanceFields => ({
  name: assistance.name,
  description: assistance.description,
  introductionDescription: assistance.introductionDescription,
  introductionTitle: assistance.introductionTitle,
  terminationCriteria: assistance.terminationCriteria,
  terminationPayload: assistance.terminationPayload,
  link: assistance.link,
  image: assistance.image,
});
