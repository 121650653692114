type SpacerProps = Partial<{
  width: number;
  height: number | string;
  type: 'inline-flex' | 'flex';
  className: string;
}>;

export const Spacer = ({ width = 0, height = 0, type = 'inline-flex', className }: SpacerProps) => {
  return (
    <div
      className={className}
      style={{
        width,
        minWidth: width,
        maxWidth: width,
        height,
        minHeight: height,
        maxHeight: height,
        display: type,
      }}
    />
  );
};
