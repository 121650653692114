import { mediaDTOMapper } from 'hooks/api/programs/mappers';
import {
  CopayFinancialAssistanceDTO,
  FinancialAssistanceDTO,
  FinancialAssistanceTerminationCriteriaType,
} from 'services/financialAssistance/types';
import { ServerObjectMappers } from 'types/apiTypes';
import { CopayFinancialAssistance, FinancialAssistance } from 'types/financialAssistance';
import { getDateFromUnknown } from 'utils/date';
import { removeEmpty } from 'utils/object';

export const {
  toCopayFinancialAssistance,
  toCopayFinancialAssistanceDTO,
}: ServerObjectMappers<'copayFinancialAssistance', CopayFinancialAssistanceDTO, CopayFinancialAssistance> = {
  toCopayFinancialAssistance: (dto) => ({
    type: dto.type ?? 'COPAY',
    uuid: dto.uuid ?? '',
    isBenefitsRemaining: dto.isBenefitsRemaining ?? false,
    isCopayRenewal: dto.isCopayRenewal ?? false,
    isBenefitsUsed: dto.isBenefitsUsed ?? false,
    isPrescriptionRefill: dto.isPrescriptionRefill ?? false,
    isFillsRemaining: dto.isFillsRemaining ?? false,
    isFillsUsed: dto.isFillsUsed ?? false,
    medicalPatientInstructions: dto.medicalPatientInstructions ?? '',
    pharmacyPatientInstructions: dto.pharmacyPatientInstructions ?? '',
    medicalTermsAndConditions: dto.medicalTermsAndConditions ?? '',
    pharmacyTermsAndConditions: dto.pharmacyTermsAndConditions ?? '',
    medicalImage: dto.medicalImage ? mediaDTOMapper(dto.medicalImage) : null,
    pharmacyImage: dto.pharmacyImage ? mediaDTOMapper(dto.pharmacyImage) : null,
    introductionDescription: dto.introductionDescription ?? '',
    introductionTitle: dto.introductionTitle ?? '',
    terminationCriteria: dto.terminationCriteriaLabel ?? null,
    ...toTerminationPayload(dto, {
      type: dto.terminationCriteriaLabel ?? null,
    }),
  }),
  toCopayFinancialAssistanceDTO: (instance) => ({
    type: instance.type ?? 'COPAY',
    uuid: instance.uuid,
    isFillsUsed: instance.isFillsUsed,
    isFillsRemaining: instance.isFillsRemaining,
    isPrescriptionRefill: instance.isPrescriptionRefill,
    isBenefitsUsed: instance.isBenefitsUsed,
    isCopayRenewal: instance.isCopayRenewal,
    isBenefitsRemaining: instance.isBenefitsRemaining,
    medicalPatientInstructions: instance.medicalPatientInstructions,
    pharmacyPatientInstructions: instance.pharmacyPatientInstructions,
    medicalTermsAndConditions: instance.medicalTermsAndConditions,
    pharmacyTermsAndConditions: instance.pharmacyTermsAndConditions,
    introductionDescription: instance.introductionDescription,
    introductionTitle: instance.introductionTitle,
    terminationCriteriaLabel: instance.terminationCriteria,
    ...toTerminationPayloadDTO(instance, {
      type: instance.terminationCriteria ?? null,
    }),
    ...(!!instance.medicalImage?.id && { medicalImage: { uuid: instance.medicalImage?.id } }),
    ...(!!instance.pharmacyImage?.id && { pharmacyImage: { uuid: instance.pharmacyImage?.id } }),
  }),
};

export const {
  toFinancialAssistance,
  toFinancialAssistanceDTO,
}: ServerObjectMappers<'financialAssistance', FinancialAssistanceDTO, FinancialAssistance> = {
  toFinancialAssistance: (dto) => ({
    uuid: dto.uuid ?? '',
    manufactureProgramUuid: dto.manufactureProgramUuid ?? '',
    type: dto.type ?? 'PAP',
    name: dto.name ?? '',
    link: dto.link ?? '',
    description: dto.description ?? '',
    introductionDescription: dto.introductionDescription ?? '',
    introductionTitle: dto.introductionTitle ?? '',
    terminationCriteria: dto.terminationCriteriaLabel ?? null,
    image: dto.image ? mediaDTOMapper(dto.image) : null,
    ...toTerminationPayload(dto, {
      type: dto.terminationCriteriaLabel ?? null,
    }),
  }),
  toFinancialAssistanceDTO: (instance) =>
    removeEmpty(
      {
        uuid: instance.uuid,
        manufactureProgramUuid: instance.manufactureProgramUuid,
        type: instance.type,
        name: instance.name,
        description: instance.description,
        introductionDescription: instance.introductionDescription,
        introductionTitle: instance.introductionTitle,
        terminationCriteriaLabel: instance.terminationCriteria,
        link: instance.link,
        ...toTerminationPayloadDTO(instance, {
          type: instance.terminationCriteria ?? null,
        }),
        ...(instance.image?.id && { image: { uuid: instance.image?.id } }),
      },
      ['']
    ),
};

const {
  toTerminationPayload,
  toTerminationPayloadDTO,
}: ServerObjectMappers<
  'terminationPayload',
  Pick<FinancialAssistanceDTO, 'terminationCriteriaValue'>,
  Pick<FinancialAssistance, 'terminationPayload'>,
  { type: FinancialAssistanceTerminationCriteriaType },
  { type: FinancialAssistanceTerminationCriteriaType }
> = {
  toTerminationPayload: (dto, { type }) => {
    switch (type) {
      case 'DATE_BASED':
        return { terminationPayload: getDateFromUnknown(dto) };
      case 'MONEY_BASED':
      case 'ELAPSED_TIME':
      case 'FILL_BASED':
        return { terminationPayload: +(dto.terminationCriteriaValue || 1) };
      case null:
      case 'API_BASED':
        return { terminationPayload: null };
      default:
        return { terminationPayload: `${dto.terminationCriteriaValue}` };
    }
  },
  toTerminationPayloadDTO: (instance, { type }) => {
    switch (type) {
      case 'DATE_BASED':
        return { terminationCriteriaValue: getDateFromUnknown(instance.terminationPayload).toISOString() };
      case 'MONEY_BASED':
      case 'ELAPSED_TIME':
      case 'FILL_BASED':
        return { terminationCriteriaValue: +(instance.terminationPayload || 1) };
      case null:
      case 'API_BASED':
        return { terminationCriteriaValue: undefined };
      default:
        return { terminationCriteriaValue: `${instance.terminationPayload}` };
    }
  },
};
