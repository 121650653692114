import { useBenefitsTab } from 'pages/ProgramDetails/tabsConfig/useBenefitsTab';
import { useBusinessRulesTab } from 'pages/ProgramDetails/tabsConfig/useBusinessRulesTab';
import { useContentTab } from 'pages/ProgramDetails/tabsConfig/useContentTab';
import { ProgramDetailsUseTabsReturnType, ProgramDetailsUseTabsReturnTypeConfig } from './types';
import { useAdditionalServicesTab } from './useAdditionalServicesTab';
import { useFinancialAssistanceTab } from './useFinancialAssistanceTab';
import { useSpecialtyPharmacyTab } from './useSpecialtyPharmacyTab';
import { useUniversalSettingsTab } from './useUniversalSettingsTab';

export const useProgramDetailsTabs = ({
  program,
}: ProgramDetailsUseTabsReturnTypeConfig): ProgramDetailsUseTabsReturnType => {
  const universalSettingTab = useUniversalSettingsTab(program);
  const benefitsTab = useBenefitsTab(program);
  const additionalServicesTab = useAdditionalServicesTab(program);
  const businessRulesTab = useBusinessRulesTab(program);
  const financialAssistanceTab = useFinancialAssistanceTab();
  const specialtyPharmacy = useSpecialtyPharmacyTab(program);
  const contentTab = useContentTab();

  return {
    tabs: [
      universalSettingTab.tab,
      financialAssistanceTab.tab,
      benefitsTab.tab,
      {
        tabData: { actionsJSX: null, contentJSX: null, contentTitle: 'Rebate Center' },
        title: 'Rebate Center',
        description: 'These settings impact the Rebate Center segment in the instance of the application',
        tabKey: 'rebate-center',
      }, // TODO: Add Content Component
      additionalServicesTab.tab,
      businessRulesTab.tab,
      specialtyPharmacy.tab,
      contentTab.tab,
    ],
    extra: {
      isTabsDisabled: Boolean(
        universalSettingTab.extra?.isUniversalSettingEditMode ||
          financialAssistanceTab.extra?.isFinancialAssistanceEditMode ||
          additionalServicesTab.extra?.isAdditionalServicesEditMode ||
          benefitsTab.extra?.isBenefitTabEditMode
      ),
    },
  };
};
