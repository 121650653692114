import { FormInstance } from 'antd';
import { AppField, AppFields } from 'components/fields/types';
import { fieldModifier } from 'components/fields/utils';
import { text } from 'constants/texts';
import { criteriaOptions } from 'pages/ProgramDetails/programTabComponents/FinancialAssistance/tabsConfig/FinancialAssistanceTab/selectOptions';
import {
  FinancialAssistanceFields,
  ProgramInformationFields,
  ProgramIntroductionFields,
  TerminationCriteriaFields,
} from 'pages/ProgramDetails/programTabComponents/FinancialAssistance/tabsConfig/FinancialAssistanceTab/types';
import { getPayloadTerminationCriteriaField } from 'pages/ProgramDetails/programTabComponents/FinancialAssistance/tabsConfig/FinancialAssistanceTab/utils';
import { imageSelectUploadConfig } from 'pages/ProgramDetails/universalSettingsFields';
import { FinancialAssistanceTerminationCriteriaType } from 'services/financialAssistance/types';
import { getInputPlaceholder, getSelectPlaceholder } from 'utils/strings';
import { RULES } from 'utils/validations';

export const getProgramInformationFields = (
  form: FormInstance<FinancialAssistanceFields>,
  fields?: FinancialAssistanceFields
): AppFields<ProgramInformationFields> => [
  [
    financialProgramNameField,
    fieldModifier(financialProgramImageField, {
      form,
      fileName: fields?.image?.mediaName || '',
      selectUploadConfig: {
        ...imageSelectUploadConfig,
        placeholder: getSelectPlaceholder(text['Financial Program Image']),
        fileName: fields?.image?.mediaName || '',
      },
    }),
  ],
  financialProgramLinkField,
  financialProgramDescriptionField,
];

export const getProgramIntroductionFields = (): AppFields<ProgramIntroductionFields> => [
  [introductionTitleField, introductionDescriptionField],
];

export const getTerminationCriteriaFields = (
  selectedCriteria: FinancialAssistanceTerminationCriteriaType
): AppFields<TerminationCriteriaFields> => [
  [terminationCriteriaField, getPayloadTerminationCriteriaField(selectedCriteria)],
];

// FIELDS
const { LINK, REQUIRED, MAX } = RULES;

const financialProgramNameField: AppField<Pick<ProgramInformationFields, 'name'>> = {
  type: 'input',
  name: 'name',
  placeholder: getInputPlaceholder(text['Financial Program Name']),
  label: text['Financial Program Name'],
  tooltip: text['The name of the Financial Program that will appear to the patient'],
  rules: [REQUIRED({ requiredFieldName: text['Financial Program Name'] }), MAX({ max: 150 })],
};

const financialProgramImageField: AppField<Pick<ProgramInformationFields, 'image'>> = {
  type: 'upload',
  name: 'image',
  selectUploadConfig: {},
  cropper: { sizes: { width: 400, height: 260 } },
  fileTypes: ['PNG', 'JPG'],
  loadFileType: 'images',
  label: text['Financial Program Image'],
  tooltip: text["An image for the Financial Program. If you don't add an image, no image will appear to the patient"],
};

const financialProgramLinkField: AppField<Pick<ProgramInformationFields, 'link'>> = {
  type: 'input',
  name: 'link',
  placeholder: getInputPlaceholder(text['Financial Program Link']),
  label: text['Financial Program Link'],
  tooltip: text['An external link for more information around the Financial Program'],
  rules: [LINK()],
};

const financialProgramDescriptionField: AppField<Pick<ProgramInformationFields, 'description'>> = {
  type: 'input',
  name: 'description',
  renderType: 'TextArea',
  rows: 4,
  placeholder: getInputPlaceholder(text['Financial Program Description']),
  label: text['Financial Program Description'],
  tooltip: text['A description of the Financial Program that will appear to the patient'],
  rules: [REQUIRED({ requiredFieldName: text['Financial Program Description'] }), MAX({ max: 2000 })],
};

const introductionTitleField: AppField<Pick<ProgramIntroductionFields, 'introductionTitle'>> = {
  type: 'input',
  name: 'introductionTitle',
  placeholder: getInputPlaceholder(text['Introduction Title']),
  label: text['Introduction Title'],
  tooltip: text['The title that will appear on this Financial Programs introduction pop up and notification card'],
  rules: [REQUIRED({ requiredFieldName: text['Introduction Title'] }), MAX({ max: 150 })],
};

const introductionDescriptionField: AppField<Pick<ProgramIntroductionFields, 'introductionDescription'>> = {
  type: 'input',
  renderType: 'TextArea',
  rows: 2,
  name: 'introductionDescription',
  placeholder: getInputPlaceholder(text['Introduction Description']),
  label: text['Introduction Description'],
  tooltip:
    text['The description that will appear on this Financial Programs introduction pop up and notification card'],
  rules: [REQUIRED({ requiredFieldName: text['Introduction Description'] }), MAX({ max: 150 })],
};

const terminationCriteriaField: AppField<Pick<TerminationCriteriaFields, 'terminationCriteria'>> = {
  type: 'select',
  name: 'terminationCriteria',
  options: criteriaOptions,
  placeholder: getInputPlaceholder(text['Termination Criteria']),
  label: text['Program Termination Criteria'],
  tooltip: text['The criteria that will cause the Financial Program to end for an enrolled patient'],
};

export const payloadTerminationCriteriaField: AppField<Pick<TerminationCriteriaFields, 'terminationPayload'>> = {
  type: 'input',
  name: 'terminationPayload',
};
