import { useSearchParams } from 'react-router-dom';
import { useBusinessRulesMeta } from './useBusinessRulesMeta';
import { useQuery } from 'react-query';
import { queryKeys } from 'services/constants';
import { getBusinessRule } from 'services/rules/requests';
import { toBusinessRule } from './mappers';
import { BusinessRule } from 'types/rules';
import { RULE_ID_PARAM } from 'routes/navigators';

export const useBusinessRule = (uuid?: string) => {
  const [params] = useSearchParams();

  const businessRuleId = uuid || params.get(RULE_ID_PARAM) || '';

  const { businessRulesMeta, isLoading: isBusinessRuleMetaLoading } = useBusinessRulesMeta();

  const { data, isLoading: isBusinessRulesLoading } = useQuery(
    [queryKeys.getBusinessRules, businessRuleId],
    () => getBusinessRule(businessRuleId),

    {
      enabled: !!businessRulesMeta,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      select: (response) => (businessRulesMeta ? toBusinessRule(response, businessRulesMeta) : ({} as BusinessRule)),
    }
  );

  const isLoading = isBusinessRulesLoading || isBusinessRuleMetaLoading;

  return {
    isLoading,
    businessRule: data,
  };
};
