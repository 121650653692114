import { ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import { Row } from 'antd';
import { Spacer } from 'components';
import { useBusinessRulesMeta } from 'hooks/api';
import { useMilestoneTrackers } from 'hooks/api/milestoneTrackers/useMilestoneTrackers';
import { useServices } from 'hooks/api/programs/useServices';
import { useCommunicationTemplates } from 'hooks/api/rules/useCommunicationTemplates';
import { useNotificationTiles } from 'hooks/api/notificationTiles/useNotificationTiles';
import { useSurveys } from 'hooks/api/surveys/useSurveys';
import { BusinessRuleAction } from 'types/rules';
import { getFontClassName } from 'utils/getFontClassName';
import { useSpecialtyPharmacyList } from 'hooks/api/specialtyPharmacy/useSpecialtyPharmacyList';

type BusinessRuleActionDetailsProps = {
  action: BusinessRuleAction;
};

export const BusinessRuleActionDetails = ({ action }: BusinessRuleActionDetailsProps) => {
  const label = useActionPayloadLabel(action);

  return (
    <>
      <Row>
        <p className={getFontClassName('paragraph')}>{action.label}</p>
        <Spacer width={24} />
        <div className={getFontClassName('paragraph')}>{label}</div>
      </Row>
      <Spacer height={16} />
    </>
  );
};

const useActionPayloadLabel = ({ payload, key }: BusinessRuleAction): ReactNode => {
  // Loading presented in BusinessRuleDetails.tsx
  const { templates: smsTemplates } = useCommunicationTemplates('sms');
  const { templates: pushTemplates } = useCommunicationTemplates('push');
  const { templates: emailTemplates } = useCommunicationTemplates('email');
  const { templates: smsKeywordTemplates } = useCommunicationTemplates('sms_keyword');
  const { notificationTiles } = useNotificationTiles();
  const { surveys } = useSurveys();
  const { milestoneTrackers } = useMilestoneTrackers();
  const { businessRulesMeta } = useBusinessRulesMeta();
  const { specialtyPharmacyList } = useSpecialtyPharmacyList();

  const { programId = '' } = useParams();
  const { data: servicesData } = useServices(programId);

  switch (key) {
    case 'SURVEY_INIT':
      return surveys.find((survey) => survey.id === payload?.surveyId)?.name ?? '';
    case 'UPDATE_MILESTONE':
      return milestoneTrackers?.find((tracker) => tracker.uuid === payload?.milestoneTrackerId)?.name ?? '';
    case 'SMS':
      return smsTemplates.find((template) => template.id === payload?.smsTemplateId)?.label ?? '';
    case 'PUSH_NOTIFICATION':
      return pushTemplates.find((template) => template.id === payload?.pushNotificationTemplateId)?.label ?? '';
    case 'NOTIFICATION_TILE_SIMPLE_TEXT':
      return (
        notificationTiles.find((notificationTile) => notificationTile.id === payload?.notificationTileId)?.name ?? ''
      );
    case 'ADDITIONAL_SERVICE_INIT':
      return servicesData?.services.find((service) => service.id === payload?.additionalServiceUuid)?.label ?? '';
    case 'EMAIL':
      return emailTemplates.find((template) => template.id === payload?.emailTemplateId)?.label ?? '';
    case 'POSTPONED_EVENT_INIT':
      return businessRulesMeta?.attributes.find((rule) => rule.key === payload?.timeBaseTriggerActions)?.label ?? '';
    case 'CONTACT_CARD':
      return (
        specialtyPharmacyList?.find((pharmacy) => pharmacy.uuid === payload?.pharmacyId)?.specialtyPharmacyName ?? ''
      );
    case 'SMS_KEYWORD':
      return smsKeywordTemplates.find((template) => template.id === payload?.keywordTemplateId)?.label ?? '';
    default:
      return '';
  }
};
