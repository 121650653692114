import { showSaveEditedProgramToast } from 'pages/ProgramDetails/utils';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { queryKeys } from 'services/constants';
import { updateSpecialtyPharmacy } from 'services/specialtyPharmacies/requests';
import { Callback } from 'types/helpers';
import { errorHandler } from 'utils/errorHandler';
import { toSpecialtyPharmacyDTO } from './mappers';

export const useUpdateSpecialtyPharmacy = (pharmacyId = '', onSuccess?: Callback) => {
  const queryClient = useQueryClient();

  const { programId = '' } = useParams();

  const { mutateAsync, isLoading } = useMutation(updateSpecialtyPharmacy, {
    mutationKey: queryKeys.updateSpecialtyPharmacy,
    onSuccess: () => {
      onSuccess?.();
      showSaveEditedProgramToast();
      queryClient.invalidateQueries(queryKeys.getSpecialtyPharmacies);
    },
    onError: errorHandler,
  });

  return {
    isLoading,
    updateSpecialtyPharmacy: (pharmacy) =>
      mutateAsync({
        programId,
        pharmacyId,
        data: toSpecialtyPharmacyDTO(pharmacy),
      }),
  };
};
