import { useAuth, usePermissionsHandler } from 'hooks';
import { AppLayout } from 'layouts';
import { AdminDetails, Admins, ApiMappings, Login, PatientSearch, ProgramDetails, Programs } from 'pages';
import { SetPassword } from 'pages/AuthPages/SetPassword';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';

export const AppRouter = () => {
  const { authenticated } = useAuth();
  const [isViewReportsAllowed, isViewProgramsAllowed, isViewAdminsAllowed, isViewAdminDetailsAllowed] =
    usePermissionsHandler(['reports.view', 'programs.view', 'admins.view', 'user_info.view']);

  if (!authenticated)
    return (
      <Router>
        <Routes>
          <Route path='/login' element={<Login />} />;
          <Route path='/set-password' element={<SetPassword registration />} />;
          <Route path='/reset-password' element={<SetPassword />} />;
          <Route path='*' element={<Navigate to='/login' replace />} />
        </Routes>
      </Router>
    );

  return (
    <Router>
      <AppLayout>
        <Routes>
          {isViewProgramsAllowed && <Route path='/programs' element={<Programs />} />}
          {isViewProgramsAllowed && <Route path='/programs/:programId' element={<ProgramDetails />} />}
          {isViewAdminsAllowed && <Route path='/admins' element={<Admins />} />}
          {isViewAdminDetailsAllowed && <Route path='/admins/:adminId' element={<AdminDetails />} />}
          <Route path='/mappings' element={<ApiMappings />} />
          {isViewReportsAllowed && <Route path='/patient-search' element={<PatientSearch />} />}
          <Route path='*' element={<Navigate to={isViewProgramsAllowed ? '/programs' : '/patient-search'} replace />} />
        </Routes>
      </AppLayout>
    </Router>
  );
};
