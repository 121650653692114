import { Button, PermissionWrapper } from 'components';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { useChildrenRef } from 'hooks';
import { text } from 'constants/texts';
import { CreateNotificationTileModal } from './components/CreateNotificationTileModal/CreateNotificationTileModal';
import { stringModifier } from 'utils/strings';

export const NotificationTilesActions = () => {
  const [modalRef, setModalRef] = useChildrenRef<ClosableWrapperRef>();

  return (
    <PermissionWrapper permissionsRequired={['notification-tiles.create']}>
      <Button onClick={modalRef?.open}>{stringModifier(text['Add {0}'], text['Notification Tile'])}</Button>
      <CreateNotificationTileModal modalRef={modalRef} setModalRef={setModalRef} />
    </PermissionWrapper>
  );
};
