import { Button, PermissionWrapper } from 'components';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { text } from 'constants/texts';
import { useChildrenRef } from 'hooks';
import { CreateMilestoneTrackerModal } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/MilestoneTrackers/components/CreateMilestoneTrackerModal/CreateMilestoneTrackerModal';
import { ProgramData } from 'types/programs';
import { stringModifier } from 'utils/strings';

type MilestoneTrackersActionsProps = {
  program?: ProgramData;
};

export const MilestoneTrackersActions = ({ program }: MilestoneTrackersActionsProps) => {
  const [modalRef, setModalRef] = useChildrenRef<ClosableWrapperRef>();

  return (
    <PermissionWrapper permissionsRequired={['milestone_trackers.create']}>
      <Button onClick={modalRef?.open}>{stringModifier(text['Add {0}'], text['Milestone Tracker'])}</Button>
      <CreateMilestoneTrackerModal modalRef={modalRef} setModalRef={setModalRef} program={program} />
    </PermissionWrapper>
  );
};
