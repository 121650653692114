import { AdditionalServicesFields } from 'pages/ProgramDetails/programTabComponents/AdditionalServices/types';
import { UniversalSettingsFields } from 'pages/ProgramDetails/programTabComponents/UniversalSettings/types';

type TooltipMapping<T> = Record<keyof T, string>;

type GeneralProgramFieldsTooltipMapping = TooltipMapping<UniversalSettingsFields>;
type AdditionalServicesTooltipMapping = TooltipMapping<AdditionalServicesFields>;

export const generalProgramFieldsTooltips: GeneralProgramFieldsTooltipMapping = {
  manufacturerId: 'The name of the manufacturer of the drug for the program.',
  programType: 'Type of the program',
  programName: 'The name of the program',
  brandEmblem: 'The full logo of the program',
  programLogo: 'The icon or emblem mark associated with the program',
  primaryColor: 'The primary color used for the program’s branding',
  secondaryColor: 'The secondary color used for the program’s branding',
  contactNumber: 'The phone number that patients can call to get help with program related questions',
  contactHoursText: 'A text box that explains the hours of operations for the program contact number',
  importantSafetyInformationLink: 'The link to the program’s important safety information',
  prescriberInformationLink: 'The link to the program’s prescriber information',
  welcomeCardHeader: 'The title that is presented on the program’s welcome card on the patient’s landing screen',
  welcomeCardImage: 'The image that is presented on the program’s welcome card on the patient’s landing screen',
  welcomeCardText:
    'The description text that is presented on the program’s welcome card on the patient’s landing screen',
  contactPopupTitle:
    'The title on the pop up that appears when a patient clicks on the contact button in the help section of the program',
  contactPopupDescription:
    'The description text on the pop up that appears when a patient clicks on the contact button in the help section of the program',
  termsAndConditionsLink: 'The link to the program’s terms and conditions',
};

export const additionalServicesTooltips: AdditionalServicesTooltipMapping = {
  label: 'The text that will appear as the title of the additional service on the additional service page',
  icon: 'The icon that will represent the content of the additional service on the addition service page',
  repeatable: 'If an additional service can be acted on more than once.',
  type: 'The type of additional service',
  title: 'The title that appears on the pop up when the additional service is opened by the patient',
  description: 'A text block used to describe the additional service to the user on the additional service pop up',
  source: 'The source of the resource type',
  question: 'Question text',
  selectionType: 'Type of selection',
  answers: 'The answer choices of the question',
  sourceName: 'Source Name',
};
