import { Spacer } from 'components';
import { ConditionalWrapper } from 'components/ShowWrapper/ConditionalWrapper';
import { text } from 'constants/texts';
import { BusinessRuleActionDetails } from 'pages/ProgramDetails/programTabComponents/BusinessRules/BusinessRuleDetails/components/BusinessRuleActionDetails/BusinessRuleActionDetails';
import { BusinessRuleConditionDetails } from 'pages/ProgramDetails/programTabComponents/BusinessRules/BusinessRuleDetails/components/BusinessRuleConditionDetails/BusinessRuleConditionDetails';
import { BusinessRule } from 'types/rules';
import { getFontClassName } from 'utils/getFontClassName';

type BusinessRuleDetailsContentProps = {
  businessRule: BusinessRule;
};

export const BusinessRuleDetailsContent = ({ businessRule }: BusinessRuleDetailsContentProps) => (
  <>
    <ConditionalWrapper condition={!!businessRule.trigger}>
      <h2 className={getFontClassName('h2')}>{text['Trigger']}</h2>
      <Spacer height={24} type='flex' />
      <label className={getFontClassName('label')}>{text['Detected Message']}</label>
      <Spacer height={8} type='flex' />
      <p className={getFontClassName('paragraph')}>{businessRule.triggerLabel}</p>
      <Spacer height={18} type='flex' />
    </ConditionalWrapper>
    <ConditionalWrapper condition={!!businessRule.conditions.and.length}>
      <h2 className={getFontClassName('h2')}>{text['Condition']}</h2>
      <Spacer height={24} type='flex' />
      {Object.values(businessRule.conditions.and).map((condition, index) => (
        <BusinessRuleConditionDetails key={index} condition={condition} withAndCondition={!!index} />
      ))}
      <Spacer height={18} type='flex' />
    </ConditionalWrapper>
    <ConditionalWrapper condition={!!businessRule.actions?.length}>
      <Spacer height={6} type='flex' />
      <h2 className={getFontClassName('h2')}>{text['Action']}</h2>
      <Spacer height={18} type='flex' />
      {businessRule.actions.map((action, index) => (
        <BusinessRuleActionDetails key={index} action={action} />
      ))}
    </ConditionalWrapper>
  </>
);
