import { ApiMapping, PatientInfo, Programs, Users } from '../../icons';
import { MenuProps } from 'antd';
import React from 'react';
import { usePermissionsHandler } from '../../hooks';

type MenuItem = Required<MenuProps>['items'][number];

const getItem = (label: React.ReactNode, key: React.Key, icon?: React.ReactNode): MenuItem => ({
  key,
  icon,
  label,
});

export const useMenuItems = (): MenuItem[] => {
  const [isViewProgramsAllowed, isViewAdminsAllowed, isViewReportsAllowed] = usePermissionsHandler([
    'programs.view',
    'admins.view',
    'reports.view',
  ]);

  return [
    isViewProgramsAllowed ? getItem('Programs', '/programs', <Programs />) : null,
    isViewAdminsAllowed ? getItem('Admins', '/admins', <Users />) : null,
    getItem('Api Mapping', '/mappings', <ApiMapping />),
    isViewReportsAllowed ? getItem('Patient Search', '/patient-search', <PatientInfo />) : null,
  ];
};
