import { Spin } from 'antd';

import { ColumnsType } from 'antd/es/table';
import { DataTable, SearchInput, Spacer } from 'components';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { EmptyDataListComponent } from 'components/EmptyDataListComponent/EmptyDataListComponent';
import { stopPropagationOnCell } from 'constants/common';
import { text } from 'constants/texts';
import { useChildrenRef, usePermissionsHandler, useSearch } from 'hooks';
import { useSurveys } from 'hooks/api/surveys/useSurveys';
import {
  CreateSurveyModal,
  SurveyRowActions,
} from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Surveys/components';
import { statusRenderer } from 'pages/shared';
import { useEffect } from 'react';
import { useGoToSurveyDetailsPage } from 'routes/navigators';
import { ProgramData } from 'types/programs';
import { Survey } from 'types/surveys';
import { stringModifier } from 'utils/strings';
import { ConditionalWrapper } from 'components/ShowWrapper/ConditionalWrapper';

type SurveyContentProps = {
  program?: ProgramData;
};

const columns: ColumnsType<Survey> = [
  {
    width: '50%',
    title: 'Survey Name',
    dataIndex: 'name',
    defaultSortOrder: 'ascend',
    className: 'name-text',
  },
  {
    title: 'Number of Questions',
    dataIndex: 'questionsCount',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: statusRenderer,
  },
  {
    title: '',
    dataIndex: 'showMore',
    render: (_, record: Survey) => <SurveyRowActions survey={record} />,
    onCell: stopPropagationOnCell,
  },
];

export const SurveysContent = ({ program }: SurveyContentProps) => {
  const [modalRef, setModalRef] = useChildrenRef<ClosableWrapperRef>();
  const { keyword, handleSearch, debouncedSearchKeyword, previousDebouncedSearchKeyword } = useSearch();
  const { isLoading, surveys, hasData, refetchSurveys, isRefetching } = useSurveys(debouncedSearchKeyword);

  const [isCreateSurveyAllowed] = usePermissionsHandler(['surveys.create']);

  useEffect(() => {
    if (previousDebouncedSearchKeyword !== debouncedSearchKeyword) refetchSurveys();
  }, [debouncedSearchKeyword, previousDebouncedSearchKeyword]);

  const goToSurveyDetails = useGoToSurveyDetailsPage();

  return (
    <Spin size='large' spinning={isLoading}>
      <Spacer height={8} />
      <ConditionalWrapper condition={hasData}>
        <SearchInput value={keyword} onChange={handleSearch} className='programs-content-search' />
        <DataTable
          rowKey={'id'}
          loading={isLoading || isRefetching}
          columns={columns}
          dataSource={surveys}
          onRow={(record) => ({ onClick: () => goToSurveyDetails(record.id) })}
        />
      </ConditionalWrapper>
      <ConditionalWrapper condition={!hasData}>
        <EmptyDataListComponent
          title={stringModifier(text["This program doesn't have any {0} configured."], 'Surveys')}
          description={stringModifier(text['Click the button below to add new {0}'], 'Survey')}
          addButtonText={stringModifier(text['Add {0}'], 'Survey')}
          addButtonDisabled={!isCreateSurveyAllowed}
          onButtonPress={modalRef?.open}
        />
      </ConditionalWrapper>
      <CreateSurveyModal closeHandler={modalRef?.close} modalRef={setModalRef} />
    </Spin>
  );
};
