import { showCustomToast } from 'components';
import { textFormatter } from 'pages/shared/renderers';
import { useMutation, useQueryClient } from 'react-query';
import { queryKeys } from 'services/constants';
import { updateProgramStatus } from 'services/programs/programs';
import { ProgramStatusUpdateRequestBody } from 'services/programs/types';

import { errorHandler } from 'utils/errorHandler';

export const useUpdateProgramStatus = () => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(updateProgramStatus, {
    mutationKey: queryKeys.updateProgramStatus,
    // Response shape is unknown
    onSuccess: (response) => {
      showCustomToast({
        type: 'success',
        message: `Program status successfully updated to ${textFormatter(response.data.status)}`,
      });

      queryClient.invalidateQueries(queryKeys.getPrograms);
      queryClient.invalidateQueries(queryKeys.getProgram);
    },
    onError: errorHandler,
  });

  return { isLoading, updateStatus: (data: ProgramStatusUpdateRequestBody) => mutate(data) };
};
