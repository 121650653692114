import { Select, SelectProps, Tooltip } from 'antd';
import clx from 'classnames';
import { text } from 'constants/texts';
import { BottomArrow } from 'icons/BottomArrow';
import { QueryKey, useQuery } from 'react-query';
import { Request } from 'types/apiTypes';
import { DevError } from 'utils/errors';
import './style.scss';

const { Option } = Select;

export type OptionType<V = unknown> = {
  value: V;
  label: string;
};

export type GroupOption<V = unknown> = {
  name: string;
  options: OptionType<V>[];
};

export type WithSelectRequest<T> = {
  queryKey: QueryKey;
  request: Request<T>;
  optionsMapper: (serverData?: T) => OptionType[];
};

export type AppSelectProps<T> = {
  options?: OptionType[];
  groupOptions?: GroupOption[];
  withRequest?: WithSelectRequest<T>;
} & Pick<SelectProps, 'disabled' | 'placeholder' | 'loading' | 'onDropdownVisibleChange' | 'defaultValue'>;

const emptyMockRequest: () => Promise<unknown> = () => Promise.resolve();

export const withSelectRequestMock = {
  queryKey: '',
  request: emptyMockRequest as () => Promise<never>,
  optionsMapper: () => [],
};

export const AppSelect = <T extends unknown>({
  options,
  groupOptions,
  withRequest: { request, optionsMapper, queryKey } = withSelectRequestMock,
  ...rest
}: AppSelectProps<T>) => {
  // -> logic for fetching dropdown items from server
  const { isLoading: isQueryLoading, data } = useQuery(queryKey, request);

  const isLoading = rest.loading || isQueryLoading;
  const selectOptions = (queryKey ? optionsMapper(data as T) : options) || [];
  const noHaveData = (!!queryKey && !isQueryLoading && optionsMapper(data).length === 0) || options?.length === 0;
  // <-

  if (selectOptions.length && groupOptions?.length)
    throw new DevError("You can't use group options and NOT group options together, please set one of them");

  return (
    <Tooltip
      overlayStyle={{ display: noHaveData ? 'block' : 'none' }}
      align={{ offset: [0, 4] }}
      title={text['No data']}
      destroyTooltipOnHide
      placement='top'
      color='#213a3a'
    >
      <Select
        className={clx('pl-select', { 'hide-label': isLoading })}
        suffixIcon={isLoading ? undefined : <BottomArrow />}
        size='large'
        notFoundContent={null}
        loading={isLoading}
        {...rest}
      >
        {groupOptions?.map(
          (group) =>
            !!group.options.length && (
              <Select.OptGroup key={group.name} label={group.name}>
                {group.options.map(({ value, label }, i) => (
                  <Option key={`${value}-${i}`} value={value}>
                    {label}
                  </Option>
                ))}
              </Select.OptGroup>
            )
        )}
        {selectOptions.map(({ value, label }, i) => (
          <Option key={`${value}-${i}`} value={value}>
            {label}
          </Option>
        ))}
      </Select>
    </Tooltip>
  );
};
