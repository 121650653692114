import { FormInstance } from 'antd';
import { AppFields } from 'components/fields/types';
import { text } from 'constants/texts';
import {
  getGraphicsFields,
  metricsFields,
  patientInstructionsFields,
  termsAndConditionsFields,
} from 'pages/ProgramDetails/programTabComponents/FinancialAssistance/tabsConfig/CopayTab/fields';
import { CopayFinancialAssistanceFields } from 'pages/ProgramDetails/programTabComponents/FinancialAssistance/tabsConfig/CopayTab/types';
import {
  getProgramIntroductionFields,
  getTerminationCriteriaFields,
} from 'pages/ProgramDetails/programTabComponents/FinancialAssistance/tabsConfig/FinancialAssistanceTab/fields';
import { FinancialAssistanceTerminationCriteriaType } from 'services/financialAssistance/types';
import { CopayFinancialAssistance } from 'types/financialAssistance';

type Section = {
  title: string;
  fields: AppFields<CopayFinancialAssistanceFields>;
};

export const getFinancialAssistanceCopaySections = (
  copayAssistance: CopayFinancialAssistance,
  selectedCriteria: FinancialAssistanceTerminationCriteriaType,
  form: FormInstance<CopayFinancialAssistanceFields>
): Section[] => [
  { title: text['Graphics'], fields: getGraphicsFields(copayAssistance, form) },
  { title: text['Metrics'], fields: metricsFields },
  { title: text['Patient Instructions'], fields: patientInstructionsFields },
  { title: text['Terms and Conditions'], fields: termsAndConditionsFields },
  { title: text['Program Introduction'], fields: getProgramIntroductionFields() },
  { title: text['Termination Criteria'], fields: getTerminationCriteriaFields(selectedCriteria) },
];
