import { ConfirmModal, showCustomToast } from 'components';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { useDeleteMedia } from 'hooks/api/programs/useDeleteMedia';
import constants from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Media/components/DeleteMediaModal/constants';
import { Ref } from 'react';
import { Callback } from 'types/helpers';
import { Media } from 'types/programs';

type DeleteMediaModalProps = {
  media: Media;
  onClose?: Callback;
  setDeleteModalRef: Ref<ClosableWrapperRef>;
};

export const DeleteMediaModal = ({ media, onClose, setDeleteModalRef }: DeleteMediaModalProps) => {
  const { isLoading, deleteMediaById } = useDeleteMedia();

  const deleteHandler = async () => {
    if (!media) return;
    const { id, programId } = media;
    await deleteMediaById({ mediaId: id, programId: programId });
    onClose?.();
    showCustomToast({
      duration: 3,
      type: 'success',
      message: `${media.mediaName} media deleted successfully`,
    });
  };

  return (
    <ConfirmModal
      isLoading={isLoading}
      title={constants.title + media.mediaName}
      description={constants.description}
      confirmTextBtn={constants.deleteButton}
      cancelTextBtn={constants.cancelButton}
      modalRef={setDeleteModalRef}
      confirmHandler={deleteHandler}
      cancelHandler={onClose}
    />
  );
};
