import { AppField, AppFields } from 'components/fields/types';
import { fieldModifier } from 'components/fields/utils';
import { text } from 'constants/texts';
import { CreateBenefitFields } from 'pages/ProgramDetails/programTabComponents/Benefits/types';
import { stringModifier } from 'utils/strings';
import { RULES } from 'utils/validations';

// Rules
const { REQUIRED, MAX } = RULES;

// Forms
export const getCreateBenefitFields = (): AppFields<CreateBenefitFields> => [benefitNameField, benefitDescriptionField];

export const getUpdateBenefitFields = (index: number): AppFields<CreateBenefitFields> => [
  [
    fieldModifier(benefitNameField, { name: [index, 'name'] }),
    fieldModifier(benefitDescriptionField, { name: [index, 'description'] }),
  ],
];

// Fields
export const benefitNameField: AppField<Pick<CreateBenefitFields, 'name'>> = {
  type: 'input',
  name: 'name',
  placeholder: stringModifier(text['{0} Characters Max'], '20'),
  tooltip: text['Name of Phase'],
  label: text['Name of Phase'],
  rules: [REQUIRED({ requiredFieldName: text['Name of Phase'] }), MAX({ max: 20 })],
};

export const benefitDescriptionField: AppField<Pick<CreateBenefitFields, 'description'>> = {
  type: 'input',
  name: 'description',
  renderType: 'TextArea',
  placeholder: stringModifier(text['{0} Characters Max'], '60'),
  tooltip: text['Phase Description'],
  label: text['Phase Description'],
  rules: [REQUIRED({ requiredFieldName: text['Phase Description'] }), MAX({ max: 60 })],
};
