import { SVGProps } from 'react';

export const BellVector = (props: SVGProps<SVGSVGElement>) => (
  <svg width='17' height='18' viewBox='0 0 17 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M5.57272 17H10.906' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M2.26434 7.00006C2.26323 6.20971 2.41849 5.42696 2.72119 4.69687C3.02389 3.96679 3.46804 3.30381 4.02806 2.74611C4.58808 2.18841 5.25291 1.74702 5.98424 1.44736C6.71558 1.1477 7.49897 0.995695 8.28931 1.00009C11.5893 1.02509 14.2309 3.76674 14.2309 7.07506V7.66672C14.2309 10.65 14.8559 12.3834 15.4059 13.3334C15.4643 13.4345 15.4951 13.5493 15.4952 13.6661C15.4954 13.7829 15.4648 13.8977 15.4065 13.999C15.3483 14.1003 15.2645 14.1845 15.1634 14.2432C15.0624 14.3018 14.9478 14.3329 14.8309 14.3334H1.66435C1.54752 14.3329 1.43286 14.3018 1.33184 14.2432C1.23082 14.1845 1.14699 14.1003 1.08876 13.999C1.03053 13.8977 0.999927 13.7829 1.00003 13.6661C1.00013 13.5493 1.03094 13.4345 1.08935 13.3334C1.63935 12.3834 2.26434 10.65 2.26434 7.66672V7.00006Z'
      stroke='white'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
