import { Row } from 'antd';
import { Button, MoreButton, PermissionWrapper, Spacer } from 'components';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { ConditionalWrapper } from 'components/ShowWrapper/ConditionalWrapper';
import { text } from 'constants/texts';
import { useChildrenRef, usePermissionsHandler } from 'hooks';
import { ProgramDetailsConfirmModal } from 'pages/ProgramDetails/common/ProgramDetailsConfirmModal';
import { CreateBenefitModal } from 'pages/ProgramDetails/programTabComponents/Benefits/components/CreateBenefitModal/CreateBenefitModal';
import { TabActionsProps } from 'pages/ProgramDetails/tabsConfig/types';
import { showCancelEditedToast, useProgramMoreButtonActions } from 'pages/ProgramDetails/utils';
import { stringModifier } from 'utils/strings';

export type BenefitsTabActionsProps = TabActionsProps & { hasBenefits: boolean };

export const BenefitsTabActions = ({
  program,
  isEditMode,
  isLoading,
  onClose,
  onSave,
  onEdit,
  hasBenefits,
}: BenefitsTabActionsProps) => {
  const [modalRef, setModalRef] = useChildrenRef<ClosableWrapperRef>();
  const [confirmModalRef, setConfirmModalRef] = useChildrenRef<ClosableWrapperRef>();
  const programMoreButtonActions = useProgramMoreButtonActions(program);

  const [isEditBenefitsAllowed] = usePermissionsHandler(['benefits.edit']);

  const onModalConfirm = () => {
    showCancelEditedToast();
    confirmModalRef?.close();
    onClose();
  };

  const detailsButtonsJSX = (
    <>
      <PermissionWrapper permissionsRequired={['benefits.create']}>
        <Button onClick={modalRef?.open}>{stringModifier(text['Add {0}'], 'Benefit')}</Button>
      </PermissionWrapper>
      <Spacer width={16} />
      <ConditionalWrapper condition={hasBenefits && isEditBenefitsAllowed}>
        <Button onClick={onEdit} loading={isLoading}>
          {text['Edit']}
        </Button>
        <Spacer width={16} />
      </ConditionalWrapper>
      <MoreButton options={programMoreButtonActions} />
    </>
  );

  const editButtonsJSX = (
    <>
      <ConditionalWrapper condition={hasBenefits}>
        <Button onClick={onSave} loading={isLoading}>
          {text['Save']}
        </Button>
        <Spacer width={16} />
      </ConditionalWrapper>
      <Button type='ghost' onClick={confirmModalRef?.open}>
        {text['Cancel']}
      </Button>
    </>
  );

  return (
    <Row>
      {isEditMode ? editButtonsJSX : detailsButtonsJSX}
      <CreateBenefitModal setModalRef={setModalRef} modalRef={modalRef} program={program} />
      <ProgramDetailsConfirmModal
        type='Cancel'
        modalRef={setConfirmModalRef}
        cancelHandler={confirmModalRef?.close}
        confirmHandler={onModalConfirm}
      />
    </Row>
  );
};
