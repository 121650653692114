import generatePicker from 'antd/es/date-picker/generatePicker';
import 'antd/es/date-picker/style/index';
import { PickerProps } from 'rc-picker';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import './style.scss';

const DatePicker = generatePicker<Date>(dateFnsGenerateConfig);

export type AppDatePickerProps = {
  format?: 'MMMM dd, yyyy';
  initialDate?: Date;
  onDateChange?: (date?: Date | null) => void;
} & Pick<PickerProps<Date>, 'placeholder' | 'disabledDate'>;

export const AppDatePicker = ({ format, onDateChange, initialDate, ...rest }: AppDatePickerProps) => (
  <DatePicker
    value={initialDate}
    onChange={onDateChange}
    className='pl-date-picker'
    size='large'
    format={format}
    {...rest}
  />
);
