import { SVGProps } from 'react';

export const Users = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M14 15v-1.667c0-.884-.316-1.732-.879-2.357C12.56 10.351 11.796 10 11 10H5c-.796 0-1.559.351-2.121.976C2.316 11.601 2 12.45 2 13.333V15M8 7a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z'
      stroke='#787D83'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
