import { text } from 'constants/texts';
import { FinancialAssistanceContent } from '../programTabComponents/FinancialAssistance/FinancialAssistanceContent';

import { ProgramDetailsUseTabReturnType } from './types';

export const useFinancialAssistanceTab = (): ProgramDetailsUseTabReturnType => ({
  tab: {
    title: text['Financial Assistance'],
    description: text['These settings impact the Financial Assistance segment in the instance of the application'],
    tabData: {
      contentTitle: text['Financial Assistance Settings'],
      actionsJSX: null,
      contentJSX: <FinancialAssistanceContent />,
    },
    tabKey: 'financial-assistance',
  },
});
