import { text } from 'constants/texts';
import { ProgramData } from 'types/programs';
import { SpecialtyPharmacyContent } from '../programTabComponents/SpecialtyPharmacies/SpecialtyPharmaciesContent';
import { SpecialtyPharmaciesTabAction } from '../programTabComponents/SpecialtyPharmacies/SpecialtyPharmaciesTabAction';

import { ProgramDetailsUseTabReturnType } from './types';

export const useSpecialtyPharmacyTab = (program?: ProgramData): ProgramDetailsUseTabReturnType => ({
  tab: {
    title: text['Specialty Pharmacies'],
    description: text['This is the repository of all Specialty Pharmacies for this program'],
    tabData: {
      contentTitle: text['Specialty Pharmacies'],
      actionsJSX: <SpecialtyPharmaciesTabAction program={program} />,
      contentJSX: <SpecialtyPharmacyContent program={program} />,
    },
    tabKey: 'specialty-pharmacies',
  },
});
