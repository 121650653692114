import { SurveyDTO } from 'services/surveys/types';
import { ServerObjectMappers } from 'types/apiTypes';
import { Survey } from 'types/surveys';
import { getHardcodedStringId } from 'utils/hardcodes';

export const { toSurvey, toSurveyDTO }: ServerObjectMappers<'survey', SurveyDTO, Survey> = {
  toSurvey: (dto) => ({
    id: dto.uuid ?? getHardcodedStringId(),
    name: dto.title ?? '',
    questionsCount: dto.questions?.length ?? 0,
    status: dto.isActive ? 'Active' : 'Inactive',
    programId: dto.programUuid ?? '',

    cardTitle: dto.landingCardTitle ?? '',
    cardDescription: dto.landingCardDescription ?? '',
    questions:
      dto.questions?.map((question) => ({
        question: question.title ?? '',
        questionDescription: question.description ?? '',
        selectionType: question.questionType ?? '',
        answers: question.answers?.map((answer) => ({
          id: answer.id ?? '',
          text: answer.text ?? '',
        })),
      })) ?? [],
  }),
  toSurveyDTO: (survey) => ({
    uuid: survey.id,
    title: survey.name ?? '',
    programUuid: survey.programId ?? '',
    landingCardTitle: survey.cardTitle ?? '',
    landingCardDescription: survey.cardDescription ?? '',
    questions:
      survey.questions?.map((question) => ({
        title: question.question ?? '',
        description: question.questionDescription ?? '',
        questionType: question.selectionType ?? '',
        answers: question.answers?.map((answer) => ({
          id: answer.id ?? 0,
          text: answer.text ?? '',
        })),
      })) ?? [],
    isActive: survey.status === 'Active',
    /* keep this fields temporarily, we don't know we need it or not */
    // TODO (be requires this fields now but it needs to be option): remove this fields after be updates this rules
    subtext: survey.cardDescription ?? '',
    logoUrl: 'https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png',
    daysToExpire: 0,
    createdAt: '',
    updatedAt: '',
    isDeleted: false,
    environment: 'TC',
  }),
};
