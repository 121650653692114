import { format } from 'date-fns';
import { useFinancialAssistances } from 'hooks/api/financialAssistance/useFinancialAssistances';
import {
  fapLabelsMapping,
  financialProgramTypeOptions,
} from 'pages/ProgramDetails/programTabComponents/BusinessRules/BusinessRuleDetails/components/BusinessRuleEditContent/utils';
import { BusinessRule, BusinessRuleCondition } from 'types/rules';
import { getDateFromUnknown } from 'utils/date';

export const canActivateBusinessRule = (rule: BusinessRule) =>
  Boolean(Object.keys(rule.conditions).length && rule.actions?.length);

export const useBusinessRuleConditionLabel = ({ value, type, key }: BusinessRuleCondition): string => {
  const { data: financialAssistances } = useFinancialAssistances();

  if (value === null || value === undefined) return 'Empty';

  switch (key) {
    case 'financial_program_type':
      return financialProgramTypeOptions.find((option) => option.value === value)?.label || '';
    case 'financial_program_id':
      const assistance = financialAssistances?.find((assistance) => assistance.uuid === value);
      return assistance?.type ? fapLabelsMapping[assistance.type] : '';
  }

  switch (type) {
    case 'boolean':
      return value ? 'True' : 'False';
    case 'date':
      return format(getDateFromUnknown(value), 'MMMM dd, yyyy');
    default:
      return `${value}`;
  }
};
