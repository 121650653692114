import { Row } from 'antd';
import { Button, PermissionWrapper, Spacer } from 'components';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { ConditionalWrapper } from 'components/ShowWrapper/ConditionalWrapper';
import { text } from 'constants/texts';
import { useChildrenRef } from 'hooks';
import { ProgramDetailsConfirmModal } from 'pages/ProgramDetails/common/ProgramDetailsConfirmModal';
import { TabActionsProps } from 'pages/ProgramDetails/tabsConfig/types';
import { showCancelEditedToast } from 'pages/ProgramDetails/utils';
import { SpecialtyPharmacyMoreAction } from '../components/SpecialtyPharmacyMoreAction';

export const SpecialtyPharmacyDetailsTabActions = ({
  program,
  onSave,
  onClose,
  onEdit,
  isEditMode,
  isLoading,
  pharmacyId,
  pharmacyName,
}: TabActionsProps & { pharmacyId: string; pharmacyName: string }) => {
  const [confirmModalRef, setConfirmModalRef] = useChildrenRef<ClosableWrapperRef>();

  if (!program) return null;

  const onModalConfirm = () => {
    showCancelEditedToast();
    confirmModalRef?.close();
    onClose();
  };

  return (
    <Row>
      <PermissionWrapper permissionsRequired={['pharmacies.edit']}>
        <Button onClick={isEditMode ? onSave : onEdit} loading={isLoading}>
          {text[isEditMode ? 'Save' : 'Edit']}
        </Button>
      </PermissionWrapper>
      <Spacer width={16} />
      <ConditionalWrapper condition={isEditMode}>
        <Button type='ghost' onClick={confirmModalRef?.open}>
          {text['Cancel']}
        </Button>
      </ConditionalWrapper>
      <PermissionWrapper permissionsRequired={['pharmacies.delete']}>
        <ConditionalWrapper condition={!isEditMode}>
          <SpecialtyPharmacyMoreAction pharmacyId={pharmacyId} pharmacyName={pharmacyName} />
        </ConditionalWrapper>
      </PermissionWrapper>
      <ProgramDetailsConfirmModal
        type='Cancel'
        modalRef={setConfirmModalRef}
        cancelHandler={confirmModalRef?.close}
        confirmHandler={onModalConfirm}
      />
    </Row>
  );
};
