import { useSearchParams } from 'react-router-dom';
import { NOTIFICATION_TILE_ID_PARAM } from 'routes/navigators';
import { useNotificationTiles } from './useNotificationTiles';

export const useNotificationTile = (id?: string) => {
  const [params] = useSearchParams();

  const { notificationTiles, isLoading } = useNotificationTiles();

  return {
    isLoading,
    notificationTile: notificationTiles.find((tile) => tile.id === (id || params.get(NOTIFICATION_TILE_ID_PARAM))),
  };
};
