import { AppField } from 'components/fields/types';
import { QuestionsFields, SurveyFields } from 'types/surveys';
import { RULES } from 'utils/validations';
import { selectionTypeOptions } from './programTabComponents/AdditionalServices/selectOptions';

const { MAX, REQUIRED } = RULES;

export const surveyNameField: AppField<Pick<SurveyFields, 'name'>> = {
  type: 'input',
  name: 'name',
  placeholder: '50 Characters Max',
  label: 'Survey Name',
  tooltip: 'Survey Name', //TODO:  Add tooltips
  maxLength: 51,
  rules: [REQUIRED({ requiredFieldName: 'Name' }), MAX({ max: 50 })],
};

export const surveyCardTitleField: AppField<Pick<SurveyFields, 'cardTitle'>> = {
  type: 'input',
  name: 'cardTitle',
  placeholder: 'Enter your Survey Title here',
  label: 'Landing Card Title',
  rules: [REQUIRED({ requiredFieldName: 'Title' })],
  tooltip: '', //TODO:  Add tooltips
};

export const surveyDescriptionField: AppField<Pick<SurveyFields, 'cardDescription'>> = {
  type: 'input',
  name: 'cardDescription',
  placeholder: 'Enter your Survey Description here',
  label: 'Landing Card Description',
  rules: [REQUIRED({ requiredFieldName: 'Description' })],
  tooltip: '', //TODO:  Add tooltips
};

export const surveyQuestionField: AppField<Pick<QuestionsFields, 'question'>> = {
  type: 'input',
  name: 'question',
  placeholder: 'Enter your question text here',
  label: 'Survey Question',
  renderType: 'TextArea',
  rows: 4,
  tooltip: '', //TODO:  Add tooltips
  rules: [REQUIRED({ requiredFieldName: 'Question' })],
};

export const questionDescriptionField: AppField<Pick<QuestionsFields, 'questionDescription'>> = {
  type: 'input',
  name: 'questionDescription',
  placeholder: 'Enter your description text here',
  label: 'Survey Question Description',
  renderType: 'TextArea',
  rows: 4,
  tooltip: '', //TODO:  Add tooltips
  rules: [REQUIRED({ requiredFieldName: 'Description' })],
};

export const selectionTypeField: AppField<Pick<QuestionsFields, 'selectionType'>> = {
  type: 'select',
  name: 'selectionType',
  label: 'Selection Type',
  tooltip: '', //TODO:  Add tooltips
  options: selectionTypeOptions,
  rules: [REQUIRED({ requiredFieldName: 'Selection Type' })],
};
