import { Row } from 'antd';
import { Spacer } from 'components';
import { ConditionalWrapper } from 'components/ShowWrapper/ConditionalWrapper';
import { text } from 'constants/texts';
import { useBusinessRuleConditionLabel } from 'pages/ProgramDetails/programTabComponents/BusinessRules/utils';
import { BusinessRuleCondition } from 'types/rules';
import { getFontClassName } from 'utils/getFontClassName';

type BusinessRuleConditionDetailsProps = {
  condition: BusinessRuleCondition;
  withAndCondition: boolean;
};

export const BusinessRuleConditionDetails = ({ condition, withAndCondition }: BusinessRuleConditionDetailsProps) => {
  const value = useBusinessRuleConditionLabel(condition);

  return (
    <>
      <ConditionalWrapper condition={withAndCondition}>
        <Spacer height={14} type='flex' />
        <h2 className={getFontClassName('h2', 'gray2')}>{text['AND']}</h2>
        <Spacer height={14} type='flex' />
      </ConditionalWrapper>
      <label className={getFontClassName('label')}>{text['Criteria']}</label>
      <Spacer height={4} type='flex' />
      <Row>
        <p className={getFontClassName('paragraph')}>{condition.label}</p>
        <Spacer width={24} />
        <p className={getFontClassName('paragraph')}>{condition.operatorLabels[condition.operator]}</p>
        <Spacer width={24} />
        <p className={getFontClassName('paragraph')}>{value}</p>
      </Row>
    </>
  );
};
