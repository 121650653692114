import { Col, Image, Row } from 'antd';
import PatientLink from '../../assets/images/pt_auth.png';
import PatientLinkColor from '../../assets/images/pt_auth_color.png';
import { ReactNode } from 'react';

import './style.scss';

type AuthLayoutProps = {
  title: string;
  children: ReactNode;
};

export const AuthLayout = ({ title, children }: AuthLayoutProps) => {
  return (
    <div className='login-page-container'>
      <Row wrap justify='center' className='wrapper'>
        <Col span={24} className='image'>
          <Image height={48} width={172} preview={false} src={PatientLinkColor} />
          <div className='title-text'>{title}</div>
        </Col>
        <Col>{children}</Col>
        <Col span={24} className='image'>
          <Image height={38} width={140} preview={false} src={PatientLink} />
        </Col>
      </Row>
    </div>
  );
};
