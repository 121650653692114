type EmptyValue = 0 | -1 | [] | null | '' | undefined;

export const removeEmpty = <T extends object>(obj: T, emptyValues: EmptyValue[] = [null, undefined, '']): T => {
  const newObj = { ...obj };

  Object.entries(newObj).forEach(([key, value]) => {
    if (emptyValues.some((e) => e instanceof Array) && value instanceof Array && !value.length) delete newObj[key];
    else if (emptyValues.includes(value as EmptyValue)) delete newObj[key];
  });

  return newObj;
};
