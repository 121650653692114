import { text } from 'constants/texts';
import { useCopayTab } from 'pages/ProgramDetails/programTabComponents/FinancialAssistance/tabsConfig/CopayTab/useCopayTab';
import { useFinancialAssistanceCardsTab } from 'pages/ProgramDetails/programTabComponents/FinancialAssistance/tabsConfig/FinancialAssistanceTab/useFinancialAssistanceTab';
import { FinancialAssistanceTabsReturnType } from 'pages/ProgramDetails/programTabComponents/FinancialAssistance/tabsConfig/types';

export const useFinancialAssistanceTabs = (): FinancialAssistanceTabsReturnType => {
  const { tab: copayTab, extra: { isCopayEditMode } = {} } = useCopayTab();

  const { tab: papTab, extra: { isEditMode: isPAPEditMode } = {} } = useFinancialAssistanceCardsTab({
    title: text['PAP'],
    type: 'PAP',
    tabKey: 'pap',
  });

  const { tab: quickstartTab, extra: { isEditMode: isQuickstartEditMode } = {} } = useFinancialAssistanceCardsTab({
    title: text['Quickstart'],
    type: 'QUICKSTART',
    tabKey: 'quickstart',
  });

  const { tab: bridgeTab, extra: { isEditMode: isBridgeEditMode } = {} } = useFinancialAssistanceCardsTab({
    title: text['Bridge'],
    type: 'BRIDGE',
    tabKey: 'bridge',
  });

  const { tab: foundationTab, extra: { isEditMode: isFoundationEditMode } = {} } = useFinancialAssistanceCardsTab({
    title: text['Foundation'],
    type: 'FOUNDATION',
    tabKey: 'foundation',
  });

  return {
    tabs: [copayTab, papTab, quickstartTab, bridgeTab, foundationTab],
    extra: {
      isTabsDisabled: Boolean(
        isCopayEditMode || isPAPEditMode || isQuickstartEditMode || isBridgeEditMode || isFoundationEditMode
      ),
    },
  };
};
