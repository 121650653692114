import { useAuth } from 'hooks';
import { Permissions } from '../types';

export const getIsAllowedPermission = (required: Permissions[], permissions: Permissions[]): boolean[] =>
  required.map((perm) => permissions.includes(perm));

export const usePermissionsHandler = (requiredPermissions: Permissions[]) => {
  const { permissions } = useAuth();

  return getIsAllowedPermission(requiredPermissions, permissions);
};
