import { FormInstance, Row } from 'antd';
import { MoreButton, Spacer } from 'components';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { EditableButtons } from 'components/EditableButtons/EditableButtons';
import { ConditionalWrapper } from 'components/ShowWrapper/ConditionalWrapper';
import { text } from 'constants/texts';
import { useUpdateBusinessRule } from 'hooks/api/rules/useUpdateBusinessRule';
import { UpdateBusinessRuleFields } from 'pages/ProgramDetails/programTabComponents/BusinessRules/components/CreateBusinessRuleModal/types';
import { canActivateBusinessRule } from 'pages/ProgramDetails/programTabComponents/BusinessRules/utils';
import { Dispatch, SetStateAction, useRef } from 'react';
import { BusinessRule } from 'types/rules';
import { usePermissionsHandler } from 'hooks';

type BusinessRuleDetailsActionsProps = {
  businessRule: BusinessRule;
  isEditMode: boolean;
  form: FormInstance<UpdateBusinessRuleFields>;
  onEditModeChange: Dispatch<SetStateAction<boolean>>;
};

export const BusinessRuleDetailsActions = ({
  form,
  businessRule,
  isEditMode,
  onEditModeChange,
}: BusinessRuleDetailsActionsProps) => {
  const cancelModalRef = useRef<ClosableWrapperRef>(null);
  const saveModalRef = useRef<ClosableWrapperRef>(null);

  const [isEditRulesAllowed] = usePermissionsHandler(['rules.edit']);

  const { updateBusinessRule, isLoading } = useUpdateBusinessRule();

  return (
    <Row>
      <EditableButtons
        isEditMode={isEditMode}
        onEdit={() => onEditModeChange(true)}
        onSave={saveModalRef.current?.open}
        onCancel={cancelModalRef.current?.open}
        cancelModal={{
          onConfirm: () => {
            form.resetFields();
            onEditModeChange(false);
          },
          ref: cancelModalRef.current,
          setRef: (ref) => (cancelModalRef.current = ref),
        }}
        saveModal={{
          onConfirm: form.submit,
          ref: saveModalRef.current,
          setRef: (ref) => (saveModalRef.current = ref),
        }}
      />
      <ConditionalWrapper condition={!isEditMode}>
        <Spacer width={16} />
        <MoreButton
          isLoading={isLoading}
          options={[
            {
              key: 'Status',
              label: text[businessRule.isActive ? 'Deactivate' : 'Activate'],
              disabled: !isEditRulesAllowed || businessRule.isActive ? false : !canActivateBusinessRule(businessRule),
              onClick: () => updateBusinessRule(businessRule, !businessRule.isActive),
            },
          ]}
        />
      </ConditionalWrapper>
    </Row>
  );
};
