import { useMutation, useQueryClient } from 'react-query';

import { queryKeys } from 'services/constants';
import { createService } from 'services/programs/services';
import { CreateServiceData } from 'services/programs/types';
import { Callback } from 'types/helpers';
import { errorHandler } from 'utils/errorHandler';

export const useCreateService = (onSuccess?: Callback) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(createService, {
    mutationKey: queryKeys.createService,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.getServices);
      onSuccess?.();
    },
    onError: errorHandler,
  });

  return {
    isLoading,
    createService: (serviceData: CreateServiceData) => mutate(serviceData),
  };
};
