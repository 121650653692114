import { AppField, AppFields } from 'components/fields/types';
import { text } from 'constants/texts';
import { RULES } from 'utils/validations';
import {
  notificationTileDescriptionField,
  notificationTileDestinationPageField,
  notificationTileExternalLinkField,
  notificationTileExternalLinkNameField,
  notificationTileNameField,
  notificationTileTitleField,
} from '../fields';
import { UpdateNotificationTileFields } from '../types';

const { REQUIRED, MAX } = RULES;

export const updateNotificationTileFields = ({
  externalLinkAdded,
}: {
  externalLinkAdded: boolean;
}): AppFields<UpdateNotificationTileFields> => [
  [notificationTileNameField, notificationTileTitleField],
  [notificationTileDescriptionField, notificationTileDestinationPageField],
  [
    notificationTileExternalLinkField,
    {
      ...notificationTileExternalLinkNameField,
      disabled: !externalLinkAdded,
      rules: [
        { ...(externalLinkAdded && REQUIRED({ requiredFieldName: text['External Link Name'] })) },
        MAX({ max: 100 }),
      ],
    } as AppField<Pick<UpdateNotificationTileFields, 'externalLinkName'>>,
  ],
];
