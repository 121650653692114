import { Form, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { UploadProps } from 'antd/lib/upload/interface';
import clx from 'classnames';
import { useState } from 'react';
import { AppUploadProps } from '../../AppUpload';

import '../../style.scss';
import {
  beforeUpload,
  getDraggerUploadDescription,
  getMetaFileTypeFromFileTypes,
  getValueFromUploadEvent,
  uploadMockRequest,
} from '../../utils';
import { UploadItem } from '../UploadItem/UploadItem';

export type DraggerConfig = {
  title?: string;
  description?: string;
};

type DraggerUploadProps = { draggerConfig: DraggerConfig } & Pick<UploadProps, 'onChange'> &
  Pick<
    AppUploadProps,
    | 'loadFileType'
    | 'createdFromSelectUpload'
    | 'cropper'
    | 'fileTypes'
    | 'filesCount'
    | 'maxSize'
    | 'field'
    | 'className'
  >;

export const DraggerUpload = ({
  loadFileType,
  fileTypes,
  maxSize,
  field,
  filesCount,
  createdFromSelectUpload,
  className,
  cropper,
  onChange,
  draggerConfig: { description = getDraggerUploadDescription(fileTypes), title = 'Drag and drop a file here' },
}: DraggerUploadProps) => {
  const [isFilled, setFilled] = useState(false);

  const onChangeHandler: UploadProps['onChange'] = (event) => {
    onChange?.(event);
    setFilled(event.fileList.length === filesCount);
  };

  const draggerJSX = (
    <Upload.Dragger
      onChange={onChangeHandler}
      className={clx('pl-upload', { hide: isFilled })}
      accept={loadFileType === 'images' ? 'image/*' : undefined}
      maxCount={filesCount}
      itemRender={(_, file, __, actions) => (
        <UploadItem fileType={getMetaFileTypeFromFileTypes(fileTypes)} file={file} remove={actions.remove} />
      )}
      beforeUpload={(file) => beforeUpload(file, fileTypes, maxSize)}
      customRequest={uploadMockRequest}
    >
      <div className='upload-dragger-content'>
        <p className='ant-upload-text'>{title}</p>
        <p className='ant-upload-hint'>{description}</p>
      </div>
    </Upload.Dragger>
  );

  return (
    <Form.Item
      className={clx(className, {
        'required-star': !!field?.rules?.some((rule) => rule?.required),
        'without-margin': !createdFromSelectUpload,
      })}
      name={field?.name}
      rules={field?.rules}
      tooltip={field?.tooltip}
      label={field?.label}
      hidden={field?.hidden}
      getValueFromEvent={getValueFromUploadEvent}
      valuePropName='fileList'
      preserve={false}
    >
      {cropper ? (
        <ImgCrop minZoom={0.3} rotationSlider aspect={cropper.sizes.width / cropper.sizes.height}>
          {draggerJSX}
        </ImgCrop>
      ) : (
        draggerJSX
      )}
    </Form.Item>
  );
};
