import { FormInstance, Row } from 'antd/es';
import clx from 'classnames';

import { Button, Spacer } from 'components';
import { getFieldsJSX } from 'components/fields/utils';
import { text } from 'constants/texts';
import { useProgramData } from 'hooks/api';
import { DynamicAnswersFields } from 'pages/ProgramDetails/common';
import { getMainQuestionFields } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Surveys/SurveyDetails/constants';
import { SurveyFields } from 'types/surveys';
import { stringModifier } from 'utils/strings';

export type DynamicFormProps = {
  form: FormInstance<SurveyFields>;
  orderIndex: number;
  remove: (index: number | number[]) => void;
  isEditMode: boolean;
};

export const DynamicForm = ({ remove, orderIndex, isEditMode, form }: DynamicFormProps) => {
  const survey = form.getFieldValue(['surveys', orderIndex]);
  const { program } = useProgramData();

  return (
    <div className='dynamic-form-block'>
      <h1 className='page-content-title'>{stringModifier(text['Question {0}'], `${orderIndex + 1}`)}</h1>
      <Spacer height={24} type='flex' />
      <Row>
        <div className='dynamic-form-block-50'>{getFieldsJSX(getMainQuestionFields(orderIndex), isEditMode)}</div>
        <Spacer width={12} height='initial' type='flex' />
        <Spacer width={12} height='initial' type='flex' className={clx({ 'dynamic-form-divider': !isEditMode })} />
        <div className='dynamic-form-block-50'>
          <DynamicAnswersFields name={[orderIndex, 'answers']} isEditMode={isEditMode} />
        </div>
        {isEditMode && (
          <Button className='delete-form-item' type='text' onClick={() => remove(orderIndex)}>
            Delete
          </Button>
        )}
      </Row>
    </div>
  );
};
