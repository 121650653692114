import { useQuery } from 'react-query';
import { queryKeys } from 'services/constants';
import { GetAdminsParams } from 'services/users/types';
import { getAdmins } from 'services/users/users';
import { toAdmin } from './mappers';
import { usePermissionsHandler } from 'hooks';

export const useAdmins = (params?: GetAdminsParams) => {
  const [isViewAdminsAllowed] = usePermissionsHandler(['admins.view']);

  const {
    data: admins,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery(queryKeys.getAdmins, () => getAdmins(params), {
    enabled: isViewAdminsAllowed,
    refetchOnMount: false,
    select: ({ items }) => items.map(toAdmin),
  });

  return { admins, isLoading, refetch, isRefetching };
};
