import {
  CreateMilestoneTrackerData,
  CreateMilestoneTrackerParams,
  DeleteMilestoneTrackerParams,
  GetMilestoneTrackerListData,
  GetMilestoneTrackerListParams,
  UpdateMilestoneTrackerParams,
} from 'services/milestoneTrackers/types';
import { ApiService, CreateRequest, DeleteRequest, GetListRequest, UpdateRequest } from 'types/apiTypes';
import { api, getApiUrlWithProxy } from 'utils/api';

const GET_MILESTONES_TRACKER_ENDPOINT = () => getApiUrlWithProxy('/admin/milestones-trackers/');

export const {
  getMilestoneTrackerList,
  updateMilestoneTracker,
  createMilestoneTracker,
  deleteMilestoneTracker,
}: ApiService<
  'milestoneTracker',
  GetListRequest<GetMilestoneTrackerListData, GetMilestoneTrackerListParams> &
    DeleteRequest<unknown, DeleteMilestoneTrackerParams> &
    CreateRequest<CreateMilestoneTrackerData, CreateMilestoneTrackerParams> &
    UpdateRequest<unknown, UpdateMilestoneTrackerParams>
> = {
  getMilestoneTrackerList: (params) => {
    return api
      .get<GetMilestoneTrackerListData>(GET_MILESTONES_TRACKER_ENDPOINT(), {
        params: {
          name: params.name,
          manufacture_program_uuid: params.programId,
        },
      })
      .then((res) => res.data);
  },
  createMilestoneTracker: (tracker) => {
    return api.post<CreateMilestoneTrackerData>(GET_MILESTONES_TRACKER_ENDPOINT(), tracker).then((res) => res.data);
  },
  updateMilestoneTracker: (data) => {
    return api.put(GET_MILESTONES_TRACKER_ENDPOINT() + data.uuid, data);
  },
  deleteMilestoneTracker: (uuid) => {
    return api.delete(GET_MILESTONES_TRACKER_ENDPOINT() + uuid);
  },
};
