import { text } from 'constants/texts';
import { useBenefits } from 'hooks/api/benefits/useBenefits';
import {
  BenefitsContent,
  BenefitsContentRef,
} from 'pages/ProgramDetails/programTabComponents/Benefits/BenefitsContent';
import { BenefitsTabActions } from 'pages/ProgramDetails/programTabComponents/Benefits/BenefitsTabActions';
import { useRef, useState } from 'react';
import { ProgramData } from 'types/programs';
import { useCoverProgramEditStatusCallbacks } from '../hooks/useCoverProgramEditStatusCallbacks';
import { ProgramDetailsUseTabReturnType } from './types';

export const useBenefitsTab = (
  program?: ProgramData
): ProgramDetailsUseTabReturnType<{ isBenefitTabEditMode: boolean }> => {
  const [isEditMode, setEditMode] = useState(false);

  const { isLoading: isBenefitsLoading, hasData } = useBenefits();

  const contentRef = useRef<BenefitsContentRef>(null);

  const {
    isLoading: isEditAccessConfigLoading,
    onSave,
    ...editAccessConfig
  } = useCoverProgramEditStatusCallbacks({
    programId: program?.id,
    onClose: () => {
      setEditMode(false);
      contentRef.current?.reset();
    },
    onEdit: () => setEditMode(true),
  });

  const onSubmit = () => contentRef.current?.submit();

  const isLoading = isEditAccessConfigLoading || isBenefitsLoading;

  return {
    tab: {
      title: text['Benefits'],
      description: text['These settings impact the Benefits segment in the instance of the application'],
      tabData: {
        contentTitle: text['Benefits Settings'],
        actionsJSX: (
          <BenefitsTabActions
            {...editAccessConfig}
            onSave={onSubmit}
            isLoading={isLoading}
            isEditMode={isEditMode}
            program={program}
            hasBenefits={hasData}
          />
        ),
        contentJSX: (
          <BenefitsContent
            onFinish={() => {
              setEditMode(false);
              onSave();
            }}
            ref={contentRef}
            isEditMode={isEditMode}
          />
        ),
      },
      tabKey: 'benefits',
    },
    extra: { isBenefitTabEditMode: isEditMode },
  };
};
