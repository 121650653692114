import { showSuccessfullyDeletedToast } from 'pages/ProgramDetails/utils';
import { useMutation, useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { NOTIFICATION_TILE_ID_PARAM } from 'routes/navigators';
import { queryKeys } from 'services/constants';
import { Callback } from 'types/helpers';
import { errorHandler } from 'utils/errorHandler';
import { deleteNotificationTile } from 'services/notificationTiles/requests';

export const useDeleteNotificationTile = (id?: string, onSuccess?: Callback) => {
  const queryClient = useQueryClient();

  const [searchParams, setSearchParams] = useSearchParams();

  const { mutateAsync, isLoading } = useMutation(deleteNotificationTile, {
    mutationKey: queryKeys.deleteNotificationTile,
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKeys.getNotificationTiles);
      showSuccessfullyDeletedToast();
      onSuccess?.();

      // If user is in detail page -> go back
      if (searchParams.get(NOTIFICATION_TILE_ID_PARAM) === id) {
        searchParams.delete(NOTIFICATION_TILE_ID_PARAM);
        setSearchParams(searchParams);
      }
    },
    onError: errorHandler,
  });

  return {
    isLoading,
    deleteNotificationTile: () => id && mutateAsync(id),
  };
};
