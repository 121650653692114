import { PageLayout } from 'layouts';
import { Button, SearchInput, Spacer } from 'components';
import { useSearch } from 'hooks';

import './style.scss';
import React, { useCallback, useEffect } from 'react';
import { getPatientsPagesBreadCrumb } from './breadCrumbs';
import { text } from 'constants/texts';
import { EmptyDataListComponent } from 'components/EmptyDataListComponent/EmptyDataListComponent';
import { PatientSearchContent } from './PatientSearchContent';
import { usePatientCommunication } from 'hooks/api/patientSearch/usePatientCommunications';
import { usePatient } from 'hooks/api/patientSearch/usePatientSearch';
import { Skeleton } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { COMMUNICATION_SURVEY_ID_PARAM, PATIENT_SEARCH_ID_PARAMS } from 'routes/navigators';
import { PatientCommunicationDetails } from './PatientComunicationDetails/PatientCommunicationDetails';
import { PATIENT_SEARCH_TAB_URL_KEY, useTabChangeHandler } from '../ProgramDetails/useTabChangeHandler';
import { PatientSearchTabs } from './tabsConfig/types';

export const PatientSearch = () => {
  const [params, setParams] = useSearchParams();
  const patientEmail = params.get(PATIENT_SEARCH_ID_PARAMS) || '';
  const communicationId = params.get(COMMUNICATION_SURVEY_ID_PARAM);
  const patientSearchTab = params.get(PATIENT_SEARCH_TAB_URL_KEY);

  const { selectedTab, onTabChange } = useTabChangeHandler(
    PATIENT_SEARCH_TAB_URL_KEY,
    'patient-information' as Extract<PatientSearchTabs, 'patient-information'>,
    {
      [PATIENT_SEARCH_ID_PARAMS]: patientEmail,
    }
  );

  const { keyword, handleSearch, debouncedSearchKeyword, previousDebouncedSearchKeyword } = useSearch(
    2000,
    patientEmail
  );

  const breadCrumbs = getPatientsPagesBreadCrumb({ name: 'PATIENTS_SEARCH' });

  const {
    patient,
    hasData: hasPatient,
    refetch: refetchPatient,
    isLoading: isPatientLoading,
    isRefetching: isPatientRefetching,
  } = usePatient(debouncedSearchKeyword);

  const {
    patientCommunications,
    hasData: hasComunications,
    refetch: refetchPatientCommunication,
    isLoading: isPatientCommunicationLoading,
    isRefetching: isPatientCommunicationRefetching,
  } = usePatientCommunication(debouncedSearchKeyword);

  const onSearchRefetch = useCallback(() => {
    if (!debouncedSearchKeyword) return;

    refetchPatient();
    refetchPatientCommunication();
  }, [debouncedSearchKeyword, refetchPatientCommunication, refetchPatient]);

  useEffect(() => {
    if (previousDebouncedSearchKeyword !== debouncedSearchKeyword) {
      setParams({
        [PATIENT_SEARCH_ID_PARAMS]: debouncedSearchKeyword,
        ...(!!patientSearchTab && { [PATIENT_SEARCH_TAB_URL_KEY]: patientSearchTab }),
        ...(!!communicationId && { [COMMUNICATION_SURVEY_ID_PARAM]: communicationId }),
      });
      onSearchRefetch();
    }
  }, [
    setParams,
    onSearchRefetch,
    communicationId,
    patientSearchTab,
    debouncedSearchKeyword,
    previousDebouncedSearchKeyword,
  ]);

  const isLoading = isPatientLoading || isPatientCommunicationLoading;
  const isRefetching = isPatientRefetching || isPatientCommunicationRefetching;
  const searchProcess = (!hasPatient && isLoading) || isRefetching;

  if (hasComunications && communicationId) return <PatientCommunicationDetails />;

  return (
    <PageLayout pageTitle={text['Patient Search']} crumbs={breadCrumbs}>
      <div className='patient-wrapper'>
        <div className='patient-wrapper-search-input'>
          <SearchInput value={keyword} onChange={handleSearch} />
          <Spacer width={16} />
          <Button type='default' loading={isLoading || isRefetching} onClick={onSearchRefetch}>
            {text['Search']}
          </Button>
        </div>
        {searchProcess ? (
          <>
            <Spacer height={20} />
            <Skeleton active loading={isLoading || isRefetching} />
          </>
        ) : hasPatient ? (
          <PatientSearchContent
            patient={patient}
            patientCommunications={patientCommunications}
            selectedTab={selectedTab}
            onTabChange={onTabChange}
          />
        ) : (
          <>
            <Spacer height={20} />
            <EmptyDataListComponent
              title={
                debouncedSearchKeyword
                  ? text['We couldn’t find any accounts with that email address.']
                  : text['Enter a Patient’s Email Address']
              }
              description={
                debouncedSearchKeyword
                  ? text[
                      'We don’t see an account associated with that email. Please check the email address you entered in the search bar.'
                    ]
                  : text['To search for a patient’s information, enter their email address in the search box above.']
              }
            />
          </>
        )}
      </div>
    </PageLayout>
  );
};
