import { toCommunicationTemplate } from 'hooks/api/rules/mappers';
import { useQuery } from 'react-query';
import { queryKeys } from 'services/constants';
import { getCommunicationTemplateList } from 'services/rules/communicationTemplates/requests';
import { CommunicationTemplateType } from 'services/rules/communicationTemplates/types';

export const useCommunicationTemplates = (channel?: CommunicationTemplateType) => {
  const { data, isLoading } = useQuery(
    [queryKeys.getCommunicationTemplates, channel],
    () => getCommunicationTemplateList({ channel }),
    {
      select: (response) => response.map(toCommunicationTemplate),
    }
  );

  return {
    isLoading,
    templates: data || [],
  };
};
