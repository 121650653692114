import { Form, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { Spacer } from 'components';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { getFieldsJSX } from 'components/fields/utils';
import { scrollToFirstErrorProps } from 'constants/common';
import { useChildrenRef } from 'hooks';
import { useUpdateSpecialtyPharmacy } from 'hooks/api/specialtyPharmacy/useUpdateSpecialtyPharmacy';
import { ProgramDetailsConfirmModal } from 'pages/ProgramDetails/common/ProgramDetailsConfirmModal';
import { SpecialtyPharmaciesFields } from 'pages/ProgramDetails/specialtyPharmaciesFields';

import { forwardRef, ForwardRefRenderFunction, useImperativeHandle } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SPECIALTY_PHARMACY_ID_PARAM } from 'routes/navigators';
import { Callback } from 'types/helpers';
import { SpecialtyPharmacy } from 'types/specialtyPharmacy';

import { getTopContactInformationFields, getTopContentFields } from './constants';
import { stringModifier } from 'utils/strings';
import { text } from 'constants/texts';

export type SpecialtyPharmacyDetailsContentRef = {
  submit: Callback;
  reset: Callback;
};

export type SpecialtyPharmacyDetailsContentProps = {
  isLoading: boolean;
  isEditMode: boolean;
  isRefetching: boolean;
  refetch: Callback;
  onSuccess: Callback;
  specialtyPharmacy: SpecialtyPharmacy;
};

const SpecialtyPharmacyDetailsContentComponent: ForwardRefRenderFunction<
  SpecialtyPharmacyDetailsContentRef,
  SpecialtyPharmacyDetailsContentProps
> = ({ isLoading, isEditMode, isRefetching, refetch, onSuccess, specialtyPharmacy }, ref) => {
  const [form] = useForm<SpecialtyPharmaciesFields>();
  const [confirmModalRef, setConfirmModalRef] = useChildrenRef<ClosableWrapperRef>();
  const [params] = useSearchParams();
  const pharmacyId = params.get(SPECIALTY_PHARMACY_ID_PARAM);

  const { isLoading: isUpdateSpecialtyPharmacy, updateSpecialtyPharmacy } = useUpdateSpecialtyPharmacy(
    pharmacyId || '',
    () => {
      onSuccess();
      confirmModalRef?.close();
    }
  );

  const handleSubmit = () => confirmModalRef?.open();

  useImperativeHandle(ref, () => ({ submit: form.submit, reset: form.resetFields }));

  const confirmHandler = async () => {
    await updateSpecialtyPharmacy(form.getFieldsValue());
    refetch();
  };

  return (
    <Spin size='large' spinning={isLoading || isUpdateSpecialtyPharmacy || isRefetching}>
      <h1 className='page-content-title'>{stringModifier(text['{0} Details'], text['Specialty Pharmacy'])}</h1>
      <h2 className='page-content-description'>
        {stringModifier(text['These are the details of the {0}'], text['Specialty Pharmacy'])}
      </h2>
      <Spacer height={24} type='flex' />
      <Form<SpecialtyPharmaciesFields>
        layout='vertical'
        form={form}
        onFinish={handleSubmit}
        initialValues={specialtyPharmacy}
        scrollToFirstError={scrollToFirstErrorProps}
      >
        {getFieldsJSX(getTopContentFields(), isEditMode)}
        <h1 className='page-content-title'>{text['Contact Information']}</h1>
        <Spacer height={24} type='flex' />
        {getFieldsJSX(getTopContactInformationFields(), isEditMode)}
      </Form>
      <ProgramDetailsConfirmModal
        type='Save'
        modalRef={setConfirmModalRef}
        confirmHandler={confirmHandler}
        cancelHandler={confirmModalRef?.close}
        isLoading={isLoading}
      />
    </Spin>
  );
};

export const SpecialtyPharmacyDetailsContent = forwardRef(SpecialtyPharmacyDetailsContentComponent);
