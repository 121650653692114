import axios from 'axios';
import { ClientMediaData } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Media/components/CreateMediaModal/types';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { queryKeys } from 'services/constants';
import { createMedia } from 'services/medias/requests';
import { CreateMediaWithFormData } from 'services/medias/types';

import { errorHandler } from 'utils/errorHandler';
import { mediaMapper } from './mappers';

const getCancelToken = axios.CancelToken.source;

export const useCreateMedia = () => {
  const queryClient = useQueryClient();
  const [progress, setProgress] = useState(0);
  const [cancelToken, setCancelToken] = useState(getCancelToken());

  const { mutateAsync, isLoading } = useMutation(
    (params: Omit<CreateMediaWithFormData, 'setProgress' | 'controller'>) =>
      createMedia({ ...params, setProgress, cancelToken: cancelToken.token }),
    {
      mutationKey: queryKeys.createMedia,
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.getMedias);
      },
      onError: errorHandler,
    }
  );

  const reset = () => setProgress(0);

  return {
    progress,
    isLoading,
    cancelRequest: () => {
      setCancelToken(getCancelToken());
      cancelToken.cancel();
    },
    createMedia: (media: ClientMediaData) => mutateAsync(mediaMapper(media)).finally(reset),
  };
};
