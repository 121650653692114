import { AppField } from 'components/fields/types';
import { text } from 'constants/texts';
import { getInputPlaceholder, getSelectPlaceholder, stringModifier } from 'utils/strings';
import { RULES } from 'utils/validations';
import { selectionStatesUsaOptions } from './statesUSA';

export type SpecialtyPharmaciesFields = {
  uuid: string;
  specialtyPharmacyName: string;
  allowShipmentTracking: boolean;
  phoneNumber: string;
  emailAddress: string;
  streetAddressLine1: string;
  streetAddressLine2: string;
  city: string;
  state: string;
  zipCode: string;
};

const { MAX, PHONE_NUMBER, EMAIL_ADDRESS, ZIP_CODE, REQUIRED } = RULES;

export const specialtyPharmacyNameField: AppField<Pick<SpecialtyPharmaciesFields, 'specialtyPharmacyName'>> = {
  type: 'input',
  name: 'specialtyPharmacyName',
  renderType: 'TextArea',
  rows: 2,
  label: text['Specialty Pharmacy Name'],
  placeholder: stringModifier(text['{0} Characters Max'], '50'),
  rules: [MAX({ max: 50 }), REQUIRED({ requiredFieldName: text['Specialty Pharmacy Name'] })],
  maxLength: 51,
};

export const allowShipmentTrackingField: AppField<Pick<SpecialtyPharmaciesFields, 'allowShipmentTracking'>> = {
  type: 'switch',
  name: 'allowShipmentTracking',
  label: text['Allow Shipment Tracking?'],
  labels: {
    checked: text['Yes'],
    unchecked: text['No'],
  },
};

export const phoneNumberField: AppField<Pick<SpecialtyPharmaciesFields, 'phoneNumber'>> = {
  type: 'input',
  name: 'phoneNumber',
  label: text['Phone Number'],
  placeholder: getInputPlaceholder(text['Phone Number']),
  rules: [PHONE_NUMBER(), REQUIRED({ requiredFieldName: text['Phone Number'] })],
};

export const emailAddressField: AppField<Pick<SpecialtyPharmaciesFields, 'emailAddress'>> = {
  type: 'input',
  name: 'emailAddress',
  label: text['Email Address'],
  placeholder: getInputPlaceholder(text['Email Address']),
  rules: [EMAIL_ADDRESS()],
};

export const streetAddressLine1Field: AppField<Pick<SpecialtyPharmaciesFields, 'streetAddressLine1'>> = {
  type: 'input',
  name: 'streetAddressLine1',
  label: stringModifier(text['Street Address Line {0}'], '1'),
  placeholder: getInputPlaceholder(text['Street Address']),
};

export const streetAddressLine2Field: AppField<Pick<SpecialtyPharmaciesFields, 'streetAddressLine2'>> = {
  type: 'input',
  name: 'streetAddressLine2',
  label: stringModifier(text['Street Address Line {0}'], '2'),
  placeholder: getInputPlaceholder(text['Street Address']),
};

export const cityField: AppField<Pick<SpecialtyPharmaciesFields, 'city'>> = {
  type: 'input',
  name: 'city',
  label: text['City'],
  placeholder: getInputPlaceholder(text['City']),
};

export const selectStateField: AppField<Pick<SpecialtyPharmaciesFields, 'state'>> = {
  type: 'select',
  name: 'state',
  label: text['State'],
  placeholder: getSelectPlaceholder(text['State']),
  options: selectionStatesUsaOptions,
};

export const zipCodeField: AppField<Pick<SpecialtyPharmaciesFields, 'zipCode'>> = {
  type: 'input',
  name: 'zipCode',
  label: text['Zip Code'],
  placeholder: getInputPlaceholder(text['Zip Code']),
  maxLength: 5,
  rules: [ZIP_CODE()],
};
