import { AppDatePickerProps } from 'components/fields/AppDatePicker/AppDatePicker';
import { AppInputProps } from 'components/fields/AppInput/AppInput';
import { AppSelectProps, OptionType, WithSelectRequest } from 'components/fields/AppSelect/AppSelect';
import { AppField, AppFields } from 'components/fields/types';
import { emptyField, fieldModifier } from 'components/fields/utils';
import { text } from 'constants/texts';
import {
  additionalServicesField,
  contactCardTemplateField,
  emailTemplateField,
  getActiveNotificationTilesList,
  getAdditionalServiceRequestData,
  getMilestoneTrackerListRequestData,
  getPharmacyList,
  getSurveyRequestData,
  milestoneDescriptionField,
  milestoneField,
  milestoneMetaDataFieldDescription,
  milestoneMetaDataFieldName,
  milestoneNameField,
  milestoneTrackerField,
  notificationTileField,
  pushNotificationTemplateField,
  smsKeywordTemplateField,
  smsTemplateField,
  surveyField,
  timeBaseTriggerActionsField,
  timeMetricsTriggerField,
  timeTriggerField,
} from 'pages/ProgramDetails/programTabComponents/BusinessRules/BusinessRuleDetails/components/BusinessRuleEditContent/fields';
import {
  ActionBusinessRuleFields,
  UpdateBusinessRuleFields,
} from 'pages/ProgramDetails/programTabComponents/BusinessRules/components/CreateBusinessRuleModal/types';
import { queryKeys } from 'services/constants';
import { getFinancialAssistanceList } from 'services/financialAssistance/requests';
import { FinancialAssistanceType, GetFinancialAssistanceListDTO } from 'services/financialAssistance/types';
import { AttributeType } from 'services/rules/meta/types';
import { BusinessRuleDTO } from 'services/rules/types';
import { Action, ActionMetaField, ActionType, Attribute, BusinessRulesMeta, Operator } from 'types/businessRuleMeta';
import { MilestoneTracker } from 'types/milestoneTracker';
import { BusinessRule, BusinessRuleCondition, TimeMetricsType } from 'types/rules';
import { getDateFromUnknown } from 'utils/date';
import { getSelectPlaceholder } from 'utils/strings';
import { RULES } from 'utils/validations';

export const updateBusinessRuleInitialValues = (rule: BusinessRule): UpdateBusinessRuleFields => ({
  name: rule.name,
  description: rule.description,
  actions: rule.actions.length
    ? rule.actions.map(({ key }) => ({
        name: key,
        ...getActionPayloadData(rule.actionsMeta),
      }))
    : [{}],
  trigger: rule.trigger || undefined,
  conditions: rule.conditions.and.length
    ? rule.conditions.and.map((condition) => ({
        attribute: condition.key,
        operator: condition.operator,
        value: condition.type === 'date' ? getDateFromUnknown(condition.value, null) : condition.value,
      }))
    : [{}],
});

export const timeMetricsOptions: OptionType<TimeMetricsType>[] = [
  {
    label: 'Hours',
    value: 'HOUR',
  },
  {
    label: 'Days',
    value: 'DAY',
  },
  {
    label: 'Weeks',
    value: 'WEEK',
  },
];

export const getTimeValueFromTimeMetrics = (timeMetrics?: TimeMetricsType) => {
  switch (timeMetrics) {
    case 'HOUR': {
      return new Array(23).fill(1).map((a, index) => ({
        label: (index + 1).toString(),
        value: (index + 1).toString(),
      }));
    }
    case 'DAY': {
      return new Array(30).fill(1).map((a, index) => ({
        label: (index + 1).toString(),
        value: (index + 1).toString(),
      }));
    }
    case 'WEEK': {
      return new Array(4).fill(1).map((a, index) => ({
        label: (index + 1).toString(),
        value: (index + 1).toString(),
      }));
    }
    default: {
      return '';
    }
  }
};

export const getAllowedActions = (
  index: number,
  selectedActions: ActionBusinessRuleFields[] = [],
  allActions: Action[] = []
) => {
  const currentAction = selectedActions[index];

  return allActions.filter(
    (action) => !selectedActions.find(({ name }) => name === action.key && name !== currentAction.name)
  );
};

export const getAllowedCompareOperators = (
  operators: Operator[],
  operatorsLabels: BusinessRulesMeta['operatorLabels'],
  selectedAttributeType: AttributeType = 'integer'
): (Operator & { label: string })[] => {
  const allowedOperatorKeys = Object.keys(operatorsLabels[selectedAttributeType] || {});

  return operators
    .filter((operator) => allowedOperatorKeys.includes(operator.key))
    .map((operator) => ({
      ...operator,
      label: operatorsLabels?.[selectedAttributeType]?.[operator.key] || '',
    }));
};

type GetActionPayloadFieldsConfig = {
  programId: string;
  orderIndex: number;
  selectedAction?: ActionType;
  actionsMetaField?: ActionMetaField[];
  selectedTracker?: MilestoneTracker;
  rulesForTrigger?: Attribute[];
  selectedRuleForTrigger?: Attribute;
  selectedTimeMetricsForTrigger?: TimeMetricsType | null;
};

export const getActionPayloadFields = ({
  selectedAction,
  actionsMetaField,
  programId,
  selectedTracker,
  rulesForTrigger,
  selectedRuleForTrigger,
  selectedTimeMetricsForTrigger,
  orderIndex,
}: GetActionPayloadFieldsConfig): {
  rightSideField: AppField<Omit<ActionBusinessRuleFields, 'name'>>;
  extraFields?: AppFields<Omit<ActionBusinessRuleFields, 'name'>>;
} => {
  switch (selectedAction) {
    case 'EMAIL':
      return { rightSideField: fieldModifier(emailTemplateField, { name: [orderIndex, 'emailTemplateId'] }) };
    case 'SURVEY_INIT':
      return {
        rightSideField: fieldModifier(surveyField, {
          name: [orderIndex, 'surveyId'],
          withRequest: getSurveyRequestData(programId),
        }),
      };
    case 'PUSH_NOTIFICATION':
      return {
        rightSideField: fieldModifier(pushNotificationTemplateField, {
          name: [orderIndex, 'pushNotificationTemplateId'],
        }),
      };
    case 'NOTIFICATION_TILE_SIMPLE_TEXT':
      return {
        rightSideField: fieldModifier(notificationTileField, {
          name: [orderIndex, 'notificationTileId'],
          withRequest: getActiveNotificationTilesList(programId),
        }),
      };
    case 'ADDITIONAL_SERVICE_INIT':
      return {
        rightSideField: fieldModifier(additionalServicesField, {
          name: [orderIndex, 'additionalServiceUuid'],
          withRequest: getAdditionalServiceRequestData(programId),
        }),
      };
    case 'POSTPONED_EVENT_INIT':
      return {
        rightSideField: fieldModifier(timeBaseTriggerActionsField, {
          name: [orderIndex, 'timeBaseTriggerActions'],
          options: rulesForTrigger?.map((rule) => ({
            label: rule.label,
            value: rule.key,
          })),
        }),
        extraFields: [
          [
            fieldModifier(timeMetricsTriggerField, {
              name: [orderIndex, 'timeMetricsTrigger'],
              options: timeMetricsOptions,
              disabled: !selectedRuleForTrigger,
            }),
            fieldModifier(timeTriggerField, {
              name: [orderIndex, 'timeTrigger'],
              ...(selectedTimeMetricsForTrigger && {
                options: getTimeValueFromTimeMetrics(selectedTimeMetricsForTrigger),
              }),
              disabled: !selectedTimeMetricsForTrigger,
            }),
          ],
        ],
      };
    case 'SMS':
      return { rightSideField: fieldModifier(smsTemplateField, { name: [orderIndex, 'smsTemplateId'] }) };
    case 'CONTACT_CARD':
      return {
        rightSideField: fieldModifier(contactCardTemplateField, {
          name: [orderIndex, 'pharmacyId'],
          withRequest: getPharmacyList(programId),
        }),
      };
    case 'SMS_KEYWORD':
      return {
        rightSideField: fieldModifier(smsKeywordTemplateField, {
          name: [orderIndex, 'keywordTemplateId'],
        }),
      };
    case 'UPDATE_MILESTONE':
      return {
        rightSideField: fieldModifier(milestoneTrackerField, {
          name: [orderIndex, 'milestoneTrackerId'],
          withRequest: getMilestoneTrackerListRequestData(programId),
        }),
        extraFields: [
          [
            fieldModifier(milestoneField, {
              name: [orderIndex, 'milestoneId'],
              disabled: !selectedTracker,
              options: selectedTracker?.milestones.map((milestone) => ({
                label: milestone.title,
                value: milestone.uuid,
              })),
            }),
            fieldModifier(milestoneNameField, {
              name: [orderIndex, 'milestoneName'],
              disabled: !selectedTracker,
            }),
          ],
          [
            fieldModifier(milestoneDescriptionField, {
              name: [orderIndex, 'milestoneDescription'],
              disabled: !selectedTracker,
              // TextArea doesn't support disabled functionality
              renderType: selectedTracker ? 'TextArea' : 'DEFAULT',
            }),
            fieldModifier(emptyField, { emptyKey: 'milestoneDescriptionEmptyField' }),
          ],
          [
            fieldModifier(milestoneMetaDataFieldName, {
              name: [orderIndex, 'milestoneMetaDataFieldName'],
              disabled: !selectedTracker,
              options: actionsMetaField?.map((action) => ({
                label: action,
                value: action,
              })),
            }),
            fieldModifier(milestoneMetaDataFieldDescription, {
              name: [orderIndex, 'milestoneMetaDataFieldDescription'],
              disabled: !selectedTracker,
            }),
          ],
        ],
      };
    default:
      return {
        rightSideField: fieldModifier(emptyField as AppField<{ actionEmptyField }>, {
          emptyKey: 'actionEmptyPayloadField',
        }),
      };
  }
};

export const getActionPayloadData = (
  actionMeta: BusinessRule['actionsMeta']
): Omit<ActionBusinessRuleFields, 'name'> => ({
  milestoneMetaDataFieldDescription: actionMeta.milestoneData.milestoneMetaFields?.[0].description || undefined,
  milestoneMetaDataFieldName: actionMeta.milestoneData.milestoneMetaFields?.[0].field || undefined,
  milestoneDescription: actionMeta.milestoneData.milestoneDescription || undefined,
  milestoneName: actionMeta.milestoneData.milestoneName || undefined,
  milestoneTrackerId: actionMeta.milestoneData.milestoneTrackerId || undefined,
  milestoneId: actionMeta.milestoneData.milestoneId || undefined,
  smsTemplateId: actionMeta.smsMessage || undefined,
  pushNotificationTemplateId: actionMeta.pushNotificationTemplateId || undefined,
  notificationTileId: actionMeta.notificationTileId || undefined,
  emailTemplateId: actionMeta.emailTemplateId || undefined,
  surveyId: actionMeta.surveyId || undefined,
  additionalServiceUuid: actionMeta.additionalServiceUuid || undefined,
  timeBaseTriggerActions: actionMeta.postponedEventData.postponed_rule_uuid || undefined,
  timeMetricsTrigger: actionMeta.postponedEventData.postponed_value || undefined,
  timeTrigger: actionMeta.postponedEventData.postponed_count || undefined,
  // Contact Card Fields for Send Contact Action
  templateId: actionMeta.contactCardData?.templateId ?? null,
  pharmacyId: actionMeta.contactCardData?.pharmacyId ?? null,
  // SMS_KEYWORD
  keywordTemplateId: actionMeta.smsKeywordData.keywordTemplateId ?? null,
});

export const fromUpdateBusinessRuleFieldsToBusinessRule = (
  businessRule: BusinessRule,
  { name, description, conditions, actions, trigger }: UpdateBusinessRuleFields
): BusinessRule => ({
  ...businessRule,
  name,
  description,
  trigger,
  actions: actions.map((action) => ({
    key: action.name || 'EMAIL',
    label: action.name || '',
    payload: {
      emailTemplateId: action.emailTemplateId || null,
      surveyId: action.surveyId || null,
      notificationTileId: action.notificationTileId || null,
      pushNotificationTemplateId: action.pushNotificationTemplateId || null,
      smsTemplateId: action.smsTemplateId || null,
      milestoneId: action.milestoneId || null,
      milestoneTrackerId: action.milestoneTrackerId || null,
      additionalServiceUuid: action.additionalServiceUuid || null,
      milestoneName: action.milestoneName || null,
      milestoneDescription: action.milestoneDescription || null,
      milestoneMetaDataFieldName: action.milestoneMetaDataFieldName || null,
      milestoneMetaDataFieldDescription: action.milestoneMetaDataFieldDescription || null,
      timeBaseTriggerActions: action.timeBaseTriggerActions || null,
      timeMetricsTrigger: action.timeMetricsTrigger || 'DAY',
      timeTrigger: action.timeTrigger || 0,
      // Contact Card Fields for Send Contact Action
      pharmacyId: action.pharmacyId ?? null,
      templateId: action.templateId ?? null,
      // SMS KEYWORD
      keywordTemplateId: action.keywordTemplateId ?? null,
    },
  })),
  conditions: {
    and:
      conditions.map(
        (condition) =>
          ({
            key: condition.attribute,
            operator: condition.operator,
            value: condition.value,
          } as BusinessRuleCondition)
      ) || [],
  },
});

export const getActionDTOMeta = (rule: BusinessRule) => {
  const meta: BusinessRuleDTO['actions_meta'] = {};

  rule.actions.forEach(({ key, payload }) => {
    switch (key) {
      case 'EMAIL':
        meta!.email_data = {
          template_id: payload?.emailTemplateId ?? '',
        };
        break;
      case 'SURVEY_INIT':
        meta!.survey_data = {
          survey_uuid: payload?.surveyId ?? '',
        };
        break;
      case 'UPDATE_MILESTONE':
        meta!.milestone_data = {
          milestone_tracker_uuid: payload?.milestoneTrackerId ?? '',
          milestone_uuid: payload?.milestoneId ?? '',
          milestone_name: payload?.milestoneName ?? '',
          milestone_description: payload?.milestoneDescription ?? '',
          meta_fields: [
            {
              field: payload?.milestoneMetaDataFieldName ?? '',
              description: payload?.milestoneMetaDataFieldDescription ?? '',
            },
          ],
        };
        break;
      case 'PUSH_NOTIFICATION':
        meta!.push_data = {
          template_id: payload?.pushNotificationTemplateId ?? '',
        };
        break;
      case 'NOTIFICATION_TILE_SIMPLE_TEXT':
        meta!.notification_tile_data = {
          // notification_tile_type: payload?.notificationTileType ?? '', TODO(implement): not supported yet notification tile type
          notification_tile_uuid: payload?.notificationTileId ?? '',
        };
        break;
      case 'SMS':
        meta!.sms_data = {
          template_id: payload?.smsTemplateId ?? '',
        };
        break;
      case 'ADDITIONAL_SERVICE_INIT':
        meta!.additional_service_data = {
          additional_service_uuid: payload?.additionalServiceUuid ?? '',
        };
        break;
      case 'POSTPONED_EVENT_INIT':
        meta!.postponed_event_data = {
          postponed_rule_uuid: payload?.timeBaseTriggerActions ?? '',
          postponed_count: payload?.timeTrigger ?? 0,
          postponed_value: payload?.timeMetricsTrigger ?? 'DAY',
        };
        break;
      case 'CONTACT_CARD':
        meta!.contact_card_data = {
          template_uuid: payload?.templateId ?? null,
          pharmacy_uuid: payload?.pharmacyId ?? '',
        };
        break;
      case 'SMS_KEYWORD':
        meta!.sms_keyword_data = {
          template_id: payload?.keywordTemplateId ?? '',
        };
        break;
    }
  });

  return meta;
};

type ConditionValueFieldProps = Partial<
  Pick<AppField, 'type' | 'rules'> &
    Pick<AppSelectProps<any>, 'options' | 'defaultValue' | 'withRequest'> &
    Pick<AppInputProps, 'renderType' | 'numberInputConfig' | 'placeholder'> &
    Pick<AppDatePickerProps, 'format'>
>;

const conditionValueFieldPropsMapper: Record<AttributeType, ConditionValueFieldProps> = {
  boolean: {
    type: 'select',
    options: [
      { value: true, label: text['True'] },
      { value: false, label: text['False'] },
    ],
  },
  integer: {
    type: 'input',
    renderType: 'NUMBER',
    numberInputConfig: { canBeNegative: true, canBeFloat: true },
  },
  string: {
    type: 'input',
  },
  date: {
    type: 'date-picker',
    format: 'MMMM dd, yyyy',
  },
  rule: {
    type: 'select',
    options: [
      { value: true, label: text['True'] },
      { value: false, label: text['False'] },
    ],
    placeholder: text['Value'],
  },
};

export const fapLabelsMapping: Record<FinancialAssistanceType, string> = {
  COPAY: text['Co-pay'],
  PAP: text['PAP'],
  BRIDGE: text['Bridge'],
  FOUNDATION: text['Foundation'],
  QUICKSTART: text['Quickstart'],
};

export const financialProgramTypeOptions: OptionType<FinancialAssistanceType>[] = [
  { value: 'COPAY', label: text['Co-pay'] },
  { value: 'PAP', label: text['PAP'] },
  { value: 'BRIDGE', label: text['Bridge'] },
  { value: 'FOUNDATION', label: text['Foundation'] },
  { value: 'QUICKSTART', label: text['Quickstart'] },
];

const getFinancialAssistancesRequestData = (programId: string): WithSelectRequest<GetFinancialAssistanceListDTO> => ({
  queryKey: [queryKeys.getFinancialAssistanceList, programId],
  optionsMapper: (data) =>
    data?.results?.map((item) => ({
      label: item.type ? fapLabelsMapping[item.type] : '',
      value: item.uuid ?? '',
    })) || [],
  request: () => getFinancialAssistanceList(programId),
});

type GetConditionValueFieldPropsConfig = {
  type: AttributeType;
  programId: string;
  selectedAttributeKey?: string;
};

export const getConditionValueFieldProps = ({
  selectedAttributeKey,
  type,
  programId,
}: GetConditionValueFieldPropsConfig): ConditionValueFieldProps => {
  switch (selectedAttributeKey) {
    case 'financial_program_type':
      return {
        type: 'select',
        placeholder: getSelectPlaceholder(text['Financial Program Type']),
        options: financialProgramTypeOptions,
        rules: [RULES.REQUIRED({ requiredFieldName: text['Financial Program Type'] })],
      };
    case 'financial_program_id':
      return {
        type: 'select',
        placeholder: getSelectPlaceholder(text['Financial Assistance Program']),
        withRequest: getFinancialAssistancesRequestData(programId),
        rules: [RULES.REQUIRED({ requiredFieldName: text['Financial Assistance Program'] })],
      };
    default:
      return conditionValueFieldPropsMapper[type];
  }
};
