import { Form } from 'antd';
import { FormInstance } from 'antd/es';
import { ModalActionButtons, Spacer } from 'components';
import { text } from 'constants/texts';
import { defaultAnswers } from 'pages/ProgramDetails/common/constants';

import { QuestionsForm } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Surveys/components/CreateSurveyModal/QuestionsForm';
import { DynamicSurveyFields } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Surveys/components/CreateSurveyModal/types';
import { useEffect, useState } from 'react';
import { Callback } from 'types/helpers';

export type SurveyDynamicFormProps = {
  form: FormInstance<DynamicSurveyFields>;
  parentGoBack?: Callback;
  isLoading: boolean;
};

const { useWatch } = Form;

type FormListAddFunction = (defaultValue?: any, insertIndex?: number) => void;

export const SurveyDynamicForm = ({ form, parentGoBack, isLoading }: SurveyDynamicFormProps) => {
  const [questionIndex, setQuestionIndex] = useState(0);
  const [shouldTriggerNextStep, setShouldTriggerNextStep] = useState(false);

  const questionsStepCount = useWatch('questions', form)?.length;
  const canGoNext = questionsStepCount > questionIndex + 1; // +1 default step count is 1;

  useEffect(() => {
    if (!shouldTriggerNextStep || !canGoNext) return;
    setQuestionIndex(questionIndex + 1);
    setShouldTriggerNextStep(false);
  }, [shouldTriggerNextStep, canGoNext]);

  const handleAddQuestion = (add: FormListAddFunction) => {
    form.validateFields().then(() => {
      add({ answers: defaultAnswers });
      setShouldTriggerNextStep(true);
    });
  };

  const submitHandler = () => (canGoNext ? setQuestionIndex(questionIndex + 1) : form.submit());

  const goBackHandler = () => (!questionIndex ? parentGoBack?.() : setQuestionIndex(questionIndex - 1));

  return (
    <>
      <Form.List name='questions'>
        {(fields, { add }) =>
          fields.map(({ key, name }, index) => (
            <QuestionsForm
              visible={index === questionIndex}
              onAdd={() => handleAddQuestion(add)}
              key={key}
              orderIndex={name}
            />
          ))
        }
      </Form.List>
      <Spacer height={32} type='flex' />
      <ModalActionButtons
        onCancel={goBackHandler}
        submitText={canGoNext ? text['Next'] : text['Create']}
        cancelText={text['Back']}
        onSubmit={submitHandler}
        htmlType='button'
        isLoading={isLoading}
      />
    </>
  );
};
