import { MilestoneTrackerDTO, MilestoneTrackerStepDTO } from 'services/milestoneTrackers/types';
import { ServerObjectMappers } from 'types/apiTypes';
import { MilestoneTracker, MilestoneTrackerStep } from 'types/milestoneTracker';

const {
  toMilestoneTrackerStep,
  toMilestoneTrackerStepDTO,
}: ServerObjectMappers<'milestoneTrackerStep', MilestoneTrackerStepDTO, MilestoneTrackerStep> = {
  toMilestoneTrackerStep: (dto) => ({
    uuid: dto.uuid,
    title: dto.title ?? '',
  }),
  toMilestoneTrackerStepDTO: (instance) => ({
    uuid: instance.uuid,
    title: instance.title,
  }),
};

export const {
  toMilestoneTracker,
  toMilestoneTrackerDTO,
}: ServerObjectMappers<'milestoneTracker', MilestoneTrackerDTO, MilestoneTracker, null, null, 'name' | 'description'> =
  {
    toMilestoneTracker: (dto) => ({
      uuid: dto.uuid ?? '',
      name: dto.name ?? '',
      description: dto.description ?? '',
      status: dto.isActive ? 'IN_USE' : 'DRAFT',
      milestones: dto.milestones?.map(toMilestoneTrackerStep) ?? [],
      manufactureProgramUuid: dto.manufacture_program_uuid ?? '',
    }),
    toMilestoneTrackerDTO: (instance) => ({
      uuid: instance.uuid,
      name: instance.name,
      description: instance.description,
      isActive: instance.status === 'IN_USE',
      milestones: instance.milestones?.map(toMilestoneTrackerStepDTO),
      manufacture_program_uuid: instance.manufactureProgramUuid,
    }),
  };
