import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

export const useFirstMountEffect = (callback: EffectCallback, deps: DependencyList) => {
  const mounted = useRef(false);

  useEffect(() => {
    if (!mounted.current) {
      callback();
      mounted.current = true;
    }
  }, deps);
};
