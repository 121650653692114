import { BenefitDTO } from 'services/benefits/types';
import { ServerObjectMappers } from 'types/apiTypes';
import { Benefit } from 'types/benefits';

export const { toBenefit, toBenefitDTO }: ServerObjectMappers<'benefit', BenefitDTO, Benefit> = {
  toBenefit: (dto) => ({
    name: dto.name ?? '',
    description: dto.description ?? '',
    uuid: dto.uuid ?? '',
  }),
  toBenefitDTO: (instance) => ({
    name: instance.name,
    description: instance.description,
    uuid: instance.uuid,
  }),
};
