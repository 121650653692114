import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { queryKeys } from 'services/constants';
import { deleteFinancialAssistance } from 'services/financialAssistance/requests';
import { Callback } from 'types/helpers';
import { errorHandler } from 'utils/errorHandler';

export const useDeleteFinancialAssistance = (uuid?: string, onSuccess?: Callback) => {
  const { programId } = useParams();

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(deleteFinancialAssistance, {
    mutationKey: queryKeys.deleteFinancialAssistance,
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKeys.getFinancialAssistanceList);

      onSuccess?.();
    },
    onError: errorHandler,
  });

  return {
    isLoading,
    deleteFinancialAssistance: () => (uuid ? mutate({ programId: programId || '', faProgramId: uuid }) : onSuccess?.()),
  };
};
