import { AnyObject } from 'types/helpers';
import { RowFieldsGroup } from '../RowFieldsGroup/RowFieldsGroup';
import { getFieldJSX } from '../utils';
import { AppField } from '../types';

type FieldMapperProps<T extends AnyObject = AnyObject> = {
  field: AppField<T> | AppField<T>[];
  isEditMode?: boolean;
};

export const FieldMapper = <T extends AnyObject>({ field, isEditMode = true }: FieldMapperProps<T>) => {
  if (field instanceof Array)
    return <RowFieldsGroup>{field.map((field) => getFieldJSX(field, isEditMode))}</RowFieldsGroup>;

  return getFieldJSX(field, isEditMode);
};
