import { toBusinessRuleMeta } from 'hooks/api/rules/mappers';
import { useQuery } from 'react-query';
import { queryKeys } from 'services/constants';
import { getBusinessRulesMeta } from 'services/rules/meta/requests';
import { useParams } from 'react-router-dom';

export const useBusinessRulesMeta = () => {
  const { programId } = useParams();

  const { data, isLoading } = useQuery(queryKeys.getBusinessRulesMeta, () => getBusinessRulesMeta(programId || ''), {
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchOnMount: false,
    select: toBusinessRuleMeta,
  });

  return {
    isLoading,
    businessRulesMeta: data,
  };
};
