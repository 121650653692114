import { WithSelectRequest } from 'components/fields/AppSelect/AppSelect';
import { SelectUploadConfig } from 'components/fields/AppUpload/components/SelectUpload/SelectUpload';
import { AppField } from 'components/fields/types';
import { text } from 'constants/texts';
import { generalProgramFieldsTooltips } from 'constants/tooltipTexts';
import { UniversalSettingsFields } from 'pages/ProgramDetails/programTabComponents/UniversalSettings/types';
import { queryKeys } from 'services/constants';
import { getManufactures } from 'services/programs/programs';
import { GetManufacturersResponse } from 'services/programs/types';
import { RULES } from 'utils/validations';
import { toManufacturerSelectOptions } from 'hooks/api/programs/mappers';

// Constants
const { HEX, REQUIRED, MAX, LETTERS_ONLY, LINK, PHONE_NUMBER } = RULES;

const {
  manufacturerId,
  programType,
  programName,
  brandEmblem,
  programLogo,
  primaryColor,
  secondaryColor,
  contactNumber,
  contactHoursText,
  importantSafetyInformationLink,
  prescriberInformationLink,
  welcomeCardText,
  welcomeCardHeader,
  welcomeCardImage,
  contactPopupDescription,
  contactPopupTitle,
} = generalProgramFieldsTooltips;

export const imageSelectUploadConfig: SelectUploadConfig = {
  title: text['Add an Image File'],
  description: text['Select a file from this programs media section or upload a new file'],
  closeButtonText: text['Cancel'],
  confirmButtonText: text['Save'],
};

// Requests
const programManufacturersRequestData: WithSelectRequest<GetManufacturersResponse> = {
  queryKey: queryKeys.getManufactures,
  optionsMapper: (data) => data?.map(toManufacturerSelectOptions) || [],
  request: getManufactures,
};

// Fields
export const programManufacturerField: AppField<Pick<UniversalSettingsFields, 'manufacturerId'>> = {
  type: 'select',
  name: 'manufacturerId',
  withRequest: programManufacturersRequestData,
  placeholder: 'Select Manufacturer',
  label: 'Manufacturer Name',
  rules: [REQUIRED({ requiredFieldName: 'Manufacturer Name' })],
  tooltip: manufacturerId,
};

export const programTypeField: AppField<Pick<UniversalSettingsFields, 'programType'>> = {
  type: 'select',
  name: 'programType',
  options: [
    { label: 'Co-pay', value: 'COPAY' },
    { label: 'Hub', value: 'HUB' },
  ],
  placeholder: 'Select',
  label: 'Program Type',
  tooltip: programType,
  rules: [REQUIRED({ requiredFieldName: 'Program Type' })],
};

export const programNameField: AppField<Pick<UniversalSettingsFields, 'programName'>> = {
  type: 'input',
  name: 'programName',
  placeholder: 'Enter Program Name',
  label: 'Program Name',
  tooltip: programName,
  rules: [REQUIRED({ requiredFieldName: 'Program Name' }), MAX({ max: 20 }), LETTERS_ONLY({ allowedLetters: ['™'] })],
};

export const programLogoField: AppField<Pick<UniversalSettingsFields, 'programLogo'>> = {
  type: 'upload',
  name: 'programLogo',
  label: 'Program Logo',
  tooltip: programLogo,
  filesCount: 1,
  loadFileType: 'images',
  fileTypes: ['PNG', 'JPG'],
  cropper: {
    sizes: { width: 378, height: 240 },
  },
};

export const brandEmblemField: AppField<Pick<UniversalSettingsFields, 'brandEmblem'>> = {
  type: 'upload',
  name: 'brandEmblem',
  label: 'Program Emblem',
  tooltip: brandEmblem,
  filesCount: 1,
  loadFileType: 'images',
  cropper: {
    sizes: { width: 48, height: 48 },
  },
  fileTypes: ['PNG', 'JPG'],
};

export const primaryColorField: AppField<Pick<UniversalSettingsFields, 'primaryColor'>> = {
  type: 'color-picker',
  name: 'primaryColor',
  placeholder: 'Enter Hex Code',
  label: 'Select Primary Color',
  tooltip: primaryColor,
  rules: [HEX()],
};

export const secondaryColorField: AppField<Pick<UniversalSettingsFields, 'secondaryColor'>> = {
  type: 'color-picker',
  name: 'secondaryColor',
  placeholder: 'Enter Hex Code',
  label: 'Select Secondary Color',
  tooltip: secondaryColor,
  rules: [HEX()],
};

export const contactNumberField: AppField<Pick<UniversalSettingsFields, 'contactNumber'>> = {
  type: 'input',
  name: 'contactNumber',
  placeholder: '000-000-0000',
  label: 'Program Contact Number',
  tooltip: contactNumber,
  rules: [REQUIRED({ requiredFieldName: 'Contact Number' }), PHONE_NUMBER()],
};

export const contactHoursTextField: AppField<Pick<UniversalSettingsFields, 'contactHoursText'>> = {
  type: 'input',
  renderType: 'TextArea',
  rows: 2,
  name: 'contactHoursText',
  placeholder: 'Enter Contact Hours Text',
  label: 'Contact Hours Text',
  tooltip: contactHoursText,
  rules: [REQUIRED({ requiredFieldName: 'Contact Hours Text' }), MAX({ max: 75 })],
};

export const contactPopupTitleField: AppField<Pick<UniversalSettingsFields, 'contactPopupTitle'>> = {
  type: 'input',
  name: 'contactPopupTitle',
  placeholder: 'Enter Contact Popup Title',
  label: 'Contact Popup Title',
  tooltip: contactPopupTitle,
  rules: [REQUIRED({ requiredFieldName: 'Contact Popup Title' })],
};

export const contactPopupDescriptionField: AppField<Pick<UniversalSettingsFields, 'contactPopupDescription'>> = {
  type: 'input',
  renderType: 'TextArea',
  rows: 2,
  name: 'contactPopupDescription',
  placeholder: 'Enter Contact Popup Description',
  label: 'Contact Popup Description',
  tooltip: contactPopupDescription,
  rules: [REQUIRED({ requiredFieldName: 'Contact Popup Description' })],
};

export const importantSafetyInformationLinkField: AppField<
  Pick<UniversalSettingsFields, 'importantSafetyInformationLink'>
> = {
  type: 'input',
  name: 'importantSafetyInformationLink',
  placeholder: 'Enter Important Safety Information Link',
  label: 'Important Safety Information Link',
  tooltip: importantSafetyInformationLink,
  rules: [REQUIRED({ requiredFieldName: 'Important Safety Information Link' }), LINK()],
};

export const prescriberInformationLinkField: AppField<Pick<UniversalSettingsFields, 'prescriberInformationLink'>> = {
  type: 'input',
  name: 'prescriberInformationLink',
  placeholder: 'Enter Prescriber Information Link',
  label: 'Prescriber Information Link',
  tooltip: prescriberInformationLink,
  rules: [REQUIRED({ requiredFieldName: 'Prescriber Information Link' }), LINK()],
};

export const welcomeCardHeaderField: AppField<Pick<UniversalSettingsFields, 'welcomeCardHeader'>> = {
  type: 'input',
  name: 'welcomeCardHeader',
  placeholder: 'Enter Welcome Card Header',
  label: 'Welcome Card Header',
  tooltip: welcomeCardHeader,
  rules: [REQUIRED({ requiredFieldName: 'Welcome Card Header' }), MAX({ max: 35 })],
};

export const welcomeCardImageField: AppField<Pick<UniversalSettingsFields, 'welcomeCardImage'>> = {
  type: 'upload',
  name: 'welcomeCardImage',
  label: 'Welcome Card Image',
  tooltip: welcomeCardImage,
  filesCount: 1,
  loadFileType: 'images',
  cropper: { sizes: { width: 480, height: 311 } },
  fileTypes: ['PNG', 'JPG'],
  rules: [REQUIRED({ requiredFieldName: 'Welcome Card Image' })],
};

export const welcomeCardTextField: AppField<Pick<UniversalSettingsFields, 'welcomeCardText'>> = {
  type: 'input',
  renderType: 'TextArea',
  rows: 2,
  name: 'welcomeCardText',
  label: 'Welcome Card Text',
  placeholder: 'Enter Welcome Card Text',
  tooltip: welcomeCardText,
  rules: [REQUIRED({ message: 'Welcome Card Text' }), MAX({ max: 1000 })],
};
