import { Form } from 'antd';
import { Rule } from 'antd/lib/form';
import { AppInput, Button } from 'components';
import { AppFieldInfo } from 'components/fields/AppFieldInfo/AppFieldInfo';
import { text } from 'constants/texts';
import { RULES } from 'utils/validations';

import './styles.scss';

export type AnswerFieldProps = {
  childIndex: number;
  remove: (index: number | number[]) => void;
  isEditMode?: boolean;
};

const { REQUIRED } = RULES;

export const AnswerField = ({ childIndex, remove, isEditMode = true }: AnswerFieldProps) => {
  const labelText = `${text['Answer choice']} ${childIndex + 1}`;
  const showDeleteButton = childIndex > 1 && isEditMode;
  const rules: Rule[] = [REQUIRED({ requiredFieldName: 'Answer' })];

  const handleDelete = () => remove(childIndex);

  return (
    <div className='answer-field'>
      <Form.Item rules={rules} name={[childIndex, 'text']} label={labelText}>
        {isEditMode ? <AppInput placeholder='Enter choice' /> : <AppFieldInfo />}
      </Form.Item>
      {showDeleteButton && (
        <Button className='delete-answer' onClick={handleDelete} type='ghost'>
          Delete
        </Button>
      )}
    </div>
  );
};
