import { useState } from 'react';
import { useDebounce } from './useDebounce';
import { usePrevious } from './usePrevious';

export const useSearch = (delay = 400, initialState = '') => {
  const [keyword, setKeyword] = useState(initialState);
  const debouncedSearchKeyword = useDebounce(keyword, delay);
  const previousDebouncedSearchKeyword = usePrevious(debouncedSearchKeyword) || '';

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
  };

  return {
    debouncedSearchKeyword,
    previousDebouncedSearchKeyword,
    handleSearch,
    keyword,
  };
};
