import { InfoCircleOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { AxiosError } from 'axios';
import { AppInput, Button } from 'components';
import { useAuthHandlers } from 'pages/AuthPages/useAuthHandlers';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { login } from 'services/auth/auth';
import { Credentials } from 'services/auth/types';
import { queryKeys } from 'services/constants';
import { axiosErrorHandler } from 'utils/errors';
import { emailFieldRules, passwordFieldRules } from '../authFieldsRules';

import './style.scss';

type LoginFormProps = {
  openForgotModal?: () => void;
};

export const LoginForm = ({ openForgotModal }: LoginFormProps) => {
  const [form] = useForm();
  const { onSuccess, onError } = useAuthHandlers();
  const [emailValidateTrigger, setEmailValidateTrigger] = useState('onBlur');

  const handleError = (error: AxiosError) => {
    if (error.response?.status === 410) {
      openForgotModal?.();
    }
  };

  const { mutate, isLoading } = useMutation(login, {
    mutationKey: queryKeys.login,
    onSuccess,
    onError: (error) => {
      onError(error);
      axiosErrorHandler(error, handleError);
    },
  });

  const onFinish = async (values: Credentials) => {
    mutate(values);
  };

  const onBlurHandler = () => setEmailValidateTrigger('onChange');

  return (
    <>
      <Form<Credentials>
        form={form}
        layout='vertical'
        autoComplete='on'
        onFinish={onFinish}
        className='login-form-wrapper'
        initialValues={{ remember: true }}
      >
        <Form.Item
          name='email'
          label='Email'
          rules={emailFieldRules}
          validateTrigger={emailValidateTrigger}
          tooltip={{ title: 'Please input your email!', icon: <InfoCircleOutlined /> }}
        >
          <AppInput onBlur={onBlurHandler} placeholder='Enter Email Address' className='login-input' />
        </Form.Item>
        <Form.Item
          name='password'
          label='Password'
          rules={passwordFieldRules}
          tooltip={{ title: 'Please input your password!', icon: <InfoCircleOutlined /> }}
        >
          <AppInput.Password size='large' placeholder='Enter Password' className='pl-input login-input' />
        </Form.Item>
        <Form.Item>
          <Button loading={isLoading} htmlType='submit'>
            Log In
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
