import { SVGProps } from 'react';

export const VideoFileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M13.474 1H2.526C1.68321 1 1 1.68321 1 2.526V13.474C1 14.3168 1.68321 15 2.526 15H13.474C14.3168 15 15 14.3168 15 13.474V2.526C15 1.68321 14.3168 1 13.474 1Z'
      stroke='#1D242D'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M5 1V15' stroke='#1D242D' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M11 1V15' stroke='#1D242D' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M1 8H15' stroke='#1D242D' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M1 4H5' stroke='#1D242D' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M1 12H5' stroke='black' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M12 12H15' stroke='black' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M12 4H15' stroke='black' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);
