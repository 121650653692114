import { ConfirmModal } from 'components';
import { ConfirmModalProps } from 'components/ConfirmModal/ConfirmModal';
import { text } from 'constants/texts';

export type ConfirmModalType = 'Cancel' | 'Save';

const modalTextMapping: Record<
  ConfirmModalType,
  Pick<ConfirmModalProps, 'title' | 'description' | 'confirmTextBtn' | 'cancelTextBtn'>
> = {
  Cancel: {
    title: text["Are you sure you want to cancel the changes you've made?"],
    description:
      text[
        "By canceling these changes, your program will be reverted to it's last save state and no changes will be saved."
      ],
    cancelTextBtn: text['Never mind'],
    confirmTextBtn: text["I'm Sure"],
  },
  Save: {
    title: text['Are you sure you want to save your changes?'],
    description: text['These changes will be saved and this cannot be undone.'],
    cancelTextBtn: text['Cancel'],
    confirmTextBtn: text['Save'],
  },
};

type ProgramDetailsConfirmModalProps = { type: ConfirmModalType } & Pick<
  ConfirmModalProps,
  'modalRef' | 'cancelHandler' | 'confirmHandler' | 'isLoading'
>;

export const ProgramDetailsConfirmModal = ({ type, ...rest }: ProgramDetailsConfirmModalProps) => (
  <ConfirmModal {...modalTextMapping[type]} {...rest} />
);
