import { ClientUpdateMediaData } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Media/components/CreateMediaModal/types';
import { useMutation, useQueryClient } from 'react-query';
import { queryKeys } from 'services/constants';
import { updateMedia } from 'services/medias/requests';
import { errorHandler } from 'utils/errorHandler';
import { updateMediaMapper } from './mappers';

export const useUpdateMedia = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(updateMedia, {
    mutationKey: queryKeys.updateMedia,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.getMedias);
    },
    onError: errorHandler,
  });

  return {
    isLoading,
    smallUpdateMedia: (media: ClientUpdateMediaData) => mutateAsync(updateMediaMapper(media)),
  };
};
