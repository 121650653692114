import { MoreOption } from 'components/tableCells/MoreOptionsCell/MoreOptionsCell';
import { text } from 'constants/texts';
import { useDeleteMilestoneTracker } from 'hooks/api/milestoneTrackers/useDeleteMilestoneTracker';
import { useUpdateMilestoneTracker } from 'hooks/api/milestoneTrackers/useUpdateMilestoneTracker';
import { MilestoneTracker } from 'types/milestoneTracker';
import { stringModifier } from 'utils/strings';
import { usePermissionsHandler } from 'hooks';

export const useMilestoneTrackerOptions = (
  tracker: MilestoneTracker
): { options: MoreOption[]; isLoading: boolean } => {
  const [isEditMilestoneTrackerAllowed, isDeleteMilestoneTrackerAllowed] = usePermissionsHandler([
    'milestone_trackers.edit',
    'milestone_trackers.delete',
  ]);

  const { updateMilestoneTrackerStatus, isLoading: isUpdateMilestoneTrackerStatusLoading } =
    useUpdateMilestoneTracker();
  const { deleteMilestoneTracker, isLoading: isDeleteMilestoneTrackerLoading } = useDeleteMilestoneTracker(
    tracker.uuid
  );

  const isLoading = isUpdateMilestoneTrackerStatusLoading || isDeleteMilestoneTrackerLoading;

  return {
    isLoading,
    options: [
      {
        key: '1',
        label: text['Activate'],
        hide: tracker.status === 'IN_USE',
        disabled: !isEditMilestoneTrackerAllowed,
        request: () => updateMilestoneTrackerStatus(tracker, 'IN_USE'),
      },
      {
        key: '2',
        label: text['Inactive'],
        hide: tracker.status === 'DRAFT',
        disabled: !isEditMilestoneTrackerAllowed,
        request: () => updateMilestoneTrackerStatus(tracker, 'DRAFT'),
      },
      {
        key: '3',
        label: text['Delete'],
        request: deleteMilestoneTracker,
        disabled: !isDeleteMilestoneTrackerAllowed,
        modalInfo: {
          title: stringModifier(text['Delete {0}'], tracker.name),
          description: stringModifier(text['Are you sure you want to delete this {0}?'], text['Milestone Tracker']),
        },
      },
    ],
  };
};
