import { InfoCircleOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { FormProps } from 'antd/es';
import { useForm } from 'antd/es/form/Form';

import { AppInput, Button } from 'components';
import { useAuthHandlers } from 'pages/AuthPages/useAuthHandlers';
import { useMutation } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { confirmPassword, confirmResetPassword } from 'services/auth/auth';
import { ConfirmPasswordData } from 'services/auth/types';
import { queryKeys } from 'services/constants';
import { confirmPasswordRules, setPasswordFieldRules } from '../authFieldsRules';

import './style.scss';

type SetPasswordData = Omit<ConfirmPasswordData, 'code'>;

export const SetPasswordForm = ({ registration }: { registration: boolean }) => {
  const [form] = useForm();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code') || '';
  const { onSuccess, onError } = useAuthHandlers();

  const mutationKey = registration ? queryKeys.confirmPassword : queryKeys.confirmResetPassword;
  const passwordRequest = registration ? confirmPassword : confirmResetPassword;

  const { mutate: setPasswordMutation, isLoading } = useMutation(passwordRequest, {
    mutationKey,
    onSuccess,
    onError,
  });

  const onFinish: FormProps['onFinish'] = async (values: SetPasswordData) => {
    setPasswordMutation({ code, ...values });
  };

  return (
    <>
      <Form<SetPasswordData>
        form={form}
        layout='vertical'
        autoComplete='on'
        scrollToFirstError
        onFinish={onFinish}
        initialValues={{ remember: true }}
        className='set-password-form-wrapper'
      >
        <Form.Item
          hasFeedback
          name='password'
          label='Enter Password'
          tooltip={{ title: 'Please input your Enter Password!', icon: <InfoCircleOutlined /> }}
          rules={setPasswordFieldRules}
        >
          <AppInput.Password size='large' placeholder='Enter Password' className='pl-input set-password-input' />
        </Form.Item>

        <Form.Item
          hasFeedback
          label='Re-Enter Password'
          name='passwordConfirmation'
          dependencies={['password']}
          rules={confirmPasswordRules}
          tooltip={{ title: 'Please input your Re-Enter Password!', icon: <InfoCircleOutlined /> }}
        >
          <AppInput.Password size='large' placeholder='Re-Enter Password' className='pl-input set-password-input' />
        </Form.Item>

        <Form.Item>
          <Button loading={isLoading} htmlType='submit'>
            Set Password
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
