import { Row } from 'antd';
import { Button } from 'components';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { useChildrenRef } from 'hooks';
import { CreateSurveyModal } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Surveys/components';
import { TabActionsProps } from 'pages/ProgramDetails/tabsConfig/types';

export const SurveyTabActions = ({ program }: TabActionsProps) => {
  const [modalRef, setModalRef] = useChildrenRef<ClosableWrapperRef>();

  if (!program) return null;

  return (
    <Row>
      <Button onClick={modalRef?.open}>Add Survey</Button>
      <CreateSurveyModal closeHandler={modalRef?.close} modalRef={setModalRef} />
    </Row>
  );
};
