import { Dropdown, Layout, Menu } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

import { useUserData } from 'hooks';
import { useMenuItems } from './menuItems';
import logoSrc from 'assets/images/logo.png';
import { Settings } from 'icons';
import { useSettingsMenuItems } from './useSettingsMenuItems';
import { getAdminFullName } from 'pages/shared/utils';

import './style.scss';

const { Sider } = Layout;

const getInitialLetters = (str: string) =>
  str
    .match(/(\b\S)?/g)
    ?.join('')
    .toUpperCase();

export const SideMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const handleMenuNavigation = ({ key }: any) => navigate(key);
  const settingsMenuItems = useSettingsMenuItems();
  const userData = useUserData();
  const fullName = getAdminFullName(userData || {});
  const items = useMenuItems();

  const defaultPathName = location.pathname.split('/')[1];

  return (
    <Sider className='custom-pt-drawer' trigger={null} collapsedWidth={72} collapsible collapsed={true}>
      <div className='logo'>
        <img src={logoSrc} alt='logo' />
      </div>
      <Menu
        onClick={handleMenuNavigation}
        theme='dark'
        selectedKeys={[`/${defaultPathName}`]}
        mode='inline'
        items={items}
      />
      <div className='drawer-footer'>
        <Dropdown
          className='settings-wrapper'
          trigger={['click']}
          overlay={<Menu overflowedIndicatorPopupClassName='hel' items={settingsMenuItems} />}
          placement='topRight'
        >
          <Settings />
        </Dropdown>
        <div className='drawer-footer-circle'>{getInitialLetters(fullName)}</div>
      </div>
    </Sider>
  );
};
