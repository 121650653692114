import { SVGProps } from 'react';

export const ApiMapping = (props: SVGProps<SVGSVGElement>) => (
  <svg width={18} height={18} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M13.667 16.333a2.667 2.667 0 1 0 0-5.333 2.667 2.667 0 0 0 0 5.333ZM4.333 7a2.667 2.667 0 1 0 0-5.333 2.667 2.667 0 0 0 0 5.333Z'
      stroke='#787D83'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.667 4.333h2.4c.424 0 .83.156 1.131.434.3.278.469.655.469 1.048V11M4.333 7v9.333'
      stroke='#787D83'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
