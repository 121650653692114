import { Dropdown, Menu } from 'antd';
import { ThreeDots } from 'icons';
import { useProgramMoreButtonActions } from 'pages/ProgramDetails/utils';
import { ProgramData } from 'types/programs';

export type ProgramRowActionsProps = {
  program: ProgramData;
};

export const ProgramRowActions = ({ program }: ProgramRowActionsProps) => {
  const programMoreButtonActions = useProgramMoreButtonActions(program);
  const items = <Menu items={programMoreButtonActions} />;

  return (
    <Dropdown overlay={items} placement='bottomRight'>
      <ThreeDots />
    </Dropdown>
  );
};
