import { SVGProps } from 'react';

export const Programs = (props: SVGProps<SVGSVGElement>) => (
  <svg width={18} height={17} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M9 5.667c4.05 0 7.333-.896 7.333-2 0-1.105-3.283-2-7.333-2-4.05 0-7.333.895-7.333 2 0 1.104 3.283 2 7.333 2ZM16.333 8.333C16.333 9.81 13.074 11 9 11S1.667 9.809 1.667 8.333'
      stroke='#787D83'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1.667 3.667v9.882c0 1.172 3.259 2.118 7.333 2.118s7.333-.946 7.333-2.118V3.667'
      stroke='#787D83'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
