import { SVGProps } from 'react';

export const CloseVector = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || '18'}
    height={props.height || '18'}
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M17 1L1 17'
      stroke={props.stroke || 'white'}
      strokeWidth={props.strokeWidth || '1.5'}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M17 17L1 1'
      stroke={props.stroke || 'white'}
      strokeWidth={props.strokeWidth || '1.5'}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
