import { API_BASE_URL } from 'services/constants';
import {
  CreateFinancialAssistanceParams,
  DeleteFinancialAssistanceParams,
  GetFinancialAssistanceListDTO,
  GetFinancialAssistanceListParams,
  UpdateFinancialAssistanceParams,
} from 'services/financialAssistance/types';
import { ApiService, CreateRequest, DeleteRequest, GetListRequest, UpdateRequest } from 'types/apiTypes';
import { api } from 'utils/api';

const FA_PROGRAM_ENDPOINT = `${API_BASE_URL}fa-program/`;

export const {
  getFinancialAssistanceList,
  createFinancialAssistance,
  updateFinancialAssistance,
  deleteFinancialAssistance,
}: ApiService<
  'financialAssistance',
  GetListRequest<GetFinancialAssistanceListDTO, GetFinancialAssistanceListParams> &
    CreateRequest<unknown, CreateFinancialAssistanceParams> &
    UpdateRequest<unknown, UpdateFinancialAssistanceParams> &
    DeleteRequest<unknown, DeleteFinancialAssistanceParams>
> = {
  getFinancialAssistanceList: (programId) => {
    return api
      .get<GetFinancialAssistanceListDTO>(FA_PROGRAM_ENDPOINT, {
        params: { manufacture_program_uuid: programId },
      })
      .then((res) => res.data);
  },
  createFinancialAssistance: ({ programId, data }) => {
    return api.post(FA_PROGRAM_ENDPOINT, data, {
      params: { manufacture_program_uuid: programId },
    });
  },
  updateFinancialAssistance: ({ programId, data }) => {
    return api.put(
      FA_PROGRAM_ENDPOINT + data.uuid,
      {
        ...data,
        manufactureProgramUuid: programId, // BE design defect
      },
      {
        params: { manufacture_program_uuid: programId },
      }
    );
  },
  deleteFinancialAssistance: ({ programId, faProgramId }) => {
    return api.delete(FA_PROGRAM_ENDPOINT + faProgramId, {
      params: { manufacture_program_uuid: programId },
    });
  },
};
