import { Form } from 'antd';
import { FormProps } from 'antd/lib/form/Form';
import { ModalActionButtons, showCustomToast, Spacer } from 'components';
import { ClosableWrapper, ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { getFieldJSX } from 'components/fields/utils';
import { useUpdateMedia } from 'hooks/api/programs/useUpdateMedia';
import { CreateMediaFields } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Media/components/CreateMediaModal/types';
import {
  renameMediaField,
  renameMediaModalText,
} from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Media/components/RenameMediaModal/constants';
import { Ref } from 'react';
import { Callback } from 'types/helpers';
import { Media } from 'types/programs';

type RenameMediaModalProps = {
  media: Media;
  onClose?: Callback;
  setRenameModalRef: Ref<ClosableWrapperRef>;
};

export const RenameMediaModal = ({ media, onClose, setRenameModalRef }: RenameMediaModalProps) => {
  const [form] = Form.useForm<Pick<CreateMediaFields, 'resourceName'>>();

  const { smallUpdateMedia, isLoading } = useUpdateMedia();

  const onFinish: FormProps<Pick<CreateMediaFields, 'resourceName'>>['onFinish'] = async (values) => {
    if (!media) return;
    const { id, programId } = media;
    await smallUpdateMedia({ programId: programId, mediaId: id, ...values });
    showCustomToast({
      type: 'success',
      message: `Media name has been updated to ${form.getFieldValue('resourceName')}`,
      duration: 3,
    });
    onClose?.();
  };

  return (
    <ClosableWrapper ref={setRenameModalRef}>
      <h1 className='modal-title'>{renameMediaModalText.title}</h1>
      <Spacer height={16} type='flex' />
      <h2 className='modal-description'>{renameMediaModalText.description}</h2>
      <Form<Pick<CreateMediaFields, 'resourceName'>>
        form={form}
        className='form'
        layout='vertical'
        preserve={false}
        requiredMark={false}
        onFinish={onFinish}
        initialValues={{ resourceName: media.mediaName }}
      >
        {getFieldJSX(renameMediaField)}
        <ModalActionButtons isLoading={isLoading} onCancel={onClose} submitText='Save' />
      </Form>
    </ClosableWrapper>
  );
};
