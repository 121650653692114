import { showCustomToast } from 'components/CustomToast/showCustomToast';
import { text } from 'constants/texts';
import { useMutation, useQueryClient } from 'react-query';
import { queryKeys } from 'services/constants';
import { createSurvey } from 'services/surveys/requests';
import { Callback } from 'types/helpers';
import { Survey } from 'types/surveys';
import { errorHandler } from 'utils/errorHandler';
import { stringModifier } from 'utils/strings';
import { toSurveyDTO } from './mappers';

export const useCreateSurvey = (onSuccess?: Callback) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(createSurvey, {
    mutationKey: queryKeys.createSurvey,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.getSurveys);
      showCustomToast({
        type: 'success',
        message: stringModifier(text['Your {0} has been created'], 'Survey'),
      });
      onSuccess?.();
    },
    onError: errorHandler,
  });

  return {
    isLoading,
    createSurvey: (data: Survey) => mutate(toSurveyDTO(data)),
  };
};
