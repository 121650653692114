import { notification } from 'antd';
import { ArgsProps } from 'antd/lib/notification';
import clx from 'classnames';
import { BellVector, CheckVector, CloseVector, WarningVector } from 'icons';
import { ReactNode } from 'react';

import './style.scss';

type CustomToastType = 'success' | 'error' | 'great' | 'missing' | 'warning';

type CustomToastProps = {
  type: CustomToastType;
  message: string;
} & Pick<ArgsProps, 'message' | 'placement' | 'duration'>;

const toastIcon: Record<CustomToastType, ReactNode> = {
  success: <CheckVector />,
  error: <CloseVector />,
  great: <BellVector />,
  missing: <BellVector />,
  warning: <WarningVector />,
};

notification.config({
  maxCount: 1,
});

// durations -> in seconds
export const showCustomToast = ({ type = 'success', message, placement = 'top', duration = 5 }: CustomToastProps) =>
  notification.open({
    className: clx(type, 'main'),
    icon: toastIcon[type],
    closeIcon: <CloseVector width={10} height={10} stroke='#1D242D' strokeWidth={2.5} />,
    message,
    placement,
    duration,
  });
