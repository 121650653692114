import { Rule } from 'rc-field-form/lib/interface';

export const emailFieldRules: Rule[] = [
  {
    type: 'email',
    message: 'The input is not valid E-mail!',
  },
  {
    required: true,
    message: 'Please input your E-mail!',
  },
];

export const emailFieldRulesWarning: Rule[] = [
  {
    warningOnly: true,
    type: 'email',
    message: 'The input is not valid E-mail!',
  },
  {
    required: true,
    message: 'Please input your E-mail!',
  },
];

export const passwordFieldRules: Rule[] = [{ required: true, message: 'Please input your password!' }];

export const setPasswordFieldRules: Rule[] = [
  { required: true, message: 'Enter Password' },
  { warningOnly: true, pattern: /(?=.*?[A-Z])/, message: 'Must contain at least one uppercase letter.' },
  { warningOnly: true, min: 8, message: 'Must be at least 8 characters in length.' },
  { warningOnly: true, pattern: /(?=.*?[0-9])/, message: 'Must contain at least 1 number' },
  { warningOnly: true, pattern: /(?=.*?[#?!@$%^&*-])/, message: 'Must contain at least one special character.' },
];

export const confirmPasswordRules: Rule[] = [
  { required: true, message: 'Please input your password!' },
  ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Passwords that you entered do not match!'));
    },
  }),
];
