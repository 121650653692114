import { text } from 'constants/texts';
import { useProgramData } from 'hooks/api';
import { useMediaTab } from 'pages/ProgramDetails/programTabComponents/Content/tabsConfig/useMediaTab';
import { useMilestoneTrackersTab } from 'pages/ProgramDetails/programTabComponents/Content/tabsConfig/useMilestoneTrackersTab';
import { useSurveysTab } from 'pages/ProgramDetails/programTabComponents/Content/tabsConfig/useSurveysTab';
import { ContentTabsReturnType } from './types';
import { getNotificationTilesTab } from './getNotificationTilesTab';

export const useContentTabs = (): ContentTabsReturnType => {
  const { program } = useProgramData();

  const mediaTab = useMediaTab(program);
  const surveyTab = useSurveysTab(program);
  const milestoneTrackersTab = useMilestoneTrackersTab();
  const notificationTilesTab = getNotificationTilesTab();

  return {
    tabs: [
      mediaTab.tab,
      // TODO: Need to implement Forms
      {
        title: text['Forms'],
        tabData: { contentJSX: null, actionsJSX: null },
        tabKey: 'forms',
      },
      surveyTab.tab,
      milestoneTrackersTab.tab,
      notificationTilesTab.tab,
    ],
    extra: {
      isTabsDisabled: Boolean(surveyTab.extra?.isEditMode),
    },
  };
};
