import { AppField } from 'components/fields/types';
import { text } from 'constants/texts';
import { getInputPlaceholder, getSelectPlaceholder } from 'utils/strings';
import { RULES } from 'utils/validations';
import { AdminRole } from 'hooks/api/admins/types';
import { fieldModifier } from 'components/fields/utils';

const { EMAIL_ADDRESS } = RULES;

export type UserDetailsFields = {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
};

export type AdminDetailsFormType = UserDetailsFields;

export const getUserDetailsFields = (adminRoles: AdminRole[], emailDisabled?: boolean) => [
  [fieldModifier(emailAddressField, { disabled: emailDisabled }), firstNameField],
  [lastNameField, fieldModifier(selectRoleField, { options: adminRoles })],
];

export const emailAddressField: AppField<Pick<UserDetailsFields, 'email'>> = {
  type: 'input',
  name: 'email',
  label: text['Email Address'],
  rules: [EMAIL_ADDRESS()],
  placeholder: getInputPlaceholder(text['Email Address']),
};

export const firstNameField: AppField<Pick<UserDetailsFields, 'firstName'>> = {
  type: 'input',
  name: 'firstName',
  label: text['First Name'],
  placeholder: getInputPlaceholder(text['First Name']),
};

export const lastNameField: AppField<Pick<UserDetailsFields, 'lastName'>> = {
  type: 'input',
  name: 'lastName',
  label: text['Last Name'],
  placeholder: getInputPlaceholder(text['Last Name']),
};

export const selectRoleField: AppField<Pick<UserDetailsFields, 'role'>> = {
  type: 'select',
  name: 'role',
  label: text['Role'],
  placeholder: getSelectPlaceholder(text['Role']),
};
