import { showCustomToast } from 'components';
import { showSuccessfullySavedToast } from 'pages/ProgramDetails/utils';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { queryKeys } from 'services/constants';
import { createSpecialtyPharmacy } from 'services/specialtyPharmacies/requests';
import { Callback } from 'types/helpers';
import { SpecialtyPharmacy } from 'types/specialtyPharmacy';
import { errorHandler } from 'utils/errorHandler';
import { toSpecialtyPharmacyDTO } from './mappers';

export const useCreateSpecialtyPharmacy = (onSuccess?: Callback) => {
  const queryClient = useQueryClient();

  const { programId = '' } = useParams();

  const { mutate, isLoading } = useMutation(createSpecialtyPharmacy, {
    mutationKey: queryKeys.createSpecialtyPharmacy,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.getSpecialtyPharmacies);
      showCustomToast({
        type: 'success',
        message: 'Your Specialty Pharmacy has been added.',
        duration: 2.5,
      });
      onSuccess?.();
    },
    onError: errorHandler,
  });

  return {
    isLoading,
    createSpecialtyPharmacy: (data: SpecialtyPharmacy) =>
      mutate({
        data: toSpecialtyPharmacyDTO(data),
        programId,
      }),
  };
};
