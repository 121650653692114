import { queryKeys } from 'services/constants';
import { getManufactures } from 'services/programs/programs';
import { useQuery } from 'react-query';
import { useAuth } from 'hooks/useAuth';

export const useManufacturers = () => {
  const { isSuperAdmin } = useAuth();

  const { data: manufacturers = [], isLoading } = useQuery(queryKeys.getManufactures, getManufactures, {
    enabled: isSuperAdmin,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  return {
    manufacturers,
    isLoading,
  };
};
