import { useState, useCallback } from 'react';

import { pdfjs, Page, Document, DocumentProps } from 'react-pdf';

import { Button } from 'components';

import './style.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

type PdfViewerProps = {
  source: string;
};

export const PdfViewer = ({ source }: PdfViewerProps) => {
  const [pageCount, setPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess: DocumentProps['onLoadSuccess'] = useCallback(({ numPages }) => {
    setPageCount(numPages);
    setPageNumber(1);
  }, []);

  const changePage = (offset: number) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => changePage(-1);

  const nextPage = () => changePage(1);

  return (
    <>
      <div className='pdf-viewer-wrapper'>
        <Document file={source} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} />
        </Document>
      </div>
      <h3>
        Page {pageNumber || (pageCount ? 1 : '--')} of {pageCount || '--'}
      </h3>
      <div className='action-buttons-wrapper'>
        <Button type='ghost' disabled={pageNumber <= 1} onClick={previousPage}>
          Previous
        </Button>
        <Button type='primary' className='next-button' disabled={pageNumber >= pageCount} onClick={nextPage}>
          Next
        </Button>
      </div>
    </>
  );
};
