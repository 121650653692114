import { useCoverProgramEditStatusCallbacks } from 'pages/ProgramDetails/hooks/useCoverProgramEditStatusCallbacks';
import { UniversalSettingsContentRef } from 'pages/ProgramDetails/programTabComponents/UniversalSettings/UniversalSettingsContent';
import { useRef, useState } from 'react';
import { ProgramData } from 'types/programs';
import { UniversalSettingsContent, UniversalSettingsTabActions } from '../programTabComponents';
import { ProgramDetailsUseTabReturnType } from './types';

export const useUniversalSettingsTab = (
  program?: ProgramData
): ProgramDetailsUseTabReturnType<{ isUniversalSettingEditMode: boolean }> => {
  const formRef = useRef<UniversalSettingsContentRef>(null);

  const [isEditMode, setEditMode] = useState(false);

  const { onEdit, onClose, isLoading } = useCoverProgramEditStatusCallbacks({
    programId: program?.id,
    onClose: () => {
      formRef.current?.reset();
      setEditMode(false);
    },
    onEdit: () => setEditMode(true),
  });

  const onSave = () => formRef.current?.submit();

  return {
    tab: {
      title: 'Universal',
      description: 'These settings impact elements that persist through out the entire instance of the application',
      tabData: {
        contentTitle: 'Universal Settings',
        actionsJSX: (
          <UniversalSettingsTabActions
            isEditMode={isEditMode}
            program={program}
            isLoading={isLoading}
            onEdit={onEdit}
            onSave={onSave}
            onClose={onClose}
          />
        ),
        contentJSX: (
          <UniversalSettingsContent
            onSuccess={() => setEditMode(false)}
            ref={formRef}
            isEditMode={isEditMode}
            program={program}
          />
        ),
      },
      tabKey: 'universal',
    },
    extra: {
      isUniversalSettingEditMode: isEditMode,
    },
  };
};
