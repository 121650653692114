import { AppField, AppFields } from 'components/fields/types';
import { text } from 'constants/texts';
import { Patient } from 'services/patientSearch/types';

export type PatientInfoFields = Pick<Patient, 'email' | 'firstName' | 'lastName'>;

export const getPatientInformationFields = (): AppFields<PatientInfoFields> => [
  [patientEmailField, patientFirstNameField],
  patientLastNameField,
];

export const patientEmailField: AppField<Pick<PatientInfoFields, 'email'>> = {
  type: 'input',
  name: 'email',
  disabled: true,
  withoutBorder: true,
  label: text['Email Address'],
};

export const patientFirstNameField: AppField<Pick<PatientInfoFields, 'firstName'>> = {
  type: 'input',
  disabled: true,
  name: 'firstName',
  withoutBorder: true,
  label: text['First Name'],
};

export const patientLastNameField: AppField<Pick<PatientInfoFields, 'lastName'>> = {
  type: 'input',
  disabled: true,
  name: 'lastName',
  withoutBorder: true,
  label: text['Last Name'],
};
