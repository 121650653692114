import { AppDatePickerProps } from 'components/fields/AppDatePicker/AppDatePicker';
import { text } from 'constants/texts';
import { RuleObject } from 'rc-field-form/lib/interface';
import { stringModifier } from 'utils/strings';
import { emailRegex, hexColorRegex, lettersOnlyRegex, linkRegex, phoneNumberRegex, zipCodeRegex } from './regulars';

export const isEmailValid = (email: string) => emailRegex.test(String(email).toLowerCase());
export const isLinkValid = (link: string) => linkRegex.test(String(link).toLowerCase());

type RuleFunction<P = {}> = (params?: P) => RuleObject;

interface Rules
  extends Readonly<{
    REQUIRED: RuleFunction<{ requiredFieldName?: string } & Pick<RuleObject, 'message' | 'required'>>;
    MAX: RuleFunction<{ max?: number }>;
    LETTERS_ONLY: RuleFunction<{ allowedLetters?: ('™' | '®' | '℠')[] }>;
    NON_ZERO: RuleFunction;
    LINK: RuleFunction;
    HEX: RuleFunction;
    PHONE_NUMBER: RuleFunction;
    EMAIL_ADDRESS: RuleFunction;
    ZIP_CODE: RuleFunction;
  }> {}

export const RULES: Rules = {
  REQUIRED: (params) => ({
    required: true,
    message: stringModifier(text['{0} field is required'], params?.requiredFieldName || text['This']),
    ...params,
  }),
  MAX: (params) => ({
    max: 10,
    message: stringModifier(text['Max {0} Characters are allowed'], `${params?.max || 10}`),
    ...params,
  }),
  LETTERS_ONLY: (params) => ({
    validator: async (_, value) => {
      let copyValue = `${value}`;
      if (params?.allowedLetters?.length)
        params.allowedLetters.forEach((letter) => (copyValue = copyValue.replaceAll(letter, '')));

      if (!copyValue && copyValue.length !== value.length) return;

      if (!lettersOnlyRegex.test(copyValue)) throw new Error(text['Numbers and Symbols are not available']);
    },
    ...params,
  }),
  NON_ZERO: (params) => ({
    validator: async (_, value) => {
      if (value === 0 || value === '0') throw new Error(text["The value of this field can't be zero."]);
    },
    ...params,
  }),
  LINK: (params) => ({
    pattern: linkRegex,
    message: text['Should be as Url Link'],
    ...params,
  }),
  HEX: (params) => ({
    pattern: hexColorRegex,
    ...params,
  }),
  PHONE_NUMBER: (params) => ({
    pattern: phoneNumberRegex,
    message: text['Should be as Phone Number'],
    ...params,
  }),
  EMAIL_ADDRESS: (params) => ({
    pattern: emailRegex,
    message: text['Should be as Email Address'],
    ...params,
  }),
  ZIP_CODE: (params) => ({
    max: 5,
    pattern: zipCodeRegex,
    message: text['Should be as Zip Code'],
    ...params,
  }),
};

// Date validation

export const disablePastDaysValidator: AppDatePickerProps['disabledDate'] = (date) => {
  if (!date) return true;

  date.setHours(0, 0, 0, 0);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return date < today;
};
