import { API_BASE_URL } from 'services/constants';
import { ApiService, CreateRequest, GetListRequest, UpdateRequest, DeleteRequest, GetRequest } from 'types/apiTypes';
import { api } from 'utils/api';
import {
  CreateSpecialtyPharmacyParams,
  DeleteSpecialtyPharmacyParams,
  GetSpecialtyPharmacyListData,
  GetSpecialtyPharmacyData,
  GetSpecialtyPharmacyListParams,
  UpdateSpecialtyPharmacyParams,
  GetSpecialtyPharmacyParams,
} from './types';

const SPECIALTY_PHARMACY_ENDPOINT = `${API_BASE_URL}specialty-pharmacy/`;

export const {
  getSpecialtyPharmacy,
  getSpecialtyPharmacyList,
  createSpecialtyPharmacy,
  updateSpecialtyPharmacy,
  deleteSpecialtyPharmacy,
}: ApiService<
  'specialtyPharmacy',
  GetRequest<GetSpecialtyPharmacyData, GetSpecialtyPharmacyParams> &
    GetListRequest<GetSpecialtyPharmacyListData, GetSpecialtyPharmacyListParams> &
    CreateRequest<unknown, CreateSpecialtyPharmacyParams> &
    UpdateRequest<unknown, UpdateSpecialtyPharmacyParams> &
    DeleteRequest<unknown, DeleteSpecialtyPharmacyParams>
> = {
  getSpecialtyPharmacy: (params) => {
    return api
      .get<GetSpecialtyPharmacyData>(SPECIALTY_PHARMACY_ENDPOINT + params.pharmacyId, {
        params: { manufacture_program_uuid: params.programId },
      })
      .then((res) => res.data);
  },
  getSpecialtyPharmacyList: (params) => {
    return api
      .get<GetSpecialtyPharmacyListData>(SPECIALTY_PHARMACY_ENDPOINT, {
        params: { manufacture_program_uuid: params.programId, search: params.search },
      })
      .then((res) => res.data);
  },
  createSpecialtyPharmacy: (params) => {
    return api.post(SPECIALTY_PHARMACY_ENDPOINT, params.data, {
      params: { manufacture_program_uuid: params.programId },
    });
  },
  updateSpecialtyPharmacy: (params) => {
    return api
      .put(SPECIALTY_PHARMACY_ENDPOINT + params.pharmacyId, params.data, {
        params: { manufacture_program_uuid: params.programId },
      })
      .then((response) => response.data);
  },
  deleteSpecialtyPharmacy: (params) => {
    return api.delete(SPECIALTY_PHARMACY_ENDPOINT + params.pharmacyId, {
      params: { manufacture_program_uuid: params.programId },
    });
  },
};
