import { BreadCrumb } from 'types';

type AdminListPage = {
  name: 'ADMIN_LIST';
};

type AdminDetailsPage = {
  name: 'ADMIN_DETAILS';
  userName?: string;
};

type NurseCoordinatorDetailsPage = {
  name: 'NURSE_COORDINATOR_DETAILS';
  userName?: string;
};

type Config = AdminListPage | AdminDetailsPage | NurseCoordinatorDetailsPage;

export const adminsListPageBreadCrumbs: BreadCrumb[] = [
  {
    name: 'All Admins',
    to: '/admins/',
  },
];

export const getAdminPagesBreadCrumb = (config: Config): BreadCrumb[] => {
  switch (config.name) {
    case 'ADMIN_LIST':
      return adminsListPageBreadCrumbs;
    case 'ADMIN_DETAILS':
      return [
        ...adminsListPageBreadCrumbs,
        {
          name: config.userName || 'Name not found',
        },
      ];
    case 'NURSE_COORDINATOR_DETAILS':
      return [
        {
          name: config.userName || 'Name not found',
        },
      ];
  }

  return [];
};
