import { Form, FormProps, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { Spacer } from 'components';

import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { EmptyDataListComponent } from 'components/EmptyDataListComponent/EmptyDataListComponent';
import { scrollToFirstErrorProps } from 'constants/common';
import { text } from 'constants/texts';
import { useChildrenRef, usePermissionsHandler } from 'hooks';
import { useServices } from 'hooks/api/programs/useServices';
import { useUpdateServices } from 'hooks/api/programs/useUpdateServices';
import { useUpdateFormEffect } from 'hooks/useUpdateFormEffect';
import { ProgramDetailsConfirmModal } from 'pages/ProgramDetails/common/ProgramDetailsConfirmModal';
import { showSaveEditedProgramToast } from 'pages/ProgramDetails/utils';
import { forwardRef, ForwardRefRenderFunction, useImperativeHandle } from 'react';

import { Callback } from 'types/helpers';
import { ProgramData } from 'types/programs';
import { stringModifier } from 'utils/strings';
import { CreateServiceModal } from './components/CreateServiceModal/CreateServiceModal';
import { ServicesDynamicForm } from './components/ServicesDynamicForm/ServicesDynamicForm';

import './styles.scss';
import { ServicesFields } from './types';

export type AdditionalServicesContentProps = {
  isEditMode: boolean;
  program?: ProgramData;
  onSuccess: Callback;
};

export type AdditionalServicesContentRef = {
  submit: Callback;
  reset: Callback;
};

const AdditionalServicesContentComponent: ForwardRefRenderFunction<
  AdditionalServicesContentRef,
  AdditionalServicesContentProps
> = ({ isEditMode, program, onSuccess }, ref) => {
  const [form] = useForm<ServicesFields>();
  const [isCreateServicesAllowed] = usePermissionsHandler(['additional_services.create']);
  const [modalRef, setModalRef] = useChildrenRef<ClosableWrapperRef>();
  const [confirmModalRef, setConfirmModalRef] = useChildrenRef<ClosableWrapperRef>();
  const { data, isLoading } = useServices(program?.id);
  const { isLoading: isUpdating, updateServices } = useUpdateServices(() => {
    showSaveEditedProgramToast();
    onSuccess();
    confirmModalRef?.close();
  });

  useImperativeHandle(ref, () => ({ submit: form.submit, reset: form.resetFields }));

  useUpdateFormEffect(form, data || {}, [data]);

  const handleSubmit: FormProps<ServicesFields>['onFinish'] = () => confirmModalRef?.open();

  return (
    <Spin size='large' spinning={isLoading || isUpdating}>
      <Spacer height={8} />
      {!!data?.services.length ? (
        <Form<ServicesFields>
          layout='vertical'
          form={form}
          onFinish={handleSubmit}
          scrollToFirstError={scrollToFirstErrorProps}
          initialValues={data}
        >
          <Spacer height={16} />
          <Form.List name='services'>
            {(fields, { remove }) => (
              <>
                {fields.map(({ key, name }) => (
                  <ServicesDynamicForm
                    key={key}
                    form={form}
                    orderIndex={name}
                    remove={remove}
                    isEditMode={isEditMode}
                  />
                ))}
              </>
            )}
          </Form.List>
        </Form>
      ) : (
        <EmptyDataListComponent
          title={stringModifier(text["This program doesn't have any {0} configured."], 'Additional Services')}
          description={stringModifier(text['Click the button below to add new {0}'], 'Additional Service')}
          onButtonPress={modalRef?.open}
          addButtonText={stringModifier(text['Add {0}'], 'Service')}
          addButtonDisabled={!isCreateServicesAllowed}
        />
      )}
      <CreateServiceModal closeHandler={modalRef?.close} modalRef={setModalRef} />
      <ProgramDetailsConfirmModal
        type='Save'
        modalRef={setConfirmModalRef}
        confirmHandler={() => updateServices(form.getFieldsValue())}
        cancelHandler={confirmModalRef?.close}
      />
    </Spin>
  );
};

export const AdditionalServicesContent = forwardRef(AdditionalServicesContentComponent);
