import { AppFields } from 'components/fields/types';
import {
  allowShipmentTrackingField,
  cityField,
  emailAddressField,
  phoneNumberField,
  selectStateField,
  SpecialtyPharmaciesFields,
  specialtyPharmacyNameField,
  streetAddressLine1Field,
  streetAddressLine2Field,
  zipCodeField,
} from 'pages/ProgramDetails/specialtyPharmaciesFields';

export const getSpecialtyPharmaciesFields = (): AppFields<SpecialtyPharmaciesFields> => {
  return [
    specialtyPharmacyNameField,
    allowShipmentTrackingField,
    phoneNumberField,
    emailAddressField,
    streetAddressLine1Field,
    streetAddressLine2Field,
    cityField,
    selectStateField,
    zipCodeField,
  ];
};
