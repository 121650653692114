import cookieKeys from 'constants/cookieKeys';
import { AuthProvider } from 'contexts/auth/AuthProvider';
import Cookies from 'js-cookie';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AppRouter } from 'routes/AppRouter';
import { isAuthPage } from 'utils';

import './styles/main.scss';

const queryClient = new QueryClient();

const App = () => {
  if (isAuthPage()) Cookies.remove(cookieKeys.TOKEN);

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <AppRouter />
      </AuthProvider>
    </QueryClientProvider>
  );
};

export default App;
