import React from 'react';

import { BreadCrumb } from 'types';

import BreadCrumbs from './BreadCrumbs';

type PageLayoutProps = {
  pageTitle: React.ReactNode;
  crumbs: BreadCrumb[];
  topSection?: React.ReactNode;
  rightSideActions?: React.ReactNode;
};

export const PageLayout = ({
  pageTitle,
  topSection,
  crumbs,
  rightSideActions,
  children,
}: React.PropsWithChildren<Partial<PageLayoutProps>>) => {
  return (
    <div className='pl-layout-container'>
      <div className='header-breadcrumb-wrapper'>
        <div>
          <BreadCrumbs crumbs={crumbs} />
          {pageTitle && <h1 className='page-title pl-layout-title'>{pageTitle}</h1>}
          {topSection}
        </div>
        {!!rightSideActions && <div className='right-side-actions'>{rightSideActions}</div>}
      </div>
      <div
        className='site-layout-background'
        style={{
          padding: 40,
          minHeight: 360,
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
        }}
      >
        {children}
      </div>
    </div>
  );
};
