import { AppField } from 'components/fields/types';
import { CreateMediaFields } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Media/components/CreateMediaModal/types';
import { RULES } from 'utils/validations';
import { text } from 'constants/texts';
import { getInputPlaceholder } from 'utils/strings';

const { REQUIRED } = RULES;

export const renameMediaField: AppField<Pick<CreateMediaFields, 'resourceName'>> = {
  type: 'input',
  name: 'resourceName',
  placeholder: getInputPlaceholder(text['Media Name']),
  label: text['Media Name'],
  tooltip: text['You can rename this Media'],
  rules: [REQUIRED({ requiredFieldName: text['Media Name'] })],
};

export const renameMediaModalText = {
  title: text['Rename Media'],
  description: text['Change the name of this media in the text box below.'],
};
