import { FormInstance } from 'antd';
import { AppFields } from 'components/fields/types';
import { text } from 'constants/texts';
import {
  getProgramInformationFields,
  getProgramIntroductionFields,
  getTerminationCriteriaFields,
} from 'pages/ProgramDetails/programTabComponents/FinancialAssistance/tabsConfig/FinancialAssistanceTab/fields';
import { FinancialAssistanceFields } from 'pages/ProgramDetails/programTabComponents/FinancialAssistance/tabsConfig/FinancialAssistanceTab/types';
import { FinancialAssistanceTerminationCriteriaType } from 'services/financialAssistance/types';

type Section = {
  title: string;
  fields: AppFields<FinancialAssistanceFields>;
};

export const getFinancialAssistanceSections = (
  form: FormInstance<FinancialAssistanceFields>,
  fields: FinancialAssistanceFields | undefined,
  selectedCriteria: FinancialAssistanceTerminationCriteriaType
): Section[] => [
  { title: text['Program Information'], fields: getProgramInformationFields(form, fields) },
  { title: text['Program Introduction'], fields: getProgramIntroductionFields() },
  { title: text['Termination Criteria'], fields: getTerminationCriteriaFields(selectedCriteria) },
];
