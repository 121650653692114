import { FormInstance, Row } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { FormListOperation } from 'antd/es/form/FormList';

import { Spacer } from 'components';
import { getFieldsJSX } from 'components/fields/utils';
import { IconButton } from 'components/IconButton/IconButton';
import { useMilestoneTrackers } from 'hooks/api/milestoneTrackers/useMilestoneTrackers';
import { useMountEffect } from 'hooks/useMountEffect';
import { TrashVector } from 'icons/TrashVector';
import { getBusinessRuleActionFields } from 'pages/ProgramDetails/programTabComponents/BusinessRules/BusinessRuleDetails/components/BusinessRuleEditContent/fields';
import { getAllowedActions } from 'pages/ProgramDetails/programTabComponents/BusinessRules/BusinessRuleDetails/components/BusinessRuleEditContent/utils';
import {
  ActionBusinessRuleFields,
  UpdateBusinessRuleFields,
} from 'pages/ProgramDetails/programTabComponents/BusinessRules/components/CreateBusinessRuleModal/types';
import { useParams } from 'react-router-dom';
import { BusinessRulesMeta } from 'types/businessRuleMeta';

type BusinessRuleConditionDynamicFormProps = {
  form: FormInstance<UpdateBusinessRuleFields>;
  orderIndex: number;
  remove: FormListOperation['remove'];
  selectedActions: ActionBusinessRuleFields[];
  meta?: BusinessRulesMeta;
};

export const BusinessRuleActionDynamicForm = ({
  form,
  remove,
  meta,
  selectedActions,
  orderIndex,
}: BusinessRuleConditionDynamicFormProps) => {
  const { milestoneTrackers } = useMilestoneTrackers();

  const selectedAction = useWatch(['actions', orderIndex, 'name'], form);
  const selectedTrackerUuid = useWatch(['actions', orderIndex, 'milestoneTrackerId'], form);
  const selectedRuleForTrigger = useWatch(['actions', orderIndex, 'timeBaseTriggerActions'], form);
  const selectedTimeMetricsForTrigger = useWatch(['actions', orderIndex, 'timeMetricsTrigger'], form);
  const { programId = '' } = useParams();

  useMountEffect(
    () =>
      form.setFields(
        (
          [
            'emailTemplateId',
            'milestoneDescription',
            'milestoneMetaDataFieldName',
            'milestoneMetaDataFieldDescription',
            'milestoneId',
            'milestoneTrackerId',
            'milestoneName',
            'pushNotificationTemplateId',
            'notificationTileTemplateId',
            'smsTemplateId',
            'additionalServiceUuid',
            'timeBaseTriggerActions',
            'timeMetricsTrigger',
            'timeTrigger',
            'surveyId',
            'pharmacyId',
            'keywordTemplateId',
          ] as (keyof ActionBusinessRuleFields)[]
        ).map((key) => ({ name: ['actions', orderIndex, key], errors: [], value: null }))
      ),
    [selectedAction],
    [!selectedAction]
  );

  useMountEffect(
    () =>
      form.setFields(
        (
          [
            'milestoneDescription',
            'milestoneMetaDataFieldName',
            'milestoneMetaDataFieldDescription',
            'milestoneId',
            'milestoneName',
          ] as (keyof ActionBusinessRuleFields)[]
        ).map((key) => ({
          name: ['actions', orderIndex, key],
          errors: [],
          value: null,
        }))
      ),
    [selectedTrackerUuid],
    [!selectedTrackerUuid]
  );

  return (
    <>
      <Row justify='space-between' align='middle'>
        <div style={{ flex: 1 }}>
          {getFieldsJSX(
            getBusinessRuleActionFields({
              orderIndex,
              actions: getAllowedActions(orderIndex, selectedActions, meta?.actions),
              actionsMetaField: meta?.actionsMetaFields ?? [],
              selectedTracker: milestoneTrackers.find((tracker) => tracker.uuid === selectedTrackerUuid),
              rulesForTrigger: meta?.attributes.filter((attribute) => attribute.trigger_type === 'POSTPONED_EVENT'),
              selectedRuleForTrigger: meta?.attributes.find((atr) => atr.key === selectedRuleForTrigger),
              selectedTimeMetricsForTrigger: selectedTimeMetricsForTrigger,
              selectedAction,
              programId,
            })
          )}
        </div>
        <Spacer width={12} />
        <IconButton onPress={() => remove(orderIndex)} icon={<TrashVector />} />
      </Row>
    </>
  );
};
