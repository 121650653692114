import { Form, FormProps } from 'antd';
import { FormInstance } from 'antd/es';
import { getFieldsJSX } from 'components/fields/utils';
import { getSurveyStepFields } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Surveys/components/CreateSurveyModal/constants';
import { MainSurveyFields } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Surveys/components/CreateSurveyModal/types';

export type FirstStepFormProps = {
  form: FormInstance<MainSurveyFields>;
  handleSubmit: FormProps['onFinish'];
  initialValues: FormProps['initialValues'];
};

export const FirstStepForm = ({ form, initialValues, handleSubmit }: FirstStepFormProps) => (
  <Form<MainSurveyFields>
    preserve={false}
    layout='vertical'
    className='form'
    form={form}
    onFinish={handleSubmit}
    initialValues={initialValues}
  >
    {getFieldsJSX(getSurveyStepFields())}
  </Form>
);
