import { UpdateMilestoneTrackerFields } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/MilestoneTrackers/types';
import { MilestoneTracker } from 'types/milestoneTracker';

export const getUpdateMilestoneTrackerInitialValues = (tracker: MilestoneTracker): UpdateMilestoneTrackerFields => {
  return {
    name: tracker.name,
    description: tracker.description,
    milestones: [
      tracker.milestones[0] || {}, // first field required to view
      tracker.milestones[1] || {}, // second field required to view
      ...tracker.milestones.slice(2),
    ],
  };
};
