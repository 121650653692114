import { Spin } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { DataTable, MoreOptionsCell, SearchInput, Spacer, StatusCell, TextCell } from 'components';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { EmptyDataListComponent } from 'components/EmptyDataListComponent/EmptyDataListComponent';
import { ConditionalWrapper } from 'components/ShowWrapper/ConditionalWrapper';
import { stopPropagationOnCell } from 'constants/common';
import { text } from 'constants/texts';
import { useChildrenRef, usePermissionsHandler, useSearch } from 'hooks';
import { useGoToNotificationTileDetailsPage } from 'routes/navigators';
import { getFontClassName } from 'utils/getFontClassName';
import { stringModifier } from 'utils/strings';
import { useNotificationTiles } from 'hooks/api/notificationTiles/useNotificationTiles';
import { NotificationTile } from 'types/notificationTile';
import { NotificationTileStatus } from 'services/notificationTiles/types';
import { useNotificationTileOptions } from './hooks/useNotificationTileOptions';
import { CreateNotificationTileModal } from './components/CreateNotificationTileModal/CreateNotificationTileModal';
import { getDestinationPageTitle } from './utils';

const NotificationTileStatusColumn = ({ notificationTile }: { notificationTile: NotificationTile }) => {
  const [isEditNotificationTilesAllowed] = usePermissionsHandler(['notification-tiles.edit']);

  return (
    <StatusCell<NotificationTileStatus>
      status={notificationTile.status || text['DRAFT']}
      statuses={[
        { key: text['DRAFT'], label: text['Draft'] },
        { key: text['IN_USE'], label: text['In Use'] },
      ]}
      disabled={!isEditNotificationTilesAllowed}
    />
  );
};

const NotificationTileMoreButtonColumn = ({ id }: { id: string }) => {
  const { options, isLoading } = useNotificationTileOptions(id);

  return (
    <Spin size='small' spinning={isLoading}>
      <MoreOptionsCell options={options} />
    </Spin>
  );
};

const columns: ColumnsType<NotificationTile> = [
  {
    width: '50%',
    title: text['Notification Tile Name'],
    dataIndex: 'name',
    sorter: (a, b) => a.name.localeCompare(b.name),
    showSorterTooltip: false,
    sortDirections: ['ascend', 'ascend'],
    render: (value) => <TextCell color='blue' className={getFontClassName('button')} text={value} />,
  },
  {
    width: '30%',
    title: text['Destination Page'],
    dataIndex: 'destinationPage',
    render: (value) => {
      return <TextCell color='gray2' className={getFontClassName('paragraph')} text={getDestinationPageTitle(value)} />;
    },
  },
  {
    width: '20%',
    title: text['Status'],
    dataIndex: 'status',
    render: (_, record) => <NotificationTileStatusColumn notificationTile={record} />,
    onCell: stopPropagationOnCell,
  },
  {
    title: '',
    dataIndex: 'showMore',
    render: (_, record) => <NotificationTileMoreButtonColumn id={record.id} />,
    onCell: stopPropagationOnCell,
  },
];

export const NotificationTilesContent = () => {
  const [isCreateNotificationTilesAllowed] = usePermissionsHandler(['notification-tiles.create']);
  const [modalRef, setModalRef] = useChildrenRef<ClosableWrapperRef>();

  const { keyword, handleSearch, debouncedSearchKeyword } = useSearch();
  const { notificationTiles, hasData, isLoading } = useNotificationTiles({
    search: debouncedSearchKeyword,
  });

  const goToNotificationTileDetailsPage = useGoToNotificationTileDetailsPage();

  return (
    <>
      <Spacer height={24} type='flex' />
      <ConditionalWrapper condition={hasData}>
        <SearchInput value={keyword} onChange={handleSearch} />
        <Spacer height={12} type='flex' />
        <DataTable<NotificationTile>
          loading={isLoading}
          rowKey='id'
          columns={columns}
          dataSource={notificationTiles}
          onRow={(record) => ({
            onClick: () => goToNotificationTileDetailsPage(record.id),
          })}
        />
      </ConditionalWrapper>
      <ConditionalWrapper condition={!hasData} hideVisually>
        <EmptyDataListComponent
          title={stringModifier(text["This program doesn't have any {0} created"], text['Notification Tiles'])}
          description={stringModifier(text['Click the button below to add new {0}'], text['Notification Tiles'])}
          addButtonText={stringModifier(text['Add {0}'], text['Notification Tiles'])}
          addButtonDisabled={!isCreateNotificationTilesAllowed}
          onButtonPress={modalRef?.open}
        />
        <CreateNotificationTileModal setModalRef={setModalRef} modalRef={modalRef} />
      </ConditionalWrapper>
    </>
  );
};
