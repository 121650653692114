import { Tabs } from 'antd';
import clx from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { DevError } from 'utils/errors';

import './style.scss';
import { AppTabs } from './types';
import { useAppTabsPermissions } from './useAppTabsPermissions';

const { TabPane } = Tabs;

export type AppTab<T, U = AppTabs> = {
  title: string;
  tabData: T;
  description?: string;
  tabKey: U;
};

type Config<T> = {
  tabs: AppTab<T>[];
  initialTabKey?: string;
  disabled?: boolean;
  withTabsScrolling?: boolean;
  onTabChange?: (tabKey: AppTabs) => void;
};

export const useAppTabs = <T extends unknown>({
  tabs,
  initialTabKey = '0',
  withTabsScrolling = true,
  disabled,
  onTabChange,
}: Config<T>) => {
  const { tabHasPermissionMapper } = useAppTabsPermissions();

  if (!tabs.length) throw new DevError('You should set at least one tab!');
  if (tabs.some((t) => t.tabKey && typeof tabHasPermissionMapper[t.tabKey] === 'undefined'))
    throw new DevError('You should add tab.tabKey as a key to the "tabsNamesWithPermission" object');

  const allowedTabs = tabs.filter((tab) => tab?.tabKey && tabHasPermissionMapper[tab.tabKey]);

  const [selectedTabKey, setSelectedTabKey] = useState(
    allowedTabs.find((t) => t?.tabKey === initialTabKey)?.tabKey || allowedTabs[0]?.tabKey || '0'
  );

  const tabChangeHandler = useCallback(
    (tabKey: string) => {
      setSelectedTabKey(tabKey as AppTabs);
      onTabChange?.(tabKey as AppTabs);
    },
    [onTabChange]
  );

  const tabsJSX = useMemo(
    () => (
      <Tabs
        type='card'
        activeKey={selectedTabKey}
        className={clx({ 'pl-tabs': true, 'pl-tabs-scrolling': withTabsScrolling })}
        onChange={tabChangeHandler}
      >
        {allowedTabs.map(({ tabKey, title }) => (
          <TabPane disabled={disabled} tab={title} key={tabKey} />
        ))}
      </Tabs>
    ),
    [tabChangeHandler, withTabsScrolling, allowedTabs, selectedTabKey, disabled]
  );

  const {
    tabData: selectedTabData,
    title,
    description,
  } = allowedTabs[+selectedTabKey] || allowedTabs.find((tab) => tab.tabKey === selectedTabKey);

  return { tabsJSX, selectedTabData, title, description, selectedTabKey };
};
