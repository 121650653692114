import { api, getApiUrlWithProxy } from 'utils/api';
import { CreateServiceData, DeleteServiceParams, ServicesResponse, UpdateServiceData } from './types';

const GET_SERVICES_ENDPOINT = () => getApiUrlWithProxy('/additional-services');

export const fetchServicesByProgram = (id: string) =>
  api.get<ServicesResponse>(`${GET_SERVICES_ENDPOINT()}?manufacture_program_uuid=${id}`);

export const createService = (data: CreateServiceData) => api.post(GET_SERVICES_ENDPOINT(), data);

export const updateService = ({ id, ...rest }: UpdateServiceData) =>
  api.patch(`${GET_SERVICES_ENDPOINT()}/${id}`, rest);

export const deleteService = ({ id, programId }: DeleteServiceParams) =>
  api.delete(`${GET_SERVICES_ENDPOINT()}/${id}?manufacture_program_uuid=${programId}`);

export const fetchServiceById = (id: string) => api.get(`${GET_SERVICES_ENDPOINT()}/${id}`);
