import { MoreButton } from 'components';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { useRef } from 'react';
import { DeleteSpecialtyPharmacyConfirmModal } from './DeleteSpecialtyPharmacyConfirmModal';
import { usePermissionsHandler } from 'hooks';
import { MoreButtonOption } from 'components/MoreButton/MoreButton';

export type SpecialtyPharmacyMoreAction = {
  pharmacyId: string;
  pharmacyName: string;
};

export const SpecialtyPharmacyMoreAction = ({ pharmacyId, pharmacyName }: SpecialtyPharmacyMoreAction) => {
  const deleteModalRef = useRef<ClosableWrapperRef>();
  const [isDeletePharmaciesAllowed] = usePermissionsHandler(['pharmacies.delete']);

  const specialtyPharmacyMoreActions: MoreButtonOption[] = [
    {
      key: '0',
      label: 'Delete',
      onClick: deleteModalRef.current?.open,
      disabled: !isDeletePharmaciesAllowed,
    },
  ];

  return (
    <>
      <MoreButton options={specialtyPharmacyMoreActions} />
      <DeleteSpecialtyPharmacyConfirmModal
        pharmacyId={pharmacyId}
        pharmacyName={pharmacyName}
        deleteModalRef={deleteModalRef}
      />
    </>
  );
};
