import { toMilestoneTrackerDTO } from 'hooks/api/milestoneTrackers/mappers';
import { UpdateMilestoneTrackerFields } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/MilestoneTrackers/types';
import { showSuccessfullySavedToast } from 'pages/ProgramDetails/utils';
import { useMutation, useQueryClient } from 'react-query';
import { queryKeys } from 'services/constants';
import { updateMilestoneTracker } from 'services/milestoneTrackers/requests';
import { MilestoneTrackerStatus } from 'services/milestoneTrackers/types';
import { Callback } from 'types/helpers';
import { MilestoneTracker } from 'types/milestoneTracker';
import { errorHandler } from 'utils/errorHandler';

export const useUpdateMilestoneTracker = (onSuccess?: Callback) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(updateMilestoneTracker, {
    mutationKey: queryKeys.updateMilestoneTracker,
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKeys.getMilestoneTrackers);
      showSuccessfullySavedToast();
      onSuccess?.();
    },
    onError: errorHandler,
  });

  return {
    isLoading,
    updateMilestoneTracker: (fields: UpdateMilestoneTrackerFields, tracker: MilestoneTracker) =>
      mutate(toMilestoneTrackerDTO({ ...tracker, ...fields })),
    updateMilestoneTrackerStatus: (tracker: MilestoneTracker, status: MilestoneTrackerStatus) =>
      mutate(toMilestoneTrackerDTO({ ...tracker, status })),
  };
};
