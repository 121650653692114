import { Button, Dropdown, Menu } from 'antd';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { ConfirmModal, ConfirmModalProps } from 'components/ConfirmModal/ConfirmModal';
import { useChildrenRef } from 'hooks';
import { ThreeDots } from 'icons';
import { useEffect, useState } from 'react';

export type MoreOption = {
  label: string;
  key: string;
  request: () => Promise<unknown> | void | unknown;
  disabled?: boolean;
  hide?: boolean;
  modalInfo?: Pick<ConfirmModalProps, 'title' | 'description' | 'confirmTextBtn' | 'cancelTextBtn'>;
};

type MoreOptionsCellProps = {
  options: MoreOption[];
  withButton?: boolean;
};

export const MoreOptionsCell = ({ options, withButton }: MoreOptionsCellProps) => {
  const [selectedOption, setSelectedOption] = useState<MoreOption>();

  const [confirmModalRef, setConfirmModalRef] = useChildrenRef<ClosableWrapperRef>();

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedOption && !selectedOption.modalInfo) selectedOption.request();
  }, [selectedOption]);

  const items = (
    <Menu
      items={options
        .filter((option) => !option.hide)
        .map((option) => ({
          key: option.key,
          label: option.label,
          disabled: option.disabled,
          onClick: () => {
            setSelectedOption(option);
            option.modalInfo && confirmModalRef?.open();
          },
        }))}
    />
  );

  const confirmHandler = async () => {
    if (!selectedOption) return;

    setLoading(true);
    await selectedOption.request();
    confirmModalRef?.close();
    setLoading(false);
  };

  return (
    <>
      <Dropdown overlay={items} trigger={[withButton ? 'click' : 'hover']} placement='bottomRight'>
        {withButton ? (
          <Button loading={isLoading} type='ghost' className='pl-more-button'>
            <ThreeDots />
          </Button>
        ) : (
          <ThreeDots />
        )}
      </Dropdown>
      <ConfirmModal
        isLoading={isLoading}
        cancelHandler={confirmModalRef?.close}
        confirmHandler={confirmHandler}
        modalRef={setConfirmModalRef}
        {...{
          ...selectedOption?.modalInfo,
          description: selectedOption?.modalInfo?.description || '',
          title: selectedOption?.modalInfo?.title || '',
        }}
      />
    </>
  );
};
