import { text } from 'constants/texts';
import { useCoverProgramEditStatusCallbacks } from 'pages/ProgramDetails/hooks/useCoverProgramEditStatusCallbacks';
import { SurveysContent } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Surveys/SurveysContent';
import { SurveyTabActions } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Surveys/SurveysTabActions';
import { ContentUseTabsReturnType } from 'pages/ProgramDetails/programTabComponents/Content/tabsConfig/types';
import { useState } from 'react';
import { ProgramData } from 'types/programs';

export const useSurveysTab = (program?: ProgramData): ContentUseTabsReturnType<{ isEditMode: boolean }> => {
  const [isEditMode, setEditMode] = useState(false);

  // This is needed for survey details
  const { onEdit, onClose, isLoading } = useCoverProgramEditStatusCallbacks({
    programId: program?.id,
    onClose: () => {
      setEditMode(false);
    },
    onEdit: () => setEditMode(true),
  });

  const onSave = () => null;

  return {
    extra: { isEditMode },
    tab: {
      tabKey: 'surveys',
      title: text['Survey'],
      tabData: {
        actionsJSX: (
          <SurveyTabActions
            isEditMode={isEditMode}
            program={program}
            isLoading={isLoading}
            onEdit={onEdit}
            onSave={onSave}
            onClose={onClose}
          />
        ),
        contentJSX: <SurveysContent program={program} />,
      },
    },
  };
};
