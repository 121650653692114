import { DeleteConfirmModal } from 'components';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { useDeleteSpecialtyPharmacy } from 'hooks/api/specialtyPharmacy/useDeleteSpecialtyPharmacy';
import { MutableRefObject } from 'react';
import { SpecialtyPharmacy } from 'types/specialtyPharmacy';

type DeleteConfirmModalProps = {
  pharmacyId: string;
  pharmacyName: string;
  deleteModalRef: MutableRefObject<ClosableWrapperRef | undefined>;
};

export const DeleteSpecialtyPharmacyConfirmModal = ({
  pharmacyId,
  pharmacyName,
  deleteModalRef,
}: DeleteConfirmModalProps) => {
  const { isLoading, deleteSpecialtyPharmacy } = useDeleteSpecialtyPharmacy(
    pharmacyId,
    () => deleteModalRef.current?.close
  );

  return (
    <DeleteConfirmModal
      titleName={pharmacyName || 'SpecialtyPharmacy'}
      descriptionName={pharmacyName || 'SpecialtyPharmacy'}
      onConfirm={deleteSpecialtyPharmacy}
      deleteModalRef={deleteModalRef}
      isLoading={isLoading}
    />
  );
};
