import { showCustomToast } from 'components';
import { useMutation, useQueryClient } from 'react-query';
import { queryKeys } from 'services/constants';
import { deleteService } from 'services/programs/services';
import { errorHandler } from 'utils/errorHandler';
import { useProgramData } from './useGetProgram';

export const useDeleteService = () => {
  const queryClient = useQueryClient();
  const { program } = useProgramData();

  const { isLoading, mutateAsync } = useMutation(deleteService, {
    mutationKey: queryKeys.deleteService,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.getServices);
      showCustomToast({ type: 'success', message: 'Your service successfully removed!' });
    },
    onError: errorHandler,
  });

  return { isLoading, deleteServiceById: (id: string) => mutateAsync({ id, programId: program?.id || '' }) };
};
