import { toCopayFinancialAssistance, toFinancialAssistance } from 'hooks/api/financialAssistance/mappers';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { queryKeys } from 'services/constants';
import { getFinancialAssistanceList } from 'services/financialAssistance/requests';
import { determineIfIsDTOCopayFinancialAssistance } from 'utils/determinations';

export const useFinancialAssistances = () => {
  const { programId } = useParams();

  const { isLoading, data } = useQuery(
    [queryKeys.getFinancialAssistanceList, programId],
    () => getFinancialAssistanceList(programId || ''),
    {
      select: (data) =>
        data.results?.map((assistance) =>
          determineIfIsDTOCopayFinancialAssistance(assistance)
            ? toCopayFinancialAssistance(assistance)
            : toFinancialAssistance(assistance)
        ),
    }
  );
  return {
    isLoading,
    data,
  };
};
