import { useCallback, useEffect, useState } from 'react';
import { Ignorable } from '../types/helpers';
import { TablePaginationConfig } from 'antd';

const DEFAULT_ITEMS_COUNT = 1;
const DEFAULT_PAGE_NUMBER = 1;
const DEFAULT_PAGE_SIZE = 10;
const PAGE_SIZE_OPTIONS = [5, 10, 15, 20, 25];

export type UsePaginationConfig<T extends string> = Ignorable<{
  tab?: T;
}>;

export const usePagination = <T extends string>({ tab }: UsePaginationConfig<T> = {}) => {
  const [pageNumber, setPageNumber] = useState(DEFAULT_PAGE_NUMBER);
  const [itemsCount, setItemsCount] = useState(DEFAULT_ITEMS_COUNT);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  // resets the page to 1 when the tab changes
  useEffect(() => {
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setItemsCount(DEFAULT_ITEMS_COUNT);
    setPageSize(DEFAULT_PAGE_SIZE);
  }, [tab]);

  const onShowSizeChange = (current, pageSize) => {
    setPageNumber(current);
    setPageSize(pageSize);
  };

  const handlePageChange = useCallback((value: number) => setPageNumber(value), []);

  const updateItemCount = useCallback((newCount: number) => setItemsCount(newCount), []);

  const paginationConfig: TablePaginationConfig = {
    total: itemsCount,
    pageSize: pageSize,
    current: pageNumber,
    showSizeChanger: true,
    onChange: handlePageChange,
    position: ['bottomCenter'],
    onShowSizeChange: onShowSizeChange,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
  };

  return {
    updateItemCount,
    pageNumber,
    pageSize,
    paginationConfig,
  };
};
