import { API_BASE_URL } from 'services/constants';
import {
  BusinessRuleDTO,
  CreateBusinessRuleParams,
  DeleteBusinessRuleParams,
  GetBusinessRuleParams,
  GetBusinessRulesListData,
  GetBusinessRulesListParams,
  UpdateBusinessRuleParams,
} from 'services/rules/types';
import { ApiService, CreateRequest, DeleteRequest, GetListRequest, GetRequest, UpdateRequest } from 'types/apiTypes';
import { api } from 'utils/api';

const BUSINESS_RULE_ENDPOINT = `${API_BASE_URL}rules/`;

export const {
  createBusinessRule,
  getBusinessRuleList,
  getBusinessRule,
  updateBusinessRule,
  deleteBusinessRule,
}: ApiService<
  'businessRule',
  CreateRequest<BusinessRuleDTO, CreateBusinessRuleParams> &
    GetListRequest<GetBusinessRulesListData, GetBusinessRulesListParams> &
    GetRequest<BusinessRuleDTO, GetBusinessRuleParams> &
    UpdateRequest<unknown, UpdateBusinessRuleParams> &
    DeleteRequest<unknown, DeleteBusinessRuleParams>
> = {
  createBusinessRule: (params) => {
    return api.post<BusinessRuleDTO>(BUSINESS_RULE_ENDPOINT, params).then((res) => res.data);
  },
  getBusinessRuleList: ({ programId, page_number, page_size }) => {
    return api
      .get<GetBusinessRulesListData>(BUSINESS_RULE_ENDPOINT, {
        params: { page_size, page_number, program_id: programId },
      })
      .then((res) => res.data);
  },
  getBusinessRule: (id) => {
    return api.get<BusinessRuleDTO>(BUSINESS_RULE_ENDPOINT + id).then((res) => res.data);
  },
  updateBusinessRule: (params) => {
    return api.put(BUSINESS_RULE_ENDPOINT + params.uuid, params);
  },
  deleteBusinessRule: (uuid) => {
    return api.delete(BUSINESS_RULE_ENDPOINT + uuid);
  },
};
