export const InfoIcon = () => (
  <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M8.99984 16.3334C13.0499 16.3334 16.3332 13.0502 16.3332 9.00008C16.3332 4.94999 13.0499 1.66675 8.99984 1.66675C4.94975 1.66675 1.6665 4.94999 1.6665 9.00008C1.6665 13.0502 4.94975 16.3334 8.99984 16.3334Z'
      stroke='#787D83'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M9.00001 9L9 12.6449' stroke='#787D83' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M9 5.66675H9.00667' stroke='#787D83' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);
