import { ClosableWrapper } from 'components';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { CreateSurveyForm } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Surveys/components/CreateSurveyModal/CreateSurveyForm';
import { Ref } from 'react';
import { Callback } from 'types/helpers';

type CreateSurveyModalProps = {
  modalRef: Ref<ClosableWrapperRef>;
  closeHandler?: Callback;
};

export const CreateSurveyModal = ({ modalRef, closeHandler }: CreateSurveyModalProps) => {
  return (
    <ClosableWrapper ref={modalRef}>
      <CreateSurveyForm closeModal={closeHandler} />
    </ClosableWrapper>
  );
};
