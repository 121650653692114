import { useQuery } from 'react-query';
import { queryKeys } from 'services/constants';
import { getMediaList } from 'services/medias/requests';
import { GetMediasParams } from 'services/medias/types';

import { serverToClientMediaMapper } from './mappers';

export const useMedias = ({ programId = '', search, mediaType }: GetMediasParams) => {
  const {
    data,
    isLoading,
    refetch: refetchMedias,
    isRefetching,
  } = useQuery(
    [queryKeys.getMedias, programId, search, mediaType],
    () => getMediaList({ programId, search, mediaType }),
    {
      enabled: !!programId,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      select: (response) => serverToClientMediaMapper(response.allMedia),
    }
  );

  const showResults = !!data?.length || !!search || isRefetching || isLoading;

  return { data, isLoading, refetchMedias, isRefetching, showResults };
};
