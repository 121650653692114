import { AppSwitchProps } from 'components/fields/AppSwitch/AppSwitch';
import { AppField } from 'components/fields/types';
import { text } from 'constants/texts';
import {
  GraphicFields,
  MetricsFields,
  PatientInstructionsFields,
  TermsAndConditionsFields,
} from 'pages/ProgramDetails/programTabComponents/FinancialAssistance/tabsConfig/CopayTab/types';
import { getInputPlaceholder } from 'utils/strings';
import { RULES } from 'utils/validations';

const { LINK, MAX } = RULES;

const metricSwitchLabels: AppSwitchProps['labels'] = {
  checked: text['Active'],
  unchecked: text['Inactive'],
};

export const mediaCoPayCardImageField: AppField<Pick<GraphicFields, 'medicalImage'>> = {
  type: 'upload',
  name: 'medicalImage',
  label: text['Medical Co-pay Card Image'],
  fileTypes: ['PNG', 'JPG'],
  loadFileType: 'images',
  cropper: { sizes: { width: 378, height: 240 } },
  selectUploadConfig: {},
};

export const pharmacyCopayCardImageField: AppField<Pick<GraphicFields, 'pharmacyImage'>> = {
  type: 'upload',
  name: 'pharmacyImage',
  label: text['Pharmacy Co-pay Card Image'],
  fileTypes: ['PNG', 'JPG'],
  loadFileType: 'images',
  cropper: { sizes: { width: 378, height: 240 } },
  selectUploadConfig: {},
};

export const benefitRemainingField: AppField<Pick<MetricsFields, 'isBenefitsRemaining'>> = {
  type: 'switch',
  name: 'isBenefitsRemaining',
  label: text['Benefits Remaining'],
  tooltip: text['Benefits Remaining'],
  labels: metricSwitchLabels,
};

export const fillsRemainingField: AppField<Pick<MetricsFields, 'isFillsRemaining'>> = {
  type: 'switch',
  name: 'isFillsRemaining',
  label: text['Fills Remaining'],
  tooltip: text['Fills Remaining'],
  labels: metricSwitchLabels,
};

export const benefitUsedField: AppField<Pick<MetricsFields, 'isBenefitsUsed'>> = {
  type: 'switch',
  name: 'isBenefitsUsed',
  label: text['Benefits Used'],
  tooltip: text['Benefits Used'],
  labels: metricSwitchLabels,
};

export const fillsUsedField: AppField<Pick<MetricsFields, 'isFillsUsed'>> = {
  type: 'switch',
  name: 'isFillsUsed',
  label: text['Fills Used'],
  tooltip: text['Fills Used'],
  labels: metricSwitchLabels,
};

export const copayRenewalField: AppField<Pick<MetricsFields, 'isCopayRenewal'>> = {
  type: 'switch',
  name: 'isCopayRenewal',
  label: text['Copay Renewal'],
  tooltip: text['Copay Renewal'],
  labels: metricSwitchLabels,
};

export const prescriptionRefillField: AppField<Pick<MetricsFields, 'isPrescriptionRefill'>> = {
  type: 'switch',
  name: 'isPrescriptionRefill',
  label: text['Last Dispense Date'],
  tooltip: text['Last Dispense Date'],
  labels: metricSwitchLabels,
};

export const medicalPatientInstructionsField: AppField<Pick<PatientInstructionsFields, 'medicalPatientInstructions'>> =
  {
    type: 'input',
    rows: 4,
    renderType: 'TextArea',
    name: 'medicalPatientInstructions',
    placeholder: getInputPlaceholder(text['Medical Patient Instructions']),
    label: text['Medical Co-pay Card Patient Instructions Body'],
    tooltip: text['Medical Patient Instructions'],
    rules: [MAX({ max: 2000 })],
  };

export const pharmacyPatientInstructionsField: AppField<
  Pick<PatientInstructionsFields, 'pharmacyPatientInstructions'>
> = {
  type: 'input',
  rows: 4,
  renderType: 'TextArea',
  name: 'pharmacyPatientInstructions',
  placeholder: getInputPlaceholder(text['Pharmacy Patient Instructions']),
  label: text['Pharmacy Co-pay Card Patient Instructions Body'],
  tooltip: text['Pharmacy Patient Instructions'],
  rules: [MAX({ max: 2000 })],
};

export const medicalTermsAndConditionsField: AppField<Pick<TermsAndConditionsFields, 'medicalTermsAndConditions'>> = {
  type: 'input',
  name: 'medicalTermsAndConditions',
  label: text['Medical Co-pay Card Terms and Conditions Link'],
  placeholder: text['Medical Co-pay Card Terms and Conditions Link'],
  rules: [LINK()],
};

export const pharmacyTermsAndConditionsField: AppField<Pick<TermsAndConditionsFields, 'pharmacyTermsAndConditions'>> = {
  type: 'input',
  name: 'pharmacyTermsAndConditions',
  label: text['Pharmacy Co-pay Card Terms and Conditions Link'],
  placeholder: text['Pharmacy Co-pay Card Terms and Conditions Link'],
  rules: [LINK()],
};
