import { FormInstance, Row } from 'antd';
import { DeleteConfirmModal, MoreButton, Spacer } from 'components';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { EditableButtons } from 'components/EditableButtons/EditableButtons';
import { ConditionalWrapper } from 'components/ShowWrapper/ConditionalWrapper';
import { text } from 'constants/texts';
import { useDeleteMilestoneTracker } from 'hooks/api/milestoneTrackers/useDeleteMilestoneTracker';
import { useUpdateMilestoneTracker } from 'hooks/api/milestoneTrackers/useUpdateMilestoneTracker';
import { useCoverProgramEditStatusCallbacks } from 'pages/ProgramDetails/hooks/useCoverProgramEditStatusCallbacks';
import { UpdateMilestoneTrackerFields } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/MilestoneTrackers/types';
import { showCancelEditedToast } from 'pages/ProgramDetails/utils';
import { useRef } from 'react';
import { AppReturnStateProps } from 'types/helpers';
import { MilestoneTracker } from 'types/milestoneTracker';
import { usePermissionsHandler } from 'hooks';

type MilestoneTrackerDetailsActionsProps = {
  form: FormInstance<UpdateMilestoneTrackerFields>;
  milestoneTracker: MilestoneTracker;
} & AppReturnStateProps<'editMode', boolean>;

export const MilestoneTrackerDetailsActions = ({
  form,
  setEditMode,
  isEditMode,
  milestoneTracker,
}: MilestoneTrackerDetailsActionsProps) => {
  const [isEditMilestoneTrackerAllowed, isDeleteMilestoneTrackerAllowed] = usePermissionsHandler([
    'milestone_trackers.edit',
    'milestone_trackers.delete',
  ]);

  const deleteModalRef = useRef<ClosableWrapperRef>(null);
  const cancelModalRef = useRef<ClosableWrapperRef>(null);
  const saveModalRef = useRef<ClosableWrapperRef>(null);

  const {
    isLoading: isUpdateEditStatusLoading,
    onEdit,
    onClose,
  } = useCoverProgramEditStatusCallbacks({
    onEdit: () => setEditMode(true),
    onClose: () => {
      showCancelEditedToast();
      form.resetFields();
      setEditMode(false);
    },
  });

  const { isLoading: isUpdateTrackerLoading, updateMilestoneTrackerStatus } = useUpdateMilestoneTracker();

  const { deleteMilestoneTracker, isLoading: isDeleteTrackerLoading } = useDeleteMilestoneTracker(
    milestoneTracker.uuid
  );

  const isLoading = isDeleteTrackerLoading || isUpdateTrackerLoading || isUpdateEditStatusLoading;

  return (
    <Row>
      <EditableButtons
        onEdit={onEdit}
        disable={milestoneTracker.status === 'IN_USE'}
        isLoading={isLoading}
        isEditMode={isEditMode}
        onSave={saveModalRef.current?.open}
        onCancel={cancelModalRef.current?.open}
        saveModal={{
          ref: saveModalRef.current,
          setRef: saveModalRef,
          onConfirm: form.submit,
        }}
        cancelModal={{
          ref: cancelModalRef.current,
          setRef: cancelModalRef,
          onConfirm: onClose,
        }}
      />
      <ConditionalWrapper hideVisually condition={!isEditMode}>
        <Spacer width={16} />
        <MoreButton
          options={[
            {
              key: '1',
              label: text['Activate'],
              hide: milestoneTracker.status === 'IN_USE',
              onClick: () => updateMilestoneTrackerStatus(milestoneTracker, 'IN_USE'),
              disabled: !isEditMilestoneTrackerAllowed,
            },
            {
              key: '2',
              label: text['Inactive'],
              hide: milestoneTracker.status === 'DRAFT',
              onClick: () => updateMilestoneTrackerStatus(milestoneTracker, 'DRAFT'),
              disabled: !isEditMilestoneTrackerAllowed,
            },
            {
              key: '3',
              label: text['Delete'],
              onClick: deleteModalRef.current?.open,
              disabled: !isDeleteMilestoneTrackerAllowed,
            },
          ]}
        />
        <DeleteConfirmModal
          onConfirm={deleteMilestoneTracker}
          titleName={milestoneTracker.name}
          descriptionName={text['Milestone Tracker']}
          deleteModalRef={deleteModalRef}
        />
      </ConditionalWrapper>
    </Row>
  );
};
