import clx from 'classnames';
import './styles.scss';

type TextCellProps = {
  text: string;
  color?: 'blue' | 'gray2' | 'default';
  className?: string;
};

export const TextCell = ({ className, text, color = 'default' }: TextCellProps) => (
  <span className={clx(className, `pl-text-cell-${color}`)}>{text}</span>
);
