import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, ModalProps } from 'antd';

import { AppInput, Button, ModalAsync, showCustomToast, Spacer } from 'components';
import { useState } from 'react';

import { useMutation } from 'react-query';
import { forgotPassword } from 'services/auth/auth';
import { queryKeys } from 'services/constants';
import { isEmailValid } from 'utils/validations';
import { emailFieldRulesWarning } from '../authFieldsRules';
import './style.scss';

type ForgotPasswordModalProps = ModalProps & {
  close: () => void;
};

type ForgotPasswordField = {
  email: string;
};

export const ForgotPasswordModal = ({ close, ...rest }: ForgotPasswordModalProps) => {
  const [form] = Form.useForm<ForgotPasswordField>();
  const [sendDisable, setSendDisable] = useState(true);
  const [emailValidateTrigger, setEmailValidateTrigger] = useState<'onBlur' | 'onChange'>('onBlur');

  const { isLoading, mutate: resetPassword } = useMutation(forgotPassword, {
    mutationKey: queryKeys.resetPassword,
    onSuccess: () => {
      close();
      showCustomToast({
        type: 'success',
        message: 'Reset Password email has ben sent successfully',
      });
    },
    onError: (error: any) => {
      showCustomToast({
        message: error?.response?.data?.message || 'Oops something went wrong',
        type: 'error',
      });
    },
  });

  const handleSubmit = (values: ForgotPasswordField) => {
    resetPassword(values.email);
  };

  const onBlurHandler = () => setEmailValidateTrigger('onChange');
  const validationEmail = () => {
    setSendDisable(!isEmailValid(form.getFieldValue('email')));
  };

  return (
    <ModalAsync onOk={close} onCancel={close} {...rest} className='forgot-modal'>
      <h1 className='modal-title'>Reset your password</h1>
      <Spacer height={24} type={'flex'} />
      <h2 className='modal-description'>
        Enter the email connected with your account to receive a email to reset your password.
      </h2>
      <Spacer height={30} type={'flex'} />
      <Form<ForgotPasswordField>
        form={form}
        layout='vertical'
        autoComplete='on'
        onFinish={handleSubmit}
        className='form-wrapper'
        onValuesChange={validationEmail}
      >
        <Form.Item
          name='email'
          label='Enter Email Address'
          className='input-container'
          rules={emailFieldRulesWarning}
          validateTrigger={emailValidateTrigger}
          tooltip={{ title: 'Please input your email!', icon: <InfoCircleOutlined /> }}
        >
          <AppInput onBlur={onBlurHandler} placeholder='Enter Email Address' className='forgot-input' />
        </Form.Item>
        <Spacer height={16} />
        <Form.Item>
          <Button loading={isLoading} disabled={sendDisable} htmlType='submit'>
            Send
          </Button>
        </Form.Item>
      </Form>
    </ModalAsync>
  );
};
