import { Spin } from 'antd';

import { useProgramData } from 'hooks/api';
import { useSurvey } from 'hooks/api/surveys';
import { PageLayout } from 'layouts';
import { useCoverProgramEditStatusCallbacks } from 'pages/ProgramDetails/hooks/useCoverProgramEditStatusCallbacks';

import {
  SurveyDetailsContent,
  SurveyDetailsContentRef,
} from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Surveys/SurveyDetails/SurveyDetailsContent';
import { SurveyDetailsTabActions } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Surveys/SurveyDetails/SurveyDetailsTabActions';
import { getProgramPagesBreadCrumb } from 'pages/Programs/breadCrumbs';
import { statusRenderer } from 'pages/shared';
import { useRef, useState } from 'react';

export const SurveyDetails = () => {
  const [isEditMode, setEditMode] = useState(false);
  const formRef = useRef<SurveyDetailsContentRef>(null);
  const { program, isLoading: isProgramLoading } = useProgramData();
  const { survey, isLoading: isSurveyLoading } = useSurvey();

  const {
    onEdit,
    onClose,
    isLoading: isEditStatusLoading,
  } = useCoverProgramEditStatusCallbacks({
    programId: program?.id,
    onClose: () => {
      formRef.current?.reset?.();
      setEditMode(false);
    },
    onEdit: () => setEditMode(true),
  });

  const isLoading = isProgramLoading || isSurveyLoading;

  if (!survey || !program || isLoading) return <Spin className='pl-page-spinner' size='large' />;

  const onSave = () => formRef.current?.submit();

  const breadCrumbs = getProgramPagesBreadCrumb({
    name: 'SURVEY_DETAILS',
    programName: program.programName,
    programId: program.id,
    surveyName: survey.name,
  });

  const pageTitleJSX = (
    <>
      {survey.name}
      {statusRenderer(survey.status)}
    </>
  );

  const rightSideActionsJSX = (
    <SurveyDetailsTabActions
      isEditMode={isEditMode}
      isLoading={isLoading}
      onClose={onClose}
      onEdit={onEdit}
      onSave={onSave}
      program={program}
      survey={survey}
    />
  );

  return (
    <PageLayout pageTitle={pageTitleJSX} crumbs={breadCrumbs} rightSideActions={rightSideActionsJSX}>
      <SurveyDetailsContent
        ref={formRef}
        isLoading={isEditStatusLoading}
        isEditMode={isEditMode}
        survey={survey}
        onSuccess={() => setEditMode(false)}
      />
    </PageLayout>
  );
};
