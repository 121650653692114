import { CopayFinancialAssistanceDTO, FinancialAssistanceDTO } from 'services/financialAssistance/types';
import { CopayFinancialAssistance, FinancialAssistance } from 'types/financialAssistance';
import { Media, ProgramMedia } from 'types/programs';
import { BaseBusinessRule, BusinessRule } from 'types/rules';

export const determineIfIsMediaFile = (file?: Media | ProgramMedia | null): file is Media =>
  !!(file as Media)?.mediaName;

export const determineIfIsBusinessRule = (rule: BusinessRule | BaseBusinessRule): rule is BusinessRule =>
  typeof (rule as BusinessRule)?.isActive === 'boolean';

export const determineIfIsDTOCopayFinancialAssistance = (
  assistance?: CopayFinancialAssistanceDTO | FinancialAssistanceDTO
): assistance is CopayFinancialAssistanceDTO => (assistance as CopayFinancialAssistanceDTO)?.type === 'COPAY';

export const determineIfIsCopayFinancialAssistance = (
  assistance?: CopayFinancialAssistance | FinancialAssistance
): assistance is CopayFinancialAssistance => (assistance as CopayFinancialAssistance)?.type === 'COPAY';
