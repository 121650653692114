import { ServerObjectMappers } from 'types/apiTypes';
import { NotificationTileDTO } from 'services/notificationTiles/types';
import { NotificationTile } from 'types/notificationTile';

export const {
  toNotificationTile,
  toNotificationTileDTO,
}: ServerObjectMappers<'notificationTile', NotificationTileDTO, NotificationTile, null, null, 'name' | 'description'> =
  {
    toNotificationTile: (dto) => ({
      id: dto.id ?? '',
      title: dto.title ?? '',
      description: dto.description ?? '',
      name: dto.display_name ?? '',
      type: dto.type ?? '',
      isSingleSession: dto.is_single_session ?? false,
      isClosable: dto.is_closable ?? true,
      status: dto.status ?? 'DRAFT',
      destinationPage: dto.destination_page ?? '',
      externalLink: dto.external_link ?? '',
      externalLinkName: dto.external_link_name ?? '',
      manufactureProgramUuid: dto.manufacture_program_uuid ?? '',
    }),

    toNotificationTileDTO: (instance) => ({
      id: instance.id,
      title: instance.title,
      description: instance.description,
      display_name: instance.name,
      type: instance.type,
      is_single_session: instance.isSingleSession,
      is_closable: instance.isClosable,
      destination_page: instance.destinationPage,
      external_link: instance.externalLink,
      external_link_name: instance.externalLinkName,
      manufacture_program_uuid: instance.manufactureProgramUuid,
      status: instance.status,
    }),
  };
