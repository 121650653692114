import { API_BASE_URL } from 'services/constants';
import { BusinessRulesMetaDTO } from 'services/rules/meta/types';
import { ApiService, GetRequest } from 'types/apiTypes';
import { api } from 'utils/api';

const BUSINESS_RULE_META_ENDPOINT = `${API_BASE_URL}meta/`;

export const {
  getBusinessRulesMeta,
}: ApiService<'businessRulesMeta', GetRequest<BusinessRulesMetaDTO>> = {
  getBusinessRulesMeta: (programId: string) => {
    return api
      .get<BusinessRulesMetaDTO>(BUSINESS_RULE_META_ENDPOINT, {
        params: {
          program_uuid: programId,
        },
      })
      .then((res) => res.data);
  },
};
