import { Row } from 'antd/es';

import { Spacer } from 'components';
import { getFieldsJSX } from 'components/fields/utils';
import { text } from 'constants/texts';
import { stringModifier } from 'utils/strings';
import { getMainQuestionFields } from './constants';
import { DynamicAnswersFields } from 'pages/ProgramDetails/common';

export type DynamicFormProps = {
  orderIndex: number;
};

export const DynamicForm = ({ orderIndex }: DynamicFormProps) => (
  <div className='dynamic-form-block'>
    <h1 className='page-content-title'>{stringModifier(text['Question {0}'], `${orderIndex + 1}`)}</h1>
    <Spacer height={24} type='flex' />
    <Row>
      <div className='dynamic-form-block-50'>{getFieldsJSX(getMainQuestionFields(orderIndex), false)}</div>
      <Spacer width={12} height='initial' type='flex' />
      <Spacer width={12} height='initial' type='flex' className='dynamic-form-divider' />
      <div className='dynamic-form-block-50'>
        <DynamicAnswersFields name={[orderIndex, 'answers']} isEditMode={false} />
      </div>
    </Row>
  </div>
);
