import { Form, FormProps, Progress } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { Button, ClosableWrapper, showCustomToast, Spacer } from 'components';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { ModalActionButtons } from 'components/fields/AppActionButtons/ModalActionButtons';
import { getFieldsJSX } from 'components/fields/utils';
import { useCreateMedia } from 'hooks/api/programs/useCreateMedia';
import { getCreateMediaFields } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Media/components/CreateMediaModal/constants';

import 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Media/components/CreateMediaModal/style.scss';

import { CreateMediaFields } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Media/components/CreateMediaModal/types';
import { useEffect } from 'react';
import { ProgramData } from 'types/programs';

const { useForm, useWatch } = Form;

type CreateMediaModalProps = {
  setModalRef: (ref: ClosableWrapperRef) => void;
  modalRef?: ClosableWrapperRef;
  program: ProgramData;
};

export const CreateMediaModal = ({ setModalRef, modalRef, program }: CreateMediaModalProps) => {
  const [form] = useForm<CreateMediaFields>();

  const selectedFiles = useWatch<UploadFile[] | undefined>('resource', form);
  const selectedFileName = selectedFiles?.[0]?.name || '';
  const { createMedia, isLoading, progress, cancelRequest: setCancelToken } = useCreateMedia();

  useEffect(() => form.setFieldsValue({ resourceName: selectedFileName }), [selectedFileName]);

  const onFinish: FormProps<CreateMediaFields>['onFinish'] = async (values) => {
    await createMedia({ programId: program.id, ...values });
    showCustomToast({
      type: 'success',
      message: `${values.resourceName} has been added to media for the ${program.programName} program.`,
      duration: 3,
    });
    modalRef?.close();
  };

  const progressJSX = (
    <>
      <h1 className='modal-title'>Please Wait...</h1>
      <Spacer height={30} />
      <div className='progress-block'>
        <Progress type='line' percent={progress} strokeColor='#00A65D' />
        <Button type='text' colorType='negative' onClick={setCancelToken}>
          Cancel
        </Button>
      </div>
      <Spacer height={40} />
    </>
  );

  return (
    <ClosableWrapper ref={setModalRef}>
      <h1 className='modal-title'>Add Media</h1>
      <Spacer height={16} type='flex' />
      <h2 className='modal-description'>{`Add new media to the ${program.programName} program.`}</h2>
      <Form<CreateMediaFields>
        requiredMark={false}
        preserve={false}
        layout='vertical'
        className='form'
        form={form}
        onFinish={onFinish}
      >
        {isLoading ? progressJSX : getFieldsJSX(getCreateMediaFields(selectedFiles))}
        <ModalActionButtons isLoading={isLoading} onCancel={modalRef?.close} />
      </Form>
    </ClosableWrapper>
  );
};
