import React, { PropsWithChildren } from 'react';

type ConditionalWrapperProps = PropsWithChildren<{
  condition: boolean;
  hideVisually?: boolean;
}>;

export const ConditionalWrapper = ({ children, hideVisually, condition }: ConditionalWrapperProps) => {
  if (hideVisually) return <div style={{ display: condition ? 'flex' : 'none' }}>{children}</div>;

  return <>{condition && children}</>;
};
