import { Spin } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { DataTable, MoreOptionsCell, SearchInput, Spacer, StatusCell, TextCell } from 'components';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { EmptyDataListComponent } from 'components/EmptyDataListComponent/EmptyDataListComponent';
import { ConditionalWrapper } from 'components/ShowWrapper/ConditionalWrapper';
import { stopPropagationOnCell } from 'constants/common';
import { text } from 'constants/texts';
import { useChildrenRef, usePermissionsHandler, useSearch } from 'hooks';
import { useMilestoneTrackers } from 'hooks/api/milestoneTrackers/useMilestoneTrackers';
import { CreateMilestoneTrackerModal } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/MilestoneTrackers/components/CreateMilestoneTrackerModal/CreateMilestoneTrackerModal';
import { useMilestoneTrackerOptions } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/MilestoneTrackers/hooks/useMilestoneTrackerOptions';
import { useGoToMilestoneTrackerDetailsPage } from 'routes/navigators';
import { MilestoneTrackerStatus } from 'services/milestoneTrackers/types';
import { MilestoneTracker, MilestoneTrackerStep } from 'types/milestoneTracker';
import { ProgramData } from 'types/programs';
import { getFontClassName } from 'utils/getFontClassName';
import { getCountableText, stringModifier } from 'utils/strings';

const MilestoneTrackerStatusColumn = ({ tracker }: { tracker: MilestoneTracker }) => {
  const [isEditMilestoneTrackerAllowed] = usePermissionsHandler(['milestone_trackers.edit']);

  return (
    <StatusCell<MilestoneTrackerStatus>
      status={tracker.status || 'DRAFT'}
      statuses={[
        { key: 'DRAFT', label: text['Draft'] },
        { key: 'IN_USE', label: text['In Use'] },
      ]}
      disabled={!isEditMilestoneTrackerAllowed}
    />
  );
};

const MilestoneTrackerMoreButtonColumn = ({ tracker }: { tracker: MilestoneTracker }) => {
  const { options, isLoading } = useMilestoneTrackerOptions(tracker);

  return (
    <Spin size='small' spinning={isLoading}>
      <MoreOptionsCell options={options} />
    </Spin>
  );
};

const columns: ColumnsType<MilestoneTracker> = [
  {
    width: '50%',
    title: text['Milestone Tracker Name'],
    dataIndex: 'name',
    sorter: (a, b) => a.name.localeCompare(b.name),
    showSorterTooltip: false,
    sortDirections: ['ascend', 'ascend'],
    render: (value) => <TextCell color='blue' className={getFontClassName('button')} text={value} />,
  },
  {
    width: '30%',
    title: text['Steps'],
    dataIndex: 'milestones',
    render: (value: MilestoneTrackerStep[]) => (
      <TextCell
        color='gray2'
        className={getFontClassName('paragraph')}
        text={getCountableText(value.length, text['{0} Step'])}
      />
    ),
  },
  {
    width: '20%',
    title: text['Status'],
    dataIndex: 'status',
    render: (_, record) => <MilestoneTrackerStatusColumn tracker={record} />,
    onCell: stopPropagationOnCell,
  },
  {
    title: '',
    dataIndex: 'showMore',
    render: (_, record) => <MilestoneTrackerMoreButtonColumn tracker={record} />,
    onCell: stopPropagationOnCell,
  },
];

type MilestoneTrackersContentProps = {
  program?: ProgramData;
};

export const MilestoneTrackersContent = ({ program }: MilestoneTrackersContentProps) => {
  const [isCreateMilestoneTrackerAllowed] = usePermissionsHandler(['milestone_trackers.create']);
  const [modalRef, setModalRef] = useChildrenRef<ClosableWrapperRef>();

  const { keyword, handleSearch, debouncedSearchKeyword } = useSearch();
  const { milestoneTrackers, hasData, isLoading } = useMilestoneTrackers({
    name: debouncedSearchKeyword,
  });

  const goToMilestoneTrackerDetailsPage = useGoToMilestoneTrackerDetailsPage();

  return (
    <>
      <Spacer height={24} type='flex' />
      <ConditionalWrapper condition={hasData}>
        <SearchInput value={keyword} onChange={handleSearch} />
        <Spacer height={12} type='flex' />
        <DataTable<MilestoneTracker>
          loading={isLoading}
          rowKey='uuid'
          columns={columns}
          dataSource={milestoneTrackers}
          onRow={(record) => ({
            onClick: () => goToMilestoneTrackerDetailsPage(record.uuid),
          })}
        />
      </ConditionalWrapper>
      <ConditionalWrapper condition={!hasData} hideVisually>
        <EmptyDataListComponent
          title={stringModifier(text["This program doesn't have any {0} created"], text['Milestone Trackers'])}
          description={stringModifier(text['Click the button below to add new {0}'], text['Milestone Tracker'])}
          addButtonText={stringModifier(text['Add {0}'], text['Milestone Tracker'])}
          addButtonDisabled={!isCreateMilestoneTrackerAllowed}
          onButtonPress={modalRef?.open}
        />
        <CreateMilestoneTrackerModal setModalRef={setModalRef} modalRef={modalRef} program={program} />
      </ConditionalWrapper>
    </>
  );
};
