import { FormProps, Spin } from 'antd';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { EditableButtons } from 'components/EditableButtons/EditableButtons';
import { text } from 'constants/texts';
import { useFinancialAssistance, useUpdateFinancialAssistance } from 'hooks/api/financialAssistance';
import { useCreateFinancialAssistance } from 'hooks/api/financialAssistance/useCreateFinancialAssistance';
import { useCoverProgramEditStatusCallbacks } from 'pages/ProgramDetails/hooks/useCoverProgramEditStatusCallbacks';
import { CopayFinancialAssistanceFields } from 'pages/ProgramDetails/programTabComponents/FinancialAssistance/tabsConfig/CopayTab/types';
import { showCancelEditedToast } from 'pages/ProgramDetails/utils';
import { useRef, useState } from 'react';
import { FinancialAssistanceUseTabsReturnType } from '../types';
import { CopayTab, CopayTabRef } from './CopayTab';
import { PermissionWrapper } from 'components';

export const useCopayTab = (): FinancialAssistanceUseTabsReturnType<{ isCopayEditMode: boolean }> => {
  const contentRef = useRef<CopayTabRef | null>(null);
  const cancelModalRef = useRef<ClosableWrapperRef>(null);
  const saveModalRef = useRef<ClosableWrapperRef>(null);

  const [isEditMode, setEditMode] = useState(false);

  const {
    onSave,
    onEdit,
    onClose,
    isLoading: isEditStatusLoading,
  } = useCoverProgramEditStatusCallbacks({
    onEdit: () => setEditMode(true),
    onClose: () => setEditMode(false),
    onSave: () => setEditMode(false),
  });

  const { financialAssistance, isLoading: isGetLoading, isEmpty } = useFinancialAssistance<true>('COPAY');

  const { isLoading: isUpdateLoading, updateCopayFinancialAssistance } = useUpdateFinancialAssistance('COPAY', onSave);
  const { isLoading: isCreateLoading, createCopayFinancialAssistance } = useCreateFinancialAssistance('COPAY', onSave);

  const isFinancialAssistanceLoading = isCreateLoading || isUpdateLoading || isGetLoading;

  const onFinish: FormProps<CopayFinancialAssistanceFields>['onFinish'] = (values) => {
    (isEmpty ? createCopayFinancialAssistance : updateCopayFinancialAssistance)(values, financialAssistance.uuid);
  };

  return {
    tab: {
      title: text['Co-pay'],
      tabKey: 'copay',
      tabData: {
        content: (
          <Spin size='large' spinning={isFinancialAssistanceLoading}>
            <CopayTab
              onFinish={onFinish}
              financialAssistance={financialAssistance}
              ref={contentRef}
              isEditMode={isEditMode}
            />
          </Spin>
        ),
        actions: (
          <PermissionWrapper permissionsRequired={['copay.edit']}>
            <EditableButtons
              isEditMode={isEditMode}
              onEdit={onEdit}
              onSave={saveModalRef.current?.open}
              onCancel={cancelModalRef.current?.open}
              isLoading={isEditStatusLoading}
              saveModal={{
                ref: saveModalRef.current,
                setRef: (ref) => (saveModalRef.current = ref),
                onConfirm: contentRef.current?.submit,
              }}
              cancelModal={{
                ref: cancelModalRef.current,
                setRef: (ref) => (cancelModalRef.current = ref),
                onConfirm: () => {
                  showCancelEditedToast();
                  contentRef.current?.reset();
                  onClose();
                },
              }}
            />
          </PermissionWrapper>
        ),
      },
    },
    extra: {
      isCopayEditMode: isEditMode,
    },
  };
};
