import './style.scss';

export type VideoViewerProps = { source: string };

export const VideoViewer = ({ source }: VideoViewerProps) => {
  return (
    <div className='video-viewer-wrapper'>
      <video controls preload='metadata' className='video-viewer-window'>
        <source src={source} type='video/mp4' />
        Sorry, your browser does not support embedded videos.
      </video>
    </div>
  );
};
