import { ReactNode } from 'react';
import { Callback } from 'types/helpers';
import './style.scss';

type IconButtonProps = {
  icon: ReactNode;
  onPress: Callback;
};

export const IconButton = ({ icon, onPress }: IconButtonProps) => (
  <div onClick={onPress} className='pl-icon-button'>
    {icon}
  </div>
);
