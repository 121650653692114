import { Spin } from 'antd';
import { useProgramData } from 'hooks/api/';
import { useAppTabs } from 'hooks/appTabs/useAppTabs';
import { PageLayout } from 'layouts';
import { MilestoneTrackerDetails } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/MilestoneTrackers/MilestoneTrackerDetails/MilestoneTrackerDetails';
import { useDetailsTabChangeHandler } from 'pages/ProgramDetails/useTabChangeHandler';
import { useSearchParams } from 'react-router-dom';
import {
  MILESTONE_TRACKER_ID_PARAM,
  NOTIFICATION_TILE_ID_PARAM,
  RULE_ID_PARAM,
  SPECIALTY_PHARMACY_ID_PARAM,
  SURVEY_ID_PARAM,
} from 'routes/navigators';

import { getProgramPagesBreadCrumb } from '../Programs/breadCrumbs';
import { statusRenderer } from '../shared';
import { BusinessRuleDetails } from './programTabComponents/BusinessRules/BusinessRuleDetails/BusinessRuleDetails';
import { SurveyDetails } from './programTabComponents/Content/ContentTabComponents/Surveys/SurveyDetails/SurveyDetails';
import { SpecialtyPharmacyDetails } from './programTabComponents/SpecialtyPharmacies/SpecialtyPharmacyDetails/SpecialtyPharmacyDetails';
import { useProgramDetailsTabs } from './tabsConfig/root';
import { NotificationTileDetails } from './programTabComponents/Content/ContentTabComponents/NotificationTiles/NotificationTileDetails/NotificationTileDetails';

export const ProgramDetails = () => {
  const { program, isLoading } = useProgramData();
  const [params] = useSearchParams();

  const { tabs, extra } = useProgramDetailsTabs({ program });

  const { selectedTab, onTabChange } = useDetailsTabChangeHandler();

  const {
    description,
    selectedTabData: { contentJSX, actionsJSX, contentTitle },
    tabsJSX,
  } = useAppTabs({
    tabs,
    disabled: extra.isTabsDisabled,
    onTabChange,
    initialTabKey: selectedTab || 'universal',
  });

  if (!program || isLoading) return <Spin className='pl-page-spinner' size='large' />;

  const breadCrumbs = getProgramPagesBreadCrumb({ name: 'PROGRAM_DETAILS', programName: program.programName });

  const pageTitleJSX = (
    <>
      {program.programName}
      {statusRenderer(program.status)}
    </>
  );

  if (params.get(RULE_ID_PARAM)) return <BusinessRuleDetails />;
  if (params.get(SPECIALTY_PHARMACY_ID_PARAM)) return <SpecialtyPharmacyDetails />;
  if (params.get(SURVEY_ID_PARAM)) return <SurveyDetails />;
  if (params.get(MILESTONE_TRACKER_ID_PARAM)) return <MilestoneTrackerDetails />;
  if (params.get(NOTIFICATION_TILE_ID_PARAM)) return <NotificationTileDetails />;

  return (
    <PageLayout pageTitle={pageTitleJSX} crumbs={breadCrumbs} topSection={tabsJSX} rightSideActions={actionsJSX}>
      {!!contentTitle && <h2 className='page-content-title'>{contentTitle}</h2>}
      {!!description && <h3 className='page-content-description'>{description}</h3>}
      {contentJSX}
    </PageLayout>
  );
};
