import { Spin } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { DataTable, SearchInput, Spacer } from 'components';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { EmptyDataListComponent } from 'components/EmptyDataListComponent/EmptyDataListComponent';
import { stopPropagationOnCell } from 'constants/common';
import { text } from 'constants/texts';

import { useChildrenRef, usePermissionsHandler, useSearch } from 'hooks';
import { useMedias } from 'hooks/api/programs/useMedias';

import { SortIcon } from 'icons';
import { CreateMediaModal } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Media/components/CreateMediaModal/CreateMediaModal';

import { MediaStatusActions } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Media/components/MediaStatusActions';
import { MediaUpdateActions } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Media/components/MediaUpdateActions';
import {
  ViewMediaModal,
  ViewMediaModalRef,
} from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Media/components/ViewMediaModal';
import { filterMediaType } from 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Media/constants';

import 'pages/ProgramDetails/programTabComponents/Content/ContentTabComponents/Media/style.scss';
import { mediaTypeTextFormatter } from 'pages/shared/renderers';
import { useEffect, useRef } from 'react';
import { Media, ProgramData } from 'types/programs';
import { stringModifier } from 'utils/strings';

export const columns: ColumnsType<Media> = [
  {
    width: '56%',
    fixed: 'left',
    title: text['Media Name'],
    dataIndex: 'mediaName',
    className: 'name-text',
    defaultSortOrder: 'ascend',
    sorter: (a, b) => a.mediaName.localeCompare(b.mediaName),
  },
  {
    width: '27%',
    filterMode: 'tree',
    filterMultiple: true,
    title: text['Media Type'],
    dataIndex: 'mediaType',
    filters: filterMediaType,
    filterIcon: <SortIcon />,
    render: mediaTypeTextFormatter,
    onFilter: (value, record) => record.mediaType.toLowerCase().includes((value as string).toLowerCase()),
  },
  {
    width: '14%',
    title: text['Status'],
    dataIndex: 'status',
    render: () => <MediaStatusActions />,
    onCell: stopPropagationOnCell,
  },
  {
    title: '',
    dataIndex: 'showMore',
    render: (_, record: Media) => <MediaUpdateActions media={record} />,
    onCell: stopPropagationOnCell,
  },
];

type MediaTabContentProps = {
  program?: ProgramData;
};

export const MediaTabContent = ({ program }: MediaTabContentProps) => {
  const [isCreateMediaAllowed] = usePermissionsHandler(['media_data.create']);
  const { keyword, handleSearch, debouncedSearchKeyword, previousDebouncedSearchKeyword } = useSearch();
  const [modalRef, setModalRef] = useChildrenRef<ClosableWrapperRef>();
  const viewMediaRef = useRef<ViewMediaModalRef>(null);

  const { data, isLoading, refetchMedias, isRefetching, showResults } = useMedias({
    programId: program?.id || '',
    search: debouncedSearchKeyword,
  });

  useEffect(() => {
    if (previousDebouncedSearchKeyword !== debouncedSearchKeyword) refetchMedias();
  }, [debouncedSearchKeyword, previousDebouncedSearchKeyword]);

  if (!program) return null;

  return (
    <Spin size='large' spinning={isLoading}>
      <div className='media-content'>
        <Spacer height={8} />
        {showResults ? (
          <>
            <SearchInput value={keyword} onChange={handleSearch} className='media-content-search' />
            <DataTable
              loading={isLoading || isRefetching}
              rowKey='id'
              columns={columns}
              dataSource={data}
              noDataContent={stringModifier(
                text['We couldn’t find any {0} that fit your search criteria.'],
                text['Media']
              )}
              onRow={(record) => ({ onClick: () => viewMediaRef.current?.open(record) })}
            />
          </>
        ) : (
          <EmptyDataListComponent
            title={stringModifier(text["This program doesn't have any {0} uploaded."], text['Media'])}
            description={stringModifier(text['Click the button below to add new {0}'], text['Media'])}
            addButtonText={stringModifier(text['Add {0}'], text['Media'])}
            addButtonDisabled={!isCreateMediaAllowed}
            onButtonPress={modalRef?.open}
          />
        )}
      </div>
      <ViewMediaModal ref={viewMediaRef} />
      <CreateMediaModal setModalRef={setModalRef} modalRef={modalRef} program={program} />
    </Spin>
  );
};
