import { useFirstMountEffect } from 'hooks/useFirstMountEffect';
import { ProgramTabs } from 'pages/ProgramDetails/tabsConfig/types';
import { useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';

export const PROGRAM_TAB_URL_KEY = 'program-tab';
export const PROGRAM_CONTENT_TAB_URL_KEY = 'content-tab';
export const PATIENT_SEARCH_TAB_URL_KEY = 'patient-search-tab';
export const FINANCIAL_ASSISTANCE_TAB_URL_KEY = 'financial-assistance-tab';

const useSetInitialTab = (selectedTab: string | null, setTab: () => void) =>
  useFirstMountEffect(() => {
    !selectedTab && setTab();
  }, [selectedTab]);

export const useTabChangeHandler = (
  tabKey:
    | typeof PROGRAM_TAB_URL_KEY
    | typeof PROGRAM_CONTENT_TAB_URL_KEY
    | typeof PATIENT_SEARCH_TAB_URL_KEY
    | typeof FINANCIAL_ASSISTANCE_TAB_URL_KEY,
  initialKey: string,
  additionalParams?: Record<string, string>
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTab = searchParams.get(tabKey);

  const onTabChange = useCallback(
    (key: string) => setSearchParams({ ...additionalParams, [tabKey]: key }),
    [additionalParams, setSearchParams, tabKey]
  );

  useSetInitialTab(selectedTab, () => onTabChange(initialKey));

  return { selectedTab, onTabChange };
};

export const useDetailsTabChangeHandler = () => useTabChangeHandler(PROGRAM_TAB_URL_KEY, 'universal');

export const useContentTabChangeHandler = () =>
  useTabChangeHandler(PROGRAM_CONTENT_TAB_URL_KEY, 'medias', {
    [PROGRAM_TAB_URL_KEY]: 'content' as Extract<ProgramTabs, 'content'>,
  });

export const useFinancialAssistanceTabChangeHandler = () =>
  useTabChangeHandler(PROGRAM_CONTENT_TAB_URL_KEY, 'copay', {
    [PROGRAM_TAB_URL_KEY]: 'financial-assistance' as Extract<ProgramTabs, 'financial-assistance'>,
  });
