import { Row } from 'antd';
import { Spacer } from 'components';
import { useAppTabs } from 'hooks/appTabs/useAppTabs';
import { useFinancialAssistanceTabs } from 'pages/ProgramDetails/programTabComponents/FinancialAssistance/tabsConfig/root';
import { useFinancialAssistanceTabChangeHandler } from 'pages/ProgramDetails/useTabChangeHandler';
import React from 'react';

export const FinancialAssistanceContent = () => {
  const { tabs, extra } = useFinancialAssistanceTabs();

  const { selectedTab, onTabChange } = useFinancialAssistanceTabChangeHandler();

  const {
    tabsJSX,
    selectedTabData: { content, actions },
  } = useAppTabs({
    tabs,
    disabled: extra.isTabsDisabled,
    initialTabKey: selectedTab || 'copay',
    onTabChange,
  });

  return (
    <>
      <Spacer height={32} />
      <Row justify='space-between' align='middle'>
        {tabsJSX}
        {actions}
      </Row>
      {content}
    </>
  );
};
