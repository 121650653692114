import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { Spacer } from 'components';
import { getFieldsJSX } from 'components/fields/utils';
import { scrollToFirstErrorProps } from 'constants/common';
import { text } from 'constants/texts';
import { getSurveyNameField } from './constants';
import { DynamicForm } from './DynamicForm';
import { CommunicationSurvey, CommunicationSurveyFields } from './types';
import { stringModifier } from 'utils/strings';

export type SurveyDetailsContentProps = {
  survey: CommunicationSurvey;
};

export const CommunicationSurveyDetailsContent = ({ survey }: SurveyDetailsContentProps) => {
  const [form] = useForm<CommunicationSurveyFields>();

  return (
    <>
      <h1 className='page-content-title'>{stringModifier(text['{0} Details'], text['Survey'])}</h1>
      <h2 className='page-content-description'>
        {stringModifier(text['These are the details of the {0}'], text['Survey'])}
      </h2>
      <Spacer height={24} type='flex' />
      <Form<CommunicationSurveyFields>
        scrollToFirstError={scrollToFirstErrorProps}
        layout='vertical'
        form={form}
        initialValues={survey}
      >
        {getFieldsJSX(getSurveyNameField(), false)}
        <Form.List name='questions'>
          {(fields) => (
            <>
              {fields.map(({ key, name }) => (
                <DynamicForm key={key} orderIndex={name} />
              ))}
            </>
          )}
        </Form.List>
      </Form>
    </>
  );
};
