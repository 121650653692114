import { PropsWithChildren } from 'react';
import { Permissions } from '../types';
import { getIsAllowedPermission, useAuth } from 'hooks';

type PermissionWrapperProps = PropsWithChildren<{
  permissionsRequired: Permissions[];
}>;

export const PermissionWrapper = ({ permissionsRequired = ['logout'], children }: PermissionWrapperProps) => {
  const { permissions } = useAuth();

  return getIsAllowedPermission(permissionsRequired, permissions) ? <>{children}</> : null;
};
