import { toSurvey } from 'hooks/api/surveys/mappers';
import { useQuery } from 'react-query';
import { queryKeys } from 'services/constants';
import { getSurveyList } from 'services/surveys/requests';
import { errorHandler } from 'utils/errorHandler';
import { useProgramData } from '../programs/useGetProgram';

export const useSurveys = (search = '') => {
  const { program: { id = '' } = {} } = useProgramData();

  const {
    data,
    isLoading,
    refetch: refetchSurveys,
    isRefetching,
  } = useQuery([queryKeys.getSurveys, id, search], () => getSurveyList({ search, programId: id }), {
    select: (data) => data.results.map(toSurvey),
    onError: errorHandler,
    enabled: !!id,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  return {
    surveys: data || [],
    isLoading: isLoading,
    hasData: !!data?.length || !!search || isLoading || isRefetching,
    refetchSurveys,
    isRefetching,
  };
};
