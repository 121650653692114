import { useQuery } from 'react-query';
import { queryKeys } from 'services/constants';
import { getPatientList } from 'services/patientSearch/requests';
import { toCommunication } from './mappers';

export const usePatientCommunication = (email: string) => {
  const {
    data: patientCommunications = [],
    isLoading,
    refetch,
    isRefetching,
  } = useQuery([queryKeys.getPatientCommunications, email], () => getPatientList(email), {
    enabled: !!email,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => data?.map(toCommunication),
  });

  return {
    refetch,
    isLoading,
    isRefetching,
    patientCommunications,
    hasData: !!patientCommunications.length && !!email,
  };
};
