import { SVGProps } from 'react';

export const SortIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width='24' height='21' viewBox='0 0 24 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M23 1H1L9.8 10.9105V17.7619L14.2 19.8571V10.9105L23 1Z'
      stroke='black'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
