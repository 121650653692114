import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

export const useMountEffect = (callback: EffectCallback, deps: DependencyList, resetList?: boolean[]) => {
  const mounted = useRef(false);

  const needReset = resetList?.some(Boolean);

  useEffect(() => {
    if (!mounted.current && !needReset) {
      mounted.current = true;
      return;
    }

    if (needReset) {
      mounted.current = false;
      return;
    }

    return callback();
  }, deps);
};
