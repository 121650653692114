import { Form, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { Spacer } from 'components';
import { getFieldsJSX } from 'components/fields/utils';
import { scrollToFirstErrorProps } from 'constants/common';
import { text } from 'constants/texts';
import { useProgramData } from 'hooks/api';
import { PageLayout } from 'layouts';
import { useCoverProgramEditStatusCallbacks } from 'pages/ProgramDetails/hooks/useCoverProgramEditStatusCallbacks';
import { getProgramPagesBreadCrumb } from 'pages/Programs/breadCrumbs';
import { getFontClassName } from 'utils/getFontClassName';
import { useNotificationTile } from 'hooks/api/notificationTiles/useNotificationTile';
import { useUpdateNotificationTile } from 'hooks/api/notificationTiles/useUpdateNotificationTile';
import { UpdateNotificationTileFields } from '../types';
import { NotificationTileDetailsActions } from './NotificationTileDetailsActions';
import { updateNotificationTileFields } from './fields';
import { isLinkValid } from 'utils/validations';
import { stringModifier } from 'utils/strings';

const { useForm, useWatch } = Form;

export const NotificationTileDetails = () => {
  const [form] = useForm<UpdateNotificationTileFields>();
  const externalLink = useWatch('externalLink', form);

  const [isEditMode, setEditMode] = useState(false);

  useEffect(() => {
    isEditMode && !externalLink && form.setFields([{ name: 'externalLinkName', value: '' }]);
  }, [form, externalLink, isEditMode]);

  const { notificationTile, isLoading: isGetNotificationTileLoading } = useNotificationTile();

  const { program, isLoading: isProgramLoading } = useProgramData();

  const { onSave } = useCoverProgramEditStatusCallbacks({ onSave: () => setEditMode(false) });

  const { updateNotificationTile, isLoading: isUpdateNotificationTileLoading } = useUpdateNotificationTile(onSave);

  const isLoading = isGetNotificationTileLoading || isProgramLoading;

  if (isLoading) return <Spin className='pl-page-spinner' size='large' />;

  if (!program || !notificationTile) return null;

  const breadCrumbs = getProgramPagesBreadCrumb({
    name: 'NOTIFICATION_TILE_DETAILS',
    programName: program.programName,
    programId: program.id,
    notificationTileName: notificationTile.name,
  });

  const rightSideActionsJSX = (
    <NotificationTileDetailsActions isEditMode={isEditMode} setEditMode={setEditMode} form={form} />
  );

  return (
    <PageLayout
      pageTitle={notificationTile.name || text['Name not found']}
      rightSideActions={rightSideActionsJSX}
      crumbs={breadCrumbs}
    >
      <Spin size='large' spinning={isUpdateNotificationTileLoading}>
        <h2 className={getFontClassName('h2')}>{stringModifier(text['{0} Details'], text['Notification Tiles'])}</h2>
        <Spacer height={6} type='flex' />
        <p className={getFontClassName('paragraph', 'gray2')}>
          {stringModifier(text['These are the details of the {0}'], text['Notification Tiles'])}
        </p>
        <Spacer height={22} type='flex' />
        <Form<UpdateNotificationTileFields>
          scrollToFirstError={scrollToFirstErrorProps}
          initialValues={notificationTile}
          layout='vertical'
          form={form}
          onFinish={(values) => updateNotificationTile(values, notificationTile)}
        >
          {getFieldsJSX(updateNotificationTileFields({ externalLinkAdded: isLinkValid(externalLink) }), isEditMode)}
        </Form>
      </Spin>
    </PageLayout>
  );
};
