import { Form, FormProps } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { Spacer } from 'components';
import { getFieldsJSX } from 'components/fields/utils';
import { scrollToFirstErrorProps } from 'constants/common';
import { useMountEffect } from 'hooks/useMountEffect';
import { useUpdateFormEffect } from 'hooks/useUpdateFormEffect';
import { getFinancialAssistanceCopaySections } from 'pages/ProgramDetails/programTabComponents/FinancialAssistance/tabsConfig/CopayTab/sections';
import { CopayFinancialAssistanceFields } from 'pages/ProgramDetails/programTabComponents/FinancialAssistance/tabsConfig/CopayTab/types';
import { forwardRef, ForwardRefRenderFunction, Fragment, useImperativeHandle } from 'react';
import { CopayFinancialAssistance } from 'types/financialAssistance';
import { Callback } from 'types/helpers';

type CopayTabProps = {
  isEditMode: boolean;
  financialAssistance: CopayFinancialAssistance;
  onFinish: FormProps<CopayFinancialAssistanceFields>['onFinish'];
};

export type CopayTabRef = {
  submit: Callback;
  reset: Callback;
};

const CopayTabComponent: ForwardRefRenderFunction<CopayTabRef, CopayTabProps> = (
  { isEditMode, financialAssistance, onFinish },
  ref
) => {
  const [form] = Form.useForm<CopayFinancialAssistanceFields>();

  const selectedCriteria = useWatch('terminationCriteria', form);

  useMountEffect(
    () => {
      form.setFields([{ name: 'terminationPayload', value: undefined, errors: [] }]);
    },
    [selectedCriteria],
    [!selectedCriteria]
  );

  useImperativeHandle(ref, () => ({ submit: form.submit, reset: form.resetFields }));

  useUpdateFormEffect(form, financialAssistance, [financialAssistance]);

  return (
    <Form<CopayFinancialAssistanceFields>
      form={form}
      className='form'
      layout='vertical'
      onFinish={onFinish}
      scrollToFirstError={scrollToFirstErrorProps}
      initialValues={financialAssistance}
    >
      {getFinancialAssistanceCopaySections(financialAssistance, selectedCriteria, form).map(({ title, fields }) => (
        <Fragment key={title}>
          <h1 className='form-title'>{title}</h1>
          <Spacer height={24} type='flex' />
          {getFieldsJSX(fields, isEditMode)}
        </Fragment>
      ))}
    </Form>
  );
};

export const CopayTab = forwardRef(CopayTabComponent);
