import axios, { AxiosError } from 'axios';
import { showCustomToast } from 'components';

export const axiosErrorHandler = <T>(
  error: unknown,
  axiosCallback: (error: AxiosError<T>) => void,
  callback?: (error: unknown) => void
) => {
  if (axios.isAxiosError(error)) axiosCallback(error as AxiosError<T>);
  else callback?.(error);
};

export const showErrorMessage = (error: string) =>
  showCustomToast({
    message: error,
    type: 'error',
  });

export class DevError extends Error {
  constructor(message?: string) {
    super(message);
  }
}

export const isHexColorErrorMessage = 'Please enter a valid hex code!';
