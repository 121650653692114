import { Dropdown, Menu } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { statusRendererWithCustomText } from 'pages/shared/renderers';
import { ProgramStatus } from 'types/union-types';

type StatusCellProps<T extends string> = {
  status: T;
  statuses: {
    key: T;
    label: string;
    disabled?: boolean;
  }[];
  changeStatus?: (newStatus: T) => void;
  isLoading?: boolean;
  disabled?: boolean;
};

export const StatusCell = <T extends string>({
  statuses,
  status,
  changeStatus,
  isLoading,
  disabled,
}: StatusCellProps<T>) => {
  const changeStatusButtonAction: ItemType[] = statuses
    .filter((item) => item.key !== status)
    .map((status) => ({
      ...status,
      onClick: () => changeStatus?.(status.key),
    }));

  const selectedStatusText = statuses.find(({ key }) => key === status)?.label;

  return (
    <Dropdown
      disabled={disabled || isLoading || !changeStatus}
      overlay={<Menu items={changeStatusButtonAction} />}
      placement='topRight'
    >
      {statusRendererWithCustomText(status as ProgramStatus, selectedStatusText, isLoading)}
    </Dropdown>
  );
};
