import { AppField, AppFields } from 'components/fields/types';
import { emptyField, fieldModifier } from 'components/fields/utils';
import { text } from 'constants/texts';

type CommunicationQuestionsField = {
  title: string;
  description: string;
  question_type: string;
};

type MainQuestionFields = Pick<CommunicationQuestionsField, 'title' | 'description' | 'question_type'>;

export const getSurveyNameField = (): AppFields<{ title: string }> => [
  [communicationSurveyNameField, fieldModifier(emptyField, { emptyKey: 'surveyNameEmptyField' })],
];

export const getMainQuestionFields = (orderIndex: number): AppFields<MainQuestionFields> => [
  fieldModifier(communicationSurveyQuestionField, { name: [orderIndex, 'title'] }),
];

export const communicationSurveyNameField: AppField<{ title: string }> = {
  type: 'input',
  name: 'title',
  label: text['Survey Name'],
  tooltip: text['Survey Name'], //TODO:  Add tooltips
};

export const communicationSurveyQuestionField: AppField<Pick<CommunicationQuestionsField, 'title'>> = {
  rows: 4,
  type: 'input',
  name: 'title',
  renderType: 'TextArea',
  label: text['Survey Question'],
  tooltip: text['Survey Question'], //TODO:  Add tooltips
};
