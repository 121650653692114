import { ColumnsType } from 'antd/es/table';

import { Button, DataTable, PermissionWrapper, SearchInput } from 'components';
import { ClosableWrapperRef } from 'components/ClosableWrapper/ClosableWrapper';
import { stopPropagationOnCell } from 'constants/common';
import { format } from 'date-fns';
import { useSearch } from 'hooks';
import { usePrograms } from 'hooks/api';
import { PageLayout } from 'layouts';
import { useEffect, useState } from 'react';
import { useGoToProgramDetailsPage } from 'routes/navigators';

import { ProgramData } from 'types/programs';
import { statusRenderer } from '../shared';
import { getProgramPagesBreadCrumb } from './breadCrumbs';
import { CreateProgramModal } from './components/CreateProgramModal/CreateProgramModal';
import { ProgramRowActions } from './components/RowActions';

import './style.scss';
import { text } from 'constants/texts';
import { stringModifier } from 'utils/strings';

export const columns: ColumnsType<ProgramData> = [
  {
    title: text['Program Name'],
    dataIndex: 'programName',
    sorter: (a, b) => a.programName.localeCompare(b.programName),
    defaultSortOrder: 'ascend',
  },
  {
    title: text['Manufacturer'],
    dataIndex: 'manufacturerName',
  },
  {
    title: text['Registered Patients'],
    dataIndex: 'registeredPatients',
  },
  {
    title: text['Start Date'],
    dataIndex: 'startDate',
    render: (record: Date | null) => (record ? format(record, 'MMM dd,yyyy') : '-'),
  },
  {
    title: text['Status'],
    dataIndex: 'status',
    render: statusRenderer,
  },
  {
    title: '',
    dataIndex: 'showMore',
    render: (_, record: ProgramData) => <ProgramRowActions program={record} />,
    onCell: stopPropagationOnCell,
  },
];

export const Programs = () => {
  const { keyword, handleSearch, debouncedSearchKeyword, previousDebouncedSearchKeyword } = useSearch();
  const { data, isLoading, refetch, isRefetching } = usePrograms(debouncedSearchKeyword);

  useEffect(() => {
    if (previousDebouncedSearchKeyword !== debouncedSearchKeyword) refetch();
  }, [debouncedSearchKeyword, previousDebouncedSearchKeyword]);

  const [modal, setModal] = useState<ClosableWrapperRef>(null);

  const goToProgramDetailsPage = useGoToProgramDetailsPage();

  const handleRowClick = (record: ProgramData) => goToProgramDetailsPage(record.id);

  const breadCrumbs = getProgramPagesBreadCrumb({ name: 'PROGRAM_LIST' });

  const rightSideActionsJSX = (
    <PermissionWrapper permissionsRequired={['programs.create']}>
      <Button onClick={modal?.open}>{text['Create New Program']}</Button>
      <CreateProgramModal onModalRef={setModal} modalRef={modal} />
    </PermissionWrapper>
  );

  return (
    <PageLayout rightSideActions={rightSideActionsJSX} crumbs={breadCrumbs} pageTitle={text['All Programs']}>
      <div className='programs-content'>
        <h2 className='page-content-title'>{text['Programs']}</h2>
        <h3 className='page-content-description'>
          {text['Below is a list of the programs associated with this client in the system']}
        </h3>
        <SearchInput value={keyword} onChange={handleSearch} className='programs-content-search' />
        <DataTable
          onRow={(record) => ({ onClick: () => handleRowClick(record) })}
          loading={isLoading || isRefetching}
          rowKey='id'
          columns={columns}
          dataSource={data}
          noDataContent={stringModifier(
            text['We couldn’t find any {0} that fit your search criteria.'],
            text['Programs']
          )}
        />
      </div>
    </PageLayout>
  );
};
