import { SVGProps } from 'react';

export const WarningVector = (props: SVGProps<SVGSVGElement>) => (
  <svg width='18' height='16' viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M8.99997 6.4608V9.5394' stroke='#654A10' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M7.93789 1.53506L1.16499 13.2337C1.05709 13.4206 1.00019 13.6325 1 13.8483C0.999809 14.0641 1.05633 14.2762 1.1639 14.4633C1.27146 14.6503 1.4263 14.8059 1.6129 14.9143C1.7995 15.0226 2.01131 15.0801 2.2271 15.0809H15.7729C15.9887 15.0801 16.2005 15.0226 16.3871 14.9143C16.5737 14.8059 16.7285 14.6503 16.8361 14.4633C16.9437 14.2762 17.0002 14.0641 17 13.8483C16.9998 13.6325 16.9429 13.4206 16.835 13.2337L10.0621 1.53506C9.95502 1.34793 9.8004 1.19242 9.61389 1.08426C9.42738 0.976105 9.2156 0.919144 9 0.919144C8.7844 0.919144 8.57262 0.976105 8.38611 1.08426C8.1996 1.19242 8.04497 1.34793 7.93789 1.53506V1.53506Z'
      stroke='#654A10'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.00009 13.0798C9.42515 13.0798 9.76973 12.7352 9.76973 12.3101C9.76973 11.8851 9.42515 11.5405 9.00009 11.5405C8.57502 11.5405 8.23044 11.8851 8.23044 12.3101C8.23044 12.7352 8.57502 13.0798 9.00009 13.0798Z'
      fill='#654A10'
    />
  </svg>
);
